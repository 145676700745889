import { useEffect, useState } from 'react';
import { SECOND } from '@constants/dates';

export const useReactiveDate = (): Date => {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const timer =  setTimeout(() => setCurrentDate(new Date()), SECOND)
    return () => clearTimeout(timer);
  }, [currentDate])

  return currentDate
}
