import './FireStarterNFTs.scss'
import { NFTCard, RoundButton, Timer } from '@components';
import { Container } from 'react-bootstrap';
import {
  dueDateForHiroNFT,
  OpenSeaFlareNFTCollection,
  OpenSeaHiroNFTCollection
} from '@constants';
import { useTimeLeft } from '@hooks/useTimeLeft';
import { useNFTs } from '@contracts/hooks/useNFTs';
import { numberToCurrency } from '@utils/balanceFormatter';
import classNames from 'classnames';
import { firestarterNFTs } from '@/mocks/NFTs';
import { formatISO9075 } from 'date-fns';
import { useNetwork } from '@firestarter-private/firestarter-library';

const mockedHiro = firestarterNFTs[1]
export const FireStarterNFTs = () => {
  const {
    flareToken,
    hiroToken,
  } = useNFTs()
  const timeLeftToHiro = useTimeLeft(dueDateForHiroNFT)

  const { isDefaultEVMNetworkSelected } = useNetwork()

  return (
    <>
      <section className='flare-section fs-nft-section'>
        <Container>
          <div className="d-flex d-lg-none justify-content-center mb-5">
            {
              !!flareToken && (
                <NFTCard
                  name={flareToken?.metadata?.name as string}
                  id={flareToken?.metadata?.id as string}
                  image={flareToken?.metadata?.image as string}
                />
              )
            }
          </div>
          <div className='flare-section__heading fs-nft-section__heading'>
            <h2 className='title'>The Flare</h2>
            <p className='subtitle'>
              11,111 NFT’s igniting the future of the FireStarter. Free to claim for our earliest community
              members. Follow the link below.
            </p>
            <RoundButton
              size="large"
              wide
              href={OpenSeaFlareNFTCollection}
            >
              See the collection
            </RoundButton>
          </div>
          <div className='flare-section__description fs-nft-section__description'>
            <h3 className='title'>The Flare</h3>
            <dl>
              <div>
                <dt>Total: </dt>
                <dd>{!!flareToken && numberToCurrency(isDefaultEVMNetworkSelected ? flareToken.total : 11111)}</dd>
              </div>

              <dl>
                <dt>Price: </dt>
                <dd>Free</dd>
              </dl>
            </dl>
            <div className="flare-section__description-list fs-nft-section__description-list">
              <h6>Utility</h6>
              <ul>
                <li><span>FireStarter Projects and $FLAME Airdrop Participation</span></li>
                <li><span>Chance to ignite a FireStarter NFT Character</span></li>
              </ul>
            </div>
            <p>
              All Flare NFT's will ignite at some point in the future
            </p>
          </div>
          <div className='fs-nft-section__card-container flare-section__card-container'>
            {
              !!flareToken && (
                <NFTCard
                  name={flareToken?.metadata?.name as string}
                  id={flareToken?.metadata?.id as string}
                  image={flareToken?.metadata?.image as string}
                />
              )
            }
          </div>
        </Container>
      </section>
      <section className='hiro-section fs-nft-section'>
        <Container>
          <div className="d-flex d-lg-none justify-content-center mb-5">
            <NFTCard
              name={(hiroToken ? hiroToken?.metadata?.name : mockedHiro.name) as string}
              id={(hiroToken ? hiroToken?.metadata?.id : mockedHiro.id) as string}
              image={(hiroToken ? hiroToken?.metadata?.image : mockedHiro.image) as string}
            />
          </div>
          <div className='hiro-section__heading fs-nft-section__heading'>
            {
              (!dueDateForHiroNFT || timeLeftToHiro) && (
                <p className='hiro-section__caption gradient-text gradient-text--purple'>Coming soon...</p>
              )
            }
            <h2 className='title'>The FireStarters</h2>
            <p className='subtitle'>
              {
                dueDateForHiroNFT ? (
                  <>On <b> {formatISO9075(dueDateForHiroNFT)}</b></>
                ) : 'Soon'
              }
              , 200 Flare NFT holders will be able to claim our first FireStarter - Hiro. A legend among the
              stars, his role in the FireStarter Metaverse will unravel with time and patience. Should your Flare
              not Ignite at this time, please try again next.
            </p>
            {
              dueDateForHiroNFT && timeLeftToHiro && (
                <Timer duration={timeLeftToHiro} />
              )
            }
            <RoundButton
              size="large"
              wide
              href={OpenSeaHiroNFTCollection}
            >
              See the collection
            </RoundButton>
          </div>
          <div className='hiro-section__description fs-nft-section__description'>
            <h3 className='title'>The FireStarters - Hiro</h3>
            <p>Ignition Event #1 Total: <b>{!!hiroToken && numberToCurrency(isDefaultEVMNetworkSelected ? hiroToken.total : 200)}</b></p>
            <div className="hiro-section__description-list fs-nft-section__description-list">
              <h6>Utility</h6>
              <ul>
                <li><span>FireStarter Projects and $FLAME Airdrop Participation</span></li>
                <li><span>Invite to the FireStarter Discord</span></li>
                <li><span>Early Whitelisting Benefits</span></li>
                <li><span>Platform Governance Participation</span></li>
              </ul>
            </div>
          </div>
          <div
            className={classNames({
              'fs-nft-section__card-container': true,
              'hiro-section__card-container': true,
              'coming-soon': !dueDateForHiroNFT || timeLeftToHiro
            })}
          >
            <NFTCard
              name={(hiroToken ? hiroToken?.metadata?.name : mockedHiro.name) as string}
              id={(hiroToken ? hiroToken?.metadata?.id : mockedHiro.id) as string}
              image={(hiroToken ? hiroToken?.metadata?.image : mockedHiro.image) as string}
            />
          </div>
        </Container>
      </section>
    </>
  );
};
