import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { useNetwork } from '@firestarter-private/firestarter-library'
import { useWhitelistSolana } from '@contracts/hooks/useWhitelist/useWhitelistSolana'
import { useWhitelistEVM } from '@contracts/hooks/useWhitelist/useWhitelistEVM'
import { useWhen } from '@contracts/hooks/useWhen'
import { IUseWhitelistArgs, IUseWhitelistReturn } from '@contracts/hooks/useWhitelist/types'
import { getCurrentNetworkId } from '@contracts/networks'

export const useWhitelist = (whitelistArgs: IUseWhitelistArgs): IUseWhitelistReturn => {
  const { currentNetworkId } = useNetwork()
  return useWhen(
    isSolanaNetwork(currentNetworkId || getCurrentNetworkId()),
    useWhitelistSolana,
    useWhitelistEVM,
  )(whitelistArgs)
}
