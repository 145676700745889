import React, { useState } from 'react'
import './Footer.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { footerLinks } from './FooterContent'
import { MobileFooter } from '@components/layout/Footer/MobileFooter'
import useScreenSize from 'use-screen-size'
import { CopyrightLinks, FooterLinksGroup, FooterLogo, SocialLinks } from '@components/layout/Footer/FooterComponents'
import { AddressComponent } from '@/components'

export const Footer = () => {
  const year = new Date().getFullYear()
  const screenSize = useScreenSize()
  const mobile = screenSize.width < 992
  const lg = screenSize.width > 1199
  const [showSubscribeModal, setShowSubscribeModal] = useState(false)

  return (
    <footer className="footer">
      <Container>
        {!mobile ? (
          <>
            <Row className="justify-content-between">
              <Col className="footer-col" xs={{ span: 3 }}>
                <FooterLogo />
                <AddressComponent lg={lg} className="footer__token-address" />
              </Col>
              <Col className="footer-col" xs={{ span: 2 }}>
                <div className="footer__col">
                  <FooterLinksGroup item={{ title: 'Company', links: footerLinks.company }} mobile={false} />
                </div>
              </Col>
              <Col className="footer-col" xs={{ span: 2 }}>
                <div className="footer__col">
                  <FooterLinksGroup item={{ title: 'Governance', links: footerLinks.governance }} mobile={false} />
                  <FooterLinksGroup item={{ title: 'Developers', links: footerLinks.developers }} mobile={false} />
                </div>
              </Col>
              <Col className="footer-col" xs={{ span: 2 }}>
                <div className="footer__col">
                  <FooterLinksGroup item={{ title: 'Help', links: footerLinks.help }} mobile={false} />
                </div>
              </Col>
              <Col className="footer-col" xs={{ span: 3 }}>
                <SocialLinks
                  className="footer__col"
                  showSubscribeModal={showSubscribeModal}
                  setShowSubscribeModal={setShowSubscribeModal}
                />
              </Col>
            </Row>
            <Row className="copyrights-row justify-content-center">
              <Col>
                <CopyrightLinks year={year} className="footer-copyrights" character="•" />
              </Col>
            </Row>
          </>
        ) : (
          <MobileFooter
            year={year}
            showSubscribeModal={showSubscribeModal}
            setShowSubscribeModal={setShowSubscribeModal}
          />
        )}
      </Container>
    </footer>
  )
}
