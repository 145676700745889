import './BlindBoxNFTs.scss'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { getLootBoxNFTsQuery, LootBoxNFTFormatted, LootBoxNFTsResponse, mapLootBoxNFT } from '@api/subgraph/loot-boxes'
import { Container } from 'react-bootstrap'
import { ReactComponent as LightIcon } from '@assets/tiers/light.svg'
import { ReactComponent as SparkIcon } from '@assets/tiers/spark.svg'
import { ReactComponent as SmokeIcon } from '@assets/tiers/smoke.svg'
import { LoadingWrap } from '@components'
import { useWalletContext } from '@firestarter-private/firestarter-library'

enum LootBoxNFTId {
  LIGHT = '1',
  SPARK = '2',
  SMOKE = '3',
}

const nftsListData = [
  {
    icon: LightIcon,
    name: 'LIGHT',
    id: LootBoxNFTId.LIGHT,
    utility: 'Access to Surprise Lottery Round',
  },
  {
    icon: SparkIcon,
    name: 'SPARK',
    id: LootBoxNFTId.SPARK,
    utility: 'Base allocation in presales, FCFS',
  },
  {
    icon: SmokeIcon,
    name: 'SMOKE',
    id: LootBoxNFTId.SMOKE,
    utility: '2x allocation in presales, FCFS',
  },
]

export const BlindBoxNFTs = () => {
  const { account } = useWalletContext()
  const [userNFTs, setUserNFTs] = useState<Record<string, LootBoxNFTFormatted>>()
  const { loading } = useQuery<LootBoxNFTsResponse>(getLootBoxNFTsQuery, {
    variables: {
      address: account
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const result = data.lootBoxNFTs.reduce<Record<string, LootBoxNFTFormatted>>((acc, item) => {
        acc[item.tokenId] = mapLootBoxNFT(item)
        return acc
      }, {})
      setUserNFTs(result)
    }
  })

  return (
    <div className="blind-boxes-nfts">
      <Container>
        <div className="bb-nfts-list">
          <div className="bb-nfts-list__heading">
            <div>NFT tier</div>
            <div>Amount</div>
            <div>Utility</div>
          </div>
          <ul className="bb-nfts-list__body">
            {nftsListData.map(({ icon: Icon, name, id, utility }) => (
              <li key={`${name}_${id}`} className="bb-nfts-list__item bb-nfts-item tile">
                <div className="bb-nfts-item__col">
                  <div>
                    <Icon />
                    <span className="fw-semibold">{name}</span>
                  </div>
                </div>
                <div className="bb-nfts-item__col">
                  <span>Amount</span>
                  <LoadingWrap loading={loading || !userNFTs}>
                    {
                      userNFTs && (<span className="fw-bold">{userNFTs[id]?.amount ?? 0}</span>)
                    }
                  </LoadingWrap>
                </div>
                <div className="bb-nfts-item__col bb-nfts-item__col--utility">
                  <span>Utility</span>
                  <span>{utility}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  )
}