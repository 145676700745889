import { LoadingWrap, ProjectsList, RoundButton } from '@/components'
import { RoutesPaths } from '@router/constants'
import React, { useCallback, useEffect } from 'react'
import { useProjectsState } from '@contracts/hooks/useProjects'
import { useSearchParams } from '@hooks/useSearchParams'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import useScreenSize from 'use-screen-size'
import './ProjectsSection.scss'
import { NormalizedProjectInfo } from '@components/Project/types'

export const NoProjects = (props: { message: string }) => (
  <Col md={{ span: 6 }}>
    <div className="tile--with-shadow no-projects-tile">
      <div className="dots">
        <div />
        <div />
        <div />
      </div>
      <p className="tile__description">
        {props.message}
      </p>
    </div>
  </Col>
);

const NavBar = () => (
  <Nav className="projects-nav">
    <Nav.Item>
      <Nav.Link eventKey="live">Live</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="upcoming">Upcoming</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="completed">Completed</Nav.Link>
    </Nav.Item>
  </Nav>
)

function ProjectsButton(props: { mobile: boolean }) {
  return <RoundButton
    className="projects-btn"
    size="large"
    to={RoutesPaths.PROJECTS}
  >
    {props.mobile ? 'All' : 'All Projects'}
  </RoundButton>
}

const ReadyToJoin = () => (
  <Col
    md={{ span: 6 }}
    lg={{ span: 6 }}
    xl={{ span: 4 }}
  >
    <div className="project-ready">
      <div className="project-ready__content">
        Is your project ready to revolutionize the digital landscape?
      </div>
      <RoundButton to={RoutesPaths.APPLY_FOR_IDO} size="large">
        Apply here
      </RoundButton>
    </div>
  </Col>
)

const Projects = (props: { projects: NormalizedProjectInfo[] }) => (
  <>
    <ProjectsList projects={props.projects} />
    <ReadyToJoin />
  </>
)

export const ProjectsSection = () => {
  const {
    loading,
    liveProjects,
    upcomingProjects,
    completedProjects,
    getProjects,
    abortRequest,
  } = useProjectsState()

  const { query } = useSearchParams()

  const loadProjects = useCallback(async () => {
    abortRequest()
    await getProjects(query)
  }, [abortRequest, getProjects, query])

  useEffect(() => {
    loadProjects()
  }, [query])

  const screenSize = useScreenSize();
  const isMediumMobile = screenSize.width <= 992

  return (
    <section className="projects-section page">
      <Container>
        <Tab.Container id="" defaultActiveKey="live">
          <div className='projects-section__header top'>
            <h2 className="title">Projects</h2>
            <NavBar />
            <ProjectsButton mobile={isMediumMobile} />
          </div>
          <div className='projects-section__header below'>
            <NavBar />
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="live">
              <Row className="g-4">
                <LoadingWrap loading={loading}>
                  {liveProjects.length
                    ? <Projects projects={liveProjects} />
                    : <NoProjects message='There are no live projects yet' />}
                </LoadingWrap>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="upcoming">
              <Row className="g-4">
                <LoadingWrap loading={loading}>
                  {upcomingProjects.length
                    ? <Projects projects={upcomingProjects} />
                    : <NoProjects message='There are no upcoming projects now' />}
                </LoadingWrap>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="completed">
              <Row className="g-4">
                <LoadingWrap loading={loading}>
                  {completedProjects.length
                    ? <Projects projects={completedProjects} />
                    : <NoProjects message='No projects have been completed yet' />}
                </LoadingWrap>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </section>
  );
}
