import { useCallback, useContext, useEffect, useMemo } from 'react'
import { Metadata, MetadataData, } from '@metaplex-foundation/mpl-token-metadata'
import { Address } from '@project-serum/anchor'
import { PublicKey } from '@solana/web3.js'
import axios from 'axios'
import { INFTAccounts, IURIMetadata } from '@contracts/hooks/useSolNFTs/types'
import { ISolNFTContext, SolNFTActionType, SolNFTContext } from '@contracts/hooks/useSolNFTs/context'
import { useConnection } from '@solana/wallet-adapter-react'
import { groupItemsBy } from '@firestarter-private/firestarter-library/lib/utils/array'
import { findAssociatedTokenAddress, toPubKey } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import { useWalletContext } from '@firestarter-private/firestarter-library'
import { useIsSolana } from '@hooks/useIsSolana'

export const getURIMetadata = async (source: string | MetadataData): Promise<IURIMetadata> => {
  const uri = source instanceof MetadataData
    ? source.data.uri
    : source

  const { data } = await axios.get<IURIMetadata>(uri)
  return data
}

export type SolNFTName = 'diamond'
export const diamondNFTName: SolNFTName = 'diamond'

export const diamondAsset = process.env.PUBLIC_URL + '/nft/diamond-nft-video.mov'
export const diamondSupply = 10_898
export const diamondCollectionNameStart = 'Diamond #'

export const useSolNFTs = () => {
  const { connection } = useConnection()
  const { account } = useWalletContext()
  const {
    state: {
      userNFTs,
      diamondNFTs,
      URIMetadataDict,
      fetchingNFTs,
    },
    dispatch
  } = useContext(SolNFTContext)

  const isSolana = useIsSolana()
  const publicKey = useMemo(
    () => (account && isSolana) ? toPubKey(account) : null,
    [account, isSolana]
  )

  const setFetching = useCallback((payload: boolean) => {
    dispatch({
      type: SolNFTActionType.SET_FETCHIN_NFTS,
      payload
    })
  }, [dispatch])

  const setUserNFTs = useCallback((payload: ISolNFTContext['userNFTs']) => {
    dispatch({
      type: SolNFTActionType.SET_USER_NFTS,
      payload
    })
  }, [dispatch])

  const setDiamondNFTs = useCallback((payload: ISolNFTContext['diamondNFTs']) => {
    dispatch({
      type: SolNFTActionType.SET_DIAMOND_NFTS,
      payload
    })
  }, [dispatch])

  const setURIMetadata = useCallback((payload: ISolNFTContext['URIMetadataDict']) => {
    dispatch({
      type: SolNFTActionType.SET_URI_METADATA,
      payload
    })
  }, [dispatch])

  const fetchMetadata = useCallback(async () => {
    if (!publicKey) return
    setFetching(true)
    const data = await Metadata.findDataByOwner(connection, publicKey)
    const diamonds = data.filter(item => item.data.name.startsWith(diamondCollectionNameStart))
    setDiamondNFTs(diamonds)
    setUserNFTs(groupItemsBy(data, 'updateAuthority'))
    setFetching(false)
  }, [publicKey, connection])

  useEffect(() => {
    if (isSolana) {
      fetchMetadata()
    }
  }, [publicKey, isSolana])

  const getSolNFTsByAuthority = useCallback((authority: Address): MetadataData[] => {
    if (!userNFTs) return []
    if (typeof authority === 'string') return userNFTs[authority] || []
    return userNFTs[authority.toString()] || []
  }, [userNFTs])

  const getSolNFTToken = useCallback((
    authority: Address,
    tokenMint: Address,
  ): MetadataData | undefined => {
    const collection = getSolNFTsByAuthority(authority)
    const mint = typeof tokenMint === 'string' ? tokenMint : tokenMint.toString()
    return collection.find(data => data.mint === mint)
  }, [userNFTs, getSolNFTsByAuthority])

  const getSolNFTAccounts = useCallback(async (
    publicKey: PublicKey,
    tokenMint: string
  ): Promise<INFTAccounts> => {
    const mint = new PublicKey(tokenMint)
    const [metadataPDA, tokenAssociatedAccount] = await Promise.all([
      Metadata.getPDA(mint),
      findAssociatedTokenAddress(publicKey, mint)
    ])

    return {
      metadataPDA,
      tokenAssociatedAccount,
      mint
    }
  }, [publicKey])

  const getTokenURIMetadata = useCallback(async (
    source: string | MetadataData,
    tokenMint?: string
  ): Promise<IURIMetadata> => {
    const mintAddress = source instanceof MetadataData
      ? source.mint
      : tokenMint

    if (mintAddress && URIMetadataDict[mintAddress]) {
      return URIMetadataDict[mintAddress]
    }

    const data = await getURIMetadata(source)

    if (mintAddress) {
      setURIMetadata({
        [mintAddress]: data
      })
    }

    return data
  }, [setURIMetadata])

  const getMultipleURIMetadata = useCallback(async (
    tokens: MetadataData[]
  ): Promise<Record<string, IURIMetadata>> => {
    const results = await Promise.all(tokens.map(async (token) => {
      const data = await getURIMetadata(token)
      return {
        mint: token.mint,
        data
      }
    }))

    const dict = results.reduce<Record<string, IURIMetadata>>((acc, item) => {
      acc[item.mint] = item.data
      return acc
    }, {})

    setURIMetadata(dict)

    return dict
  }, [setURIMetadata])

  return {
    fetchingNFTs,
    userNFTs,
    diamondNFTs,
    URIMetadataDict,
    fetchMetadata,
    getSolNFTsByAuthority,
    getSolNFTToken,
    getSolNFTAccounts,
    getTokenURIMetadata,
    getMultipleURIMetadata,
  }
}
