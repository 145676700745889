import React from 'react';
import { ButtonGroup, Dropdown, DropdownProps } from 'react-bootstrap'
import {
  getNetworkIcon,
  supportedNetworksFiltered
} from '@contracts/networks';
import './NetworkSwitcher.scss'
import classNames from 'classnames';
import { useNetwork } from '@firestarter-private/firestarter-library';
import { RoundButton } from '../RoundButton';
import { withNetworkSwitching, WithNetworkSwitchingProps } from '@/hocs'
import { NetworkInfo } from '@firestarter-private/firestarter-library/lib/constants'

const getNetworkContent = (network: NetworkInfo, showName = true): React.ReactNode => {
  return (
    <>
      <img
        className="network-switcher__icon"
        src={getNetworkIcon(network)}
        alt={network.icon.toUpperCase()}
      />
      {
        showName && (
          <span className="network-switcher__text">{network.userFriendlyName}</span>
        )
      }
    </>
  )
}

interface Props extends WithNetworkSwitchingProps {
  drop: DropdownProps['drop']
  children: React.ReactNode
}

const NetworkSwitcherComponent = ({
  drop,
  isSwitching,
  handleSwitch,
  children
}: Props) => {
  const {
    currentNetwork,
    checkIfSelected,
  } = useNetwork()

  return (
    <Dropdown
      as={ButtonGroup}
      className={classNames({
        'network-switcher__dropdown': true,
        disabled: isSwitching
      })}
      drop={drop}
    >
      <Dropdown.Toggle
        as={RoundButton}
        split={!!children}
        color="DARK"
        id="network-switcher-split"
        className={classNames({
          'network-switcher__toggle': true,
          disabled: !currentNetwork,
        })}
      >
        {currentNetwork ? getNetworkContent(currentNetwork, !children) : '--'}
      </Dropdown.Toggle>
      <Dropdown.Menu className="network-switcher__menu nav-dropdown__menu">
        {
          Object.values(supportedNetworksFiltered).map((network: NetworkInfo) => (
            <Dropdown.Item
              key={network.networkId}
              className={classNames({
                'network-switcher__item': true,
                'nav-dropdown__item': true,
                'selected': checkIfSelected(network.networkId)
              })}
              onClick={() => handleSwitch(network.networkId)}
              as={'div'}
            >
              {getNetworkContent(network)}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
      {!!children && children}
    </Dropdown>
  )
}

export const NetworkSwitcher = withNetworkSwitching(NetworkSwitcherComponent)
