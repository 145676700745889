import React from 'react'
import { footerLinks } from '@components/layout/Footer/FooterContent'
import './MobileFooter.scss'
import { FireStarterLogoLink } from '@components/layout/Header/HeaderComponents'
import { CopyrightLinks, FooterLinksGroup, SocialLinks } from '@components/layout/Footer/FooterComponents'

export const MobileFooter = (props: { year: number; showSubscribeModal: any; setShowSubscribeModal: any }) => (
  <div className="mobile-footer">
    <FireStarterLogoLink />
    <FooterLinksGroup item={{ title: 'Company', links: footerLinks.company }} mobile={true} />
    <FooterLinksGroup item={{ title: 'Governance', links: footerLinks.governance }} mobile={true} />
    <FooterLinksGroup item={{ title: 'Developers', links: footerLinks.developers }} mobile={true} />
    <FooterLinksGroup item={{ title: 'Help', links: footerLinks.help }} mobile={true} />
    <SocialLinks
      className="mobile-footer__col"
      showSubscribeModal={props.showSubscribeModal}
      setShowSubscribeModal={props.setShowSubscribeModal}
    />
    <CopyrightLinks year={props.year} className="mobile-footer-copyrights" character="" />
  </div>
)
