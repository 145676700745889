import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import './ProjectFilter.scss'
import { RoundButton } from '@components/common';

interface FilterItem {
  value: any
  content: string | React.ReactNode
}

interface Props {
  name?: string
  value: FilterItem['value']
  items: FilterItem[]
  onChange: (value: FilterItem['value']) => unknown | Promise<unknown>
}

export const ProjectFilter: React.FC<Props> = ({
  name,
  value,
  items,
  onChange
}) => {const selectedLabel = useMemo(
    () => items.find(item => item.value === value)?.content ?? items[0].content,
    [items, value]
  )

  return (
    <div className="project-filter d-flex align-items-center">
      <span className="project-filter__name">{name}</span>
      <Dropdown className="project-filter__dropdown">
        <Dropdown.Toggle
          as={RoundButton}
          color="DARK"
          id="project-filter-toggle"
          className="project-filter__toggle"
        >
          {selectedLabel}
        </Dropdown.Toggle>
        <Dropdown.Menu className="project-filter__menu">
          {
            items.map(item => (
              <Dropdown.Item
                key={item.value}
                active={value === item.value}
                className="project-filter__item"
                as={'div'}
                onSelect={() => onChange(item.value)}
              >
                <span>{item.content}</span>
              </Dropdown.Item>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
