import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { RoundButton, Spinner } from '@components'
import { useWalletContext } from '@firestarter-private/firestarter-library'
import * as yup from 'yup'
import { SigningStatus } from '@pages/Account/LinkingWallets/LinkingComponents'
import { createEthWalletSignature } from '@utils/signature'
import { setTimeoutPromise } from '@firestarter-private/firestarter-library/lib/utils/promises'
import { linkSolanaWallet } from '@api/link-wallets'

interface LinkingWalletsFormValues {
  email: string
}

const initialValues: LinkingWalletsFormValues = { email: '' };

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Wrong format of email')
    .required('Email is required')
});

export const LinkingForm = (props: { getLinkingStatus: () => void }) => {

  const { account } = useWalletContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [signingStatus, setSigningStatus] = useState<SigningStatus | null>();

  const submitLinkingForm = async (values: LinkingWalletsFormValues) => {
    setLoading(true);
    setSigningStatus(SigningStatus.email);
    const signed_message = await createEthWalletSignature(values.email);

    if (account && signed_message) {
      setSigningStatus(SigningStatus.request);
      await setTimeoutPromise(500);
      const response = await linkSolanaWallet({
        email: values.email,
        wallet_address: account,
        signed_message
      });

      if ('error' in response) {
        setFieldError('email', response.error);
      } else {
        await props.getLinkingStatus();
      }
    }

    setSigningStatus(null);
    setLoading(false);
  };

  const {
    errors,
    touched,
    handleSubmit,
    isValid,
    getFieldProps,
    setFieldError
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: submitLinkingForm,
    initialValues
  });

  return <Form
    noValidate
    className="linking-wallets-form"
    onSubmit={handleSubmit}
  >
    <div className="linking-wallets-form__inner text-center">
      <h2 className="title">Link your Wallet</h2>
      <p className="linking-wallets__description">
        Add your email below to link your <b>Ethereum</b> and{' '}
        <b>Solana</b> wallets
      </p>
      <Form.Group controlId="email">
        <Form.Control
          type="email"
          placeholder="username@gmail.com"
          {...getFieldProps('email')}
          isValid={touched.email && !errors.email}
          isInvalid={touched.email && !!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <RoundButton
        className="linking-wallets-form__submit-button"
        type="submit"
        size="large"
        disabled={!isValid || loading}
      >
        {loading ? (
          <>
            <Spinner />
            {` ${signingStatus}`}
          </>
        ) : (
          'Link'
        )}
      </RoundButton>
    </div>
  </Form>
}
