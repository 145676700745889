import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import {
  useFlameStakingContract,
  useTokenInfo,
  useTransactions,
  useWalletContext,
  useWeb3,
} from '@firestarter-private/firestarter-library'
import { Contract } from 'web3-eth-contract'
import {
  getAPY,
  getFlameAndUSDCPrice,
  getReserves,
  getStakingStats,
  getTotalSupply,
} from '@contracts/hooks/useStaking/functions'
import { BlockNumber, TransactionReceipt } from 'web3-core'
import { differenceInDays } from 'date-fns'
import { retry } from '@firestarter-private/firestarter-library/lib/utils/promises'
import { sendExceptionReport } from '@utils/errors'
import {
  LoadStatsReturns,
  StakingStats,
} from '@contracts/hooks/useStaking/types'
import { defaultStakingStatsEVM } from '@contracts/hooks/useStaking/constants'
import {
  balanceToNumber,
  toBigNumber,
} from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import {
  abis,
  NetworkType,
  polygonContractAddresses,
} from '@firestarter-private/firestarter-library/lib/constants'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'

export const useStakingEVM = () => {
  const isMountedRef = useIsMounted()
  const { account } = useWalletContext()
  const web3 = useWeb3()
  const { callTransaction, sendTransaction } = useTransactions()

  const lpToken = useTokenInfo(
    polygonContractAddresses.flameUSDCLpToken,
    NetworkType.evm,
    abis.lpToken,
  )
  const flameToken = useTokenInfo(
    polygonContractAddresses.flameToken,
    NetworkType.evm,
  )

  const stakingContract = useFlameStakingContract(NetworkType.evm)
  const [loading, setLoading] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const [isStakingActive, setStakingActive] = useState(false)

  const [flamePrice, setFlamePrice] = useState(0)
  const [lpTokenPrice, setLpTokenPrice] = useState(0)

  const [rewards, setRewards] = useState(toBigNumber(0))
  const [staked, setStaked] = useState(toBigNumber(0))
  const [lastStaked, setLastStaked] = useState(0)

  const [totalStaked, setTotalStaked] = useState(toBigNumber(0))
  const [totalRewards, setTotalRewards] = useState(toBigNumber(0))
  const [rewardsPerSecond, setRewardsPerSecond] = useState(toBigNumber(0))
  const [stakingStart, setStakingStart] = useState(0)
  const [stakingDuration, setStakingDuration] = useState(0)
  const [earlyWithdrawalPeriod, setEarlyWithdrawalPeriod] = useState(0)

  const lpBalance = lpToken.amount
  const lpDecimals = lpToken.decimals
  const flameDecimals = flameToken.decimals
  const usdcDecimal = 6

  const resetUserInfo = () => {
    setRewards(toBigNumber(0))
    setStaked(toBigNumber(0))
    setLastStaked(0)
  }

  const loadUserInfo = useCallback(async () => {
    if (!account || !stakingContract) {
      resetUserInfo()
      return
    }

    const getStakingInfo = async () => {

      const userInfo = await callTransaction(
        stakingContract.methods.userInfo(account),
        blockNumber,
      )
      if (isMountedRef.current) {
        setStaked(toBigNumber(userInfo.amount))
        if (+userInfo.lastDepositedAt)
          setLastStaked(+userInfo.lastDepositedAt * 1000)
      }
    }

    const getStakingRewards = async () => {
      const stakingRewards = await callTransaction(
        stakingContract.methods.pendingFlame(account),
        blockNumber,
      )
      if (isMountedRef.current) setRewards(toBigNumber(stakingRewards))
    }

    try {
      await retry(() => Promise.all([getStakingInfo(), getStakingRewards()]))
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetUserInfo()
    }
  }, [stakingContract, account, isMountedRef, blockNumber])

  const resetStats = () => {
    setTotalStaked(toBigNumber(0))
    setTotalRewards(toBigNumber(0))
    setRewardsPerSecond(toBigNumber(0))
    setStakingStart(0)
    setStakingDuration(0)
    setStakingActive(false)
  }

  const getLpTokenPrice = async (
    lpTokenContract: Contract,
    flamePrice: number,
    usdcPrice: number,
  ): Promise<number> => {
    const { flameReserves, usdcReserves } = await getReserves(
      lpTokenContract,
      flameDecimals,
      usdcDecimal,
    )
    const totalSupply = await getTotalSupply(lpTokenContract, lpDecimals)

    return (
      (flameReserves * flamePrice + usdcReserves * usdcPrice) / (totalSupply || 1)
    )
  }

  const loadPrices = useCallback(async () => {
    if (!lpToken.token) return

    const { flamePrice: flamePriceValue, usdcPrice } = await getFlameAndUSDCPrice()
    const lpPrice = await getLpTokenPrice(
      lpToken.token,
      flamePriceValue,
      usdcPrice,
    )

    setFlamePrice(flamePriceValue)
    setLpTokenPrice(lpPrice)
  }, [lpToken.token])

  const loadStatsPromise = useCallback(async (): Promise<
    LoadStatsReturns | undefined
  > => {
    let stakingStatsEVM = defaultStakingStatsEVM

    let batch = new web3.BatchRequest()


    if (stakingContract) {
      const earlyWithdrawal = await callTransaction(
        stakingContract.methods.earlyWithdrawal(),
        blockNumber
      )

      setEarlyWithdrawalPeriod(earlyWithdrawal / 60 / 60 / 24)

      await retry(
        () =>
          new Promise<void>((resolve, reject) => {
            batch.add(
              stakingContract.methods
                .isStakingInProgress()
                .call.request(
                  { from: account },
                  (error: Error, result: boolean) => {
                    if (error) return reject(error)
                    stakingStatsEVM.stakingInProgress = result
                  },
                ),
            )
            batch.add(
              lpToken.token &&
                lpToken.token.methods
                  .balanceOf(polygonContractAddresses.flameStaking)
                  .call.request(
                    { from: account },
                    (error: Error, result: string) => {
                      if (error) return reject(error)
                      stakingStatsEVM.totalStakedAmount = toBigNumber(result)
                    },
                  ),
            )
            batch.add(
              stakingContract.methods
                .flamePerSecond()
                .call.request(
                  { from: account },
                  (error: Error, result: string) => {
                    if (error) return reject(error)
                    stakingStatsEVM.flamePerSecond = toBigNumber(result)
                  },
                ),
            )
            batch.add(
              stakingContract.methods
                .totalRewards()
                .call.request(
                  { from: account },
                  (error: Error, result: string) => {
                    if (error) return reject(error)
                    stakingStatsEVM.totalRewardsAmount = toBigNumber(result)
                  },
                ),
            )
            batch.add(
              stakingContract.methods
                .startTime()
                .call.request(
                  { from: account },
                  (error: Error, result: string) => {
                    if (error) {
                      return reject(error)
                    }
                    stakingStatsEVM.startTime = +result * 1000
                  },
                ),
            )
            batch.add(
              stakingContract.methods
                .stakingPeriod()
                .call.request(
                  { from: account },
                  (error: Error, result: string) => {
                    if (error) return reject(error)
                    stakingStatsEVM.stakingPeriod = +result * 1000
                    resolve()
                  },
                ),
            )
            batch.execute()
          }),
      )
    }

    return stakingStatsEVM
  }, [lpToken.token, stakingContract, web3, account])

  const loadStats = useCallback(async () => {
    if (!stakingContract || !lpToken.token) {
      resetStats()
      return
    }

    try {
      const stats = await loadStatsPromise()
      if (isMountedRef.current && stats) {
        setTotalStaked(stats.totalStakedAmount)
        setRewardsPerSecond(stats.flamePerSecond)
        setStakingStart(stats.startTime)
        setStakingDuration(stats.stakingPeriod)
        setStakingActive(stats.stakingInProgress)
        setTotalRewards(stats.totalRewardsAmount)
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetStats()
    }
  }, [stakingContract, lpToken.token, isMountedRef])

  useEffect(() => {
    if (!loading && account) {
      loadUserInfo()
    }
  }, [account, loading, blockNumber, stakingContract])

  useEffect(() => {
    if (!loading) {
      loadStats()
      loadPrices()
    }
  }, [loading, stakingContract, blockNumber, lpToken.token])

  useEffect(() => {
    if (!loading) {
      lpToken.getTokenInfo()
    }
  }, [loading, lpToken])

  const stakingStats = useMemo<StakingStats>(() => {
    return getStakingStats(
      isStakingActive,
      stakingStart,
      stakingDuration,
      rewardsPerSecond,
      totalRewards,
    )
  }, [
    totalStaked,
    totalRewards,
    rewardsPerSecond,
    stakingStart,
    stakingDuration,
    isStakingActive,
  ])

  const currentPenalty = useMemo(() => {
    if (balanceToNumber(rewards, flameDecimals) <= 0) {
      return toBigNumber(0)
    }
    if (differenceInDays(new Date(), lastStaked) < earlyWithdrawalPeriod) {
      return rewards.div(2)
    }
    return toBigNumber(0)
  }, [rewards, lastStaked, flameDecimals, earlyWithdrawalPeriod])

  const APY = useMemo(() => {
    return getAPY(
      rewardsPerSecond,
      totalStaked,
      lpDecimals,
      flameDecimals,
      lpTokenPrice,
      flamePrice,
    )
  }, [
    rewardsPerSecond,
    totalStaked,
    flameDecimals,
    lpDecimals,
    flamePrice,
    lpTokenPrice,
  ])

  const onStake = useCallback(
    async (amount: string, callbacks: INotifyTxCallbacks = {}) => {
      if (!account || !stakingContract) return

      setLoading(true)
      const receipt = (await sendTransaction(
        await stakingContract.methods.deposit(amount, account),
        callbacks,
      )) as TransactionReceipt

      setBlockNumber(receipt.blockNumber)
      setLoading(false)
    },
    [account, stakingContract, sendTransaction],
  )

  const onClaim = useCallback(
    async (callbacks: INotifyTxCallbacks = {}) => {
      if (!account || !stakingContract) return

      setLoading(true)
      const receipt = (await sendTransaction(
        await stakingContract.methods.harvest(account),
        callbacks,
      )) as TransactionReceipt

      setBlockNumber(receipt.blockNumber)
      setLoading(false)
    },
    [account, stakingContract, sendTransaction],
  )

  const onUnstake = useCallback(
    async (amount: string, callbacks: INotifyTxCallbacks = {}) => {
      if (!account || !stakingContract) return

      setLoading(true)
      const receipt = (await sendTransaction(
        await stakingContract.methods.withdraw(amount, account),
        callbacks,
      )) as TransactionReceipt

      setBlockNumber(receipt.blockNumber)
      setLoading(false)
    },
    [account, stakingContract, sendTransaction],
  )

  return {
    rewardsPerSecond,
    lastStaked,
    lpDecimals,
    lpBalance,
    staked,
    rewards,
    flameDecimals,
    totalStaked,
    isStakingActive,
    stakingStats,
    currentPenalty,
    APY,
    onStake,
    onClaim,
    onUnstake,
  }
}
