import { Container, Image } from 'react-bootstrap';
import { FeaturesSection, LeaderboardList, RoundButton } from '../../components';
import './Leaderboard.scss'
import LinkArrow from '../../assets/link-arrow.svg';
import { tiersArticleUrl } from '@constants'

export const Leaderboard = () => {

  return (
    <div className='leaderboard-page page'>
      <Container className="title-container">
        <h1 className='title mb-4 leaderboard-title'>
          FLAME Leaderboard
        </h1>
        <p className='subtitle'>
          Reach the top - Own The Future. Leaders receive higher allocations and more access to our IDOs. The more FLAME
          Power you accumulate, the better chance you have of winning.
        </p>
      </Container>
      <Container>
        <h2 className='title leaderboard-page__features-title'>
          <span>How to increase your FLAME Power?</span>
          <RoundButton color="DARK" href={tiersArticleUrl}>
            <Image src={LinkArrow} width={24} height={24} />
          </RoundButton>
        </h2>
      </Container>
      <FeaturesSection />
      <section className='leaders-section'>
        <Container>
          <p className='leaders-section__caption'>
            <span className='purple-text fw-bold'>Top 100 Flamers{' '}</span> get Guaranteed Access. Others are placed in our
            tiered lottery system.
          </p>
          <LeaderboardList />
        </Container>
      </section>
    </div>
  )
}
