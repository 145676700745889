import React, { useEffect, useState } from 'react';
import { scrollToPosition } from '@utils/scrollToPosition';
import {ReactComponent as ToTopIcon } from '@assets/to-top-btn.svg'
import classNames from 'classnames';
import './ScrollToTop.scss'
import { throttle, throttleAnimation } from '@utils/throttle'

interface Props {
  container: Window
}

const ScrollToTop = ({ container }: Props) => {
  let oldScroll = 0
  const isDesktop = window.matchMedia('(min-width: 992px)').matches

  const [isShown, setIsShown] = useState(false)

  const scrollHandler = (event: Event) => {
    const doc = event.target as Document
    const el = doc.documentElement as HTMLElement

    if (
      el.scrollTop > container.innerHeight &&
      (isDesktop || oldScroll > el.scrollTop)
    ) {
      setIsShown(true)
    } else {
      setIsShown(false)
    }

    oldScroll = el.scrollTop
  }

  useEffect(() => {
    const throttledScrollHandler = throttleAnimation(scrollHandler)

    container.addEventListener('scroll', throttledScrollHandler)

    return () => {
      container.removeEventListener('scroll', throttledScrollHandler)
    }
  }, [container])

  const scrollToTop = () => scrollToPosition({ y: 0}, container)

  return (
    <div
      className={classNames({
        'scroll-to-top': true,
        'shown': isShown
      })}
      onClick={scrollToTop}
    >
      <ToTopIcon />
    </div>
  )
}

ScrollToTop.defaultProps = {
  container: window
}

export { ScrollToTop }
