import { Container } from 'react-bootstrap'
import { AddressComponent } from '@components'
import { ReactComponent as PolygonIcon } from '@assets/partners/Polygon.svg'
import { ReactComponent as SolanaIcon } from '@assets/partners/Solana.svg'
import { ReactComponent as EthereumIcon } from '@assets/partners/Ethereum.svg'
import { ReactComponent as BinanceIcon } from '@assets/partners/Binance.svg'
import './BelowBannerSection.scss'
import React from 'react'

export const BelowBannerSection = () => (
  <section className='below-banner-section'>
    <Container>
      <div className="below-banner text-center">
        <div className="below-banner__powered-by">
          <span className="below-banner__powered-by__title">Powered by</span>
          <div className="icons below-banner__powered-by__icons">
            <PolygonIcon />
            <SolanaIcon />
            <EthereumIcon />
            <BinanceIcon />
          </div>
        </div>
        <div className="below-banner__contract">
          <span className="below-banner__contract__title">Contract</span>
          <AddressComponent lg={true} className="below-banner__token-address"/>
        </div>
      </div>
    </Container>
  </section>
)
