export function isDefined<T>(prop: NonNullable<T> | undefined | null): prop is NonNullable<T> | null {
  return prop !== undefined
}

export function isEmptyObject(value: Object) {
  if (value === null) {
    return true
  }

  for (let key in value) {
    if (value.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

export function arrayToCollection<T = any>(array: T[], identifier: keyof T) {
  return array.reduce<Record<string, T>>((acc, item) => {
    // @ts-ignore
    acc[item[identifier]] = item
    return acc
  }, {} as Record<string, T>)
}
