import { getBaseAllocationString, getTokenPriceString, getFundraiseGoalString } from '@components/Project/utils'
import { RoundButton } from '@components'
import { RoutesPaths } from '@router/constants'
import './ProjectIMODetails.scss'
import { format } from 'date-fns'

interface Props {
  fundTokenName: string
  rewardTokenName: string
  totalAmount?: number
  exchangeRate?: number
  baseAllocation?: number
  releaseScheduleUrl?: string
  isExternalPresale?: boolean
  externalPresaleClaimLink?: string
}

export const ProjectIDODetails = ({
  fundTokenName,
  rewardTokenName,
  totalAmount,
  baseAllocation,
  exchangeRate,
  releaseScheduleUrl,
  isExternalPresale,
  externalPresaleClaimLink,
}: Props) => {
  return (
    <div className="tile imo-details">
      <dl className="info-list">
        <div className="info-list__item">
          <dt className="name">Fundraise goal</dt>
          <dd className="value">{getFundraiseGoalString(fundTokenName, totalAmount)}</dd>
        </div>
        <div className="info-list__item">
          <dt className="name">Price per token</dt>
          <dd className="value">{getTokenPriceString(fundTokenName, rewardTokenName, exchangeRate)}</dd>
        </div>
        <div className="info-list__item">
          <dt className="name">Base allocation</dt>
          <dd className="value">{getBaseAllocationString(baseAllocation)}</dd>
        </div>
        <div className="info-list__item info-list__item--with-button">
          <dt className="name">Vesting schedule</dt>
          <dd className="value d-flex flex-wrap d-md-block">
            {releaseScheduleUrl && (
              <a
                href={releaseScheduleUrl}
                className="text-wide me-3 flex-grow-1 w-100 w-md-auto"
                target="_blank"
                rel="noopener noreferrer"
              >
                Review schedule
              </a>
            )}
            <RoundButton
              className="mt-3 mt-md-0"
              size="small"
              color="DARK"
              wide
              {
              ...(
                isExternalPresale
                ? {
                    href: externalPresaleClaimLink || '',
                  }
                : {
                    to: RoutesPaths.ACCOUNT.MAIN,
                  }
              )
            }
            >
              Claim
            </RoundButton>
          </dd>
        </div>
      </dl>
    </div>
  )
}

interface AirdropProps {
  tokenName: string
  airdropAmount?: number
  airdropDate?: Date | number | null
}

export const ProjectAirdropDetails = ({
  tokenName,
  airdropAmount,
  airdropDate,
}: AirdropProps) => {

  return (
    <div className="tile imo-details">
      <dl className="info-list">
        <div className="info-list__item">
          <dt className="name">Airdrop Amount</dt>
          <dd className="value">{getFundraiseGoalString(tokenName, airdropAmount)}</dd>
        </div>
        <div className="info-list__item">
          <dt className="name">Airdrop Date</dt>
          <dd className="value">{airdropDate ? format(airdropDate, 'yyyy-MM-dd') : 'TBA'}</dd>
        </div>
      </dl>
    </div>
  )
}
