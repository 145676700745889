import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { numberToPercentage } from '@utils/balanceFormatter';
import { getReadablePeriod } from '../utils';
import { LockupPeriodTooltip } from '../LockPeriodTooltip';
import { LockPeriodInfo } from '@contracts/hooks/useMultiperiodLocking/types';

interface Props {
  lockPeriods: LockPeriodInfo[]
  value?: any
  onChange?: (value: any, event?: any) => void
}

const amountOfTiers = 4

export const LockupPeriodOptions: React.FC<Props> = ({
  lockPeriods,
  value,
  onChange,
}) => {
  return (
    <ToggleButtonGroup
      className='lockup-form__options-group'
      name={'lockup-periods'}
      type={'radio'}
      value={value}
      onChange={onChange}
    >
      {
        lockPeriods
          .filter(period => period.isActive)
          .sort((a, b) => a.lockPeriod - b.lockPeriod)
          .slice(0, amountOfTiers)
          .map((period) => (
            <ToggleButton
              id={`lockup-period-${period.tierIndex}`}
              key={period.lockPeriod}
              value={period.tierIndex}
              bsPrefix={'lockup-option'}
            >
              <h4 className='lockup-option__title'>
                {numberToPercentage(period.apy)} <small>APY</small>
              </h4>
              <p className='lockup-option__desc text-truncate m-0'>
                {getReadablePeriod(period.lockPeriod)}
                {', '}
                {period.power}x FLAME power
              </p>
              <LockupPeriodTooltip
                period={period}
                id={`lockup-option-${period.tierIndex}-tooltip`}
                placement={'auto'}
                triggerClass={'lockup-option__tooltip-trigger'}
                tooltipClass={'lockup-option__tooltip lockup-tooltip'}
              />
            </ToggleButton>
          ))
      }
    </ToggleButtonGroup>
  )
}
