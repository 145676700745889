import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { LoadingWrap } from '@components'
import './BlindBoxesClaiming.scss'
import { BoxesList } from '@components/BlindBoxes'
import { useLazyQuery } from '@apollo/client'
import { getLootBoxesQuery, LootBoxesResponse, LootBoxFormatted, mapLootBox } from '@api/subgraph/loot-boxes'
import { usePlatformSettings } from '@hooks/usePlatformSettings'
import { useWalletContext } from '@firestarter-private/firestarter-library'

export const BlindBoxesClaiming = () => {
  const { account } = useWalletContext()
  const { settings } = usePlatformSettings()
  const [lootBoxes, setLootBoxes] = useState<LootBoxFormatted[]>()
  const [loadBoxes, { called, refetch }] = useLazyQuery<LootBoxesResponse>(getLootBoxesQuery, {
    variables: {
      address: account,
      contract: settings?.contracts?.blindBoxLotteryPhase2
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLootBoxes(
        data.lootBoxes
          .map(mapLootBox)
          .sort((a, b) => +b.createdAt - +a.createdAt)
      )
    }
  })

  useEffect(() => {
    if (account && settings?.contracts) {
      const variables = {
        address: account,
        contract: settings.contracts.blindBoxLotteryPhase2
      }
      if (!called) {
        loadBoxes({ variables })
      } else {
        refetch(variables)
      }
    }
  }, [account, settings])

  const onAfterClaim = useCallback(async () => {
    await refetch({
      address: account,
      contract: settings!.contracts!.blindBoxLotteryPhase2
    })
  }, [account, settings, refetch])

  return (
    <div className="blind-boxes-claiming-page">
      <section className="vesting-section">
        <Container>
          <LoadingWrap loading={!lootBoxes}>
            {lootBoxes && (
              <BoxesList boxes={lootBoxes} onAfterClaim={onAfterClaim} />
            )}
          </LoadingWrap>
        </Container>
      </section>
    </div>
  )
}
