import { useLotteryContract } from '@contracts/hooks/useContracts'
import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useIsMounted } from '@hooks/useIsMounted'
import { BlockNumber, TransactionReceipt } from 'web3-core'

import { TransactionResult } from '@contracts/types'
import { apolloClient } from '@api/subgraph/client'
import {
  getReferralRewards,
  getSingleLootBoxQuery,
  LootBox,
  mapLootBox,
  ReferralReward,
} from '@api/subgraph/loot-boxes'
import { usePlatformSettings } from '@hooks/usePlatformSettings'
import Web3 from 'web3'
import { INotifyTxCallbacks, useWalletContext } from '@firestarter-private/firestarter-library/lib/hooks'
import { ContractAddress } from '@firestarter-private/firestarter-library/lib/types'
import { useTransactions } from '@firestarter-private/firestarter-library'

export enum LotteryWinTypes {
  NFT,
  FLAME,
}

export interface BoxRewardData {
  isNFT: boolean
  isVesting: boolean
  buyAmount: BigNumber
  rewardAmount: BigNumber
  openedAt: number
  txHash?: string
}

export interface ReferralRewardData {
  avialableBoxes: ReferralReward[]
  openedBoxes: ReferralReward[]
}

/**
 *
 * @param address - address of lottery contract
 * @param web3 - web3 instance (use only when need to display static data)
 */
export const useBlindBoxLottery = (address?: ContractAddress, web3?: Web3) => {
  const contract = useLotteryContract(address, web3)
  const { account } = useWalletContext()
  const [openedBoxes, setOpenedBoxes] = useState<ReferralReward[]>()
  const [avialableBoxes, setAvialableBoxes] = useState<ReferralReward[]>()

  const { settings } = usePlatformSettings()
  const [minFlameAmount, setMinFlameAmount] = useState<BigNumber>(
    new BigNumber(0),
  )
  const [maxFlameAmount, setMaxFlameAmount] = useState<BigNumber>(
    new BigNumber(0),
  )
  const [startDate, setStartDate] = useState<Date | null>()
  const [endDate, setEndDate] = useState<Date | null>()

  const isMountedRef = useIsMounted()

  const [blockNumber, setBlockNumber] = useState<BlockNumber | undefined>('latest')
  const { callTransaction, sendTransaction } = useTransactions()

  const getPhaseData = useCallback(async () => {
    if (!contract) return

    const [start, end, minFlame, maxFlame] = await Promise.all([
      callTransaction(contract.methods.startTime(), blockNumber),
      callTransaction(contract.methods.endTime(), blockNumber),
      callTransaction(contract.methods.minFlameAmount(), blockNumber),
      callTransaction(contract.methods.maxFlameAmount(), blockNumber),
    ])

    const output = {
      startDate: start === '0' ? null : new Date(+start * 1000),
      endDate: end === '0' ? null : new Date(+end * 1000),
      minFlameAmount: new BigNumber(minFlame),
      maxFlameAmount: new BigNumber(maxFlame),
    }
    if (isMountedRef.current) {
      setStartDate(output.startDate)
      setEndDate(output.endDate)
      setMinFlameAmount(output.minFlameAmount)
      setMaxFlameAmount(output.maxFlameAmount)
    }
    return output
  }, [contract, isMountedRef, callTransaction])

  const getBoxRewardsData = useCallback(
    async (id: string): Promise<BoxRewardData | undefined> => {
      if (!address || !contract) return
      const data = await callTransaction(
        contract.methods.boxes(id),
        blockNumber,
      )
      const response = await apolloClient.query({
        query: getSingleLootBoxQuery,
        variables: {
          id: `${address.toLowerCase()}_${id}`,
        },
        fetchPolicy: 'network-only',
      })

      if (!response.data?.lootBox) {
        return {
          isNFT: false,
          buyAmount: new BigNumber(0),
          rewardAmount: new BigNumber(0),
          openedAt: 0,
          isVesting: false,
        }
      }

      const box = mapLootBox(response.data.lootBox)

      return {
        isNFT: +box.rewardType === LotteryWinTypes.NFT,
        buyAmount: new BigNumber(box.buyAmount),
        rewardAmount: new BigNumber(box.rewardAmount),
        openedAt: box.txHash ? box.createdAt.getTime() : +data.openedAt * 1000,
        isVesting: box.isVesting,
        txHash: box.txHash,
      }
    },
    [address, contract, callTransaction, blockNumber],
  )

  const getReferralRewardsData = useCallback(async (): Promise<
    ReferralRewardData | undefined
  > => {
    if (!address) return
    const response = await apolloClient.query({
      query: getReferralRewards,
      variables: {
        address: account,
        contract: settings?.contracts?.blindBoxLotteryPhase1,
      },
      fetchPolicy: 'network-only',
    })

    const referralRewards: ReferralReward[] = response.data.referralRewards
    const openedBoxes = referralRewards.filter((item) => item.isUsed)
    const avialableBoxes = referralRewards.filter((item) => !item.isUsed)
    setOpenedBoxes(openedBoxes)
    setAvialableBoxes(avialableBoxes)

    return {
      openedBoxes,
      avialableBoxes,
    }
  }, [address, account])

  const getWithdrawable = useCallback(
    async (boxId: string) => {
      if (!contract) return new BigNumber(0)
      const amount = await callTransaction(
        contract.methods.withdrawable(boxId),
        blockNumber,
      )
      return new BigNumber(amount)
    },
    [contract, callTransaction, blockNumber],
  )

  const purchase = useCallback(
    async (
      amount: string,
      callbacks: INotifyTxCallbacks = {},
    ): Promise<TransactionResult> => {
      if (!contract) {
        return {
          error: 'No contract initialized. Try again later',
        }
      }

      try {
        const receipt = (await sendTransaction(
          contract.methods.createBox(amount),
          callbacks,
        )) as TransactionReceipt
        setBlockNumber(receipt.blockNumber)

        return {
          data: receipt.events?.BoxCreated.returnValues,
          txHash: receipt.transactionHash,
          blockNumber: receipt.blockNumber,
        }
      } catch (error) {
        return { error }
      }
    },
    [contract, sendTransaction],
  )

  const openFreeBox = useCallback(
    async (callbacks: INotifyTxCallbacks = {}): Promise<TransactionResult> => {
      if (!contract) {
        return {
          error: 'No contract initialized. Try again later',
        }
      }
      if (!avialableBoxes || avialableBoxes.length === 0) {
        return {
          error: 'Do not have boxes to open',
        }
      }

      try {
        const lastReferralId = avialableBoxes.sort(
          (a: ReferralReward, b: ReferralReward) =>
            Number(b.referralId) - Number(a.referralId),
        )[0].referralId
        const receipt = (await sendTransaction(
          contract.methods.claimFreeLootBox(lastReferralId),
          callbacks,
        )) as TransactionReceipt
        setBlockNumber(receipt.blockNumber)

        return {
          data: receipt.events?.ClaimFreeLootBox.returnValues,
          txHash: receipt.transactionHash,
          blockNumber: receipt.blockNumber,
        }
      } catch (error) {
        return { error }
      }
    },
    [contract, sendTransaction, avialableBoxes],
  )

  const claim = useCallback(
    async (boxId: string, callbacks: INotifyTxCallbacks = {}) => {
      if (!contract) return
      const receipt = (await sendTransaction(
        contract.methods.withdraw(boxId),
        callbacks,
      )) as TransactionReceipt
      setBlockNumber(receipt.blockNumber)
    },
    [contract, sendTransaction],
  )

  return {
    startDate,
    endDate,
    minFlameAmount,
    maxFlameAmount,
    getPhaseData,
    getBoxRewardsData,
    getWithdrawable,
    purchase,
    claim,
    getReferralRewardsData,
    openedBoxes,
    openFreeBox,
    avialableBoxes,
  }
}
