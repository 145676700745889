import MobileDetect from 'mobile-detect';

export const minWebsiteWidth = 991

export function detectMobile(): boolean {
  const ua = navigator.userAgent
  const detect = new MobileDetect(ua, minWebsiteWidth)
  return !!detect.mobile()
}

export function detectPhantomInAppBrowser(): boolean {
  return detectMobile()
    && (window.solana?.isPhantom || window.phantom?.solana.isPhantom)
}
