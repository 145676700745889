import { Timer } from '@components/common'
import './LotteryBannerPhase.scss'
import { useTimeLeft } from '@hooks/useTimeLeft'
import { LotteryRulesModal } from '../LotteryRulesModal/LotteryRulesModal'
import { numberToCurrency } from '@utils/balanceFormatter'
import { useMemo } from 'react'
import { useReactiveDate } from '@hooks/useReactiveDate'
import { isFuture, isPast } from 'date-fns'

interface Phase {
  startDate: number | null
  endDate: number | null
  maxAmount: number
}

interface Props {
  phases: [Phase, Phase]
}

enum CurrentPhase {
  Pending1st,
  InProgress1st,
  Pending2nd,
  InProgress2nd,
  Finished,
}

interface BlockProps {
  currentPhase: CurrentPhase
  phases: [Phase, Phase]
}

const calcCurrentPhase = (phases: [Phase, Phase]): CurrentPhase => {
  switch (true) {
    case !phases[0].startDate || isFuture(phases[0].startDate):
      return CurrentPhase.Pending1st
    case phases[0].startDate && isPast(phases[0].startDate) && (!phases[0].endDate || isFuture(phases[0].endDate)):
      return CurrentPhase.InProgress1st
    case phases[0].endDate && isPast(phases[0].endDate) && (!phases[1].startDate || isFuture(phases[1].startDate)):
      return CurrentPhase.Pending2nd
    case phases[1].startDate && isPast(phases[1].startDate) && (!phases[1].endDate || isFuture(phases[1].endDate)):
      return CurrentPhase.InProgress2nd
    default:
      return CurrentPhase.Finished
  }
}

const getInfoForMainBlock = ({ currentPhase, phases }: BlockProps) => {
  let labelText: string
  let countdownTitle: string = 'Ends in'
  let isActive: boolean = false
  let end: number

  switch (currentPhase) {
    case CurrentPhase.InProgress1st:
    case CurrentPhase.InProgress2nd:
      labelText = 'In Progress'
      isActive = true
      break
    case CurrentPhase.Pending1st:
    case CurrentPhase.Pending2nd:
      labelText = 'Coming soon'
      countdownTitle = 'Starts in'
      break
    case CurrentPhase.Finished:
      labelText = 'Completed'
      countdownTitle = ''
  }

  switch (currentPhase) {
    case CurrentPhase.Pending1st:
      end = phases[0].startDate || -1
      break
    case CurrentPhase.InProgress1st:
      end = phases[0].endDate || -1
      break
    case CurrentPhase.Pending2nd:
      end = phases[1].startDate || -1
      break
    case CurrentPhase.InProgress2nd:
      end = phases[1].endDate || -1
      break
    default:
      end = -1
  }
  return {
    labelText,
    countdownTitle,
    isActive,
    end,
  }
}

const PrevPhaseBlock = ({ currentPhase }: Omit<BlockProps, 'phases'>) => {
  const condition = currentPhase >= CurrentPhase.Pending2nd
  return condition ? (
    <div className="lottery-phase__prev">
      <span>Phase 1</span>
      <div className="lottery-phase__badge">Completed</div>
    </div>
  ) : null
}

const NextPhaseBlock = ({ currentPhase }: Omit<BlockProps, 'phases'>) => {
  const condition = currentPhase < CurrentPhase.Pending2nd
  return condition ? (
    <div className="lottery-phase__next">
      <span>Phase 2</span>
      <div className="lottery-phase__badge">Coming soon</div>
    </div>
  ) : null
}

const CurrentPhaseBlock = ({ currentPhase, phases }: BlockProps) => {
  const { labelText, countdownTitle, isActive, end } = useMemo(
    () =>
      getInfoForMainBlock({
        currentPhase,
        phases,
      }),
    [currentPhase, phases],
  )

  const duration = useTimeLeft(new Date(end))

  let phaseTitle: string = `Phase ${
    currentPhase < CurrentPhase.Pending2nd ? 1 : 2
  }`

  const currentPhaseIndex = currentPhase < CurrentPhase.Pending2nd ? 0 : 1

  return (
    <div className={`lottery-phase__current${isActive ? ' active' : ''}`}>
      <div className="lottery-phase__badge">{labelText}</div>
      <div className="lottery-phase__info">
        <span className="lottery-phase__phase-title">{phaseTitle}</span>
        <span className="lottery-phase__rules">
          <LotteryRulesModal />
        </span>
        {
          !!phases[currentPhaseIndex].startDate && currentPhase < CurrentPhase.Finished && (
            <span className="lottery-phase__prize">
              Win up to
              <strong>
                {numberToCurrency(
                  currentPhase < CurrentPhase.Pending2nd
                    ? phases[0].maxAmount
                    : phases[1].maxAmount,
                )}{' '}
                <small>FLAME</small>
              </strong>
            </span>
          )
        }
        {
          duration && currentPhase !== CurrentPhase.InProgress2nd && (
            <span className="lottery-phase__countdown">
              <span className="title">{countdownTitle}</span>
              <Timer duration={duration} />
            </span>
          )
        }
        {
          currentPhase === CurrentPhase.InProgress2nd && (
            <span className="lottery-phase__live-title gradient-text gradient-text--purple">Live</span>
          )
        }
      </div>
    </div>
  )
}

export const LotteryBannerPhase = ({ phases }: Props) => {
  const reactiveDate = useReactiveDate()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentPhase: CurrentPhase = useMemo(() => calcCurrentPhase(phases), [
    reactiveDate,
    phases,
  ])

  return (
    <section className="lottery-phase">
      <div className="lottery-phase__wrapper">
        <h2 className="lottery-phase__heading">Blind Box Lottery</h2>
        <p className="lottery-phase__desc">
          Experience the excitement of Blind Box Lottery - purchase a sealed box
          for a surprise collectible. Discover amazing prizes!
        </p>
        <PrevPhaseBlock currentPhase={currentPhase} />
        <CurrentPhaseBlock currentPhase={currentPhase} phases={phases} />
        <NextPhaseBlock currentPhase={currentPhase} />
      </div>
    </section>
  )
}
