import { useCallback, useEffect, useMemo, useState } from 'react'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { PublicKey, SYSVAR_CLOCK_PUBKEY } from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import {
  useProjectVestingContract,
  useTokenInfo,
  useTransactions,
  useWalletContext,
} from '@firestarter-private/firestarter-library'
import { toPubKey } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import { BNToBigNumber, toBigNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'
import {
  IUseMultipleVestingArgs,
  IUseMultipleVestingReturns,
  VestingAccountResponse,
  VestingInfo,
  VestingRecipientInfoResponse,
} from '@contracts/hooks/useVesting/types'
import {
  getVestingAccountWithBump,
  getVestingRecipientAccount,
  getVestingVaultAccountWithBump,
} from '@contracts/hooks/useVesting/functions'
import { NetworkType } from '@firestarter-private/firestarter-library/lib/constants'

export const useVestingSolana = ({
  rewardTokenAddress,
  vestingProgramId,
  vestingIdlName,
}: IUseMultipleVestingArgs): IUseMultipleVestingReturns => {
  const isMountedRef = useIsMounted()
  const { account } = useWalletContext()
  const publicKey = useMemo(() => account && toPubKey(account), [account])
  const vestingProgram = useProjectVestingContract(vestingProgramId, vestingIdlName)
  const { sendTransaction } = useTransactions()

  const [vestingAccount, setVestingAccount] = useState<PublicKey>()
  const [vestingAccountBump, setVestingBump] = useState<number>()
  const [vaultAccount, setVaultAccount] = useState<PublicKey>()
  const [vaultAccountBump, setVaultBump] = useState<number>()
  const [recipientInfoAccount, setRecipientAccount] = useState<PublicKey>()
  const [recipientInfoBump, setRecipientBump] = useState<number>()

  const [loading, setLoading] = useState(false)
  const [vestingInfo, setVestingInfo] = useState<VestingInfo>()
  const [totalVested, setVested] = useState<BigNumber>(toBigNumber(0))
  const [claimed, setClaimed] = useState<BigNumber>(toBigNumber(0))

  const rewardToken = useTokenInfo(rewardTokenAddress, NetworkType.solana)

  const initialUnlockAmount = useMemo(() => {
    return vestingInfo ? totalVested.multipliedBy(vestingInfo.initialUnlock).div(1000) : toBigNumber(0)
  }, [totalVested, vestingInfo])

  const vestingUnlockAmount = useMemo(() => {
    return vestingInfo ? totalVested.multipliedBy(vestingInfo.vestingUnlock).div(1000) : toBigNumber(0)
  }, [totalVested, vestingInfo])

  const unlockAmountPerInterval = useMemo(() => {
    return vestingInfo ? totalVested.multipliedBy(vestingInfo.releaseRate).div(1000) : toBigNumber(0)
  }, [totalVested, vestingInfo])

  const unlockedAmount = useMemo(() => {
    if (!vestingInfo) return toBigNumber(0)

    const currentTimeBN = toBigNumber(new Date().getTime() / 1000)
    if (vestingInfo.startTime.isZero() || vestingInfo.startTime.gt(currentTimeBN)) {
      return toBigNumber(0)
    }
    if (vestingInfo.startTime.lte(currentTimeBN) && vestingInfo.lockEndTime.gt(currentTimeBN)) {
      return vestingUnlockAmount
    }

    const vestingEndTime = vestingInfo.lockEndTime.plus(vestingInfo.vestingPeriod)

    if (currentTimeBN.gt(vestingEndTime)) {
      return totalVested
    }

    const vestingDuration = BigNumber.min(currentTimeBN, vestingEndTime).minus(vestingInfo.lockEndTime)

    return vestingDuration
      .div(vestingInfo.releaseInterval)
      .multipliedBy(unlockAmountPerInterval)
      .plus(vestingUnlockAmount)
      .plus(initialUnlockAmount)
  }, [vestingInfo, initialUnlockAmount, unlockAmountPerInterval, vestingUnlockAmount, totalVested])

  const withdrawable = useMemo(() => {
    return unlockedAmount.isZero() ? toBigNumber(0) : unlockedAmount.minus(claimed)
  }, [unlockedAmount, claimed])

  const unvested = useMemo(() => {
    return totalVested.isZero() ? toBigNumber(0) : totalVested.minus(unlockedAmount)
  }, [totalVested, unlockedAmount])

  const resetAddresses = () => {
    setVestingAccount(undefined)
    setVestingBump(undefined)
    setVaultAccount(undefined)
    setVaultBump(undefined)
  }

  const fetchAddresses = useCallback(async () => {
    if (!vestingProgram || !rewardTokenAddress || !vestingProgramId) {
      isMountedRef.current && resetAddresses()
      return
    }

    const [vaultAcc, vestingAcc] = await Promise.all([
      getVestingVaultAccountWithBump(new PublicKey(rewardTokenAddress), new PublicKey(vestingProgramId)),
      getVestingAccountWithBump(new PublicKey(rewardTokenAddress), new PublicKey(vestingProgramId)),
    ])

    if (isMountedRef.current) {
      setVestingAccount(vestingAcc[0])
      setVestingBump(vestingAcc[1])
      setVaultAccount(vaultAcc[0])
      setVaultBump(vaultAcc[1])
    }
  }, [vestingProgram, rewardTokenAddress, vestingProgramId, isMountedRef])

  useEffect(() => {
    fetchAddresses()
  }, [vestingProgram, rewardTokenAddress])

  const getRecipientInfoAccount = useCallback(async () => {
    if (!rewardTokenAddress || !publicKey || !vestingProgram || !vestingProgramId) {
      setRecipientAccount(undefined)
      setRecipientBump(undefined)
      return
    }

    const [recpInfoAccount, recpInfoBump] = await getVestingRecipientAccount(
      publicKey,
      new PublicKey(rewardTokenAddress),
      new PublicKey(vestingProgramId),
    )

    if (isMountedRef.current) {
      setRecipientAccount(recpInfoAccount)
      setRecipientBump(recpInfoBump)
    }
  }, [vestingProgram, publicKey, rewardTokenAddress, vestingProgramId, isMountedRef])

  useEffect(() => {
    getRecipientInfoAccount()
  }, [publicKey, rewardTokenAddress, vestingProgram])

  const getVestingInfo = useCallback(async () => {
    if (!vestingProgram || !vestingAccount) {
      isMountedRef.current && setVestingInfo(undefined)
      return
    }

    try {
      const data = (await vestingProgram.account.vestingAccount.fetch(vestingAccount)) as VestingAccountResponse
      const lockEndTime = BNToBigNumber(data.startTime).plus(BNToBigNumber(data.lockPeriod))
      isMountedRef.current &&
        setVestingInfo({
          ...data,
          amountToBeVested: BNToBigNumber(data.amountToBeVested),
          startTime: BNToBigNumber(data.startTime),
          releaseInterval: BNToBigNumber(data.releaseInterval),
          releaseRate: BNToBigNumber(data.releaseRate),
          vestingUnlock: BNToBigNumber(data.vestingUnlock),
          initialUnlock: BNToBigNumber(data.initialUnlock),
          lockPeriod: BNToBigNumber(data.lockPeriod),
          vestingPeriod: BNToBigNumber(data.vestingPeriod),
          totalVestingAmount: BNToBigNumber(data.totalVestingAmount),
          lockEndTime,
        } as VestingInfo)
    } catch (err) {
      setVestingInfo(undefined)
    }
  }, [vestingProgram, vestingAccount, isMountedRef])

  useEffect(() => {
    getVestingInfo()
  }, [vestingProgram, vestingAccount])

  const resetUserInfo = () => {
    setVested(toBigNumber(0))
    setClaimed(toBigNumber(0))
  }

  const getUserVestingInfo = useCallback(async () => {
    if (!vestingProgram || !recipientInfoAccount) {
      isMountedRef.current && resetUserInfo()
      return
    }

    try {
      const data = (await vestingProgram.account.recipientInfo.fetch(
        recipientInfoAccount,
      )) as VestingRecipientInfoResponse
      if (isMountedRef.current) {
        setVested(BNToBigNumber(data.totalAmount))
        setClaimed(BNToBigNumber(data.withdrawnAmount))
      }
    } catch (err) {
      isMountedRef.current && resetUserInfo()
    }
  }, [vestingProgram, recipientInfoAccount, isMountedRef])

  useEffect(() => {
    if (!loading && vestingProgram && recipientInfoAccount) {
      getUserVestingInfo()
    }
  }, [vestingProgram, recipientInfoAccount, loading])

  const withdraw = useCallback(
    async (callbacks: INotifyTxCallbacks = {}) => {
      if (
        !publicKey ||
        !vestingProgram ||
        !vaultAccount ||
        !vestingAccount ||
        !vaultAccountBump ||
        !vestingAccountBump ||
        !recipientInfoAccount ||
        !recipientInfoBump
      ) {
        return
      }
      setLoading(true)

      const rewardTokenAccount = await rewardToken.getOrCreateAssociatedAccount()

      if (!rewardTokenAccount) {
        setLoading(false)
        return
      }

      const tx = await vestingProgram.transaction.withdraw(vestingAccountBump, vaultAccountBump, recipientInfoBump, {
        accounts: {
          taker: publicKey,
          takerReceiveTokenAccount: rewardTokenAccount,
          vaultAccount,
          vestingAccount,
          vestingInfo: recipientInfoAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          clock: SYSVAR_CLOCK_PUBKEY,
        },
      })

      await sendTransaction(tx, callbacks)
      setLoading(false)
    },
    [
      publicKey,
      vestingProgram,
      rewardToken.getOrCreateAssociatedAccount,
      vestingInfo,
      recipientInfoAccount,
      recipientInfoBump,
      vaultAccount,
      vaultAccountBump,
      vestingAccount,
      vestingAccountBump,
      sendTransaction,
    ],
  )

  return {
    isClaiming: loading,
    totalVested,
    unvested,
    claimed,
    withdrawable,
    getUserVestingInfo,
    withdraw,
  }
}
