import { WalletAddress } from '@contracts/address';
import { KYCSuccessResponse } from './types';
import { instanceWithSignature as API } from '../config';
import { sendExceptionReport } from '@utils/errors';
import { withWalletRequestFactory } from '@api/factories'

export const geyKYCStatus = withWalletRequestFactory(
  async (walletParamName, address: WalletAddress): Promise<KYCSuccessResponse | null> => {
    try {
      const { data } = await API.get<KYCSuccessResponse>('/get-kyc-status', {
        params: {
          [walletParamName]: address,
        },
      })

      return data
    } catch (err) {
      sendExceptionReport(err)
      return null
    }
  })
