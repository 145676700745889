import { ProjectStatusesProps, ProjectStatusProp } from '@components/Project/types'
import React, { useEffect, useMemo } from 'react'
import { useTimeLeft } from '@hooks/useTimeLeft'
import { Timer } from '@components'
import './ProjectStatusBar.scss'
import { format } from 'date-fns'

interface Props {
  status: ProjectStatusProp
  whitelistOpens?: Date | null
  whitelistCloses?: Date | null
  presaleOpens?: Date | number | null
}
export const ProjectStatusBar = React.memo(({
  status,
  whitelistOpens,
  whitelistCloses,
  presaleOpens,
}: Props) => {
  const isProjectActive = useMemo(
    () =>  [
      ProjectStatusesProps['FCFS Live'],
      ProjectStatusesProps['FCFS Coming'],
      ProjectStatusesProps['Guaranteed Live'],
    ].includes(status as ProjectStatusesProps),
    [status])

  const isProjectClosed = useMemo(
    () => status === ProjectStatusesProps.Closed,
    [status]
  )

  const startsIn = useTimeLeft(presaleOpens)

  const statusTitle = useMemo(() => {
    if (
      (status === ProjectStatusesProps['Coming Soon'] && !whitelistOpens) ||
      (status === ProjectStatusesProps['Registration Closed'] && !presaleOpens)
    ) {
      return 'Coming Soon'
    }
    if (status === ProjectStatusesProps['Coming Soon'] && whitelistOpens) {
      return 'Registration opens'
    }

    if (status === ProjectStatusesProps['Registration Open'] && whitelistCloses) {
      return 'Registration closes'
    }

    if (status === ProjectStatusesProps['Registration Open'] && !whitelistCloses) {
      return 'Registration in progress'
    }

    if (status === ProjectStatusesProps['Registration Closed'] && presaleOpens) {
      return 'Starts in'
    }

    if (isProjectActive) {
      return 'In progress'
    }

    if (isProjectClosed) {
      return 'Closed'
    }
  }, [status, isProjectActive, isProjectClosed])

  return (
    <div className="project-statusbar">
      <div className="project-statusbar__title">
        {statusTitle}
      </div>
      <div className="project-statusbar__date">
        {
          (status === ProjectStatusesProps['Coming Soon'] && whitelistOpens)
            ? format(whitelistOpens, 'yyyy-MM-dd')
            : (status === ProjectStatusesProps['Registration Open'] && whitelistCloses)
              ? format(whitelistCloses, 'yyyy-MM-dd')
              : (status === ProjectStatusesProps['Registration Closed'] && startsIn)
                ? <Timer duration={startsIn} />
                : null
        }
      </div>
    </div>
  )
})
