import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as LightIcon } from '@assets/tiers/light.svg'
import { ReactComponent as SparkIcon } from '@assets/tiers/spark.svg'
import { ReactComponent as SmokeIcon } from '@assets/tiers/smoke.svg'
import { ReactComponent as FireIcon } from '@assets/tiers/fire.svg'
import { ReactComponent as FlameIcon } from '@assets/tiers/flame.svg'
import { ReactComponent as TierArrow } from '@assets/tier-arrow.svg'
import { ReactComponent as PillBottom } from '@assets/half-circle.svg'
import { ReactComponent as PillTop } from '@assets/half-circle-down.svg'
import { FlameTier, FlameTiers, tierBreakpoints } from '@contracts/hooks/useFlameTier/constants';
import './FlameLevelMobile.scss';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import { numberToCurrency } from '@utils/balanceFormatter';

interface Props {
  currentTier: FlameTier | null
}

const tiersArray = Object.values(FlameTiers) as FlameTier[]

const Tier = (props: { currentTier: string | null, breakpoint: string, title: FlameTier, children: React.ReactNode }) =>
  <div className="item">
    <div className="icon">
      {props.currentTier === props.title && <TierArrow className="arrow" />}
      {props.children}
    </div>
    <div className="description">
      <div className="title">
        {props.title}
      </div>
      <div className="subtitle">
        {`${props.breakpoint}+ FLAME POWER`}
      </div>
    </div>
  </div>

const Pill = () =>
  <div className="pill">
    <PillTop />
    <PillBottom />
  </div>

const FlameLevelMobile = ({ currentTier }: Props) => {
  const { settings } = usePlatformSettings()
  const breakpoints = useMemo(() => {
    return tierBreakpoints.map(point => numberToCurrency(+point * (settings?.flameTiersFactor ?? 1)))
  }, [settings])

  const tierIndex: number = useMemo(() => {
    return tiersArray.findIndex(t => t === currentTier)
  }, [currentTier])

  const overlayWidth: number = useMemo(() => {
    if (tierIndex === -1) return 0
    if (tierIndex === tiersArray.length - 1) return 100
    return 20 * (tierIndex + 1)
  }, [tierIndex])

  return (
    <Row className="mobile-heat-row gx-0">
      <Col xs={1}>
        <div className="color-strip">
          <div className="pills">
            <Pill />
            <Pill />
            <Pill />
            <Pill />
            <Pill />
          </div>
          <div
            className='color-strip__overlay'
            style={{ height: `${overlayWidth}%`, }}
          />
        </div>
      </Col>
      <Col className="text-left column" xs={11}>
        <div className="progress">
          <Tier
            currentTier={currentTier}
            breakpoint={breakpoints[4]}
            title="FLAME">
            <FlameIcon />
          </Tier>
          <Tier
            currentTier={currentTier}
            breakpoint={breakpoints[3]}
            title="FIRE">
            <FireIcon />
          </Tier>
          <Tier
            currentTier={currentTier}
            breakpoint={breakpoints[2]}
            title="SMOKE">
            <SmokeIcon />
          </Tier>
          <Tier
            currentTier={currentTier}
            breakpoint={breakpoints[1]}
            title="SPARK">
            <SparkIcon />
          </Tier>
          <Tier
            currentTier={currentTier}
            breakpoint={breakpoints[1]}
            title="LIGHT">
            <LightIcon />
          </Tier>
        </div>
      </Col>
    </Row>
  );
};

FlameLevelMobile.defaultProps = {
  currentTier: FlameTiers.FLAME
}

export { FlameLevelMobile }
