import { getAppStorage } from '@hooks/useStorages'
import {
  defaultEVMNetworkId,
  defaultSolanaNetworkId,
  supportedNetworks,
  NetworksIDs,
  NetworkId,
  NetworkInfo,
} from '@firestarter-private/firestarter-library/lib/constants'
import { detectPhantomInAppBrowser } from '@utils/detectMobile'

export const getNetworkIcon = (network: NetworkInfo): string => {
  return `/networks/${network?.icon}.png`
}

const supportedIds = [
  NetworksIDs.matic,
  NetworksIDs.maticmum,
  NetworksIDs.bnb,
  NetworksIDs.bnbt,
  NetworksIDs.eth,
  NetworksIDs.rin,
  NetworksIDs.solana,
  NetworksIDs.solanadev,
]
export const supportedNetworksFiltered = Object
  .entries(supportedNetworks)
  .reduce<Record<NetworkId, NetworkInfo>>((acc, [id, network]) => {
    if (supportedIds.includes(id as NetworksIDs)) {
      acc[id] = network
    }
    return acc
  }, {})

export const getCurrentNetworkId = (): NetworkId => {
  if (detectPhantomInAppBrowser()) return defaultSolanaNetworkId
  return getAppStorage().currentNetworkId || defaultEVMNetworkId
}
