import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  balanceToCurrency,
  balanceToNumber,
  balanceToNumeric,
  numericToBalance,
  numericToUint256,
} from '@utils/balanceFormatter'
import {
  ApprovalSteps,
  BalanceItem,
  Divider,
  LotteryResult,
  LotteryResultModal,
  LotteryResultsList,
  LotteryRulesModal,
  RoundButton,
  Spinner,
  StayTunedBanner,
  TutorialSection,
} from '@components'
import { Col, Container, Form, FormControl, Image, InputGroup, Row } from 'react-bootstrap'
import InputIcon from '@assets/input-icon.svg'
import OpenBoxIcon from '@assets/lottery/free-box/box-opened.png'
import EarnedBoxIcon from '@assets/lottery/free-box/box-earned.png'
import { ReactComponent as CloseIcon } from '@assets/close.svg'
import { ReactComponent as ForwardArrow } from '@assets/forward_arrow.svg'
import { ReactComponent as AttentionIcon } from '@assets/error_black.svg'
import './BlindBoxesLottery.scss'
import { useBlindBoxLottery } from '@contracts/hooks/useBlindBoxLottery'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import { useLotteryContext } from '@pages/BlindBoxes/useLotteryContext'
import { useReactiveDate } from '@hooks/useReactiveDate'
import { differenceInMinutes, isFuture, isPast } from 'date-fns'
import { ChainvineClient, getReferrerId } from '@chainvine/sdk'
import { useStorages } from '@hooks/useStorages'
import { RoutesHashes, RoutesPaths } from '@/router/constants'
import { useLazyQuery } from '@apollo/client'
import { getAllLootBoxesQuery, LootBoxesResponse, LootBoxFormatted, mapLootBox } from '@api/subgraph/loot-boxes'
import { BlockNumber } from 'web3-core'
import { getBlockNumberByTxHash } from '@utils/web3'
import { useApproval, useTokenInfo, useWalletContext } from '@firestarter-private/firestarter-library'
import {
  defaultEVMNetworkData,
  defaultEVMNetworkId,
  NetworkType, polygonContractAddresses,
  web3NoAccountInstances,
} from '@firestarter-private/firestarter-library/lib/constants'

const CHAINVINE_CAMPAIGN_ID = process.env.REACT_APP_CHAINVINE_CAMPAIGN_ID

interface FormMessage {
  type: 'error' | 'message' | 'pending'
  text: string
}

const isBoxOpeningTimeoutExceeded = (createdAt: Date | number) => {
  return differenceInMinutes(Date.now(), createdAt) > 10
}
export const BlindBoxesLottery = () => {
  const { currentPhase, currentPhaseIndex } = useLotteryContext()
  const reactiveDate = useReactiveDate()
  const { account } = useWalletContext()
  const { appStorage } = useStorages()

  const isPhaseInProgress = useMemo(() => {
    if (!currentPhase || !currentPhase.startDate) return false
    return isPast(currentPhase.startDate) && (!currentPhase.endDate || isFuture(currentPhase.endDate))
  }, [reactiveDate, currentPhase])

  const {
    maxFlameAmount,
    minFlameAmount,
    getPhaseData,
    purchase,
    getBoxRewardsData,
    getReferralRewardsData,
    openedBoxes,
    avialableBoxes,
    openFreeBox,
  } = useBlindBoxLottery(currentPhase?.contractAddress)

  useEffect(() => {
    getPhaseData()
  }, [currentPhase?.contractAddress])

  useEffect(() => {
    getReferralRewardsData()
  }, [account, currentPhase?.contractAddress])

  const [isBuying, setIsBuying] = useState(false)
  const [isOpening, setIsOpening] = useState(false)
  const [amountToBuy, setAmountToBuy] = useState('')
  const [agreeWithRules, setAgreeWithRules] = useState(true)
  const [formMessage, setFormMessage] = useState<FormMessage>()
  const [lotteryResult, setLotteryResult] = useState<LotteryResult>()
  const boxOpeningInterval = useRef<ReturnType<typeof setInterval> | null>(null)

  const [usersLootBoxes, setUsersLootBoxes] = useState<LootBoxFormatted[]>()
  const [showResultsList, setShowResultsList] = useState(false)

  const [
    loadUserBoxes,
    {
      loading: loadingUserBoxes,
      called: calledUserBoxes,
      refetch: refetchUserBoxes,
    },
  ] = useLazyQuery<LootBoxesResponse>(getAllLootBoxesQuery, {
    variables: {
      address: account,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setUsersLootBoxes(
        data.lootBoxes
          .filter(box => box.rewardType !== null)
          .map(mapLootBox)
          .sort((a, b) => +b.createdAt - +a.createdAt),
      )
    },
  })

  const fetchUsersLootBoxes = useCallback(async () => {
    if (calledUserBoxes) {
      await refetchUserBoxes({ address: account })
    } else {
      await loadUserBoxes({ variables: { address: account } })
    }
  }, [loadUserBoxes, refetchUserBoxes, account, calledUserBoxes])

  useEffect(() => {
    if (account && showResultsList) {
      fetchUsersLootBoxes()
    }
  }, [account, showResultsList])

  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const {
    amount: flameBalance,
    decimals: flameDecimals,
    getTokenInfo,
  } = useTokenInfo(polygonContractAddresses.flameToken, NetworkType.evm)
  const { allowance, onApprove } = useApproval(
    polygonContractAddresses.flameToken,
    currentPhase?.contractAddress,
  )

  useEffect(() => {
    if (account && !isBuying) {
      getTokenInfo(blockNumber)
    }
  }, [account, isBuying, blockNumber])

  const setMaxToBuy = useCallback(() => {
    setAmountToBuy(
      balanceToNumeric(
        BigNumber.min(flameBalance, maxFlameAmount),
        flameDecimals,
      ),
    )
  }, [flameBalance, flameDecimals, maxFlameAmount])

  const invalidInput = useMemo(() => {
    const bnAmount = numericToBalance(amountToBuy, flameDecimals)
    return (
      +amountToBuy > 0 &&
      (flameBalance.isLessThan(bnAmount) ||
        bnAmount.isLessThan(minFlameAmount) ||
        bnAmount.isGreaterThan(maxFlameAmount))
    )
  }, [amountToBuy, flameBalance, flameDecimals, maxFlameAmount, minFlameAmount])

  const validationMessage = useMemo(() => {
    if (!+amountToBuy) return
    const bnAmount = numericToBalance(amountToBuy, flameDecimals)
    if (flameBalance.isLessThan(bnAmount)) {
      return 'The amount exceeds your balance'
    }
    if (
      bnAmount.isLessThan(minFlameAmount) ||
      bnAmount.isGreaterThan(maxFlameAmount)
    ) {
      return `Please enter a value between ${balanceToCurrency(
        minFlameAmount,
        flameDecimals,
      )} and ${balanceToCurrency(maxFlameAmount, flameDecimals)} FLAME`
    }
  }, [amountToBuy, flameBalance, flameDecimals, minFlameAmount, maxFlameAmount])

  const disabledBuying = useMemo(() => {
    const bnAmount = numericToBalance(amountToBuy, flameDecimals)
    return (
      +amountToBuy <= 0 ||
      invalidInput ||
      allowance.isLessThan(bnAmount) ||
      isOpening
    )
  }, [amountToBuy, allowance, flameDecimals, invalidInput, isOpening])

  const disabledOpening = useMemo(() => {
    return isOpening || avialableBoxes?.length === 0
  }, [avialableBoxes, isOpening])

  const handleApprove = () => onApprove()

  const finishBoxOpening = useCallback(
    (isBoxOpening: boolean) => {
      setFormMessage(undefined)
      isBoxOpening ? setIsOpening(false) : setIsBuying(false)
      boxOpeningInterval.current && clearInterval(boxOpeningInterval.current)
      boxOpeningInterval.current = null
      delete appStorage.openingLootBoxes[account!]

      isBoxOpening && getReferralRewardsData()
    },
    [boxOpeningInterval, appStorage.openingLootBoxes, account],
  )

  const startListeningForBoxOpening = useCallback(
    async (boxId: string, createdAt: number, isBoxOpening: boolean) => {
      isBoxOpening ? setIsOpening(true) : setIsBuying(true)
      setFormMessage({
        type: 'pending',
        text: 'Opening your box...',
      })

      boxOpeningInterval.current && clearInterval(boxOpeningInterval.current)
      boxOpeningInterval.current = setInterval(async () => {
        if (isBoxOpeningTimeoutExceeded(createdAt)) {
          finishBoxOpening(isBoxOpening)
          return
        }
        const data = await getBoxRewardsData(boxId)
        if (data && data.openedAt > 0) {
          setLotteryResult({
            isNFT: data.isNFT,
            isVesting: data.isVesting,
            buyAmount: balanceToNumber(data.buyAmount, flameDecimals),
            rewardAmount: balanceToNumber(data.rewardAmount, flameDecimals),
            explorerLink: `${defaultEVMNetworkData.explorerUrl}/tx/${data.txHash}`,
          })
          if (data.txHash) {
            const block = await getBlockNumberByTxHash(
              web3NoAccountInstances[defaultEVMNetworkId],
              data.txHash
            )
            setBlockNumber(block || 'latest')
          } else {
            setBlockNumber('latest')
          }
          finishBoxOpening(isBoxOpening)
          fetchUsersLootBoxes()
        }
      }, 5000)
    },
    [
      getBoxRewardsData,
      finishBoxOpening,
      boxOpeningInterval,
      appStorage.ignitingNFTs,
      flameDecimals,
    ],
  )

  const checkForPendingBoxes = useCallback(() => {
    if (!account) return
    const boxData = appStorage?.openingLootBoxes?.[account]
    if (!boxData) {
      finishBoxOpening(false)
      return
    }
    if (isBoxOpeningTimeoutExceeded(boxData.createdAt)) {
      delete appStorage.openingLootBoxes[account]
      return
    }
    startListeningForBoxOpening(boxData.boxId, boxData.createdAt, false)
  }, [account, appStorage.openingLootBoxes, startListeningForBoxOpening])

  useEffect(() => {
    if (account) {
      checkForPendingBoxes()
    } else {
      finishBoxOpening(false)
    }
  }, [account])

  const handleBuy = useCallback(async () => {
    if (disabledBuying || !account || !CHAINVINE_CAMPAIGN_ID) {
      return
    }
    setIsBuying(true)
    setFormMessage({
      type: 'pending',
      text: 'Signing transaction...',
    })
    const amount = numericToUint256(amountToBuy, flameDecimals)
    const response = await purchase(amount, {
      onHash: () => {
        setAmountToBuy('')
        setFormMessage({
          type: 'pending',
          text: 'Processing transaction...',
        })
      },
    })

    if ('error' in response) {
      setFormMessage({
        type: 'error',
        text:
          typeof response.error === 'string'
            ? response.error
            : 'Something went wrong. Try again please',
      })
      setIsBuying(false)
      return
    }

    if (!response.data) {
      setFormMessage(undefined)
      setIsBuying(false)
      return
    }

    setBlockNumber(response.blockNumber!)

    const client = new ChainvineClient()

    appStorage.openingLootBoxes[account] = {
      boxId: response.data.boxId,
      wallet: account,
      createdAt: Date.now(),
    }
    await startListeningForBoxOpening(response.data.boxId, Date.now(), false)

    const userClient = await client.syncUser(account)
    const referrerId = getReferrerId()
    if (referrerId) {
      await userClient
        .referral({
          campaign: {
            id: CHAINVINE_CAMPAIGN_ID,
          },
        })
        .linkToReferrer(referrerId)
      const completion = await userClient.completeRequirement({
        campaign: {
          id: CHAINVINE_CAMPAIGN_ID,
        },
        id: 'r1AcwTye_dNgT7YT16nW4',
      })
    }
  }, [account, amountToBuy, purchase, flameDecimals, disabledBuying])

  const handleOpenFreeBox = useCallback(async () => {
    if (!account) {
      return
    }
    setIsOpening(true)
    const response = await openFreeBox()

    if ('error' in response) {
      setIsOpening(false)
      return
    }

    if (!response.data) {
      setIsOpening(false)
      return
    }
    await startListeningForBoxOpening(response.data.boxId, Date.now(), true)
  }, [
    openFreeBox,
    avialableBoxes,
    disabledOpening,
    flameDecimals,
    account,
    getReferralRewardsData,
  ])

  return (
    <div className="blind-boxes-lottery">
      <TutorialSection />
      <section className="lottery-section">
        <Container>
          {isPhaseInProgress ? (
            <Row className="lottery-block">
              <Col lg={{ span: 6 }}>
                <div className="lottery-block__balance tile">
                  <BalanceItem
                    image="/token-logos/FLAME.svg"
                    title="Available to pay"
                    balance={balanceToCurrency(flameBalance, flameDecimals)}
                    token="FLAME"
                  />
                  <div className="balance-item balance-item--referral">
                    <div className="balance-item__desc">
                      <Image
                        className="balance-item__icon"
                        src={EarnedBoxIcon}
                        rounded
                      />
                      <div>
                        <div className="tile__description balance-item__title">
                          {avialableBoxes?.length
                            ? 'Free Boxes Available'
                            : <>Share your referral <br /> to earn free loot boxes</>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="tile__main balance-item__maind-flex g-5 justify-content-between">
                      {avialableBoxes?.length
                        ? <>
                          {avialableBoxes.length}
                          <RoundButton
                            size="large"
                            disabled={disabledOpening}
                            onClick={handleOpenFreeBox}
                          >
                            Open box
                          </RoundButton>
                        </>
                        : <RoundButton
                          color="LIGHT"
                          to={{
                            pathname: RoutesPaths.LOOT_BOXES.REFERRAL,
                            hash: RoutesHashes.LOOT_BOXES.LOTTERY_BLOCK,
                          }}
                        >
                          Share
                        </RoundButton>
                      }
                    </div>
                  </div>
                  <BalanceItem
                    image={OpenBoxIcon}
                    title="Free Boxes opened"
                    balance={String(openedBoxes?.length ?? 0)}
                  />
                </div>
                <div className="lottery-block__rules tile">
                  <span>How to participate in a lottery?</span>
                  <LotteryRulesModal />
                </div>
              </Col>
              <Col lg={{ span: 6 }}>
                <div
                  className={classNames('lottery-form tile', {
                    hidden: showResultsList,
                  })}
                >
                  <div className="lottery-form__heading">
                    <h2 className="title">Purchase a box</h2>
                    <RoundButton
                      color="DARK"
                      size="small"
                      onClick={() => setShowResultsList(true)}
                    >
                      My results <ForwardArrow />
                    </RoundButton>
                  </div>
                  <InputGroup
                    className={classNames(
                      'lottery-form__input-group input-group-big',
                      { invalid: invalidInput },
                    )}
                  >
                    <InputGroup.Prepend>
                      <img
                        className="lottery-form__input-icon"
                        src={InputIcon}
                        alt="FLAME"
                      />{' '}
                      <span>FLAME</span>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder="0.0"
                      type="number"
                      value={amountToBuy}
                      onChange={(e) => setAmountToBuy(e.target.value)}
                    />
                    <InputGroup.Append>
                      <RoundButton
                        size="small"
                        color="DARK"
                        onClick={setMaxToBuy}
                      >
                        MAX
                      </RoundButton>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Group controlId="agree_to_rules">
                    <Form.Check
                      className="justify-content-center"
                      label={
                        <>
                          I agree with the{' '}
                          <LotteryRulesModal
                            trigger={
                              <a href="javascrupt:">Blind Box Lottery Rules</a>
                            }
                          />{' '}
                        </>
                      }
                      checked={agreeWithRules}
                      onChange={(event) =>
                        setAgreeWithRules(event.target.checked)
                      }
                    />
                  </Form.Group>
                  <div
                    className={classNames('form-message text-center', {
                      'form-message--warning':
                        validationMessage ?? formMessage?.type === 'error',
                      hidden: !validationMessage && !formMessage,
                    })}
                  >
                    {formMessage ? (
                      <>
                        {formMessage.type === 'pending' && <Spinner />}{' '}
                        {formMessage.text}
                      </>
                    ) : (
                      validationMessage
                    )}
                  </div>
                  <div className="lottery-form__buttons">
                    <RoundButton
                      size="large"
                      disabled={
                        allowance.isGreaterThanOrEqualTo(
                          numericToBalance(amountToBuy, flameDecimals),
                        ) || !+amountToBuy
                      }
                      onClick={handleApprove}
                    >
                      Approve
                    </RoundButton>
                    <RoundButton
                      size="large"
                      disabled={disabledBuying}
                      onClick={handleBuy}
                    >
                      Buy
                    </RoundButton>
                  </div>
                  <ApprovalSteps
                    fillingCondition={allowance.isGreaterThanOrEqualTo(
                      numericToBalance(amountToBuy, flameDecimals),
                    )}
                  />
                  <div className="lottery-form__disclaimer form-disclaimer">
                    <div className="form-disclaimer__divider">
                      <Divider />
                      <AttentionIcon />
                      <Divider />
                    </div>
                    <p className="form-disclaimer__text">
                      Please note that there is a certain percentage
                      probability, that users may receive an amount between 10%
                      and 100% of their initial purchase price. The actual
                      outcome may vary and is subject to chance
                    </p>
                  </div>
                </div>
                <div
                  className={classNames('lottery-form lottery-form--results tile', {
                    hidden: !showResultsList,
                  })}
                >
                  <div className="lottery-form__heading">
                    <h2 className="title">My results</h2>
                    <CloseIcon
                      className="lottery-result__close"
                      onClick={() => setShowResultsList(false)}
                    />
                  </div>
                  <LotteryResultsList
                    loading={loadingUserBoxes}
                    lootBoxes={usersLootBoxes}
                    referralRewards={openedBoxes}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <StayTunedBanner phaseIndex={currentPhaseIndex} />
          )}
        </Container>
      </section>
      <LotteryResultModal
        result={lotteryResult}
        show={!!lotteryResult}
        onHide={() => setLotteryResult(undefined)}
      />
    </div>
  )
}
