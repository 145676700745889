import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ModalFormFillResponseView, SubscribeForm, SubscribeFormValues } from '@components'
import { subscribe } from '@api/subscription'
import { ReactComponent as CloseIcon } from '@assets/close.svg'

interface Props {
  show: boolean
  setShow: any
}

export const SubscribeModal = ({ show, setShow }: Props) => {
  const [formStage, setFormStage] = useState<'FILL' | 'RESPONSE'>('FILL')
  const [responseStatus, setResponseStatus] = useState<'error' | 'success' | null>(null)

  const resetForm = () => {
    setFormStage('FILL')
    setResponseStatus(null)
  }

  const submitSubscription = async (values: SubscribeFormValues) => {
    const response = await subscribe(values)
    setResponseStatus(response.result)
    setFormStage('RESPONSE')
  }

  return (
    <Modal
      show={show}
      centered
      onHide={() => setShow(false)}
      className="subscribe-modal"
      contentClassName="tile text-center"
    >
      <button className="modal-close-button" onClick={() => setShow(false)}>
        <CloseIcon />
      </button>
      <Modal.Body>
        {formStage === 'FILL' ? (
          <SubscribeForm onSubmit={submitSubscription} />
        ) : (
          <ModalFormFillResponseView status={responseStatus} onReset={resetForm} />
        )}
      </Modal.Body>
    </Modal>
  )
}
