import { FormFillResponse } from './types';
import axios from 'axios';
import { sendExceptionReport } from '@utils/errors';
import { IDOFormValues } from "@components/formfills/IMOForm/constants";

const IDOFormEndpoint = 'https://script.google.com/macros/s/AKfycbx3CoDQ4EKHkKWTRsgsWEIApCe5UjtZjGbtSHBrjgC5IBrftyBBkVDfdEXKYNYqZGjp7w/exec'

const spreadSheetKeys: Record<keyof IDOFormValues, string> = {
  contactName: 'Contact Name',
  contactPosition: 'Contact Position',
  contactTelegram: 'Contact Telegram Handle',
  contactEmail: 'Contact Email',
  teamGeography: 'Core Team’s Geography',
  projectName: 'Project Name',
  description: 'Project Description',
  projectStatus: 'Project Status (When do you expect working product/mainnet? Feel free to share your roadmap)',
  websiteUrl: 'Website',
  pitchDeckUrl: 'Pitch Deck (Optional)',
  projectTwitterUrl: 'Project Twitter',
  sourceAboutFS: 'How did you learn about FireStarter?'
}

export const submitToIDOForm = async (values: IDOFormValues): Promise<FormFillResponse> => {
  const formData = new FormData()
  formData.append('Timestamp', new Date().toLocaleString())
  for (let _key in values) {
    let key = _key as keyof IDOFormValues
    formData.append(spreadSheetKeys[key], values[key])
  }

  try {
    const response = await axios.post(
      IDOFormEndpoint,
      formData
    )

    const data = response.data as FormFillResponse

    if (data.result === 'error') {
      sendExceptionReport(data.error)
    }
    return data
  } catch (err) {
    sendExceptionReport(err)
    return {
      result: 'error',
      error: err
    }
  }
}
