import { PublicKey } from '@solana/web3.js'

export const getVestingRecipientAccount = async (
  publicKey: PublicKey,
  rewardMint: PublicKey,
  vestingProgramId: PublicKey,
): Promise<[PublicKey, number]> => {
  const [recpInfoAccount, recpInfoBump] = await PublicKey.findProgramAddress(
    [rewardMint.toBuffer(), publicKey.toBuffer(), Buffer.from('vesting-info')],
    vestingProgramId,
  )
  return [recpInfoAccount, recpInfoBump]
}

export const getVestingAccountWithBump = async (
  rewardMint: PublicKey,
  vestingProgramId: PublicKey,
): Promise<[PublicKey, number]> => {
  const [account, bump] = await PublicKey.findProgramAddress(
    [rewardMint.toBuffer(), Buffer.from('vesting-seed')],
    vestingProgramId,
  )
  return [account, bump]
}

export const getVestingVaultAccountWithBump = async (
  rewardMint: PublicKey,
  vestingProgramId: PublicKey,
): Promise<[PublicKey, number]> => {
  const [account, bump] = await PublicKey.findProgramAddress(
    [rewardMint.toBuffer(), Buffer.from('token-seed')],
    vestingProgramId,
  )
  return [account, bump]
}
