import { gql } from '@apollo/client'

export const getLootBoxesQuery = gql`
  query getLootBoxes($address: String, $contract: String) {
    lootBoxes(
      where: { owner: $address, contract: $contract, rewardType: "1" }
    ) {
      id
      owner
      contract
      buyAmount
      boxId
      rewardType
      rewardAmount
      receivedAmount
      createdAt
      isVesting
    }
  }
`

export const getAllLootBoxesQuery = gql`
  query getAllLootBoxes($address: String) {
    lootBoxes(
      where: { owner: $address }
    ) {
      id
      owner
      contract
      buyAmount
      boxId
      rewardType
      rewardAmount
      receivedAmount
      createdAt
      isVesting
    }
  }
`

export const getSingleLootBoxQuery = gql`
  query getSingleLootBox($id: String) {
    lootBox(id: $id) {
      id
      owner
      contract
      buyAmount
      boxId
      rewardType
      rewardAmount
      nftId
      isVesting
      createdAt
      transaction
    }
  }
`

export const getLootBoxNFTsQuery = gql`
  query getLootBoxNFTs($address: String) {
    lootBoxNFTs(where: { owner: $address }) {
      id
      owner
      tokenId
      value
    }
  }
`


export const getReferralRewards = gql`
  query getReferralRewards(
    $address: String
    $contract: String
    $isUsed: Boolean
  ) {
    referralRewards(where: { owner: $address, contract: $contract }) {
      id
      isUsed
      contract
      boxId
      referralId
      referralAmount
    }
  }
`
