import { sendExceptionReport } from './errors';
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { getCurrentNetworkId } from '@contracts/networks'
import { defaultEVMNetworkId, web3NoAccountInstances } from '@firestarter-private/firestarter-library/lib/constants'
import { detectMobile } from '@utils/detectMobile'
import { PRIORITIZED_CONNECTORS } from '@firestarter-private/firestarter-library/lib/connectors'
import { getWalletAdapterForDevice } from '@contracts/adapters'

type WalletSignatureRequest = (data: string) => Promise<string | null>

export const createEthWalletSignature: WalletSignatureRequest = async (data) => {
  const isMobile = detectMobile()
  const web3 = web3NoAccountInstances[defaultEVMNetworkId]
  const provider = isMobile ? PRIORITIZED_CONNECTORS.WALLET_CONNECT_V2.connector.provider : web3.givenProvider
  const account = isMobile ? provider.accounts[0] : provider.selectedAddress

  if (!web3) {
    return null
  }

  const params = [
    `0x${Buffer.from(data, "utf8").toString("hex")}`,
    account
  ]

  const signature = await provider.request({
    method: 'personal_sign',
    params,
    from: account
  })

  if (!signature) {
    throw new Error('Failed to create signature')
  }

  return signature
}

export const createSolanaWalletSignature: WalletSignatureRequest = async (data) => {
  const provider = getWalletAdapterForDevice()

  if (!provider || !provider.signMessage) {
    return null
  }

  if (!provider.connected) {
    await provider.connect()
  }

  const messageData = new TextEncoder().encode(data)

  try {
    const signatureData = await provider.signMessage(messageData)
    return new Uint8Array(signatureData).toString()
  } catch (err) {
    sendExceptionReport(err)
    return null
  }
}

export const getWalletSignatureRequest = (): WalletSignatureRequest => {
  const currentNetwork = getCurrentNetworkId()
  return isSolanaNetwork(currentNetwork) ? createSolanaWalletSignature : createEthWalletSignature
}

