import { useEffect } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import './NFTLanding.scss'
import { Container, Nav, Row } from 'react-bootstrap';
import { FireStarterNFTs } from './FireStarterNFTs';
import { NFTCollection } from './NFTCollection';
import { NFTBannerSlider } from '@components';
import { flareAsset, hiroAsset, hiroBlurredAsset, useNFTs } from '@contracts/hooks/useNFTs';
import { RoutesPaths } from '@router/constants';
import { isFuture } from 'date-fns';
import { dueDateForHiroNFT } from '@constants';

export const NFTLanding = () => {
  const { path, isExact } = useRouteMatch()
  const matchCollection = useRouteMatch(RoutesPaths.NFTS.COLLECTION)

  const {
    getFlareData,
    getHiroData,
  } = useNFTs()

  useEffect(() => {
    getFlareData()
    getHiroData()
  }, [])

  const bannerSlides = [
    {
      id: '1',
      image: flareAsset
    },
    {
      id: '2',
      image: (!dueDateForHiroNFT || isFuture(new Date(dueDateForHiroNFT))) ? hiroBlurredAsset : hiroAsset
    }
  ]

  return (
    <div className="nfts-page page">
      <section className='top-banner'>
        <div className='background'>
          {
            !!bannerSlides.length && <NFTBannerSlider slides={bannerSlides} />
          }
        </div>
        <Container>
          <Row className="hero-row">
            <div className='hero-row__main text-center'>
              <h2 className='title'>
                The FireStarter NFTs
              </h2>
              <p className='subtitle'>
                Ultra-rare NFT’s with platform utility. Collect, Trade, Ignite. Own The Future.
              </p>
            </div>
          </Row>
        </Container>
      </section>
      <section className='nfts-page-tabs'>
        <Container>
          <Nav
            defaultActiveKey={path}
            className="nfts-page-navbar nav-tabs nav fs-tabs-rounded"
          >
            <Nav.Link
              as={Link}
              className="nav-item"
              to={RoutesPaths.NFTS.MAIN}
              active={isExact}
            >
              FireStarter NFT's
            </Nav.Link>
            <Nav.Link
              as={Link}
              className="nav-item"
              to={RoutesPaths.NFTS.COLLECTION}
              active={matchCollection?.isExact}
            >
              My Collection
            </Nav.Link>
          </Nav>
        </Container>
        <Switch>
          <Route exact path={RoutesPaths.NFTS.MAIN} component={FireStarterNFTs} />
          <Route exact path={RoutesPaths.NFTS.COLLECTION} component={NFTCollection} />
        </Switch>
      </section>
    </div>
  )
}
