import React, { useCallback, useMemo, useState } from 'react'
import './Staking.scss'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { SEOTags } from '@components'
import { formatDuration, intervalToDuration } from 'date-fns'
import {
  useApproval,
  useNetwork,
} from '@firestarter-private/firestarter-library'
import {
  DAY,
  defaultEVMNetworkId,
  polygonContractAddresses,
  SECOND,
} from '@firestarter-private/firestarter-library/lib/constants'
import { useStaking } from '@contracts/hooks/useStaking'
import { shortenUnitsInDuration } from '@firestarter-private/firestarter-library/lib/utils/dates'
import {
  balanceToNumber,
  balanceToNumeric,
  numericToBalance,
  numericToUint256,
} from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { seoTags } from '@/seo-content'
import {
  isEthNetwork,
  isSolanaNetwork,
} from '@firestarter-private/firestarter-library/lib/utils/networks'
import { getCurrentNetworkId } from '@contracts/networks'
import {
  BalancesComponent,
  MyStakesComponent,
  StakingStatsTabComponent,
  StakingTabComponent,
  UnstakeTabComponent,
} from '@pages/Account/Staking/StakingComponents'

enum FormTypes {
  STAKE = 'STAKE',
  UNSTAKE = 'UNSTAKE',
  STATS = 'STATS',
}

type FormType = keyof typeof FormTypes

export const Staking = () => {
  const { isDefaultEVMNetworkSelected, currentNetworkId } = useNetwork()

  const { allowance, onApprove } = useApproval(
    ...(isDefaultEVMNetworkSelected
      ? [
          polygonContractAddresses.flameUSDCLpToken,
          polygonContractAddresses.flameStaking,
        ]
      : []),
  )

  const {
    APY,
    currentPenalty,
    rewardsPerSecond,
    isStakingActive,
    lastStaked,
    rewards,
    staked,
    lpBalance,
    onStake,
    onUnstake,
    onClaim,
    stakingStats,
    lpDecimals,
    flameDecimals,
    totalStaked,
  } = useStaking()

  const readableTimeSinceLastStake = useMemo(() => {
    if (!lastStaked) return
    const duration = intervalToDuration({
      start: lastStaked,
      end: new Date(),
    })
    const formattedDuration = formatDuration(duration, {
      zero: false,
      format: ['months', 'days', 'hours', 'minutes'],
    })
    if (!formattedDuration) return 'few moments'
    return shortenUnitsInDuration(formattedDuration)
  }, [lastStaked])

  const [stakeFormType, setStakeFormType] = useState<FormType>(FormTypes.STAKE)
  const [amountToStake, setAmountToStake] = useState('0')
  const [amountToUnstake, setAmountToUnstake] = useState('0')

  const estimatedRewards = useMemo(() => {
    if (!isStakingActive) return 0

    const monthInSeconds = (30 * DAY) / SECOND
    const totalStakedNumber = balanceToNumber(totalStaked, lpDecimals)
    const rewardsPerSecondNumber = balanceToNumber(
      rewardsPerSecond,
      flameDecimals,
    )
    return (
      +amountToStake *
      ((rewardsPerSecondNumber * monthInSeconds) /
        (totalStakedNumber + +amountToStake || 1))
    )
  }, [
    amountToStake,
    stakingStats,
    rewardsPerSecond,
    isStakingActive,
    flameDecimals,
    lpDecimals,
  ])

  const setMaxToStake = useCallback(() => {
    setAmountToStake(balanceToNumeric(lpBalance, lpDecimals))
  }, [lpBalance, lpDecimals])

  const setMaxToUnstake = useCallback(() => {
    setAmountToUnstake(balanceToNumeric(staked, lpDecimals))
  }, [staked, lpDecimals])

  const disableStaking = useMemo(() => {
    return (
      +amountToStake <= 0 ||
      lpBalance.isLessThan(numericToBalance(amountToStake, lpDecimals)) ||
      (isEthNetwork(currentNetworkId || defaultEVMNetworkId) &&
        allowance.isLessThan(numericToBalance(amountToStake, lpDecimals))) ||
      !isStakingActive
    )
  }, [amountToStake, lpBalance, allowance, lpDecimals, isStakingActive])

  const disableUnstaking = useMemo(() => {
    return (
      +amountToUnstake <= 0 ||
      staked.isLessThan(numericToBalance(amountToUnstake, lpDecimals))
    )
  }, [amountToUnstake, staked, lpDecimals])

  const handleApprove = () => onApprove()

  const handleStake = useCallback(async () => {
    if (disableStaking) return

    const amount = isSolanaNetwork(currentNetworkId || getCurrentNetworkId())
      ? amountToStake
      : numericToUint256(amountToStake, lpDecimals)

    await onStake(amount, { onHash: () => setAmountToStake('0') })
  }, [amountToStake, onStake, lpDecimals, disableStaking])

  const handleUnstake = useCallback(async () => {
    if (disableUnstaking) return

    const amount = isSolanaNetwork(currentNetworkId || getCurrentNetworkId())
      ? amountToUnstake
      : numericToUint256(amountToUnstake, lpDecimals)

    await onUnstake(amount, { onHash: () => setAmountToUnstake('0') })
  }, [amountToUnstake, onUnstake, lpDecimals, disableUnstaking])

  const handleClaim = useCallback(async () => {
    if (!balanceToNumber(rewards, flameDecimals)) return

    await onClaim()
  }, [onClaim, flameDecimals, rewards])

  return (
    <div className="account-staking">
      <SEOTags {...seoTags.accountStaking} />
      <section className="stake-allocation-section">
        <Container>
          <Row className="stake-block account-staking__stake-block">
            <Col className="block-col" md={{ span: 6 }}>
              <Row className="stake-allocation-row">
                <Col className="text-left">
                  <h2 className="title">
                    Earn $<span className="purple-text">FLAME</span> rewards by
                    staking your FLAME-USDC QS LP tokens.
                  </h2>
                  <div className="bottom-description">
                    The amount of allocation will depend on the amount locked.
                  </div>
                </Col>
              </Row>
              <div className="stake-block__info">
                <BalancesComponent
                  apy={APY}
                  staked={staked}
                  lpDecimals={lpDecimals}
                  rewards={rewards}
                  flameDecimals={flameDecimals}
                />
                <MyStakesComponent
                  readableTimeSinceLastStake={readableTimeSinceLastStake}
                  currentPenalty={currentPenalty}
                  flameDecimals={flameDecimals}
                />
              </div>
            </Col>
            <Col className="block-col" md={{ span: 6 }}>
              <div className="stake-form">
                <Tabs
                  id="stake-block-tabs"
                  className="stake-block-tabs"
                  activeKey={stakeFormType}
                  onSelect={(eventKey) =>
                    setStakeFormType(eventKey as FormType)
                  }
                >
                  <Tab
                    eventKey={FormTypes.STAKE}
                    title={FormTypes.STAKE.toLowerCase()}
                    className="stake-block-tab-stake tile"
                  >
                    <StakingTabComponent
                      lpBalance={lpBalance}
                      lpDecimals={lpDecimals}
                      amountToStake={amountToStake}
                      setAmountToStake={(e: any) =>
                        setAmountToStake(e.target.value)
                      }
                      disableStaking={disableStaking}
                      setMaxToStake={setMaxToStake}
                      estimatedRewards={estimatedRewards}
                      currentNetworkId={currentNetworkId}
                      allowance={allowance}
                      handleApprove={handleApprove}
                      handleStake={handleStake}
                    />
                  </Tab>
                  <Tab
                    eventKey={FormTypes.UNSTAKE}
                    title={FormTypes.UNSTAKE.toLowerCase()}
                    className="stake-block-tab-unstake tile"
                  >
                    <UnstakeTabComponent
                      amountToUnstake={amountToUnstake}
                      setAmountToUnstake={(e: any) =>
                        setAmountToUnstake(e.target.value)
                      }
                      disableUnstaking={disableUnstaking}
                      setMaxToUnstake={setMaxToUnstake}
                      currentPenalty={currentPenalty}
                      flameDecimals={flameDecimals}
                      rewards={rewards}
                      handleClaim={handleClaim}
                      handleUnstake={handleUnstake}
                    />
                  </Tab>
                  <Tab
                    eventKey={FormTypes.STATS}
                    title={FormTypes.STATS.toLowerCase()}
                    className="tile"
                  >
                    <StakingStatsTabComponent
                      stakingStats={stakingStats}
                      flameDecimals={flameDecimals}
                      totalStaked={totalStaked}
                      lpDecimals={lpDecimals}
                    />
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
