import React, { useMemo } from 'react'
import './Media.scss'
import { normalizeURL } from '@utils/urls'

interface Props {
  value: {
    mimeType: string
    alt?: string
    url: string
    sizes: {
      full: {
        width?: number
        height?: number
        url?: string
      }
      mobile: {
        width: number
        height: number
        url: string
      }
    }
  }
}

const defaultWidth = 713

export const Media = ({ value: { mimeType, alt, sizes, url } }: Props) => {
  const isVideo = useMemo(() => mimeType.includes('video'), [mimeType])

  if (isVideo) {
    return (
      <div className="cms-media-wrapper">
        <video
          className="cms-media cms-video"
          src={url}
          autoPlay
          controls={false}
          loop
          muted
          playsInline
          preload="metadata"
        />
      </div>
    )
  }

  return (
    <div className="cms-media-wrapper">
      <img
        className="cms-media cms-image"
        srcSet={`${normalizeURL(sizes.mobile.url || '')} 327w,
         ${normalizeURL(sizes.full.url || '')} 713w`}
        sizes="(max-width: 767px) 327px, 713px"
        src={normalizeURL(sizes.full.url || url)}
        alt={alt} />
    </div>
  )
}
