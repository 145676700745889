import { Image } from 'react-bootstrap'
import TooltipIcon from '@assets/tooltip-icon.svg'

export const SFTTooltip = () => (
  <div className="tooltip__inner">
    <h5 className='tooltip__title'>
      <Image src={TooltipIcon} />
      <span>SFT</span>
    </h5>
    <div className='tooltip__content'>
      <p>
        SFT vesting contract is represented by NFT (ERC-721)
        that has fungible tokens (ERC-20) casted into it and
        released according to the vesting schedule.
      </p>
    </div>
  </div>
);
