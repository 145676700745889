import React, { useMemo } from 'react'
import { numberToCurrency } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { ProgressBar } from 'react-bootstrap'
import './ProjectProgress.scss'

interface Props {
  fundTokenName: string
  fundsSwapped?: number
  totalAmount?: number
}

export const ProjectProgress = React.memo(({
  fundTokenName,
  fundsSwapped,
  totalAmount,
}: Props) => {
  const progressRange = useMemo(() => {
    if (!fundsSwapped) return 0
    return Math.round(fundsSwapped / (totalAmount ?? 1) * 100)
  }, [fundsSwapped, totalAmount])

  return (
    <div className="project-progress">
      <div className="project-progress__values">
        <div>
          {!!(fundsSwapped && totalAmount) && (
            <>
              <big>{numberToCurrency(fundsSwapped, 0)}</big>
              /{numberToCurrency(totalAmount, 0)}
              {` ${fundTokenName}`}
            </>
          )}
        </div>
        <div>{progressRange} %</div>
      </div>
      <ProgressBar now={progressRange} />
    </div>
  )
})
