import { IUsePresaleArgs, IUsePresaleReturn } from '@contracts/hooks/usePresale/types'
import { useNetwork } from '@firestarter-private/firestarter-library'
import { useWhen } from '@contracts/hooks/useWhen'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { getCurrentNetworkId } from '@contracts/networks'
import { usePresaleSolana } from '@contracts/hooks/usePresale/usePresaleSolana'
import { usePresaleEVM } from '@contracts/hooks/usePresale/usePresaleEVM'

export const usePresale = (presaleArgs: IUsePresaleArgs): IUsePresaleReturn => {
  const { currentNetworkId } = useNetwork()
  return useWhen(
    isSolanaNetwork(currentNetworkId || getCurrentNetworkId()),
    usePresaleSolana,
    usePresaleEVM
  )(presaleArgs)
}
