import { Col, Container, Row } from "react-bootstrap";
import { BuyFlameButton, RoundButton } from "@components";
import { RoutesPaths } from "@router/constants";
import React from "react";
import './AdvantagesSection.scss'

export const AdvantagesSection: React.FC = () => (
  <section className="advantages-section">
    <Container>
      <Row className="justify-content-between">
        <Col lg={{ span: 7 }}
             xl={{ span: 6 }}>
          <h2 className="advantages-section__title title gradient-text gradient-text--purple">
            We facilitate the early stage funding of top digital innovation projects and their communities by amplifying
            them through culture.
          </h2>
          <AdvantagesButtons className={"advantages-section__buttons"} />
        </Col>
        <Col lg={{ span: 5 }}
             xl={{ span: 5 }}>
          <div className="platform-advantages">
            <div className="advantages-item">
              <h3 className="advantages-item__title">Initial DEX Offering</h3>
              <p className="advantages-item__text">
                Introducing the IDO. Incubated projects with innovative digital solutions. Powered by communities from
                around the world. Own The Future of Digital Innovation.
              </p>
            </div>
            <div className="advantages-item">
              <h3 className="advantages-item__title">Amplified through Culture</h3>
              <p className="advantages-item__text">
                Our Celebrity, Influencer, and Industry Partners - providing unparalleled reach and social backing
                to
                FireStarter Projects.
              </p>
            </div>
            <div className="advantages-item">
              <h3 className="advantages-item__title">Community over Everything</h3>
              <p className="advantages-item__text">
                We don’t just launch tokens, we launch communities. Giving early adopters the first chance at
                participation, governance, and access.
              </p>
            </div>
          </div>
        </Col>
        <AdvantagesButtons className={"advantages-section__buttons-below"} />
      </Row>
    </Container>
  </section>
);

interface Props {
  className: string
}

const AdvantagesButtons = ({ className }: Props) => (
  <div className={className}>
    <BuyFlameButton />
    <RoundButton
      to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
      color="LIGHT"
      size="large"
    >
      Lock $Flame Tokens
    </RoundButton>
  </div>
);
