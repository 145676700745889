import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Col, Form, FormGroup, Row } from 'react-bootstrap'
import {
  ApprovalSteps,
  RoundButton,
  CommonTooltip,
  TierStatus, IncrementInput,
} from '@components/common'
import { WhitelistStatus, WhitelistStatuses } from '@contracts/hooks/useWhitelist';
import {
  balanceToCurrency,
  balanceToNumber,
  balanceToNumeric,
  numericToBalance,
  numericToUint256
} from '@utils/balanceFormatter';
import BigNumber from 'bignumber.js';
import { TokenInfo } from '../types';
import { useFlameTier } from '@contracts/hooks/useFlameTier/useFlameTier';
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks';
import { ITransactionError, TransactionResult } from '@contracts/types'
import { ERROR_DISMISS_TIMEOUT, getStringTxError } from '@contracts/hooks/utils'
import { Divider } from '@/components'
import './SwapForm.scss'
import { numberToCurrency } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { useKYC } from '@contracts/hooks/useKYC'
import { maxUint256 } from '@firestarter-private/firestarter-library/lib/constants'
import { KYCStatuses } from '@api/kyc/types'

interface Props {
  totalSwapAmount: BigNumber
  fundsSwapped: BigNumber
  publicMaxAllocation: BigNumber
  privateMaxAllocation: BigNumber
  exchangeRate: BigNumber
  privatePresaleAllowed: boolean
  swappedByUser: BigNumber
  swappedPrivateByUser: BigNumber
  swappedPublicByUser: BigNumber
  balance: BigNumber
  fundsDecimals: number
  allowance: BigNumber
  whiteListStatus: WhitelistStatus | null
  projectId: string
  fundToken: TokenInfo
  isPrivatePhaseInProgress: boolean
  isPublicPhaseInProgress: boolean
  isClosePeriod?: boolean
  isOpenAccess?: boolean
  isSingleApproval?: boolean
  isEthProject?: boolean
  onApprove: (amount?: string, callbacks?: INotifyTxCallbacks) => Promise<void>
  onDeposit: (amount: string, callbacks?: INotifyTxCallbacks) => Promise<TransactionResult | undefined>
  onDepositPrivate: (amount: string, callbacks?: INotifyTxCallbacks) => Promise<TransactionResult | undefined>
}

export const SwapNodesForm: React.FC<Props> = ({
  totalSwapAmount,
  fundsSwapped,
  publicMaxAllocation,
  privateMaxAllocation,
  privatePresaleAllowed,
  exchangeRate,
  swappedByUser,
  swappedPrivateByUser,
  swappedPublicByUser,
  balance,
  fundsDecimals,
  allowance,
  whiteListStatus,
  projectId,
  fundToken,
  onApprove,
  isPrivatePhaseInProgress,
  isPublicPhaseInProgress,
  isClosePeriod,
  isOpenAccess,
  isSingleApproval,
  isEthProject,
  onDeposit,
  onDepositPrivate,
}) => {

  const {
    userTierInfo,
    loading: loadingTier
  } = useFlameTier()

  const { KYCStatus } = useKYC()

  const remainingFundsToSwap = useMemo(() => {
    return totalSwapAmount.minus(fundsSwapped)
  }, [totalSwapAmount, fundsSwapped])

  const allowedInClosePeriod = useMemo(() => {
    return (privatePresaleAllowed || !privateMaxAllocation.isZero()) || (!privateMaxAllocation.isZero() && !publicMaxAllocation.isZero())
  }, [
    privatePresaleAllowed,
    privateMaxAllocation,
    publicMaxAllocation,
  ])

  const availableAllocation = useMemo(() => {
    if (isPrivatePhaseInProgress) {
      return privateMaxAllocation.minus(swappedPrivateByUser)
    }
    if (isOpenAccess) {
      return maxUint256
    }
    if (isClosePeriod && allowedInClosePeriod) {
      return publicMaxAllocation.plus(privateMaxAllocation).minus(swappedByUser)
    }
    if (isPublicPhaseInProgress && allowedInClosePeriod) {
      return new BigNumber(0)
    }
    return publicMaxAllocation.minus(swappedPublicByUser)
  }, [
    isOpenAccess,
    publicMaxAllocation,
    privateMaxAllocation,
    swappedPublicByUser,
    swappedPrivateByUser,
    swappedByUser,
    isPrivatePhaseInProgress,
    isPublicPhaseInProgress,
    isClosePeriod,
    allowedInClosePeriod,
  ])

  const availableNodesToBuy = useMemo(
    () => balanceToNumber(availableAllocation.dividedBy(exchangeRate), fundsDecimals),
    [availableAllocation, exchangeRate, fundsDecimals])

  const maxSwapAmount = useMemo(() => {
    if (isOpenAccess) {
      return balanceToNumeric(
        BigNumber.min(
          balance,
          remainingFundsToSwap,
        ),
        fundsDecimals
      )
    }
    return balanceToNumeric(
      BigNumber.min(
        availableAllocation,
        balance,
        remainingFundsToSwap,
      ),
      fundsDecimals
    );
  }, [
    isOpenAccess,
    remainingFundsToSwap,
    availableAllocation,
    balance,
    fundsDecimals
  ])

  const maxNodesAmount = useMemo(
    () => Math.floor(balanceToNumber(
      numericToBalance(maxSwapAmount, fundsDecimals).dividedBy(exchangeRate),
      fundsDecimals,
    )),
    [maxSwapAmount, fundsDecimals, exchangeRate],
  )

  const [isApproving, setIsApproving] = useState(false)
  const [isSwapping, setIsSwapping] = useState(false)
  const [swappingError, setSwappingError] = useState<string | undefined>()
  const [nodesToBy, setNodesToBy] = useState(0)

  const amountToSwap = useMemo(
    () => balanceToNumeric(
      exchangeRate.multipliedBy(nodesToBy),
      0
    ),
    [nodesToBy, exchangeRate]
  )
  const setSwapErrorMessage = (error: ITransactionError['error']) => {
    setSwappingError(getStringTxError(error))
    setTimeout(() => setSwappingError(undefined), ERROR_DISMISS_TIMEOUT)
  }

  const setMaxToSwap = useCallback(() => {
    setNodesToBy(maxNodesAmount)
  }, [maxNodesAmount]);

  const disableApproval = useMemo(() => {
    if (isOpenAccess) {
      return KYCStatus !== KYCStatuses.approved
        || allowance.isGreaterThanOrEqualTo(numericToBalance(amountToSwap, fundsDecimals))
        || +amountToSwap <= 0
        || isApproving
    }

    return whiteListStatus !== WhitelistStatuses.passed
      || allowance.isGreaterThanOrEqualTo(numericToBalance(amountToSwap, fundsDecimals))
      || +amountToSwap <= 0
      || isApproving
  }, [
    isOpenAccess,
    KYCStatus,
    whiteListStatus,
    allowance,
    amountToSwap,
    fundsDecimals,
    isApproving,
  ])

  const disableSwapping = useMemo(() => {
    const disableForAllPhases = () => {
      return +amountToSwap <= 0
        || (isEthProject && !balanceToNumber(allowance, fundsDecimals))
        || numericToBalance(maxSwapAmount, fundsDecimals).isLessThan(numericToBalance(amountToSwap, fundsDecimals))
        || (isEthProject && allowance.isLessThan(numericToBalance(amountToSwap, fundsDecimals)))
        || isSwapping
    }
    const disableForOpenAccess = () => {
      return disableForAllPhases()
        || KYCStatus !== KYCStatuses.approved
    }

    const disableForPrivatePhase = () => {
      return disableForAllPhases()
        || whiteListStatus !== WhitelistStatuses.passed
        || !privatePresaleAllowed
    }

    const disableForPublicPhase = () => {
      return disableForAllPhases()
        || whiteListStatus !== WhitelistStatuses.passed
        || (!isClosePeriod && allowedInClosePeriod)
    }

    const disableForClosePeriod = () => {
      return disableForAllPhases()
        || whiteListStatus !== WhitelistStatuses.passed
        || availableAllocation.isZero()
    }

    if (isPrivatePhaseInProgress) return disableForPrivatePhase()
    if (isOpenAccess) return disableForOpenAccess()
    if (isClosePeriod) return disableForClosePeriod()
    if (isPublicPhaseInProgress) return disableForPublicPhase()
  }, [
    maxSwapAmount,
    amountToSwap,
    allowance,
    whiteListStatus,
    fundsDecimals,
    isEthProject,
    isPrivatePhaseInProgress,
    isPublicPhaseInProgress,
    privatePresaleAllowed,
    isClosePeriod,
    allowedInClosePeriod,
    isSwapping,
    isOpenAccess,
    KYCStatus,
    availableAllocation,
  ])

  const warningMessage = useMemo(() => {
    if (isOpenAccess && KYCStatus !== KYCStatuses.approved) {
      return 'You have to pass KYC to be able to participate'
    }
    if (isPrivatePhaseInProgress && !privatePresaleAllowed) {
      return 'The sale for FCFS is not open yet'
    }
    if (isPublicPhaseInProgress && !isClosePeriod && !isOpenAccess && publicMaxAllocation.isZero()) {
      return 'The sale by Guaranteed access is already over'
    }
    if (publicMaxAllocation.isZero() && privateMaxAllocation.isZero() && !isOpenAccess) {
      return `Your wallet didn't win to have access for this sale`
    }
    if (numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(remainingFundsToSwap)) {
      return 'The amount exceeds the remaining funds to raise'
    }
    if (!isOpenAccess && numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(availableAllocation)) {
      return 'The amount exceeds what is available for your Tier.'
    }
    if (numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(balance)) {
      return 'The amount exceeds your balance of the tokens'
    }
    if (remainingFundsToSwap.isZero()) {
      return 'All the funds have already been raised'
    }
  }, [
    amountToSwap,
    balance,
    availableAllocation,
    fundsDecimals,
    remainingFundsToSwap,
    isPrivatePhaseInProgress,
    isPublicPhaseInProgress,
    privatePresaleAllowed,
    publicMaxAllocation,
    privateMaxAllocation,
    isClosePeriod,
    isOpenAccess,
    KYCStatus,
  ])

  const handleApprove = useCallback(async () => {
    if (disableApproval) return
    setIsApproving(true)
    await onApprove(numericToUint256(amountToSwap, fundsDecimals))
    setIsApproving(false)
  }, [onApprove, amountToSwap, fundsDecimals])

  const handleSwap = useCallback(async () => {
    if (disableSwapping) {
      return
    }
    setIsSwapping(true)
    const depositMethod = isPrivatePhaseInProgress ? onDepositPrivate : onDeposit
    const result = await depositMethod(
      amountToSwap,
      {
        onHash: () => setNodesToBy(0)
      }
    )
    setIsSwapping(false)
    if (result && ('error' in result)) {
      setSwapErrorMessage(result.error)
    }
  }, [
    amountToSwap,
    onDeposit,
    onDepositPrivate,
    disableSwapping,
    isPrivatePhaseInProgress,
  ])

  return (
    <form className="swap-form tile">
      <TierStatus loading={loadingTier} userTierInfo={userTierInfo} />
      <Divider />
      <div className="info-list">
        <div>
          <dt className="name">Your nodes</dt>
          <dd className="value"><big>{balanceToNumber(swappedByUser.dividedBy(exchangeRate), fundsDecimals)}</big></dd>
        </div>
        <div>
          <dt className="name">Available nodes for your tier</dt>
          <dd className="value"><big>{isOpenAccess ? 'No limit' : availableNodesToBuy}</big></dd>
        </div>
      </div>
      <Divider />
      <div className="swap-form__controls">
        <FormGroup as={Row} className="align-items-center">
          <Form.Label column xs={4}>Nodes: </Form.Label>
          <Col xs={6}>
            <IncrementInput
              min={0}
              {...(isOpenAccess ? {} : {
                max: availableNodesToBuy
              })}
              value={nodesToBy}
              onChange={setNodesToBy}
            />
          </Col>
          <Col xs={2}>
            <RoundButton size="small" color="DARK" onClick={setMaxToSwap}>
              MAX
            </RoundButton>
          </Col>
        </FormGroup>
        <div className="info-list">
          <div>
            <dt className="name">Price:</dt>
            <dd className="value">{balanceToCurrency(exchangeRate, 0)} {fundToken.name}</dd>
          </div>
          <div>
            <dt className="name">Total:</dt>
            <dd className="value">{numberToCurrency(Number(amountToSwap), 2)} {fundToken.name}</dd>
          </div>
        </div>
        {!!warningMessage && (
          <div className='form-message form-message--warning text-center'>
            <span>{warningMessage}</span>
          </div>
        )}
        <div className="swap-form__buttons">
          {
            isEthProject && <RoundButton
              size="large"
              disabled={disableApproval}
              onClick={handleApprove}
              loading={isApproving}
            >
              Approve
              {
                isSingleApproval && (
                  <CommonTooltip id="allowance-tip" placement="auto">
                    <p>
                      The {fundToken.name} token has a special allowance behavior. <br />
                      Thus, it may take additional transaction to approve the spending of your tokens.
                    </p>
                  </CommonTooltip>
                )
              }
            </RoundButton>
          }
          <RoundButton
            size="large"
            disabled={disableSwapping}
            onClick={handleSwap}
            loading={isSwapping}
          >
            SWAP
          </RoundButton>
        </div>
        {isEthProject && <ApprovalSteps fillingCondition={disableApproval} />}
        <div
          className={classNames(
            'form-message form-message--error form-message--no-padding text-center mt-3',
            { hidden: !swappingError }
          )}
        >
          <span>{swappingError}</span>
        </div>
      </div>
    </form>
  )
}
