import { useMemo } from 'react'
import { WhitelistStatus, WhitelistStatuses } from '@contracts/hooks/useWhitelist'
import WLIcon from '@assets/wallet-avatar.svg'
import WLNotPassedIcon from '@assets/account-warning.svg'
import './WhitelistBadge.scss'
import { Image } from 'react-bootstrap'
import classNames from 'classnames'

interface Props {
  status: WhitelistStatus
}

export const WhitelistBadge = ({ status }: Props) => {
  const message = useMemo(() => {
    switch (status) {
      case WhitelistStatuses.passed:
        return 'Wallet is whitelisted'
      case WhitelistStatuses.in_review:
        return 'Application is under review'
      default:
        return 'Wallet is NOT whitelisted'
    }
  }, [status])

  const iconSrc = useMemo(() => {
    switch (status) {
      case WhitelistStatuses.passed:
        return WLIcon
      case WhitelistStatuses.rejected:
        return WLNotPassedIcon
      default:
        return null
    }
  },[status])

  return (
    <div className={classNames('whitelist-badge', { [status]: true })}>
      {iconSrc && <Image src={iconSrc} />}
      <span>{message}</span>
    </div>
  )
}