import React from 'react'
import { Image } from 'react-bootstrap'
import { RoundButton } from '@components'
import { blindBoxesTutorialUrl, buyFlameLink, KuCoinFlameLink } from '@constants'
import KuCoinLogo from '@assets/features/kucoin-logo.png'
import QuickSwapLogo from '@assets/features/quickswap-logo.png'
import { ReactComponent as PlayIcon } from '@assets/play-icon.svg'
import './TutorialSection.scss'
import useScreenSize from 'use-screen-size'
import { Swiper, SwiperSlide } from 'swiper/react'

const navigationOptions = {
  nextEl: '.features-slider__nav-next',
  prevEl: null
}

export const TutorialSection = () => {
  const screenSize = useScreenSize()
  const noSwiping = screenSize.width > 992

  return <section className="tutorial-section mb-5">
    <div className="tutorial-slider">
      <Swiper
        className="tutorial-slider-slides"
        slidesPerView="auto"
        navigation={navigationOptions}
        initialSlide={1}
        noSwiping={noSwiping}
        allowSlidePrev={!noSwiping}
        allowSlideNext={!noSwiping}
      >
        <SwiperSlide className="tutorial-slide" key={0}>
          <div className="tile tutorial-tile tutorial-tile--markets">
            <div className="tutorial-tile__content">
              <h3 className="tile__main">Buy $FLAME tokens to participate</h3>
              <div className="tutorial-tile__buttons">
                <RoundButton
                  href={KuCoinFlameLink}
                  color="DARK"
                >
                  <Image src={KuCoinLogo} roundedCircle />
                  <span>KUCOIN</span>
                </RoundButton>
                <RoundButton
                  href={buyFlameLink}
                  color="DARK"
                >
                  <Image src={QuickSwapLogo} roundedCircle />
                  <span>QUICKSWAP</span>
                </RoundButton>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="tutorial-slide" key={1}>
          <div className="tile tutorial-tile tutorial-tile--guide">
            <div className="tutorial-tile__content">
              <h3 className="tile__main">How to Participate?</h3>
              <div className="tile__description">
                <small>Watch the tutorial video to understand the rules</small>
              </div>
              <div className="tutorial-tile__buttons">
                <RoundButton color="DARK" href={blindBoxesTutorialUrl}>
                  Watch video
                  <PlayIcon className="link-arrow" />
                </RoundButton>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
}