import { Nav } from 'react-bootstrap'
import React, { useEffect, useMemo, useRef } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import './SlidingNav.scss'

interface SlidingNavItem {
  to: string
  label: string | React.ReactNode
}

interface Props {
  defaultActiveKey: string
  items: SlidingNavItem[]
}

export const SlidingNav = ({
  defaultActiveKey,
  items
}: Props) => {
  const location = useLocation()

  const activeLinkRef = useRef<HTMLAnchorElement | null>(null)
  const scrollPosition = useMemo(
    () => activeLinkRef.current?.offsetLeft || 0,
    [activeLinkRef.current]
  )

  const setActiveLink = (e: MouseEvent) => {
    activeLinkRef.current = (e.target as HTMLElement).closest('.nav-link')
  }

  useEffect(() => {
    activeLinkRef.current = document.querySelector(
      '.nav-wrapper .nav-link.active',
    )
  }, [location])

  const getNavWrapper = () => document.querySelector('.sliding-nav-wrapper')

  useEffect(() => {
    getNavWrapper()?.scrollTo({ left: scrollPosition })
  }, [scrollPosition])

  return (
    <div className="sliding-nav-wrapper">
      <Nav
        as="nav"
        defaultActiveKey={defaultActiveKey}
        className="sliding-nav"
      >
        {items.map((item) => (
          <Nav.Item>
            <Nav.Link
              as={Link}
              to={item.to}
              active={matchPath(
                location.pathname,
                { path: item.to.replace(/#.+/, '') }
              )?.isExact}
              onClick={(e: any) => setActiveLink(e as MouseEvent)}
            >
              {item.label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  )
}