import './ProjectHero.scss'
import { ProjectStatusProp, ProjectType, ProjectTypes, SocialLink } from '@components/Project/types'
import { NetworkName } from '@firestarter-private/firestarter-library/lib/constants'
import React from 'react'
import { ProjectTag } from '@components'
import { Image } from 'react-bootstrap'
import { ProjectSocialLinks } from '@components/Project/ProjectSocialLinks/ProjectSocialLinks'

interface Props {
  logo: string
  banner: string
  status: ProjectStatusProp
  network: NetworkName
  projectType: ProjectType
  fundTokenName: string
  name: string
  socialLinks: SocialLink[]
  onDownloadTerms?: () => void
}
export const ProjectHero = React.memo(({
  logo,
  banner,
  status,
  network,
  projectType,
  fundTokenName,
  name,
  socialLinks,
  onDownloadTerms,
}: Props) => {
  return (
    <section className="project-hero">
      <header className="project-hero__head">
        <Image src={logo} roundedCircle className="project-hero__logo" />
        <div className="project-hero__head-row">
          <div className="project-tags">
            {projectType !== ProjectTypes.token_presale &&
              <ProjectTag type="project" projectType={projectType} value={projectType} />}
            <ProjectTag type="status" value={status} />
            <ProjectTag type="network" value={network} />
            {![ProjectTypes.NFT_claim, ProjectTypes.NFT_presale, ProjectTypes.token_airdrop].includes(projectType as ProjectTypes) &&
              <ProjectTag type="token" projectType={projectType} value={fundTokenName} />}
          </div>
          <h3 className="project-hero__name">{name}</h3>
        </div>
      </header>
      <main>
        <div className="project-hero__banner">
          <Image src={banner} className="project-hero__banner-image" />
        </div>
      </main>
      <footer className="project-hero__footer">
        {
          !!onDownloadTerms && (
            <div className="project-hero__download-terms">
              <a
                href=""
                onClick={onDownloadTerms}
              >
                Download terms & conditions
              </a>
            </div>
          )
        }
        {socialLinks.length && (
          <>
            <h6 className="white-transparent-text text-wide fw-normal mb-3">
              Project in Social media
            </h6>
            <ProjectSocialLinks socialLinks={socialLinks} />
          </>
        )}
      </footer>
    </section>
  )
})
