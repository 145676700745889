import { Col, Container, Row } from "react-bootstrap";
import { Accordion } from "@/components";
import { faqItems } from "@pages/Homepage/contents";
import './FaqSection.scss'
import React from "react";

export const FaqSection: React.FC = () => {
  return <section className="faq-section">
    <Container>
      <Row className="faq-column justify-content-center">
        <Col
          className="text-center col-xxl-10"
          md={{ span: 12 }}
          xl={{ span: 11 }}
        >
          <h2 className="title">FAQ</h2>
          <Accordion items={faqItems.slice(0, 4)} />
        </Col>
      </Row>
    </Container>
  </section>
}
