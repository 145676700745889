import Web3 from 'web3';
import { IUseTransactions, SwitchNetworkCallback } from '@firestarter-private/firestarter-library/lib/hooks'
import { BlockNumber } from 'web3-core'
import { AppLocalStorage } from '@hooks/useStorages'
import { isEthNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'


export const withTransactionCaller = <T extends unknown = unknown, A extends any[] = any[]>(
  callback: (
    callTransaction: IUseTransactions['callTransaction'],
    blockNumber: BlockNumber,
    ...args: A
  ) => Promise<T>,
) => (
  callTransaction: IUseTransactions['callTransaction'],
  blockNumber?: BlockNumber,
) => (...args: A) => callback(callTransaction, blockNumber || 'latest', ...args)

export const switchNetworkCallback = (
  appStorage: AppLocalStorage,
  {
    from,
    to
  }: Parameters<SwitchNetworkCallback>[0]
) => {
  if (isEthNetwork(from) !== isEthNetwork(to)) {
    appStorage.currentNetworkId = to
    window.location.reload()
  }
}

export const getBlockNumberByTxHash = async (web3: Web3, txHash: string): Promise<number | null> => {
  const res = await web3.eth.getTransaction(txHash)
  return res.blockNumber
}
