import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Account.scss'
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import { Lockup } from './Lockup'
import { Portfolio } from './Portfolio'
import { Staking } from './Staking'
import { useKYC } from '@contracts/hooks/useKYC'
import { RoutesPaths } from '@router/constants'
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { ContractNotSupportedWidget, LockupNotSupported, SlidingNav } from '@components'
import { LinkingWallets } from './LinkingWallets'
import { LockupMultiperiod } from './LockupMultiperiod'

export const Account = () => {
  const { path } = useRouteMatch()
  const { getKYCStatus } = useKYC()
  const { account } = useWalletContext()
  const {
    isDefaultEVMNetworkSelected,
    isDefaultSolanaNetworkSelected,
  } = useNetwork()

  useEffect(() => {
    getKYCStatus()
  }, [account])

  return (
    <div className="account-page page">
      <Container>
        <Row className="heading-row">
          <Col className="text-left">
            <h1 className="title">Account</h1>
          </Col>
        </Row>
        <SlidingNav
          defaultActiveKey={`${path}/lockup`}
          items={[
            {
              to: RoutesPaths.ACCOUNT.MAIN,
              label: 'My Portfolio'
            },
            {
              to: RoutesPaths.ACCOUNT.LOCKING,
              label: 'Lockup'
            },
            {
              to: RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD,
              label: <>Lockup <span className="purple-text">2.0</span></>
            },
            {
              to: RoutesPaths.ACCOUNT.STAKING,
              label: 'Staking'
            },
            {
              to: RoutesPaths.ACCOUNT.LINKING_WALLETS,
              label: 'Linking Wallets'
            },
          ]}
        />
      </Container>
      <Switch>
        <Route exact path={RoutesPaths.ACCOUNT.MAIN} component={Portfolio} />
        <Route
          exact
          path={RoutesPaths.ACCOUNT.LOCKING}
          component={isDefaultEVMNetworkSelected ? Lockup : LockupNotSupported}
        />
        <Route
          exact
          path={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
          component={
            isDefaultEVMNetworkSelected || isDefaultSolanaNetworkSelected
              ? LockupMultiperiod
              : ContractNotSupportedWidget
          }
        />
        <Route
          exact
          path={RoutesPaths.ACCOUNT.STAKING}
          component={
            isDefaultEVMNetworkSelected || isDefaultSolanaNetworkSelected
              ? Staking
              : ContractNotSupportedWidget
          }
        />
        <Route
          exact
          path={RoutesPaths.ACCOUNT.LINKING_WALLETS}
          component={LinkingWallets}
        />
      </Switch>
    </div>
  )
}
