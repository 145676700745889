import { ChainvineWidget } from '@chainvine/widget'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useWalletContext } from '@firestarter-private/firestarter-library'
const CHAINVINE_API_KEY = process.env.REACT_APP_CHAINVINE_API_KEY
const CHAINVINE_CAMPAIGN_ID = process.env.REACT_APP_CHAINVINE_CAMPAIGN_ID

export const BlindBoxesReferral = () => {
  const { account } = useWalletContext()
  const [open, setOpen] = useState(true)

  useEffect(() => {
    const copyHandler = () => {
      const inputElement = document.getElementsByClassName('ant-input')
      navigator.clipboard.writeText(
        inputElement.length > 0
          ? inputElement[0]?.getAttribute('value') ?? ''
          : '',
      )
    }
    const timer = setInterval(() => {
      const buttonElementCollection = document.getElementsByClassName(
        'ant-btn ant-btn-text ant-btn-loading',
      )

      if (buttonElementCollection.length > 0) {
        clearInterval(timer)
        const buttonElement = buttonElementCollection[0]
        buttonElement.firstChild?.remove()
        buttonElement.classList.remove('ant-btn-loading')
        buttonElement.addEventListener('click', copyHandler)
      }
    }, 500)

    return () => {
      const buttonElementCollection = document.getElementsByClassName(
        'ant-btn ant-btn-text ant-btn-loading',
      )

      if (buttonElementCollection.length > 0) {
        const buttonElement = buttonElementCollection[0]
        buttonElement.removeEventListener('click', copyHandler)
      }
    }
  }, [])

  return (
    <div className="blind-boxes-lottery">
      <section className="lottery-section">
        <Container>
          <ChainvineWidget
            isOpen={open}
            userWalletAddress={account}
            campaignId={CHAINVINE_CAMPAIGN_ID}
            clientConfig={{ apiKey: CHAINVINE_API_KEY, logToConsole: true }}
            onClose={() => setOpen(false)}
            placement="bottom"
            theme={'Dark'}
            mode={'Inline'}
          ></ChainvineWidget>
        </Container>
      </section>
    </div>
  )
}
