import BigNumber from 'bignumber.js'
import { IWhitelistRequestFormData } from '@api/whitelist/types'
import { PublicKey } from '@solana/web3.js'
import { ContractAddress, ProgramAccountAddress, ProgramId } from '@contracts/address'
import { u64 } from '@solana/spl-token'

export interface IUseWhitelistArgs {
  projectId: string
  contract?: ContractAddress | ProgramId
  idlName?: string
}

export enum WhitelistStatuses {
  not_submitted = 'not_submitted',
  in_review = 'in_review',
  rejected = 'rejected',
  passed = 'passed',
}

export type WhitelistStatus = keyof typeof WhitelistStatuses

export interface WhitelistUserSolana {
  isKycPassed: boolean;
  privateAllo: u64;
  publicAllo: u64;
  user: PublicKey
}

export interface WhitelistAccountResponse {
  authority: PublicKey;
  userlist: WhitelistUserSolana[]
}

interface BaseUseWhitelistReturn {
  whiteListStatus: WhitelistStatus | null
  privatePresaleAllowed: boolean
  publicMaxAllocation: BigNumber
  privateMaxAllocation: BigNumber
  applyToWhitelist: (formData: IWhitelistRequestFormData) => Promise<WhitelistStatus | null>
  applyToExclusiveWhitelist: (
    formData: IWhitelistRequestFormData,
    referrer: string,
    password: string
  ) => Promise<WhitelistStatus | null>
}

export interface IUseWhitelistReturnEVM extends BaseUseWhitelistReturn {
  isKycPassed?: boolean
  airdropAmount?: BigNumber
  merkleProof?: string[]
}

export interface IUseWhitelistReturnSolana extends BaseUseWhitelistReturn {
  whitelistAccountAddress?: ProgramAccountAddress
  whitelistedUsers?: number
}

export type IUseWhitelistReturn = IUseWhitelistReturnEVM & IUseWhitelistReturnSolana
