import './TiersTable.scss'
import { CommonTooltip, DynamicImage } from '@/components';
import {
  CooldownPeriodTooltip,
  FCFSLotteryTooltip,
  FPRequirementsTooltip,
  GuaranteedAllocationTooltip,
  SurpriseLotteryTooltip
} from './tooltips';
import { ReactComponent as TicketSingle } from '@assets/tiers/ticket-single.svg';
import { ReactComponent as Tickets } from '@assets/tiers/tickets.svg';
import { tierBreakpoints } from '@contracts/hooks/useFlameTier/constants';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import React, { useMemo } from 'react';
import { numberToCurrency } from '@utils/balanceFormatter';
import { TiersTableMobile } from '@components/common/TiersTableMobile'
import useScreenSize from 'use-screen-size'

export const TiersTable = () => {
  const { settings } = usePlatformSettings()

  const breakpoints = useMemo(() => {
    const factor = settings?.flameTiersFactor ?? 1

    return tierBreakpoints.map(point => numberToCurrency(+point * factor))
  }, [settings])

  const screenSize = useScreenSize()
  const mobile = screenSize.width < 992;

  return (
    <>
      {!mobile
        ? (<div className="tiers-table">
          <div className="tiers-table__head">
            <div className="tiers-table__col">Access</div>
            <div className="tiers-table__col">Tier</div>
            <div className="tiers-table__col">
              Flame Power
              Requirement
              <CommonTooltip
                id="flame-power-requirement"
                triggerClass="flame-power-requirement"
                tooltipClass="tiers-tooltip"
              >
                <FPRequirementsTooltip />
              </CommonTooltip>
            </div>
            <div className="tiers-table__col">Base Allocation Multiplier per Tier</div>
            <div className="tiers-table__col">Lottery Tickets (chances to win)</div>
            <div className="tiers-table__col">
              Cooldown period
              <CommonTooltip
                id="cooldown-period"
                triggerClass="cooldown-period"
                tooltipClass="tiers-tooltip"
                placement="left-start"
              >
                <CooldownPeriodTooltip />
              </CommonTooltip>
            </div>
          </div>
          <div className="tiers-table__body">
            <div className="tiers-table__col tiers-table__col--access">
              Surprise
              <br />
              lottery
              <CommonTooltip
                id="surprise-lottery"
                triggerClass="surprise-lottery"
                tooltipClass="tiers-tooltip"
                placement="right-start"
              >
                <SurpriseLotteryTooltip />
              </CommonTooltip>
            </div>
            <div className="tiers-table__col tiers-table__col--with-image tiers-table__col--tier">
              <DynamicImage path="tiers/light.svg" />
              LIGHT
            </div>
            <div className="tiers-table__col">{breakpoints[0]} +</div>
            <div className="tiers-table__col">n/a</div>
            <div className="tiers-table__col tiers-table__col--surprise">
              <DynamicImage path='tiers/present.svg' />
              <span>
                <span className="purple-text">10 lucky winners{' '}</span>
                will get IDO launch prize
              </span>
            </div>
            <div className="tiers-table__col rowspan-1-4 tiers-table__col--cooldown">
              <DynamicImage path="tiers/timer.svg" />
              <span className="text">
                <span className='purple-text'>7 days{' '}</span>
                after <br />
                successful IDO <br />
                participation
              </span>
            </div>
            <div className="tiers-table__col rowspan-2-4 tiers-table__col--access">
              FCFS
              <br />
              whitelist
              <br />
              lottery
              <CommonTooltip
                id="fcfs-lottery"
                triggerClass="fcfs-lottery"
                tooltipClass="tiers-tooltip"
                placement="right-start"
              >
                <FCFSLotteryTooltip />
              </CommonTooltip>
            </div>
            <div className="tiers-table__col tiers-table__col--with-image tiers-table__col--tier">
              <DynamicImage path="tiers/spark.svg" />
              SPARK
            </div>
            <div className="tiers-table__col">{breakpoints[1]} +</div>
            <div className="tiers-table__col text-center">Base allocation</div>
            <div className="tiers-table__col tiers-table__col--with-image">
              <TicketSingle />
              1-9
            </div>
            <div className="tiers-table__col tiers-table__col--with-image tiers-table__col--tier">
              <DynamicImage path="tiers/smoke.svg" />
              SMOKE
            </div>
            <div className="tiers-table__col">{breakpoints[2]} +</div>
            <div className="tiers-table__col">
              <span className='tiers-multiplier--rotate gradient-text gradient-text--purple'>2x</span>
            </div>
            <div className="tiers-table__col tiers-table__col--with-image">
              <Tickets />
              10-19
            </div>
            <div className="tiers-table__col tiers-table__col--with-image tiers-table__col--tier">
              <DynamicImage path="tiers/fire.svg" />
              FIRE
            </div>
            <div className="tiers-table__col">{breakpoints[3]} +</div>
            <div className="tiers-table__col">
              <span className='tiers-multiplier--rotate gradient-text gradient-text--purple'>4x</span>
            </div>
            <div className="tiers-table__col tiers-table__col--with-image">
              <Tickets />
              20-49
            </div>
            <div
              className="tiers-table__col tiers-table__col--with-image tiers-table__col--highlight tiers-table__col--tier">
              <DynamicImage path="tiers/flame.svg" />
              FLAME
            </div>
            <div className="tiers-table__col tiers-table__col--highlight">{breakpoints[4]} +</div>
            <div className="tiers-table__col tiers-table__col--highlight">
              <span className='tiers-multiplier--rotate gradient-text gradient-text--purple'>8x</span>
            </div>
            <div className="tiers-table__col tiers-table__col--with-image tiers-table__col--highlight">
              <Tickets />
              50+
            </div>
            <div className="tiers-table__col tiers-table__col--non-cooldown tiers-table__col--highlight">
              <DynamicImage path='tiers/cup.svg' />
              <span className='text'>
                Not applicable <br />
                to <span className='purple-text'>{' '}TOP{' '}</span> 10 on the
                <span className='purple-text fst-italic text-decoration-underline'>{' '}FLAME
                  Leaderboard{' '}</span>
              </span>
            </div>
            <div className="tiers-table__col tiers-table__col--access">
              Guaranteed
              <br />
              allocation
              <CommonTooltip
                id="guaranteed-allocation"
                triggerClass="guaranteed-allocation"
                tooltipClass="tiers-tooltip"
                placement="right-start"
              >
                <GuaranteedAllocationTooltip />
              </CommonTooltip>
            </div>
            <div className="tiers-table__col colspan-2-5 tiers-table__col--leaders">
              <span className='text text-center'>
                Top 100 on
                <span className='purple-text fst-italic text-decoration-underline'>
                  {' '}FLAME Leaderboard{' '}
                </span> and
                <span className='purple-text fst-italic text-decoration-underline'>
                  {' '}Hiro NFT{' '}
                </span> holders. Amount of max
                allocation depends on Tier.
              </span>
            </div>
          </div>
        </div>)
        : (<TiersTableMobile breakpoints={breakpoints} />)
      }
    </>
  )
}
