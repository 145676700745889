import { useSelector } from './useSelector';
import { ActionType, AppState } from '@store/types';
import { useDispatch } from './useDispatch';
import { getPlatformSettings } from '@api/settings';
import { PlatformSettingsResponse } from '@api/settings/types';
import { FSProjectId } from '@constants';
import { ContractAddress } from '@firestarter-private/firestarter-library/lib/types'

export interface PlatformContracts {
  blindBoxLotteryPhase1: ContractAddress
  blindBoxLotteryPhase2: ContractAddress
}

export interface PlatformSettings {
  featuredProjectId: string
  flameTiersFactor: number
  contracts?: PlatformContracts
}

const mapSettings = ({
   featured_project_id,
   flame_tiers_factor,
   contracts,
 }: PlatformSettingsResponse): PlatformSettings => ({
  featuredProjectId: featured_project_id ?? FSProjectId as string,
  flameTiersFactor: flame_tiers_factor ?? 1,
  contracts: contracts && {
    blindBoxLotteryPhase1: contracts.blind_box_lottery_phase1,
    blindBoxLotteryPhase2: contracts.blind_box_lottery_phase2,
  },
})
export const usePlatformSettings = () => {
  const settings = useSelector<AppState['settings']>(state => state.settings)
  const dispatch = useDispatch()

  const getSettings = async () => {
    const payload = mapSettings(await getPlatformSettings())

    dispatch({
      type: ActionType.SET_PLATFORM_SETTINGS,
      payload
    })
  }

  return {
    settings,
    getSettings
  }
}
