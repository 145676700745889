import { useCallback, useEffect, useMemo, useState } from 'react';
import { getWhitelistData, sendDataForExclusiveWhitelist, sendDataForWhitelist } from '@api/whitelist';
import { BNToBigNumber, toBigNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { useNetwork, useWalletContext, useWhitelistContract } from '@firestarter-private/firestarter-library'
import { toPubKey } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import { ProgramAccountAddress } from '@contracts/address'
import {
  IUseWhitelistArgs,
  IUseWhitelistReturn,
  WhitelistAccountResponse,
  WhitelistStatus,
  WhitelistStatuses,
  WhitelistUserSolana
} from '@contracts/hooks/useWhitelist/types'
import { defaultEVMNetworkId } from '@firestarter-private/firestarter-library/lib/constants'


export const useWhitelistSolana = ({ contract: programId, idlName, projectId }: IUseWhitelistArgs): IUseWhitelistReturn => {
  const isMountedRef = useIsMounted()
  const { account } = useWalletContext()
  const { currentNetworkId } = useNetwork()
  const publicKey = useMemo(() => account ? toPubKey(account) : undefined, [account])
  const wlProgram = useWhitelistContract(programId, idlName)
  const [accountAddress, setAccountAddress] = useState<ProgramAccountAddress>()
  const [whitelistAccounts, setWhitelistAccounts] = useState<WhitelistUserSolana[]>([])
  const [whiteListStatus, setStatus] = useState<WhitelistStatus | null>(null)
  const whitelistedUsers = useMemo(() => whitelistAccounts.length, [whitelistAccounts])

  const userAccount = useMemo(() => {
    if (!publicKey || !account) return null
    return whitelistAccounts.find(acc =>
      acc.user.equals(publicKey) ||
      acc.user.toString().toLowerCase() === account.toLowerCase()
    ) || null
  }, [whitelistAccounts, publicKey, account])

  const privateMaxAllocation = useMemo(() => {
    if (!userAccount) return toBigNumber(0)
    return BNToBigNumber(userAccount.privateAllo)
  }, [userAccount])

  const publicMaxAllocation = useMemo(() => {
    if (!userAccount) return toBigNumber(0)
    return BNToBigNumber(userAccount.publicAllo)
  }, [userAccount])

  const privatePresaleAllowed = useMemo(() => {
    return !privateMaxAllocation.isZero()
  }, [privateMaxAllocation])

  const getStatus = useCallback(async () => {
    if (!account || !projectId) {
      setStatus(WhitelistStatuses.not_submitted)
      setAccountAddress(undefined)
      return
    }
    const data = await getWhitelistData(currentNetworkId || defaultEVMNetworkId)( { account, projectId })
    if (!isMountedRef.current) return

    if (!data) {
      setStatus(WhitelistStatuses.not_submitted)
      setAccountAddress(undefined)
      return
    }
    setStatus(data.status)
    setAccountAddress(data.whitelist_account_address)
  }, [account, projectId, isMountedRef, currentNetworkId])

  const getAllocation = useCallback(async () => {
    if (!wlProgram || !accountAddress) {
      setWhitelistAccounts([])
      return
    }

    try {
      const data = await wlProgram.account.whitelist.fetch(accountAddress) as WhitelistAccountResponse
      isMountedRef.current && setWhitelistAccounts(data.userlist)
    } catch (err) {
      isMountedRef.current && setWhitelistAccounts([])
    }
  }, [wlProgram, accountAddress, isMountedRef])

  useEffect(() => {
    getStatus()
  }, [account, projectId])

  useEffect(() => {
    getAllocation()
  }, [accountAddress, wlProgram])

  const applyToWhitelist = useCallback(async (formData) => {
    if (!account || !projectId) return null

    const status = await sendDataForWhitelist(currentNetworkId || defaultEVMNetworkId)( {
      project_id: projectId,
      account,
      form_data: formData
    })

    if (status) setStatus(status)

    return status
  }, [projectId, account, currentNetworkId])

  const applyToExclusiveWhitelist = useCallback(async (
    formData,
    referrer,
    password
  ) => {
    if (!account || !projectId) return null

    const status = await sendDataForExclusiveWhitelist(currentNetworkId || defaultEVMNetworkId)( {
      project_id: projectId,
      account,
      form_data: formData,
      referrer,
      password
    })

    if (status) setStatus(status)

    return status
  }, [projectId, account, currentNetworkId])

  return {
    whiteListStatus,
    whitelistAccountAddress: accountAddress,
    privatePresaleAllowed,
    publicMaxAllocation,
    privateMaxAllocation,
    whitelistedUsers,
    applyToWhitelist,
    applyToExclusiveWhitelist
  }
}
