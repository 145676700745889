import { isFuture } from 'date-fns'
import { dueDateForHiroNFT } from '@constants'

export const firestarterNFTs = [
  {
    name: 'Flare',
    description:
      "Igniting the future of The FireStarter. Ultra-rare NFT's for our earliest community members with FireStarter Platform integrations. See what ignites, own the future.",
    image: 'https://ipfs.infura.io/ipfs/QmbTY6FFXkNeo5jyK6wRQEXtN2b8G1b7gCitjyP5YpJxiy',
    id: '0001',
  },
  {
    name: 'Hiro',
    description: 'Ignition Event #1. Coming soon...',
    image: isFuture(new Date(dueDateForHiroNFT ?? '')) ? '/nft/hiro-blurred-image.png' : '/nft/hiro-video.mp4',
    id: '0002',
  },
]
