import { instance as API } from '../config';
import {
  GetEthLinkingStatusArgs,
  ILinkingAccount
} from './types';
import { sendExceptionReport } from '@utils/errors';

export const getEthLinkingStatus = async ({
  eth_wallet_address
}: GetEthLinkingStatusArgs): Promise<ILinkingAccount | null> => {
  try {
    const { data } = await API.get('/get-linking-status', {
      params: {
        wallet_address: eth_wallet_address
      }
    });

    return data as ILinkingAccount;
  } catch (err) {
    sendExceptionReport(err);
    return null;
  }
};
