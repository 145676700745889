import {
  APY_ACCURACY,
  defaultStakingStats,
  FLAME_PER_SHARE_ACCURACY,
  YEAR_IN_SECONDS
} from '@contracts/hooks/useStaking/constants'
import BigNumber from 'bignumber.js'
import { StakingStats } from '@contracts/hooks/useStaking/types'
import { differenceInSeconds, formatDistance } from 'date-fns'
import { Contract } from 'web3-eth-contract'
import { PublicKey } from '@solana/web3.js'
import { COINGECKO_FLAME_ID, COINGECKO_USDC_ID, coingeckoRetryTimeout, getPricesByTokenIds } from '@api/prices'
import { balanceToNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { SECOND } from '@firestarter-private/firestarter-library/lib/constants'
import { retry } from '@firestarter-private/firestarter-library/lib/utils/promises'

export const getStakePoolAccount = async (stakingProgramId: PublicKey) => {
  const [stakePool] = await PublicKey.findProgramAddress(
    [Buffer.from("stake_pool")],
    stakingProgramId
  )
  return stakePool
}

export const getStakeRewardsAccount = async (
  publicKey: PublicKey,
  stakePoolAccount: PublicKey,
  stakingProgramId: PublicKey
): Promise<PublicKey> => {
  const [stakeRewards] = await PublicKey.findProgramAddress(
    [publicKey.toBuffer(), stakePoolAccount.toBuffer()],
    stakingProgramId
  )
  return stakeRewards
}

export const getLpAccount = async (stakingProgramId: PublicKey): Promise<PublicKey> => {
  const [lpAccount] = await PublicKey.findProgramAddress(
    [Buffer.from("lp_account")],
    stakingProgramId
  )
  return lpAccount
}

export const getFlameAccount = async (stakingProgramId: PublicKey): Promise<PublicKey> => {
  const [flameAccount] = await PublicKey.findProgramAddress(
    [Buffer.from("flame_account")],
    stakingProgramId
  )
  return flameAccount
}

const secondsSinceLastFlame = (lastFlameTimestamp: number) =>
  (+new Date() - lastFlameTimestamp) / SECOND

export const newflamePerShare = (flamePerSecond: BigNumber, totalStaked: BigNumber, lastFlameTimestamp: number) =>
  flamePerSecond
    .times(secondsSinceLastFlame(lastFlameTimestamp))
    .times(FLAME_PER_SHARE_ACCURACY)
    .div(totalStaked)

export const getFlameAndUSDCPrice = () =>
  getPricesByTokenIds([
      COINGECKO_FLAME_ID,
    ]
  ).then(prices => ({
    flamePrice: prices[COINGECKO_FLAME_ID],
    usdcPrice: 1
  })).catch(() => ({
    flamePrice: 0.2,
    usdcPrice: 1
  }))

export const rewardsCalc = (amountStaked: BigNumber, rewardDebt: BigNumber, accumulatedReward: BigNumber, flamePerShare: BigNumber) =>
  amountStaked
    .multipliedBy(flamePerShare)
    .div(FLAME_PER_SHARE_ACCURACY)
    .minus(rewardDebt)
    .plus(accumulatedReward)

const formatNumbers = (number: any, decimals: any) =>
  balanceToNumber(new BigNumber(number), decimals)

export const getReserves = (lpTokenContract: Contract, flameDecimals: number, usdcDecimal: number) =>
  lpTokenContract.methods.getReserves().call()
    .then((reserves: number[]) => ({
      flameReserves: formatNumbers(reserves[0], flameDecimals),
      usdcReserves: formatNumbers(reserves[1], usdcDecimal)
    }))

export const getTotalSupply = (lpTokenContract: Contract, lpDecimals: number) =>
  lpTokenContract.methods.totalSupply().call()
    .then((totalSupply: number) => formatNumbers(totalSupply, lpDecimals))

export const getStakingStats = (isStakingActive: boolean, startTimestamp: number, stakingPeriod: number, flamePerSecond: BigNumber, totalRewards: BigNumber): StakingStats => {
  const programEndTime = new Date(startTimestamp + stakingPeriod)
  const stakingStartTime = isStakingActive ? new Date() : startTimestamp
  const secondsToProgramEnd = differenceInSeconds(programEndTime, stakingStartTime)
  const lockedRewards = flamePerSecond.times(secondsToProgramEnd)
  const unlockedRewards = totalRewards.minus(lockedRewards)
  const programDuration = isStakingActive ? formatDistance(programEndTime, new Date()) : undefined;
  return { ...defaultStakingStats, totalRewards, lockedRewards, unlockedRewards, programDuration }
}

export const getAPY = (rewardsPerSecond: BigNumber, totalStaked: BigNumber, lpDecimals: number, flameDecimals: number, lpTokenPrice: number, flamePrice: number) => {
  const annualRewards = rewardsPerSecond.times(YEAR_IN_SECONDS)
  const totalStakedNumber = balanceToNumber(totalStaked, lpDecimals)
  const annualRewardsValue = balanceToNumber(annualRewards, flameDecimals) * flamePrice
  const stakedValue = totalStakedNumber * lpTokenPrice
  const notRoundedAPY = +annualRewardsValue / (stakedValue || 1)
  return Math.floor(notRoundedAPY * APY_ACCURACY) / 100
}
