import React, { useEffect, useState } from "react";
import { RoundButton, Spinner } from '@components/common'
import { Col, Form, Row } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  allFieldNames,
  IDOFormValues,
  initialValues,
  optionalFieldNames,
  requiredFieldNames
} from './constants'

const validationSchema = yup.object().shape({
  contactName: yup.string().required('Contact Name is required'),
  contactPosition: yup.string().required('Please provide your position'),
  contactTelegram: yup.string().optional(),
  contactEmail: yup.string().email('Wrong format of email').required('Please provide your email for contact'),
  teamGeography: yup.string().required('This information is required'),
  projectName: yup.string().required('Please provide the project name'),
  description: yup.string().required('Write a short project description'),
  projectStatus: yup.string().required('Specify a current state of the project'),
  websiteUrl: yup.string().url('Wrong format').required('Please provide the project website'),
  pitchDeckUrl: yup.string().url('Wrong format').optional(),
  projectTwitterUrl: yup.string().url('Wrong format').optional(),
  sourceAboutFS: yup.string().required('Tell how did you lear about FireStarter')
})

interface Props {
  onSubmit: (formData: IDOFormValues) => Promise<unknown> | unknown
  setFilledFields: (count: number) => void
  setTotalFields: (count: number) => void
}

export const IDOForm = ({ onSubmit, setFilledFields, setTotalFields }: Props) => {
  const [loading, setLoading] = useState(false)

  const onSubmitHandler = async (values: IDOFormValues) => {
    if (loading) return

    setLoading(true)
    await onSubmit(values)
    setLoading(false)
  }

  const {
    errors,
    touched,
    handleSubmit,
    isValid,
    getFieldProps,
    setFieldTouched,
  } = useFormik({
    validateOnChange: true,
    validationSchema,
    onSubmit: onSubmitHandler,
    initialValues
  })

  const updateFilledFieldsCount = () => {
    const validField = (fieldName: keyof IDOFormValues): number => (!!touched[fieldName] && !errors[fieldName] ? 1 : 0)

    const optionalFieldsFilled = optionalFieldNames.reduce(
      (acc, field) => validField(field) + acc,
      0
    )
    const fieldsTotal = requiredFieldNames.length + optionalFieldsFilled
    const filledFields = allFieldNames.reduce((acc: number, field) => validField(field) + acc, 0)

    setFilledFields(filledFields)
    setTotalFields(fieldsTotal)
  }

  const getFieldPropsUpgraded = (field: keyof IDOFormValues) => {
    const props = getFieldProps(field)
    const onChange = (e: any) => {
      props.onChange(e)
      setFieldTouched(field, true, true)
    }
    return { ...props, onChange }
  }

  useEffect(() => {
    updateFilledFieldsCount()
  }, [errors, touched])

  return (
    <Form noValidate className="imo-form marketing-page-form hide-scroll" onSubmit={handleSubmit}>
      <div className="marketing-page-form__inner hide-scroll">
        <Row className="tile">
          <Col>
            <h4 className="title">Contact details</h4>
            <Form.Group controlId="contactName">
              <Form.Label>Contact Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="John Doe"
                {...getFieldPropsUpgraded('contactName')}
                isValid={touched.contactName && !errors.contactName}
                isInvalid={touched.contactName && !!errors.contactName}
              />
              <Form.Control.Feedback type="invalid">{errors.contactName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactPosition">
              <Form.Label>Contact Position</Form.Label>
              <Form.Control
                type="text"
                placeholder="CEO, Manager, ..."
                {...getFieldPropsUpgraded('contactPosition')}
                isValid={touched.contactPosition && !errors.contactPosition}
                isInvalid={touched.contactPosition && !!errors.contactPosition}
              />
              <Form.Control.Feedback type="invalid">{errors.contactPosition}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactTelegram">
              <Form.Label>Contact Telegram Handle</Form.Label>
              <Form.Control
                type="text"
                placeholder="@JohnDoe"
                {...getFieldPropsUpgraded('contactTelegram')}
                isValid={touched.contactTelegram && !errors.contactTelegram}
                isInvalid={touched.contactTelegram && !!errors.contactTelegram}
              />
              <Form.Control.Feedback type="invalid">{errors.contactTelegram}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactEmail">
              <Form.Label>Contact Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="example@mail.com"
                {...getFieldPropsUpgraded('contactEmail')}
                isValid={touched.contactEmail && !errors.contactEmail}
                isInvalid={touched.contactEmail && !!errors.contactEmail}
              />
              <Form.Control.Feedback type="invalid">{errors.contactEmail}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="teamGeography">
              <Form.Label>Core Team’s Geography</Form.Label>
              <Form.Control
                type="text"
                {...getFieldPropsUpgraded('teamGeography')}
                isValid={touched.teamGeography && !errors.teamGeography}
                isInvalid={touched.teamGeography && !!errors.teamGeography}
              />
              <Form.Control.Feedback type="invalid">{errors.teamGeography}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="tile">
          <Col>
            <h4 className="title">Project Details</h4>
            <Form.Group controlId="projectName">
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                {...getFieldPropsUpgraded('projectName')}
                isValid={touched.projectName && !errors.projectName}
                isInvalid={touched.projectName && !!errors.projectName}
              />
              <Form.Control.Feedback type="invalid">{errors.projectName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Project Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                {...getFieldPropsUpgraded('description')}
                isValid={touched.description && !errors.description}
                isInvalid={touched.description && !!errors.description}
              />
              <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="projectStatus">
              <Form.Label>Project Status (When do you expect working product/mainnet? Feel free to share your roadmap)</Form.Label>
              <Form.Control
                type="text"
                {...getFieldPropsUpgraded('projectStatus')}
                isValid={touched.projectStatus && !errors.projectStatus}
                isInvalid={touched.projectStatus && !!errors.projectStatus}
              />
              <Form.Control.Feedback type="invalid">{errors.projectStatus}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="tile">
          <Col>
            <h4 className="title">Links</h4>
            <Form.Group controlId="websiteUrl">
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="url"
                placeholder="https://www.example.com"
                {...getFieldPropsUpgraded('websiteUrl')}
                isValid={touched.websiteUrl && !errors.websiteUrl}
                isInvalid={touched.websiteUrl && !!errors.websiteUrl}
              />
              <Form.Control.Feedback type="invalid">{errors.websiteUrl}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="pitchDeckUrl">
              <Form.Label>Pitch Deck (Optional)</Form.Label>
              <Form.Control
                type="url"
                placeholder="https://example.com"
                {...getFieldPropsUpgraded('pitchDeckUrl')}
                isValid={touched.pitchDeckUrl && !errors.pitchDeckUrl}
                isInvalid={touched.pitchDeckUrl && !!errors.pitchDeckUrl}
              />
              <Form.Control.Feedback type="invalid">{errors.pitchDeckUrl}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="projectTwitterUrl">
              <Form.Label>Project Twitter (Optional)</Form.Label>
              <Form.Control
                type="url"
                placeholder="https://x.com/ProjectName"
                {...getFieldPropsUpgraded('projectTwitterUrl')}
                isValid={touched.projectTwitterUrl && !errors.projectTwitterUrl}
                isInvalid={touched.projectTwitterUrl && !!errors.projectTwitterUrl}
              />
              <Form.Control.Feedback type="invalid">{errors.projectTwitterUrl}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="tile">
          <Col>
            <h4 className="title">How did you learn about FireStarter?</h4>
            <Form.Group controlId="sourceAboutFS">
              <Form.Control
                as="textarea"
                rows={3}
                {...getFieldPropsUpgraded('sourceAboutFS')}
                isValid={touched.sourceAboutFS && !errors.sourceAboutFS}
                isInvalid={touched.sourceAboutFS && !!errors.sourceAboutFS}
              />
              <Form.Control.Feedback type="invalid">{errors.sourceAboutFS}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="text-center marketing-page-form__submit-row">
          <Col>
            <RoundButton type="submit" size="large" wide disabled={!isValid || loading}>
              {loading ? <Spinner /> : 'Submit'}
            </RoundButton>
          </Col>
        </Row>
      </div>
    </Form>
  )
}
