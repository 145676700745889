import { Dropdown, Image } from 'react-bootstrap'
import { buyFlameLink, KuCoinFlameLink } from '@constants'
import KuCoinLogo from '@assets/features/kucoin-logo.png'
import QuickSwapLogo from '@assets/features/quickswap-logo.png'
import React from 'react'
import './BuyFlameButton.scss'
import { ArrowIcon } from '@/components'

export const BuyFlameButton = () => (
  <Dropdown className="buy-flame-dropdown">
    <Dropdown.Toggle>
      Buy $Flame Tokens
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item
        className="buy-flame-dropdown__item"
        href={KuCoinFlameLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={KuCoinLogo} roundedCircle />
        <span>KUCOIN</span>
      </Dropdown.Item>
      <Dropdown.Item
        className="buy-flame-dropdown__item"
        href={buyFlameLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={QuickSwapLogo} roundedCircle />
        <span>QUICKSWAP</span>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);
