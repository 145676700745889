import { SolanaWalletAdapterType } from '@contracts/types'
import { BaseMessageSignerWalletAdapter, WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets'
import {
  createDefaultAddressSelector,
  createDefaultAuthorizationResultCache,
  createDefaultWalletNotFoundHandler,
  SolanaMobileWalletAdapter,
} from '@solana-mobile/wallet-adapter-mobile'
import { defaultSolanaNetworkData } from '@firestarter-private/firestarter-library/lib/constants'
import { detectMobile } from '@utils/detectMobile'

export const SOLANA_WALLET_ADAPTERS: { [key in SolanaWalletAdapterType]: BaseMessageSignerWalletAdapter } = {
  [SolanaWalletAdapterType.PHANTOM]: new PhantomWalletAdapter(),
  [SolanaWalletAdapterType.MOBILE]: new SolanaMobileWalletAdapter({
    addressSelector: createDefaultAddressSelector(),
    appIdentity: {
      name: 'FireStarter',
      uri: 'https://firestarter.fi',
      icon: 'https://firestarter.fi/favicon.ico'
    },
    authorizationResultCache: createDefaultAuthorizationResultCache(),
    cluster: defaultSolanaNetworkData?.cluster || WalletAdapterNetwork.Mainnet,
    onWalletNotFound: createDefaultWalletNotFoundHandler()
  })
}

export function getWalletAdapter(type: SolanaWalletAdapterType): BaseMessageSignerWalletAdapter {
  const adapter = SOLANA_WALLET_ADAPTERS[type]
  if (!adapter) {
    throw Error('Unsupported adapter')
  }
  return adapter
}

export function getWalletAdapterForDevice(): BaseMessageSignerWalletAdapter {
  const isMobile = detectMobile()
  return getWalletAdapter(
    isMobile
      ? SolanaWalletAdapterType.MOBILE
      : SolanaWalletAdapterType.PHANTOM
  )
}