import { Modal, ModalProps } from 'react-bootstrap'
import React from 'react'
import { RoundButton } from '@components'
import './ConfirmationPopup.scss'

interface Props {
  show: boolean
  size?: ModalProps['size']
  centered?: ModalProps['centered']
  className?: string
  contentClassName?: string
  dialogClassName?: string
  title: React.ReactNode
  cancelButton?: React.ReactComponentElement<any>
  confirmButton?: React.ReactComponentElement<any>
  onCancel: () => void
  onConfirm: () => void
  children: React.ReactNode
}

export const ConfirmationPopup = ({
  show,
  size,
  centered,
  className,
  contentClassName,
  dialogClassName,
  title,
  cancelButton,
  confirmButton,
  onCancel,
  onConfirm,
  children,
}: Props) => {
  return (
    <Modal
      show={show}
      onHide={() => {}}
      backdrop="static"
      size={size}
      centered={centered ?? true}
      className={`${className} confirm-popup`}
      backdropClassName="confirm-popup__backdrop"
      contentClassName={`${contentClassName} confirm-popup__content tile`}
      dialogClassName={`${dialogClassName} confirm-popup__dialog`}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {
          cancelButton
            ? React.cloneElement(
              cancelButton,
              { onClick: onCancel },
            )
            : <RoundButton size="large" color="DARK" onClick={onCancel}>
              Cancel
            </RoundButton>
        }
        {
          confirmButton
            ? React.cloneElement(
              confirmButton,
              { onClick: onConfirm }
            )
            : <RoundButton size="large" color="LIGHT" onClick={onConfirm}>
              Confirm
            </RoundButton>
        }
      </Modal.Footer>
    </Modal>
  )
}