import React, { useRef, useState } from 'react';
import { Modal, Overlay, OverlayProps, Tooltip, TooltipProps } from 'react-bootstrap';
import { ReactComponent as TooltipIcon } from '@assets/tooltip-icon.svg';
import './CommonTooltip.scss'
import useScreenSize from 'use-screen-size'

interface Props extends Omit<OverlayProps, 'children' | 'target'>, TooltipProps {
  triggerEl: React.ReactNode | React.ReactComponentElement<any>
  triggerClass?: string
  tooltipClass?: string
  children: React.ReactNode
}

const CommonTooltip = (props: Props) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)

  const screenSize = useScreenSize()
  const mobile = screenSize.width < 992;

  return (
    <>
      {!mobile
        ? (<span
          className={`tooltip-wrapper ${props.triggerClass ?? ''}`}
          ref={target}
          onMouseOver={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          {props.triggerEl}
          <Overlay
            {...props}
            target={target.current}
            show={show}
          >
            {(properties) => (
              <Tooltip
                className={`common-tooltip ${props.tooltipClass ?? ''}`}
                id={props.id}
                {...properties}
              >
                {props.children}
              </Tooltip>
            )}
          </Overlay>
        </span>)
        : (
          <span onClick={(e) => e.stopPropagation()}>
            <span
              className={`tooltip-wrapper ${props.triggerClass ?? ''}`}
              onClick={(e) => {
                setShow(true);
                e.preventDefault();
              }}
            >
              {props.triggerEl}
            </span>
            <Modal
              show={show}
              centered
              onHide={() => setShow(false)}
              contentClassName="tile text-center tooltip-modal m-3"
            >
              <Modal.Body>
                {props.children}
              </Modal.Body>
            </Modal>
          </span>)
      }
    </>
  );
}

CommonTooltip.defaultProps = {
  placement: 'auto',
  flip: true,
  triggerEl: <TooltipIcon />
}

export { CommonTooltip }
