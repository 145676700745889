import { LoadingWrap, LotteryBanner, LotteryBannerPhase } from '@components'
import { generatePath } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'
import { useLotteryContext } from '@pages/BlindBoxes/useLotteryContext'
import { defaultEVMNetworkId, web3NoAccountInstances } from '@firestarter-private/firestarter-library/lib/constants'
import { balanceToNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { FLAME_DECIMALS } from '@constants'
import { useEffect } from 'react'
import { usePlatformSettings } from '@hooks/usePlatformSettings'

interface Props {
  buttonText: string
}
export const LotteryBannerSection = ({ buttonText }: Props) => {
  const { settings } = usePlatformSettings()
  const { phases, getData } = useLotteryContext(web3NoAccountInstances[defaultEVMNetworkId])

  useEffect(() => {
    if (
      settings?.contracts?.blindBoxLotteryPhase1 &&
      settings.contracts.blindBoxLotteryPhase2
    ) {
      getData()
    }
  }, [settings?.contracts])

  return (
      <LotteryBanner
        link={generatePath(RoutesPaths.LOOT_BOXES.LOTTERY)}
        buttonText={buttonText}
      >
        <LoadingWrap loading={!phases.length}>
          {!!phases.length &&
            <LotteryBannerPhase
              // @ts-ignore
              phases={phases.map(p => ({
                startDate: p.startDate?.getTime(),
                endDate: p.endDate?.getTime(),
                maxAmount: balanceToNumber(p.maxPrize, FLAME_DECIMALS),
              }))}
            />
          }
        </LoadingWrap>
      </LotteryBanner>
  );
}