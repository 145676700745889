import { useCallback, useEffect, useState } from 'react'
import { getUserTier } from '@api/tiers/getUserTier'
import { IUserTier } from '@api/tiers/types'
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { useKYC } from '@contracts/hooks/useKYC'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { FlameTier, FlameTiers } from '@contracts/hooks/useFlameTier/constants'

export const tiersColors: Record<FlameTier, string> = {
  [FlameTiers.LIGHT]: '#C58BFF',
  [FlameTiers.SPARK]: '#C58BFF',
  [FlameTiers.SMOKE]: '#C58BFF',
  [FlameTiers.FIRE]: '#C58BFF',
  [FlameTiers.FLAME]: '#C58BFF',
}

export const tiersImages: Record<FlameTier, string> = {
  [FlameTiers.LIGHT]: 'tiers/light.svg',
  [FlameTiers.SPARK]: 'tiers/spark.svg',
  [FlameTiers.SMOKE]: 'tiers/smoke.svg',
  [FlameTiers.FIRE]: 'tiers/fire.svg',
  [FlameTiers.FLAME]: 'tiers/flame.svg',
}

export interface UserTierInfo extends IUserTier {
  tierColor: string | null
  tierImage: string | null
}

export interface IUseFlameTier {
  userTierInfo: UserTierInfo | null,
  getTier: () => Promise<void>
  loading: boolean
}

export const useFlameTier = (): IUseFlameTier => {
  const { account } = useWalletContext()
  const { KYCStatus, ethWalletAddress, getKYCStatus } = useKYC()
  const { currentNetworkId } = useNetwork()
  const [userTierInfo, setUserTier] = useState<UserTierInfo | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!KYCStatus && isSolanaNetwork(currentNetworkId as string)) {
      getKYCStatus()
    }
  }, [account, KYCStatus])

  const getTier = useCallback(async () => {
    if (!ethWalletAddress) return
    setLoading(true)
    const tierInfo = await getUserTier(ethWalletAddress)
    setUserTier(tierInfo ? {
      ...tierInfo,
      tierColor: tierInfo.tier ? tiersColors[tierInfo.tier] : null,
      tierImage: tierInfo.tier ? tiersImages[tierInfo.tier] : null
    } : null)
    setLoading(false)
  }, [ethWalletAddress])

  useEffect(() => {
    if (ethWalletAddress) {
      getTier()
    }
  }, [ethWalletAddress])

  return {
    userTierInfo,
    getTier,
    loading
  }
}
