import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Image } from 'react-bootstrap'
import { ConfirmationPopup, LoadingWrap, RoundButton, Spinner, WrongNetworkBlock } from '@components/common'
import { generatePath, Link } from 'react-router-dom'
import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg'
import './PortfolioItem.scss'
import { getMultipleVestingForWallet } from '@api/projects'
import { IUsePresaleArgs, usePresale } from '@contracts/hooks/usePresale'
import useScreenSize from 'use-screen-size'
import { IUseWhitelistArgs, useWhitelist } from '@contracts/hooks/useWhitelist'
import { isEthNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { IUseMultipleVestingArgs, useMultipleVesting } from '@contracts/hooks/useVesting'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import { NormalizedProjectInfo, ProjectTypes } from '@components/Project/types'
import { projectGetters } from '@contracts/getters/projectGetters'
import { ContractAddress } from '@firestarter-private/firestarter-library/lib/types'
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { balanceToCurrency, balanceToNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { getNetworkIcon } from '@contracts/networks'
import { RoutesPaths } from '@router/constants'
import { RefundingPanel } from '@components/Portfolio/RefundingPanel'
import { format, isFuture, isPast } from 'date-fns'

interface Props {
  project: NormalizedProjectInfo
}

export const PortfolioItem = ({ project }: Props) => {
  const isMountedRef = useIsMounted()
  const { account } = useWalletContext()
  const { checkIfSelected, currentNetworkId, getNetwork } = useNetwork()

  const isEthProject = useMemo(() => (project ? isEthNetwork(project.chainId) : false), [project])
  const isMultipleVesting = useMemo(() => projectGetters.getIsMultipleVesting(project), [project])
  const isExternalPresale = useMemo(() => projectGetters.getIsExternalPresale(project), [project])
  const isNodesSale = useMemo(() => project.project_type === ProjectTypes.nodes_sale, [project])
  const [isAddressesChecked, setAddressesChecked] = useState(!isMultipleVesting)
  const [multipleVestingAddresses, setVestingAddresses] = useState<ContractAddress[]>([])

  const isRefundable = useMemo(() => projectGetters.getIsRefundable(project), [project])
  const [refundablePeriod, setRefundablePeriod] = useState<Interval | null>(null)
  const [isRefunded, setIsRefunded] = useState(false)

  const [showConfirmClaim, setShowConfirmClaim] = useState(false)
  const [showConfirmRefund, setShowConfirmRefund] = useState(false)

  const isProjectNetworkSelected = useMemo(
    () => checkIfSelected(project.chainId.toString()), [project, checkIfSelected])

  const projectNetwork = useMemo(() => getNetwork(project.chainId), [project, getNetwork])

  const getVestingAddresses = useCallback(async () => {
    if (!account || !isProjectNetworkSelected) {
      setVestingAddresses([])
      return
    }
    const data = await getMultipleVestingForWallet(account)
    if (isMountedRef.current) {
      setVestingAddresses(data || [])
      setAddressesChecked(true)
    }
  }, [account, project, isMountedRef, isProjectNetworkSelected])

  useEffect(() => {
    if (isMultipleVesting) {
      getVestingAddresses()
    }
  }, [account, isMultipleVesting, getVestingAddresses, currentNetworkId])

  const vestingAddresses = useMemo(() => {
    let addresses = multipleVestingAddresses
    if (project.presale.vesting_contract_address) {
      addresses = [...addresses, project.presale.vesting_contract_address]
    }
    return isAddressesChecked && isProjectNetworkSelected ? addresses : []
  }, [multipleVestingAddresses, project, isAddressesChecked, isProjectNetworkSelected])

  const vestingArgs = useMemo<IUseMultipleVestingArgs>(() => {
    if (!project || !isProjectNetworkSelected) return {}
    if (isEthProject) return { contractAddresses: vestingAddresses }

    return {
      rewardTokenAddress: project.presale.reward_token.address,
      vestingProgramId: project.presale.vesting_program_id,
      vestingIdlName: project.presale.vesting_idl_name,
    }
  }, [project, isProjectNetworkSelected, isEthProject, vestingAddresses])

  const {
    isClaiming,
    totalVested,
    unvested,
    claimed,
    withdrawable,
    getUserVestingInfo,
    withdraw
  } = useMultipleVesting(vestingArgs)

  const whitelistArgs = useMemo<IUseWhitelistArgs>(() => {
    const contractAddress = isEthProject
      ? project?.presale.whitelist_contract_address
      : project?.presale.whitelist_program_id

    return {
      projectId: project.id,
      contract: isProjectNetworkSelected ? contractAddress : undefined,
      idlName: isProjectNetworkSelected && !isEthProject ? project?.presale.whitelist_idl_name : undefined,
    }
  }, [isEthProject, isProjectNetworkSelected, project])

  const { whitelistAccountAddress } = useWhitelist(whitelistArgs)

  const presaleArgs = useMemo<IUsePresaleArgs>(() => {
    if (!project || !isProjectNetworkSelected) return {}
    const argsEVM = {
      presaleAddress: project.presale.presale_contract_address,
      fundTokenAddress: project.presale.fund_token.address,
      rewardTokenAddress: project.presale.reward_token.address,
    }
    return isEthProject
      ? argsEVM
      : {
          ...argsEVM,
          presaleAddress: project.presale.presale_program_id,
          whitelistDataAccount: whitelistAccountAddress,
          presaleIdlName: project.presale.presale_idl_name,
        }
  }, [project, isProjectNetworkSelected, isEthProject, whitelistAccountAddress])

  const {
    rewardTokenInfo,
    getRefundablePeriod,
    getIsRefunded,
    onRefund
  } = usePresale(presaleArgs)

  useEffect(() => {
    if (isRefundable && getIsRefunded && getRefundablePeriod) {
      getIsRefunded()
        .then(result => setIsRefunded(result))
      getRefundablePeriod()
        .then(result => setRefundablePeriod(result))
    }
  }, [isRefundable, project.id]);

  const showRefundPanel = useMemo(() => {
    return isRefundable &&
      refundablePeriod &&
      isPast(refundablePeriod.start) &&
      claimed.isZero()
  }, [
    isRefundable,
    refundablePeriod,
    claimed,
  ])

  const ableToRefund = useMemo(() => {
    return showRefundPanel && isFuture(refundablePeriod!.end) && !isRefunded
  }, [
    showRefundPanel,
    refundablePeriod,
    isRefunded,
  ])

  const handleClickClaim = useCallback(async () => {
    if (ableToRefund) {
      setShowConfirmClaim(true)
    } else {
      await withdraw()
    }
  }, [ableToRefund, withdraw])

  const handleConfirmClaim = useCallback(async () => {
    setShowConfirmClaim(false)
    await withdraw()
  }, [withdraw])

  const handleClickRefund = useCallback(() => {
    setShowConfirmRefund(true)
  }, [])

  const handleConfirmRefund = useCallback(async () => {
    if (!onRefund) return
    setShowConfirmRefund(false)
    const result = await onRefund()
    if (!('error' in result)) {
      setIsRefunded(true)
      await getUserVestingInfo()
    }
  }, [onRefund, getUserVestingInfo])

  const rewardsDecimals = rewardTokenInfo.decimals
  const rewardTokenName = useMemo(() => {
    return project?.presale.reward_token.name
  }, [project])

  const screenSize = useScreenSize()
  const mobile = screenSize.width < 992

  const PortfolioItemCol = useCallback((props: { title: string, className?: string, amount: string}) => {
    return (
      <div className={`portfolio-item__col ${props.className && props.className}`}>
        {mobile && <span>{props.title}</span>}
        <span>
          {(!isExternalPresale && !isNodesSale) && `${props.amount} ${rewardTokenName}`}
        </span>
      </div>
    )
  }, [mobile, isEthProject, rewardTokenName])

  return isProjectNetworkSelected && totalVested.isZero() && !isExternalPresale && !isNodesSale ? (
    <></>
  ) : (
    <Col sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 12 }}>
      <div className="portfolio-item tile">
        <div className="portfolio-item__main">
          <LoadingWrap loading={!project}>
            <div className="portfolio-item__col portfolio-item__col--title">
              <div className="portfolio-item__logo">
                <Image src={project.assets.logo_image_url} roundedCircle className="portfolio-item__logo-image" />
                <Image src={getNetworkIcon(projectNetwork)} roundedCircle className="portfolio-item__network-image" />
              </div>
              <h4 className="portfolio-item__name">
                <Link to={generatePath(RoutesPaths.PROJECT, { id: project.id })}>{project.name}</Link>
              </h4>
            </div>
            {!isExternalPresale && !isNodesSale && !isProjectNetworkSelected ? (
              <div className="portfolio-item__col portfolio-item__col--wrong-network">
                <WrongNetworkBlock
                  prefix={'To claim your tokens'}
                  expectedNetwork={projectNetwork}
                  embedded={false}
                />
              </div>
            ) : (
              <>
                {isNodesSale ? (
                  <div className="portfolio-item__col portfolio-item__col--nodes">
                    <p>
                      Node Licenses will be distributed{' '}
                      {project.presale.nodes_distribution_starts ? `after ${format(project.presale.nodes_distribution_starts, 'do MMMM')}` : 'soon'}
                    </p>
                  </div>
                ) : (
                  <>
                    <PortfolioItemCol title="Tokens" amount={balanceToCurrency(totalVested, rewardsDecimals)} />
                    <PortfolioItemCol title="Unvested" amount={balanceToCurrency(unvested, rewardsDecimals)} />
                    <PortfolioItemCol title="Claimed" amount={balanceToCurrency(claimed, rewardsDecimals)} />
                    <PortfolioItemCol className="purple-text" title="Claim" amount={balanceToCurrency(withdrawable, rewardsDecimals)} />
                  </>
                )}

                <div className="portfolio-item__col">
                  {(isExternalPresale || isNodesSale) ? (
                    <RoundButton size="large" href={project.presale.external_claim_link ?? project.presale.external_presale_link}>
                      Go To Website <LinkArrow />
                    </RoundButton>
                  ) : (
                    <>
                      <RoundButton
                        size="large"
                        color="LIGHT"
                        disabled={!balanceToNumber(withdrawable, rewardsDecimals) || isClaiming}
                        onClick={handleClickClaim}
                      >
                        {isClaiming ? <Spinner /> : 'Claim'}
                      </RoundButton>
                    </>
                  )}
                </div>
              </>
            )}
          </LoadingWrap>
        </div>
        {showRefundPanel && <RefundingPanel
          refundablePeriodEnd={refundablePeriod!.end}
          isRefunded={isRefunded}
          onRefund={handleClickRefund}
        />}
        {
          ableToRefund && (
            <>
              <ConfirmationPopup
                show={showConfirmClaim}
                title="Are you claiming before the refund period ends?"
                onCancel={() => setShowConfirmClaim(false)}
                onConfirm={handleConfirmClaim}
              >
                <p>Please note that you won’t be qualified for a refund after claiming the tokens</p>
              </ConfirmationPopup>
              <ConfirmationPopup
                show={showConfirmRefund}
                title="Are you sure you want to issue refund?"
                onCancel={() => setShowConfirmRefund(false)}
                onConfirm={handleConfirmRefund}
              >
                <p>Please note that once you make a refund, you won’t be qualified for claiming the tokens</p>
              </ConfirmationPopup>
            </>
          )
        }
      </div>
    </Col>
  )
}
