import React from 'react'
import LotteryPlaceholder1 from '@assets/lottery/lottery-placeholder-1.png'
import LotteryPlaceholder2 from '@assets/lottery/lottery-placeholder-2.png'
import { Col, Image, Row } from 'react-bootstrap'
import './StayTunedBanner.scss'

interface BannerProps {
  phaseIndex: number
}
export const StayTunedBanner = ({ phaseIndex }: BannerProps) => {
  return (
    <Row className="lottery-block tile lottery-placeholder justify-content-center">
      <Col className="h-100 text-center" lg={{ span: 6 }}>
        <div className="lottery-placeholder__image">
          <Image src={phaseIndex === 0 ? LotteryPlaceholder1 : LotteryPlaceholder2} />
        </div>
        <p className="lottery-placeholder__message">
          Stay tuned for Phase {phaseIndex + 1} <br/>
          to start!
        </p>
      </Col>
    </Row>
  )
}