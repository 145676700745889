import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'

interface Props {
  children: React.ReactNode
}

export const ScrollRestoration = ({ children }: Props) => {
  const { pathname, hash } = useLocation()

  useEffect(() => {
    !hash && window.scrollTo(0, 0)
  }, [pathname, hash])

  return <>{children}</>
}
