import { useCallback, useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { sendExceptionReport } from '@utils/errors'
import {
  useMultipleVestingContracts,
  useTransactions,
  useWalletContext,
} from '@firestarter-private/firestarter-library'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import { BlockNumber, TransactionReceipt } from 'web3-core'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'
import {
  IUseMultipleVestingArgs,
  IUseMultipleVestingReturns,
  MultipleVestingPromiseReturns,
} from '@contracts/hooks/useVesting/types'
import { toBigNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'

export const useMultipleVestingEVM = ({ contractAddresses }: IUseMultipleVestingArgs): IUseMultipleVestingReturns => {
  const isMountedRef = useIsMounted()
  const { callTransaction, sendTransaction } = useTransactions()
  const { account } = useWalletContext()
  const vestingContracts = useMultipleVestingContracts(contractAddresses ?? [])

  const [loading, setLoading] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const [totalVested, setVested] = useState<BigNumber>(toBigNumber(0))
  const [claimed, setClaimed] = useState<BigNumber>(toBigNumber(0))
  const [withdrawable, setWithdrawable] = useState<BigNumber>(toBigNumber(0))

  const unvested = useMemo(() => {
    return totalVested.minus(claimed).minus(withdrawable)
  }, [totalVested, claimed, withdrawable])

  const resetVestingInfo = () => {
    setVested(toBigNumber(0))
    setClaimed(toBigNumber(0))
    setWithdrawable(toBigNumber(0))
  }

  const getVestingPromise = async (blockNumber: BlockNumber): Promise<MultipleVestingPromiseReturns> => {
    let vestedSum = toBigNumber(0)
    let claimedSum = toBigNumber(0)
    let withdrawableSum = toBigNumber(0)

    await Promise.all([
      ...vestingContracts.map((contract) => {
        return callTransaction(contract.methods.recipients(account), blockNumber).then((result) => {
          vestedSum = vestedSum.plus(toBigNumber(result.totalAmount))
          claimedSum = claimedSum.plus(toBigNumber(result.amountWithdrawn))
        })
      }),
      ...vestingContracts.map((contract) => {
        return callTransaction(contract.methods.withdrawable(account), blockNumber).then((result) => {
          withdrawableSum = withdrawableSum.plus(toBigNumber(result))
        })
      }),
    ])

    return {
      vestedSum,
      claimedSum,
      withdrawableSum,
    }
  }

  const getUserVestingInfo = useCallback(async () => {
    if (!account || !vestingContracts.length) {
      resetVestingInfo()
      return
    }

    try {
      const { vestedSum, claimedSum, withdrawableSum } = await getVestingPromise(blockNumber)

      if (isMountedRef.current) {
        setVested(vestedSum)
        setClaimed(claimedSum)
        setWithdrawable(withdrawableSum)
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetVestingInfo()
    }
  }, [isMountedRef, account, vestingContracts, blockNumber])

  useEffect(() => {
    if (!loading && account && vestingContracts.length) {
      getUserVestingInfo()
    }
  }, [loading, vestingContracts, blockNumber, account])

  const withdraw = useCallback(
    async (callbacks: INotifyTxCallbacks = {}) => {
      if (!account || !vestingContracts.length) {
        return
      }

      for (let contract of vestingContracts) {
        setLoading(true)

        try {
          const withdrawableAmount = await callTransaction(contract.methods.withdrawable(account), 'latest')

          if (toBigNumber(withdrawableAmount).isGreaterThan(toBigNumber(0))) {
            const receipt = (await sendTransaction(contract.methods.withdraw(), callbacks)) as TransactionReceipt

            setBlockNumber(receipt.blockNumber)
          }
        } finally {
          setLoading(false)
        }
      }
    },
    [account, vestingContracts, sendTransaction, callTransaction],
  )

  return {
    isClaiming: loading,
    totalVested,
    unvested,
    claimed,
    withdrawable,
    getUserVestingInfo,
    withdraw,
  }
}
