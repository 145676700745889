import Web3 from 'web3';
import { getLotteryContract, } from '@contracts/getContract'
import { useMemo } from 'react';
import { ContractAddress } from '@firestarter-private/firestarter-library/lib/types'
import { defaultEVMNetworkId } from '@firestarter-private/firestarter-library/lib/constants'
import { useWeb3 } from '@firestarter-private/firestarter-library'


export const useLotteryContract = (address?: ContractAddress, _web3?: Web3) => {
  const web3 = useWeb3()

  return useMemo(() => getLotteryContract(
    defaultEVMNetworkId,
    address,
    _web3 ?? web3
  ), [web3, _web3, address])
}
