import { Accordion as BaseAccordion, Card } from 'react-bootstrap';
import { ArrowIcon, CommonTooltip, CustomToggle } from '@components';
import React from 'react'
import ticketImage from '@assets/tiers/ticket-single.svg';
import ticketsImage from '@assets/tiers/tickets.svg';
import presentImage from '@assets/tiers/present.svg';
import timerImage from '@assets/tiers/timer.svg';
import cupImage from '@assets/tiers/cup.svg';
import lightImage from '@assets/tiers/light.svg'
import fireImage from '@assets/tiers/fire.svg'
import smokeImage from '@assets/tiers/smoke.svg'
import sparkImage from '@assets/tiers/spark.svg'
import flameImage from '@assets/tiers/flame.svg'
import {
  CooldownPeriodTooltip,
  FCFSLotteryTooltip,
  FPRequirementsTooltip,
  GuaranteedAllocationTooltip,
  SurpriseLotteryTooltip
} from '@components/common/TiersTable/tooltips'


const getImage = (img: string) => {
  switch (img) {
    case 'light': return lightImage
    case 'fire': return fireImage
    case 'smoke': return smokeImage
    case 'spark': return sparkImage
    case 'flame': return flameImage
  }
}

export const FlamePowerRequired = (props: { power: string }) => (
  <div className="accordion-section">
    <div className="accordion-section-title">
      FLAME Power Requirement
      <CommonTooltip
        id="flame-power-requirement"
        triggerClass="flame-power-requirement"
        tooltipClass="tiers-mobile-tooltip"
      >
        <FPRequirementsTooltip />
      </CommonTooltip>
    </div>
    <span className="accordion-section-data power">{`${props.power} +`}</span>
  </div>
);

export const AllocationMultiplier = (props: { multiple: string, tier: string }) => (
  <div className="accordion-section">
    <div className="accordion-section-title">
      Base Allocation Multiplier per Tier
    </div>
    <span className={`accordion-section-data multiple ${props.tier.toLowerCase()}`}>
      {props.multiple}
    </span>
  </div>
);

export const LuckyWinners = (props: { tickets: string }) => (
  <div className="accordion-section lucky">
    <div className="accordion-section-title">
      Lottery Tickets (chances to win)
    </div>
    <div className="accordion-section-data">
      <img src={presentImage} alt='present' />
      <span>
        <span className="purple-text">{props.tickets}
          {' '}lucky winners{' '}
        </span>
        <br />
        will get IDO launch prize
      </span>
    </div>
  </div>
);

export const LotteryTickets = (props: { tickets: string }) => (
  <div className="accordion-section lottery">
    <div className="accordion-section-title">Lottery Tickets (chances to win)</div>
    <div className="accordion-section-data">
      <img src={ticketsImage} alt='tickets' />
      <span>
        {props.tickets}
      </span>
    </div>
  </div>
);

export const LotteryTicket = (props: { tickets: string }) => (
  <div className="accordion-section lottery">
    <div className="accordion-section-title">Lottery Tickets (chances to win)</div>
    <div className="accordion-section-data">
      <img src={ticketImage} alt='ticket' />
      <span>
        {props.tickets}
      </span>
    </div>
  </div>
);

export const Tier = (props: { tier: string, className: string }) => (
  <div className={`${props.className} ${props.tier.toLowerCase()}`}>
    <img src={getImage(props.tier.toLowerCase())} alt={props.tier.toLowerCase()}/>
    {props.tier}
  </div>
);

export const sevenDayCooldown = () => (
  <div className="accordion-section-data">
    <img src={timerImage} alt='timer' />
    <span className="text">
      <span className='purple-text'>7 days{' '}</span>
      after successful<br /> IDO participation
    </span>
  </div>
);

export const noCooldown = () => (
  <div className="accordion-section-data">
    <img src={cupImage} alt='cup' />
    <span className='text'>
      Not applicable to <span className='purple-text'>{' '}TOP{' '}</span> 10 on the <br />
      <span className='purple-text fst-italic text-decoration-underline'>{' '}FLAME Leaderboard{' '}</span>
    </span>
  </div>
);

export const CooldownPeriod = (props: { cooldown: () => React.ReactElement }) => (
  <div className="accordion-section cooldown">
    <div className="accordion-section-title">
      Cooldown period
      <CommonTooltip
        id="cooldown-period"
        triggerClass="cooldown-period"
        tooltipClass="tiers-mobile-tooltip"
        placement="left-start"
      >
        <CooldownPeriodTooltip />
      </CommonTooltip>
    </div>
    {props.cooldown()}
  </div>
);


export const SurpriseHeader = () => (
  <div className="accordion-header">
    <div className="accordion-header-title">
      Surprise lottery
    </div>
    <CommonTooltip
      id="surprise-lottery"
      triggerClass="surprise-lottery"
      tooltipClass="tiers-mobile-tooltip"
      placement="right-start"
    >
      <SurpriseLotteryTooltip />
    </CommonTooltip>
  </div>
);

export const GuaranteedHeader = () => (
  <div className="accordion-header">
    <div className="accordion-header-title">
      Guaranteed allocation
    </div>
    <CommonTooltip
      id="guaranteed-allocation"
      triggerClass="guaranteed-allocation"
      tooltipClass="tiers-mobile-tooltip"
      placement="right-start"
    >
      <GuaranteedAllocationTooltip />
    </CommonTooltip>
  </div>
);

export const FCFSHeader = () => (
  <div className="accordion-header">
    <div className="accordion-header-title">
      FCFS whitelist lottery
    </div>
    <CommonTooltip
      id="fcfs-lottery"
      triggerClass="fcfs-lottery"
      tooltipClass="tiers-mobile-tooltip"
      placement="right-start"
    >
      <FCFSLotteryTooltip />
    </CommonTooltip>
  </div>
);

export const GuaranteedBody = () => (
  <div className="accordion-section guaranteed">
    <div className="accordion-section-data">
      <span className="text text-center">
        Top 100 on
        <span className="purple-text fst-italic text-decoration-underline">
          {' '}FLAME Leaderboard{' '}
        </span> and
        <span className="purple-text fst-italic text-decoration-underline">
          {' '}Hiro NFT{' '}
        </span> holders. Amount of max
        allocation depends on Tier.
      </span>
    </div>
  </div>
);

interface AccordionProps {
  tier: string,
  power: string,
  multiple: string,
  tickets: string,
  cooldown: () => React.ReactElement
}

export const LightAccordion = ({ tier, power, multiple, tickets, cooldown }: AccordionProps) => (
  <BaseAccordion key='LIGHT'>
    <Card className="tiers-mobile-accordion">
      <Card.Header>
        <CustomToggle eventKey='LIGHT'>
          <SurpriseHeader />
          <ArrowIcon />
        </CustomToggle>
      </Card.Header>
      <BaseAccordion.Collapse eventKey='LIGHT'>
        <Card.Body>
          <Tier tier={tier} className='accordion-section-header' />
          <FlamePowerRequired power={power} />
          <AllocationMultiplier multiple={multiple} tier={tier} />
          <LuckyWinners tickets={tickets} />
          <CooldownPeriod cooldown={cooldown} />
        </Card.Body>
      </BaseAccordion.Collapse>
    </Card>
  </BaseAccordion>
);

export const TierAccordion = ({ tier, power, multiple, tickets, cooldown }: AccordionProps) => (
  <BaseAccordion key="tier">
    <Card className={`tiers-mobile-accordion nested ${tier.toLowerCase()}`}>
      <Card.Header>
        <CustomToggle eventKey='tier'>
          <Tier tier={tier} className='accordion-header' />
          <ArrowIcon />
        </CustomToggle>
      </Card.Header>
      <BaseAccordion.Collapse eventKey='tier'>
        <Card.Body className='nested'>
          <FlamePowerRequired power={power} />
          <AllocationMultiplier multiple={multiple} tier={tier} />
          {tier === 'SPARK' ? (<LotteryTicket tickets={tickets} />) : <LotteryTickets tickets={tickets} />}
          <CooldownPeriod cooldown={cooldown} />
        </Card.Body>
      </BaseAccordion.Collapse>
    </Card>
  </BaseAccordion>
);
