import { useCallback, useMemo } from 'react'
import { geyKYCStatus, updateWalletKYC } from '@api/kyc'
import { sendExceptionReport } from '@utils/errors'
import { useSelector } from '@hooks/useSelector'
import { useDispatch } from '@hooks/useDispatch'
import { ActionType, AppState, KYCData } from '@store/types'
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { defaultEVMNetworkId } from '@firestarter-private/firestarter-library/lib/constants'
import { useActionFactory } from '@store/reducer'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'

export const useKYC = () => {
  const {
    KYC,
    loading
  } = useSelector<Pick<AppState, 'loading' | 'KYC'>>(
    ({ KYC, loading }) => ({
      KYC,
      loading
    })
  )
  const dispatch = useDispatch()
  const KYCStatus = useMemo(() => KYC.status, [KYC])
  const KYCRefId = useMemo(() => KYC.referenceId, [KYC])
  const ethWalletAddress = useMemo(() => KYC.ethWalletAddress, [KYC])
  const { account } = useWalletContext()
  const { currentNetworkId } = useNetwork()

  const setKYCData = useActionFactory<KYCData>(dispatch, ActionType.SET_KYC_DATA)
  const setLoading = useActionFactory<boolean | unknown>(dispatch, ActionType.SET_LOADING)

  const getKYCStatus = useCallback(async () => {
    if (!account) {
      setKYCData({
        status: null,
        referenceId: null,
        ethWalletAddress: undefined
      })
      return
    }
    setLoading(true)
    const data = await geyKYCStatus(currentNetworkId || defaultEVMNetworkId)(account)
    if (data) {
      const ethWalletAddress = isSolanaNetwork(currentNetworkId || defaultEVMNetworkId)
        ? data?.wallet_address
        : account

      setKYCData({
        status: data.kyc_status,
        referenceId: data.reference_id,
        ethWalletAddress
      })
    }
    setLoading(false)
  }, [account, setKYCData, setLoading, currentNetworkId])

  const updateKYCStatus = useCallback( async () => {
    if (account) {
      setLoading(true)
      const data = await updateWalletKYC(account)
      if (data) {
        setKYCData({
          status: data.kyc_status,
          referenceId: KYCRefId as string
        })
      }
    } else {
      sendExceptionReport(new Error('Trying to update KYC status without connected wallet'))
    }
  }, [account, KYCRefId, setLoading, setKYCData])

  return {
    loading,
    KYCStatus,
    KYCRefId,
    ethWalletAddress,
    getKYCStatus,
    updateKYCStatus,
  }
}
