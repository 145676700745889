import { PublicKey } from '@solana/web3.js'
import { Program } from '@project-serum/anchor'
import { PresaleIdlType } from '@firestarter-private/firestarter-library/lib/hooks/useContracts/idls'
import { ContractAddress } from '@firestarter-private/firestarter-library/lib/types'
import { toPubKey } from '@firestarter-private/firestarter-library/lib/utils/addresses'

export const getPresaleRecipientAccount = async (
  publicKey: PublicKey,
  rewardMint: PublicKey,
  presaleProgramId: PublicKey
) => {
  const [recpInfoAccount] = await PublicKey.findProgramAddress(
    [
      rewardMint.toBuffer(),
      publicKey.toBuffer(),
      Buffer.from("recipient-info-seed")
    ],
    presaleProgramId
  );

  return recpInfoAccount
}

export const getPresaleAccountWithBump = async (
  rewardMint: PublicKey,
  presaleProgramId: PublicKey
): Promise<[PublicKey, number]> => {
  const [account, bump] = await PublicKey.findProgramAddress(
    [rewardMint.toBuffer(), Buffer.from("presale-seed")],
    presaleProgramId
  )
  return [account, bump]
}

export const getPresaleVaultAccountWithBump = async (
  rewardMint: PublicKey,
  presaleProgramId: PublicKey
): Promise<[PublicKey, number]> => {
  const [account, bump] = await PublicKey.findProgramAddress(
    [rewardMint.toBuffer(), Buffer.from("token-seed")],
    presaleProgramId
  )
  return [account, bump]
}
