import { ILinkingAccount } from '@api/link-wallets/types'
import { RoundButton, Spinner } from '@components'
import React, { useState } from 'react'
import { SigningStatus, WalletAddress } from '@pages/Account/LinkingWallets/LinkingComponents'
import { createSolanaWalletSignature } from '@utils/signature'
import { setTimeoutPromise } from '@firestarter-private/firestarter-library/lib/utils/promises'
import { finalizeLinkSolanaWallet } from '@api/link-wallets'
import { generatePath, useHistory } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'
import { useWalletContext } from '@firestarter-private/firestarter-library'
import { useSearchParams } from '@hooks/useSearchParams'


export const FinalizeLinking = ({ linkingStatusData }: { linkingStatusData: ILinkingAccount }) => {
  const { account } = useWalletContext();
  const { query } = useSearchParams();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [signingStatus, setSigningStatus] = useState<SigningStatus | null>();

  const finishLinkingWallets = async () => {
    if (linkingStatusData?.message) {
      setLoading(true);
      setSigningStatus(SigningStatus.message);

      const solana_signed_message =
        await createSolanaWalletSignature(linkingStatusData.message);

      if (solana_signed_message && account) {
        setSigningStatus(SigningStatus.request);
        await setTimeoutPromise(500);

        await finalizeLinkSolanaWallet({
          solana_reference_hash: query.reference,
          solana_wallet_address: account,
          solana_signed_message
        });

        history.replace(generatePath(RoutesPaths.ACCOUNT.LINKING_WALLETS));
      } else {
        setSigningStatus(null);
        setLoading(false);
      }
    }
  };

  return <>
    <div className="text-big fw-semibold linking-wallets-status__text">
      <div className="linking mb-3">
        <p>
          You are going to linking your wallets:
        </p>
        <WalletAddress address={linkingStatusData.wallet_address!} />
        <WalletAddress wallet="phantom" address={account!} />
      </div>
      <RoundButton
        className="link-wallet-dialogue__link-button"
        type="submit"
        size="large"
        disabled={loading}
        onClick={finishLinkingWallets}
      >
        {loading ? (
          <>
            <Spinner />
            {` ${signingStatus}`}
          </>
        ) : (
          'Confirm Linking'
        )}
      </RoundButton>
    </div>
  </>
}
