import { LeaderboardData, LeaderboardResponse } from './types';
import BigNumber from 'bignumber.js';

export const mapLeaderboardResponse = ({
  data
}: LeaderboardResponse): LeaderboardData => {
  return {
    accounts: data.accounts.map(acc => ({
      walletAddress: acc.wallet_address ?? undefined,
      solanaWalletAddress: acc.solana_wallet_address ?? undefined,
      tier: acc.tier,
      flamePower: acc.flame_power,
      accessType: acc.access_type,
      lotteryTickets: acc.lottery_tickets,
      allocationMultiplier: acc.allocation_multiplier,
      hirosAmount: acc.hiros_amount,
      stakedAmount: new BigNumber(acc.staked_amount),
      lockedAmount: new BigNumber(acc.locked_amount)
    })),
    lastUpdatedAt: new Date(data.last_updated_at)
  }
}
