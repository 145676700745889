import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as LightIcon } from '@assets/tiers/light.svg'
import { ReactComponent as SparkIcon } from '@assets/tiers/spark.svg'
import { ReactComponent as SmokeIcon } from '@assets/tiers/smoke.svg'
import { ReactComponent as FireIcon } from '@assets/tiers/fire.svg'
import { ReactComponent as FlameIcon } from '@assets/tiers/flame.svg'
import { FlameTier, FlameTiers, tierBreakpoints } from '@contracts/hooks/useFlameTier/constants';
import './FlameLevel.scss';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import { numberToCurrency } from '@utils/balanceFormatter';

interface Props {
  currentTier: FlameTier | null
}

const tiersArray = Object.values(FlameTiers) as FlameTier[]

const FlameLevel = ({ currentTier }: Props) => {
  const { settings } = usePlatformSettings()
  const breakpoints = useMemo(() => {
    return tierBreakpoints.map(point => numberToCurrency(+point * (settings?.flameTiersFactor ?? 1)))
  }, [settings])

  const tierIndex = useMemo(() => {
    return tiersArray.findIndex(t => t === currentTier)
  }, [currentTier])

  const overlayWidth = useMemo(() => {
    console.log({ tierIndex })
    if (tierIndex === -1) {
      return '5%'
    }
    if (tierIndex === tiersArray.length - 1) {
      return '100%'
    }
    return 8 + 18.5 * (tierIndex + 1) + '%'
  }, [tierIndex])

  return (
    <Row className="heat-row gx-0">
      <Col className="text-left column">
        <div className="color-strip">
          <div
            className='color-strip__overlay'
            style={{
              width: overlayWidth
            }}
          />
        </div>
        <div className="progress">
          <div className="item">
            <div className="icon" />
            <div className="title">Start</div>
          </div>
          <div className="item">
            <div className="icon">
              <LightIcon />
            </div>
            <div className="description">
              <div className="title">
                Light
              </div>
              <div className="subtitle">
                {breakpoints[0]}+ FLAME POWER
              </div>
              <p>
                Surprise Lottery <br/> Round
              </p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <SparkIcon />
            </div>
            <div className="description">
              <div className="title">
                Spark
              </div>
              <div className="subtitle">
                {breakpoints[1]}+ FLAME POWER
              </div>
              <p>First Come - <br/> First Served</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <SmokeIcon />
            </div>
            <div className="description">
              <div className="title">
                Smoke
              </div>
              <div className="subtitle">
                {breakpoints[2]}+ FLAME POWER
              </div>
              <p>First Come - <br/> First Served</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <FireIcon />
            </div>
            <div className="description">
              <div className="title">
                Fire
              </div>
              <div className="subtitle">
                {breakpoints[3]}+ FLAME POWER
              </div>
              <p>First Come - <br/> First Served</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <FlameIcon />
            </div>
            <div className="description">
              <div className="title">
                Flame
              </div>
              <div className="subtitle">
                {breakpoints[4]}+ FLAME POWER
              </div>
              <p>First Come - <br/> First Served</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

FlameLevel.defaultProps = {
  currentTier: FlameTiers.FLAME
}

export { FlameLevel }
