import React, { useState } from 'react'
import './ApplyForIMO.scss'
import { FormFillResponseView, IDOForm, SEOTags, StaticPageWrapper, StatusBar } from '../../../components'
import { Container, Row } from 'react-bootstrap'
import { submitToIDOForm } from '../../../api/form-fills'
import { seoTags } from '../../../seo-content'
import { IDOFormValues } from '@components/formfills/IMOForm/constants'

export const ApplyForIDO = () => {
  const [formStage, setFormStage] = useState<'FILL' | 'RESPONSE'>('FILL')
  const [filledFields, setFilledFields] = useState<number>(0)
  const [totalFields, setTotalFields] = useState<number>(0)
  const [responseStatus, setResponseStatus] = useState<'error' | 'success' | null>(null)

  const resetForm = () => {
    setFormStage('FILL')
    setResponseStatus(null)
  }

  const submitIDO = async (values: IDOFormValues) => {
    const response = await submitToIDOForm(values)
    setResponseStatus(response.result)
    setFormStage('RESPONSE')
  }

  return (
    <StaticPageWrapper title="Apply For IDO">
      <SEOTags {...seoTags.ido} />
      <section className="imo-application">
        <Container>
          <Row className="status-bar-container justify-content-center">
            <StatusBar filledFields={filledFields} totalFields={totalFields} />
          </Row>
          {formStage === 'FILL' ? (
            <IDOForm onSubmit={submitIDO} setTotalFields={setTotalFields} setFilledFields={setFilledFields} />
          ) : (
            <FormFillResponseView status={responseStatus} onReset={resetForm} />
          )}
        </Container>
      </section>
    </StaticPageWrapper>
  )
}
