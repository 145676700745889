import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './NFTPage.scss';
import { Col, Container, Row } from 'react-bootstrap';
import {
  BackLink,
  IgnitingResultModal,
  LoadingWrap,
  MediaAsset,
  RoundButton,
  KYCBadge,WrongNetworkBlock
} from '@components';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { flareName, hiroName, IgnitionStatuses, NFTName, useNFTs } from '../../contracts/hooks/useNFTs';
import { useKYC } from '@contracts/hooks/useKYC';
import { dueDateForHiroNFT, supportEmail } from '@constants';
import { RoutesPaths } from '@router/constants';
import { useTimeLeft } from '@hooks/useTimeLeft';
import { useIsMounted } from '@hooks/useIsMounted';
import { WalletAddress } from '@contracts/address';
import { useStorages } from '@hooks/useStorages';
import { useDispatch } from '@hooks/useDispatch';
import { ActionType } from '@store/types';
import { useWalletContext, useNetwork } from '@firestarter-private/firestarter-library'
import { numberToCurrency } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'


interface ParamTypes {
  token_name: NFTName
  nft_id: string
}

interface ResultModalState {
  show: boolean
  result: IgnitionStatuses
}

const utilities = {
  [flareName]: [
    'FireStarter Projects and $FLAME Airdrop Participation',
    'Chance to ignite a FireStarter NFT Character'
  ],
  [hiroName]: [
    'FireStarter Projects and $FLAME Airdrop Participation',
    'Invite to the FireStarter Discord',
    'Early Whitelisting Benefits',
    'Platform Governance Participation'
  ]
}

export const NFTPage = () => {
  const { nft_id, token_name } = useParams<ParamTypes>()
  const nftId = useMemo(() => nft_id.replace(/[^0-9]/g, ''), [nft_id])
  const history = useHistory()
  const { account } = useWalletContext()
  const timeLeftToHiro = useTimeLeft(dueDateForHiroNFT)
  const isMountedRef = useIsMounted()
  const dispatch = useDispatch()
  const { appStorage } = useStorages()
  const checkIgnitionInterval = useRef<ReturnType<typeof setInterval> | null>(null)

  const [resultModalState, setResultModalState] = useState<ResultModalState>({
    show: false,
    result: IgnitionStatuses.NEVER_IGNITED
  })

  const {
    getKYCStatus
  } = useKYC()

  useEffect(() => {
    getKYCStatus()
  }, [account])

  const {
    isDefaultEVMNetworkSelected,
    currentNetworkId,
    defaultEVMNetwork,
  } = useNetwork()

  const {
    fetchingCurrentToken: loading,
    availableHiros,
    currentNFTToken,
    getCurrentNFT,
    getIgnitionStatus,
    getIgnitedTokenId,
    checkIsOwner,
    ignite,
  } = useNFTs()

  const redirectToHiro = useCallback(async () => {
    const hiroId = await getIgnitedTokenId(nftId)
    history.push(generatePath(
      RoutesPaths.NFT_TOKEN,
      {
        token_name: 'hiro',
        nft_id: hiroId
      }
    ))
    setResultModalState({
      show: false,
      result: IgnitionStatuses.NEVER_IGNITED
    })
  }, [nftId])

  const startListeningForIgniting = useCallback(() => {
    dispatch({
      type: ActionType.SET_IGNITION_STATUS,
      payload: IgnitionStatuses.IGNITION_IN_PROGRESS
    })
    checkIgnitionInterval.current = setInterval(async () => {
      if (!isDefaultEVMNetworkSelected) {
        checkIgnitionInterval.current && clearInterval(checkIgnitionInterval.current)
        return
      }
      const ignitionStatus = await getIgnitionStatus(nftId)
      if (
        ignitionStatus === IgnitionStatuses.UNIGNITED ||
        ignitionStatus === IgnitionStatuses.IGNITED
      ) {
        checkIgnitionInterval.current && clearInterval(checkIgnitionInterval.current)
        dispatch({
          type: ActionType.SET_IGNITION_STATUS,
          payload: ignitionStatus
        })
        delete appStorage.ignitingNFTs[nftId]
        isMountedRef.current && setResultModalState({
          show: true,
          result: ignitionStatus,
        })
      }
    }, 2000)
  }, [isMountedRef, checkIgnitionInterval, isDefaultEVMNetworkSelected, nftId])

  const isIgniting = useMemo(
    () => currentNFTToken?.ignitionStatus === IgnitionStatuses.IGNITION_IN_PROGRESS,
    [currentNFTToken]
  )

  const isUnignited = useMemo(
    () => currentNFTToken?.ignitionStatus === IgnitionStatuses.UNIGNITED,
    [currentNFTToken]
  )

  const checkStatus = useCallback(async () => {
    if (!isDefaultEVMNetworkSelected) {
      return
    }
    const status = await getIgnitionStatus(nftId)
    if (
      (
        appStorage.ignitingNFTs[nftId] ||
        status === IgnitionStatuses.IGNITION_IN_PROGRESS
      ) && token_name === flareName
    ) {
      startListeningForIgniting()
    }
    if (status === IgnitionStatuses.IGNITED && token_name === flareName) {
      isMountedRef.current && setResultModalState({
        show: true,
        result: status
      })
    }
  }, [token_name, nftId, isDefaultEVMNetworkSelected])

  const checkOwnership = useCallback(async () => {
    if (!isDefaultEVMNetworkSelected) {
      return
    }
    const isOwner = await checkIsOwner(token_name, nftId, account as WalletAddress)
    if (!isOwner) {
      isMountedRef.current && history.push(RoutesPaths.NFTS.COLLECTION)
    }
  }, [token_name, nftId, account, isMountedRef, history, isDefaultEVMNetworkSelected])

  useEffect(() => {
    if (token_name === flareName) {
      checkStatus()
    }

    return () => {
      checkIgnitionInterval.current && clearInterval(checkIgnitionInterval.current)
      checkIgnitionInterval.current = null
    }
  }, [token_name, nftId, currentNetworkId])

  useEffect(() => {
    if (account && !loading) {
      checkOwnership()
    }
  }, [account, nftId, token_name, loading, currentNetworkId])

  useEffect(() => {
    getCurrentNFT(token_name, nftId)
  }, [token_name, nftId, currentNetworkId])

  const disableIgnition = useMemo(() => {
    return !currentNFTToken ||
      isIgniting ||
      currentNFTToken.ignitionStatus !== IgnitionStatuses.NEVER_IGNITED ||
      !availableHiros ||
      !!timeLeftToHiro ||
      !isDefaultEVMNetworkSelected
  }, [isIgniting, currentNFTToken, availableHiros, timeLeftToHiro, isDefaultEVMNetworkSelected])

  const handleIgnite = useCallback(async () => {
    if (disableIgnition) return

    await ignite(
      nftId,
      {
        onHash: () => {
          appStorage.ignitingNFTs[nftId] = true
          startListeningForIgniting()
        }
      }
    )
  }, [ignite, disableIgnition, nftId])

  return (
    <div className="nft-token-page page">
      {
        isIgniting && (
          <div className='ignition-progress-bar'>
            <div className='ignition-progress-bar__line' />
            <p>Igniting your Flare...</p>
          </div>
        )
      }
      <IgnitingResultModal
        show={resultModalState.show}
        result={resultModalState.result}
        onConfirmSuccess={redirectToHiro}
        onConfirmFail={() => setResultModalState({
          ...resultModalState,
          show: false
        })}
      />
      <Container>
        <BackLink to={RoutesPaths.NFTS.COLLECTION}>
          My Collection
        </BackLink>
      </Container>
      <section className='nft-token-section'>
        <Container>
          <LoadingWrap loading={loading}>
            <Row className="g-lg-5">
              {
                !isDefaultEVMNetworkSelected && (
                  <Col lg={{ span: 6 }} className="tile non-polygon-block my-5 mx-auto p-5">
                    <WrongNetworkBlock
                      prefix={'To see and ignite your NFT'}
                      expectedNetwork={defaultEVMNetwork}
                      embedded={false}
                    />
                  </Col>
                )
              }
              {!!currentNFTToken && isDefaultEVMNetworkSelected && (
                <>
                  <Col xl={{ span: 6 }}>
                    <div className='nft-token__heading d-lg-none'>
                      <div className='nft-token__heading-text'>
                        <h2 className='title'>#{currentNFTToken?.metadata?.id}</h2>
                        <p className='subtitle'>FireStarter {currentNFTToken?.metadata?.name}</p>
                      </div>
                      <div className='nft-token__heading-action'>
                        {
                          token_name === flareName &&
                          !isUnignited && (
                            <RoundButton
                              size="large"
                              disabled={disableIgnition}
                              onClick={handleIgnite}
                            >
                              <LoadingWrap loading={isIgniting}>
                                Ignite
                              </LoadingWrap>
                            </RoundButton>
                          )
                        }
                      </div>
                    </div>
                    <div className='nft-token-asset-box'>
                      <MediaAsset
                        className="nft-token-asset"
                        src={currentNFTToken?.metadata?.image || ''}
                      />
                    </div>
                  </Col>
                  <Col xl={{ span: 6 }}>
                    <div className='nft-token-content'>
                      <div className='nft-token__heading d-none d-lg-flex'>
                        <div className='nft-token__heading-text'>
                          <h2 className='title'>#{currentNFTToken?.metadata?.id}</h2>
                          <p className='subtitle'>FireStarter {currentNFTToken?.metadata?.name}</p>
                        </div>
                        <div className='nft-token__heading-action'>
                          {
                            token_name === flareName &&
                            !isUnignited && (
                              <RoundButton
                                size="large"
                                wide
                                disabled={disableIgnition}
                                onClick={handleIgnite}
                              >
                                <LoadingWrap loading={isIgniting}>
                                  Ignite
                                </LoadingWrap>
                              </RoundButton>
                            )
                          }
                        </div>
                      </div>
                      <div className='nft-token__description'>
                        <p>{currentNFTToken?.metadata?.description}</p>
                        <dl>
                          <div>
                            <dt>{token_name === hiroName && 'Ignition Event #1'} Total: </dt>
                            <dd>{!!currentNFTToken && numberToCurrency(currentNFTToken.total)}</dd>
                          </div>
                        </dl>
                        <div className="nft-token__description-list">
                          <h6>Utility</h6>
                          <ul>
                            {
                              utilities[token_name].map(
                                utility => (
                                  <li key={utility}><span>{utility}</span></li>
                                )
                              )
                            }
                          </ul>
                        </div>
                        {
                          token_name === flareName && (
                            <p>
                              All Flare NFT's will ignite at some point in the future
                            </p>
                          )
                        }
                      </div>
                    </div>
                    <KYCBadge />
                    <div className='nft-token__help tile'>
                      <p className='text-big fw-semibold'>Need some help with FireStarter?</p>
                      <RoundButton href={`mailto:${supportEmail}`} size="large" color="DARK">
                        Get Help
                      </RoundButton>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </LoadingWrap>
        </Container>
      </section>
    </div>
  )
}
