import './NFTCard.scss'
import { generatePath, Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import FireIcon from '@assets/nft/nft-fire-logo.png'
import { RoutesPaths } from '@router/constants';
import { MediaAsset } from '@components/common/MediaAsset';

interface Props {
  name: string
  image: string
  id: string
}

export const NFTCard = ({
  name,
  image,
  id
}: Props) => {
  return (
    <Link
      className="nft-card"
      to={generatePath(RoutesPaths.NFT_TOKEN, { token_name: name.toLowerCase().split(' ')[0], nft_id: id })}
    >
      <div className='nft-card__border' />
      <div className='nft-card__inner'>
        <div className='nft-card__asset-box'>
          <MediaAsset
            src={image}
            className="nft-card__asset"
          />
        </div>
        <div className='nft-card__content'>
          <Image
            className="nft-card__icon"
            src={FireIcon}
          />
          <div className='nft-card__description'>
            <h4 className='nft-card__title'>{name}</h4>
            <p className='nft-card__id'>#{id}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}
