import { WhitelistStatus } from '@contracts/hooks/useWhitelist';
import { instanceWithSignature as API } from '../config';
import { IApplyToWhitelistArgs, IWhitelistSuccessResponse } from './types';
import { sendExceptionReport } from '@utils/errors';
import { withWalletRequestFactory } from '@api/factories'

export const sendDataForWhitelist = withWalletRequestFactory(
  async (walletParamName, {
    project_id,
    account,
    form_data
  }: IApplyToWhitelistArgs): Promise<WhitelistStatus | null> => {
    try {
      const { data } = await API.post(
        '/apply-to-whitelist',
        {
          [walletParamName]: account,
          form_data,
        },
        {
          params: {
            project_id
          }
        }
      )

      return (data as IWhitelistSuccessResponse).status
    } catch (err) {
      sendExceptionReport(err)
      return null
    }
  }
)
