import React from 'react'
import './PressKit.scss'
import { RoundButton, SEOTags, StaticPageWrapper } from '@components'
import { Col, Container, Row } from 'react-bootstrap'
import { FSBrandBook, FSGraphicAssets, FSPlatformScreenshots, infoEmail } from '@constants'
import BrandBookImage from '@assets/press-kit/brand-book-bg.png'
import BrandBookSquare from '@assets/press-kit/brand-book-square.png'
import AssetsImage from '@assets/press-kit/assets-bg.png'
import AssetsSquare from '@assets/press-kit/assets-square.png'
import ScreenshotsImage from '@assets/press-kit/screenshots-bg.png'
import ScreenshotsSquare from '@assets/press-kit/screenshots-square.png'
import { seoTags } from '@/seo-content'
import useScreenSize from 'use-screen-size'

export const PressKit = () => {
  const screenSize = useScreenSize()
  const mobile = screenSize.width < 992

  const assetsList = [
    {
      title: <>FireStarter Brand Book</>,
      background: mobile ? BrandBookSquare : BrandBookImage,
      link: FSBrandBook,
    },
    {
      title: <>Video and Graphic Assets</>,
      background: mobile ? AssetsSquare : AssetsImage,
      link: FSGraphicAssets,
    },
    {
      title: <>Platform Screenshots</>,
      background: mobile ? ScreenshotsSquare : ScreenshotsImage,
      link: FSPlatformScreenshots,
    },
  ]

  return (
    <StaticPageWrapper title="FireStarter Press Kit">
      <SEOTags {...seoTags.pressKit} />
      <section className="press-kit marketing-page__text">
        <Container>
          <h2 className="title subtitle">Download our press assets here</h2>
          <Row className="press-kit__list g-4 justify-content-center">
            {assetsList.map((item) => (
              <Col
                key={item.link}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
                className="d-flex justify-content-center align-items-center"
              >
                <div
                  className="press-kit__item tile tile--with-shadow"
                  style={{
                    backgroundImage: `url(${item.background})`,
                  }}
                >
                  <h2 className="title">{item.title}</h2>
                  <RoundButton size="large" wide color="DARK" href={item.link}>
                    Download
                  </RoundButton>
                </div>
              </Col>
            ))}
            <div className="inquiries d-flex">
              <p>
                For media inquiries please email{' '}
                <a className="info-email" href={`mailto:${infoEmail}`}>
                  {infoEmail}
                </a>
              </p>
            </div>
          </Row>
        </Container>
      </section>
    </StaticPageWrapper>
  )
}
