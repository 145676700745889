import {
  IUseMultiperiodLocking,
  LockEntry,
  LockEntryInfo,
  LockPeriodInfo,
} from '@contracts/hooks/useMultiperiodLocking/types'
import {
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
} from '@solana/web3.js'
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  MintLayout,
  Token,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import {
  useMultiperiodLockingContract,
  useTokenInfo,
  useTransactions,
  useWalletContext,
} from '@firestarter-private/firestarter-library'
import { useConnection } from '@solana/wallet-adapter-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toPubKey } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import {
  NetworkType,
  solanaContractAddresses,
} from '@firestarter-private/firestarter-library/lib/constants'
import {
  numericToBalance,
  numericToBN,
  toBigNumber,
  toBN,
} from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import {
  getAccumulatedLockingRewards,
  getFlameAccount,
  getLockEntriesForSolana,
  getLockingPenaltyForSolana,
  getLockingPeriodsForSolana,
  getMasterEditionAddress,
  getMetadataAddress,
  getNFTTokenAccount,
  getRandomMint,
  getStakeInfoAccount,
  getStakePoolAccount,
} from './functions'
import BigNumber from 'bignumber.js'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'
import { getLockingError, getLockingErrorAndReport } from '@contracts/hooks/useMultiperiodLocking/errors'
import { sendExceptionReport } from '@utils/errors'
import { TOKEN_METADATA_PROGRAM_ID } from '@constants'
import { INFTAccounts } from '@contracts/hooks/useSolNFTs/types'

export const useMultiperiodLockingSolana = (): IUseMultiperiodLocking => {
  const isMountedRef = useIsMounted()

  const lockingProgram = useMultiperiodLockingContract(NetworkType.solana)
  const { connection } = useConnection()
  const { account } = useWalletContext()
  const publicKey = useMemo(() => (account ? toPubKey(account) : null), [
    account,
  ])
  const flameToken = useTokenInfo(
    solanaContractAddresses?.flameToken,
    NetworkType.solana,
  )
  const { sendTransaction, callTransaction } = useTransactions()

  const [stakePoolAccount, setStakePoolAccount] = useState<PublicKey>()
  const [stakeInfoAccount, setStakeInfoAccount] = useState<PublicKey>()
  const [stakeRewardsAccount, setStakeRewardsAccount] = useState<PublicKey>()

  const [loadingPeriods, setLoadingPeriods] = useState(false)
  const [loadingEntries, setLoadingEntries] = useState(false)
  const [lockPeriods, setLockPeriods] = useState<LockPeriodInfo[]>()
  const [lockEntries, setLockEntries] = useState<
    Record<string, LockEntryInfo>
  >()
  const [hiroAuthority, setHiroAuthority] = useState<PublicKey>()
  const [diamondAuthority, setDiamondAuthority] = useState<PublicKey>()
  const [diamondsDataAccount, setDiamondsDataAccount] = useState<PublicKey>()

  const [isNFTRewardReceived, setNFTRewardReceived] = useState(false)
  const [rewardCapableLockAmount, setRewardCapableAmount] = useState(
    toBigNumber(0),
  )
  const [totalLocked, setTotalLocked] = useState(toBigNumber(0))

  const getAccounts = useCallback(async () => {
    if (!publicKey) return
    const [stakePool, stakeInfo] = await Promise.all([
      getStakePoolAccount(),
      getStakeInfoAccount(publicKey),
    ])

    if (isMountedRef.current) {
      setStakePoolAccount(stakePool)
      setStakeInfoAccount(stakeInfo)
    }
  }, [publicKey, isMountedRef])

  useEffect(() => {
    if (publicKey) {
      getAccounts()
    }
  }, [publicKey])

  const getPeriods = useCallback(async () => {
    if (!lockingProgram || !stakePoolAccount) return
    setLoadingPeriods(true)

    const periodsResponse = await getLockingPeriodsForSolana(callTransaction)(
      lockingProgram,
      stakePoolAccount,
    )

    if (isMountedRef.current) {
      setLockPeriods(periodsResponse.periods)
      setHiroAuthority(periodsResponse.hiroAuthority)
      setDiamondAuthority(periodsResponse.diamondAuthority)
      setDiamondsDataAccount(periodsResponse.diamondsData)
      setRewardCapableAmount(
        periodsResponse.rewardCapableLockAmount || toBigNumber(0),
      )
      setLoadingPeriods(false)
    }
  }, [lockingProgram, stakePoolAccount, callTransaction, isMountedRef])

  useEffect(() => {
    if (stakePoolAccount) {
      getPeriods()
    }
  }, [stakePoolAccount])

  const getAccumulatedRewards = useCallback(
    (lockInfo: LockEntry): BigNumber => {
      return getAccumulatedLockingRewards(lockInfo, lockPeriods)
    },
    [lockPeriods],
  )

  const getPenalty = useCallback(
    (stakeId: string): BigNumber => {
      return getLockingPenaltyForSolana(stakeId, lockPeriods, lockEntries)
    },
    [lockPeriods, lockEntries],
  )

  const getEntries = useCallback(async () => {
    if (!lockingProgram || !publicKey || !stakeInfoAccount || !lockPeriods)
      return
    setLoadingEntries(true)

    const entriesResponse = await getLockEntriesForSolana(callTransaction)({
      lockingProgram,
      account: publicKey,
      stakeInfoAccount,
      periods: lockPeriods,
    })

    if (isMountedRef.current) {
      setStakeRewardsAccount(entriesResponse.nextStakeRewardsAccount)
      setLockEntries(entriesResponse.entries)
      setTotalLocked(entriesResponse.totalLocked)
      setNFTRewardReceived(!!entriesResponse.rewardReceived)
      setLoadingEntries(false)
    }
  }, [lockingProgram, publicKey, stakeInfoAccount, isMountedRef, lockPeriods])

  useEffect(() => {
    if (lockingProgram && publicKey && stakeInfoAccount) {
      getEntries()
    }
  }, [lockingProgram, publicKey, stakeInfoAccount])

  const checkIfRewardAvailable = useCallback(
    (amountToLock: BigNumber): boolean => {
      return (
        amountToLock.plus(totalLocked).isGreaterThan(rewardCapableLockAmount) &&
        !isNFTRewardReceived
      )
    },
    [totalLocked, rewardCapableLockAmount, isNFTRewardReceived],
  )

  const mintDiamond = useCallback(
    async (pubkey: PublicKey, mint: Keypair, nftReceiveAccount: PublicKey) => {
      if (!lockingProgram) return
      const lamports = await connection.getMinimumBalanceForRentExemption(
        MintLayout.span,
      )

      const mintTx = new Transaction().add(
        SystemProgram.createAccount({
          fromPubkey: pubkey,
          newAccountPubkey: mint.publicKey,
          space: MintLayout.span,
          programId: TOKEN_PROGRAM_ID,
          lamports,
        }),
        Token.createInitMintInstruction(
          TOKEN_PROGRAM_ID,
          mint.publicKey,
          0,
          pubkey,
          pubkey,
        ),
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mint.publicKey,
          nftReceiveAccount,
          pubkey,
          pubkey,
        ),
      )

      try {
        await lockingProgram.provider.send(mintTx, [mint])
      } catch (error) {
        return { error }
      }
    },
    [lockingProgram, connection],
  )

  const lock = useCallback(
    async (
      amount: string,
      tierIndex: number,
      callbacks: INotifyTxCallbacks = {},
    ) => {
      if (!lockingProgram) return getLockingErrorAndReport('No contract')
      if (!publicKey) return getLockingErrorAndReport('No user account')
      if (!lockPeriods) return getLockingErrorAndReport('No lock tiers')

      if (
        !stakeInfoAccount ||
        !stakePoolAccount ||
        !stakeRewardsAccount ||
        !diamondAuthority ||
        !diamondsDataAccount
      ) {
        return getLockingErrorAndReport('No PDA accounts')
      }

      const [flameAccount, userFlameAccount, mint] = await Promise.all([
        getFlameAccount(),
        flameToken.getOrCreateAssociatedAccount(),
        getRandomMint(),
      ])

      const [metadata, masterEdition, nftReceiveAccount] = await Promise.all([
        getMetadataAddress(mint.publicKey),
        getMasterEditionAddress(mint.publicKey),
        getNFTTokenAccount(publicKey, mint.publicKey),
      ])

      if (!userFlameAccount) return getLockingErrorAndReport('No Flame ATA')

      const isRewardAvailable = checkIfRewardAvailable(
        numericToBalance(amount, flameToken.decimals),
      )

      if (isRewardAvailable) {
        const res = await mintDiamond(publicKey, mint, nftReceiveAccount)
        if (res?.error) {
          sendExceptionReport(res.error)
          return res
        }
      }

      try {
        const tx = await lockingProgram.transaction.depositStake(
          numericToBN(amount, flameToken.decimals),
          toBN(tierIndex),
          {
            accounts: {
              staker: publicKey,
              stakePool: stakePoolAccount,
              flameAccount,
              stakeInfo: stakeInfoAccount,
              stakeRewards: stakeRewardsAccount,
              stakerFlame: userFlameAccount,
              diamondAuthority,
              diamondsData: diamondsDataAccount,
              mint: mint.publicKey,
              metadata,
              masterEdition,
              nftReceiveAccount: isRewardAvailable
                ? nftReceiveAccount
                : userFlameAccount,
              tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
              rent: SYSVAR_RENT_PUBKEY,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
          },
        )

        const data = await sendTransaction(tx, callbacks)
        return { data }
      } catch (error) {
        sendExceptionReport(error)
        return { error }
      }
    },
    [
      lockingProgram,
      publicKey,
      stakeInfoAccount,
      stakePoolAccount,
      stakeRewardsAccount,
      flameToken,
      sendTransaction,
      lockPeriods,
      diamondAuthority,
      diamondsDataAccount,
      mintDiamond,
      checkIfRewardAvailable,
    ],
  )

  const unlock = useCallback(
    async (stakeId: string, callbacks: INotifyTxCallbacks = {}) => {
      if (!lockingProgram) return getLockingErrorAndReport('No contract')
      if (!publicKey) return getLockingErrorAndReport('No user account')
      if (!lockEntries) return getLockingErrorAndReport('No lock entries')

      if (!stakePoolAccount || !stakeInfoAccount) {
        return getLockingErrorAndReport('No PDA accounts')
      }

      const [flameAccount, userFlameAccount] = await Promise.all([
        getFlameAccount(),
        flameToken.getOrCreateAssociatedAccount(),
      ])

      const rewardsAccount = toPubKey(lockEntries[stakeId]?.account)

      if (!userFlameAccount) return getLockingErrorAndReport('No Flame ATA')
      if (!rewardsAccount) return getLockingErrorAndReport('No PDA accounts')

      try {
        const tx = await lockingProgram.transaction.withdrawStake(
          toBN(stakeId),
          {
            accounts: {
              staker: publicKey,
              stakePool: stakePoolAccount,
              flameAccount,
              stakeRewards: rewardsAccount,
              stakerFlame: userFlameAccount,
              stakeInfo: stakeInfoAccount,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
          },
        )

        const data = await sendTransaction(tx, callbacks)
        return { data }
      } catch (error) {
        sendExceptionReport(error)
        return { error }
      }
    },
    [
      lockingProgram,
      publicKey,
      lockEntries,
      stakePoolAccount,
      stakeInfoAccount,
      flameToken,
      sendTransaction,
    ],
  )

  const unlockEarlyWithHiro = useCallback(
    async (
      stakeId: string,
      hiroAccounts: INFTAccounts,
      callbacks: INotifyTxCallbacks = {},
    ) => {
      if (!lockingProgram) return getLockingErrorAndReport('No contract')
      if (!publicKey) return getLockingErrorAndReport('No user account')
      if (!lockEntries) return getLockingErrorAndReport('No lock entries')

      if (!stakePoolAccount || !stakeInfoAccount) {
        return getLockingErrorAndReport('No PDA accounts')
      }

      const [flameAccount, userFlameAccount] = await Promise.all([
        getFlameAccount(),
        flameToken.getOrCreateAssociatedAccount(),
      ])
      const rewardsAccount = toPubKey(lockEntries[stakeId]?.account)

      if (!userFlameAccount) return getLockingErrorAndReport('No Flame ATA')
      if (!rewardsAccount) return getLockingErrorAndReport('No PDA accounts')

      try {
        const tx = await lockingProgram.transaction.withdrawStakeWithHiro(
          toBN(stakeId),
          {
            accounts: {
              staker: publicKey,
              stakePool: stakePoolAccount,
              flameAccount,
              stakeRewards: rewardsAccount,
              stakeInfo: stakeInfoAccount,
              stakerFlame: userFlameAccount,
              hiroMint: hiroAccounts.mint,
              hiroMetadata: hiroAccounts.metadataPDA,
              hireTokenAccount: hiroAccounts.tokenAssociatedAccount,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
          },
        )

        const data = await sendTransaction(tx, callbacks)
        return { data }
      } catch (error) {
        sendExceptionReport(error)
        return { error }
      }
    },
    [
      publicKey,
      lockingProgram,
      lockEntries,
      stakePoolAccount,
      stakeInfoAccount,
      flameToken,
      sendTransaction,
    ],
  )

  return {
    loadingPeriods,
    loadingEntries,
    lockPeriods,
    lockEntries,
    totalLocked,
    getPeriods,
    getEntries,
    getPenalty,
    flameToken,
    getAccumulatedRewards,
    lock,
    unlock,
    unlockEarlyWithHiro,
    hiroAuthority,
  }
}
