import Web3 from 'web3'
import { AbiItem } from 'web3-utils'

import lotteryAbi from './abi/lotteryABI.json'
import { ContractAddress } from './address'
import {
  defaultEVMNetworkId,
  NetworkId,
  web3NoAccountInstances,
} from '@firestarter-private/firestarter-library/lib/constants'

const getContract = (
  abi: any,
  address: ContractAddress,
  chainId: string,
  web3?: Web3
) => {
  const _web3 = web3 ?? web3NoAccountInstances[chainId];
  return new _web3.eth.Contract(abi as AbiItem, address);
};

export const getLotteryContract = (currentChainId: NetworkId, contractAddress?: ContractAddress,  web3?: Web3) => {
  if (currentChainId !== defaultEVMNetworkId) {
    throw new Error('Calling internal contract with a non default network')
  }
  return contractAddress ? getContract(
    lotteryAbi,
    contractAddress,
    defaultEVMNetworkId,
    web3
  ) : null
}
