import { useCallback, useEffect, useState } from 'react';
import {
  useFlareNFTContract,
  useHiroNFTContract,
  useIgnitorContract,
  useTransactions, useWalletContext,
} from '@firestarter-private/firestarter-library'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'
import { ContractAddress, WalletAddress } from '@firestarter-private/firestarter-library/lib/types'
import axios from 'axios';
import { sendExceptionReport } from '@utils/errors';
import { useSelector } from '@hooks/useSelector';
import { ActionType, AppState } from '@store/types';
import { useDispatch } from '@hooks/useDispatch';
import { Contract } from 'web3-eth-contract';
import { getIPFSGateway, toChecksumAddress } from '@utils/string';
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import { chunkArrayBySize } from '@firestarter-private/firestarter-library/lib/utils/array';
import { retry } from '@firestarter-private/firestarter-library/lib/utils/promises';
import { useStorages } from '@hooks/useStorages';
import { isFuture } from 'date-fns';
import { dueDateForHiroNFT } from '@constants';
import { useIsSolana } from '@hooks/useIsSolana'
import { polygonContractAddresses } from '@firestarter-private/firestarter-library/lib/constants'
import { arrayFromRange } from '@utils/array'

export enum IgnitionStatuses {
  NEVER_IGNITED,
  IGNITION_IN_PROGRESS,
  IGNITED,
  UNIGNITED,
}

export interface NFTMetadata {
  name: string
  description: string
  image: string
  id: string
}

export type NFTName = 'flare' | 'hiro'
export const flareName: NFTName = 'flare'
export const hiroName: NFTName = 'hiro'

export const flareAsset = process.env.PUBLIC_URL + '/nft/flare-video.mov'
export const hiroBlurredAsset = process.env.PUBLIC_URL + '/nft/hiro-blurred-image.png'
export const hiroAsset = process.env.PUBLIC_URL + '/nft/hiro-video.mp4'

export const useNFTs = () => {
  const flareNFTContract = useFlareNFTContract()
  const hiroNFTContract = useHiroNFTContract()
  const ignitorContract = useIgnitorContract()
  const isMountedRef = useIsMounted()
  const { appStorage } = useStorages()
  const [flareBalance, setFlareBalance] = useState(0)
  const [hiroBalance, setHiroBalance] = useState(0)
  const [availableHiros, setAvailableHiros] = useState(0)

  const {
    flare,
    hiro,
    userNFTTokens,
    currentNFTToken,
    fetchingCollection,
    fetchingCurrentToken,
  } = useSelector<AppState['NFT']>(
    state => state.NFT
  )
  const dispatch = useDispatch()
  const { account } = useWalletContext()
  const { callTransaction, sendTransaction } = useTransactions()
  const isSolana = useIsSolana()

  const setFetchingCollection = useCallback((fetching: boolean) => {
    dispatch({ type: ActionType.SET_FETCHING_NFT_COLLECTION, payload: fetching })
  }, [dispatch])

  const setUserNFTs = useCallback((data) => {
    dispatch({ type: ActionType.SET_USER_NFTS, payload: data })
  }, [dispatch])

  /**
   * Function is called for getting data about the NFT contract
   * When you want to get information about concrete token on NFTPage, provide tokenId
   * For Promo page tokenId is not needed
   *
   * @param {NFTName} NFTTokenName
   * @param {string} tokenId
   */
  const getNFTContractData = async (NFTTokenName: NFTName, tokenId?: string) => {
    const NFTContract = NFTTokenName === flareName ? flareNFTContract : hiroNFTContract
    const totalSupply = NFTContract ? await NFTContract.methods.totalSupply().call() : 0
    tokenId = tokenId ?? (NFTContract ? await NFTContract.methods.tokenByIndex(0).call() : 1)
    const name = getNFTName(NFTTokenName)
    const tokenURI = NFTContract ? await NFTContract.methods.tokenURI(tokenId).call() : null
    const image = getNFTImage(NFTTokenName)

    if (!tokenURI) {
      return {
        metadata: {
          name,
          description: '',
          image,
          id: tokenId
        },
        total: totalSupply,
      }
    }
    const { data } = await axios.get(getIPFSGateway(tokenURI))

    return {
      metadata: { ...data, id: tokenId, image },
      total: totalSupply
    }
  }

  const getUserTokenMetadataByIndex = (
    NFTTokenName: NFTName,
    account: WalletAddress,
    index: number
  ): Promise<NFTMetadata> => new Promise(async (resolve) => {
    const NFTContract = (NFTTokenName === flareName ? flareNFTContract : hiroNFTContract) as Contract
    const name = getNFTName(NFTTokenName)
    const id = await NFTContract.methods.tokenOfOwnerByIndex(
      account,
      index
    ).call()
    const image = getNFTImage(NFTTokenName)
    return resolve({
      id,
      name,
      description: '',
      image
    })
  })

  const getFlareData = useCallback(async () => {
    try {
      const {
        metadata,
        total
      } = await getNFTContractData(flareName)

      dispatch({
        type: ActionType.SET_FLARE_DATA,
        payload: {
          metadata,
          total
        }
      })
    } catch (err) {
      sendExceptionReport(err)
      dispatch({
        type: ActionType.SET_FLARE_DATA,
        payload: null
      })
    }
  }, [flareNFTContract, dispatch])

  const getHiroData = useCallback(async () => {
    try {
      const {
        metadata,
        total
      } = await getNFTContractData(hiroName)

      dispatch({
        type: ActionType.SET_HIRO_DATA,
        payload: {
          metadata,
          total
        }
      })
    } catch (err) {
      sendExceptionReport(err)
      dispatch({
        type: ActionType.SET_HIRO_DATA,
        payload: null
      })
    }
  }, [hiroNFTContract])

  const getFlareBalance = useCallback(async () => {
    if (flareNFTContract && account) {
      const balance = await callTransaction(
        flareNFTContract.methods.balanceOf(account)
      )
      isMountedRef.current && setFlareBalance(+balance)
      return +balance
    }
  }, [flareNFTContract, isMountedRef, account, callTransaction])

  const getHiroBalance = useCallback(async () => {
    if (hiroNFTContract && account) {
      const balance = await callTransaction(
        hiroNFTContract.methods.balanceOf(account)
      )
      isMountedRef.current && setHiroBalance(+balance)
      return +balance
    }
  }, [hiroNFTContract, isMountedRef, account, callTransaction])

  const getUserNFTBalance = useCallback(async () => {
    const [flares, hiros] = await Promise.all([
      getFlareBalance(),
      getHiroBalance()
    ])
    return { flares, hiros }
  }, [getFlareBalance, getHiroBalance])

  const _getUserTokens = useCallback(async (
    account?: WalletAddress | null,
    firstCall?: boolean,
    switchWallet = false
  ) => {
    if (switchWallet) {
      setUserNFTs([])
    }
    if (firstCall && userNFTTokens?.length) return
    setFetchingCollection(true)

    if (!account) {
      setUserNFTs([])
      setFetchingCollection(false)
      return
    }

    const loadedTokensAmount = userNFTTokens?.length ?? 0
    const chunkSize = 9

    const flaresToLoad = loadedTokensAmount >= flareBalance
      ? []
      : arrayFromRange(
        loadedTokensAmount,
        Math.min(loadedTokensAmount + chunkSize - 1, flareBalance - 1)
      )

    const hirosToLoad = loadedTokensAmount + chunkSize < flareBalance
      ? []
      : arrayFromRange(
        Math.max(loadedTokensAmount - flareBalance, 0),
        Math.min((loadedTokensAmount + chunkSize - 1) - flareBalance, hiroBalance - 1)
      )

    try {
      const tokens = await Promise.all([
        ...flaresToLoad.map((idx) => getUserTokenMetadataByIndex(flareName, account, idx)),
        ...hirosToLoad.map((idx) => getUserTokenMetadataByIndex(hiroName, account, idx))
      ])
      setUserNFTs([...(userNFTTokens ?? []), ...tokens])
    } catch (err) {
      sendExceptionReport(err)
      if (!userNFTTokens) {
        setUserNFTs([])
      }
    } finally {
      setFetchingCollection(false)
    }

  }, [
    userNFTTokens,
    flareBalance,
    hiroBalance,
    setFetchingCollection,
    setUserNFTs,
    getUserTokenMetadataByIndex
  ])

  const getIgnitionStatus = async (tokenId: string) => {
    const ignitionStatus = ignitorContract ? await ignitorContract.methods.ignitionStatuses(tokenId).call() : IgnitionStatuses.UNIGNITED
    return +ignitionStatus
  }

  const getCurrentNFT = useCallback(async (NFTTokenName: NFTName, tokenId: string) => {
    dispatch({ type: ActionType.SET_FETCHING_NFT_TOKEN, payload: true })
    const NFTContract = NFTTokenName === flareName ? flareNFTContract : hiroNFTContract

    if (!NFTContract) {
      dispatch({
        type: ActionType.SET_CURRENT_NFT,
        payload: null
      })
      dispatch({ type: ActionType.SET_FETCHING_NFT_TOKEN, payload: false })
      return
    }

    try {
      const tokenData = await getNFTContractData(NFTTokenName, tokenId)
      const ignitionStatus = appStorage.ignitingNFTs[tokenId] ? IgnitionStatuses.IGNITION_IN_PROGRESS : await getIgnitionStatus(tokenId)

      isMountedRef.current && dispatch({
        type: ActionType.SET_CURRENT_NFT,
        payload: {
          ...tokenData,
          ignitionStatus
        }
      })
    } catch (err) {
      if (err.message.includes('nonexistent token')) {
        return
      }
      sendExceptionReport(err)
      isMountedRef.current && dispatch({
        type: ActionType.SET_CURRENT_NFT,
        payload: null
      })
    } finally {
      dispatch({ type: ActionType.SET_FETCHING_NFT_TOKEN, payload: false })
    }
  }, [flareNFTContract, hiroNFTContract])

  const getIgnitedTokenId = useCallback(async (tokenId: string) => {
    return await (ignitorContract as Contract).methods.flareToHiro(tokenId).call()
  }, [ignitorContract])

  const getAvailableHiros = useCallback(async () => {
    const hirosLeft = (hiroNFTContract && polygonContractAddresses.ignitorContract)
      ? await hiroNFTContract?.methods.balanceOf(polygonContractAddresses.ignitorContract).call()
      : '0'

    setAvailableHiros(+hirosLeft)
  }, [hiroNFTContract])

  const getIdOfHiroByIndex = useCallback(async (index: number) => {
    if (!hiroNFTContract || !account) return
    return await hiroNFTContract.methods.tokenOfOwnerByIndex(
      account,
      index
    ).call()
  }, [account, hiroNFTContract])

  const approveHiro = useCallback(async (
    spenderAddress: ContractAddress,
    tokenId: string
  ) => {
    if (!hiroNFTContract || !account) return
    await sendTransaction(
      hiroNFTContract.methods.approve(
        spenderAddress,
        tokenId
      )
    )
  }, [
    hiroNFTContract,
    account,
    sendTransaction
  ])

  useEffect(() => {
    if (!isSolana) {
      getAvailableHiros()
      retry(getHiroBalance)
    }
  }, [hiroNFTContract, account, isSolana])

  const checkIsOwner = useCallback(async (tokenName: NFTName, id: string, walletAddress: WalletAddress) => {
    const NFTContract = tokenName === flareName ? flareNFTContract : hiroNFTContract
    if (!walletAddress || !NFTContract) return false

    const owner = await NFTContract.methods.ownerOf(id).call()
    return toChecksumAddress(owner) === toChecksumAddress(walletAddress)
  }, [flareNFTContract, hiroNFTContract])

  const ignite = useCallback(async (
    tokenId: string,
    callbacks: INotifyTxCallbacks = {}
  ) => {
    if (!account || !ignitorContract) {
      return
    }

    return await sendTransaction(
      ignitorContract.methods.ignite(tokenId),
      callbacks
    )
  }, [ignitorContract, account])

  return {
    fetchingCollection,
    fetchingCurrentToken,
    availableHiros,
    checkIsOwner,
    flareBalance,
    hiroBalance,
    flareToken: flare,
    hiroToken: hiro,
    getFlareBalance,
    getHiroBalance,
    getUserNFTBalance,
    _getUserTokens,
    getIdOfHiroByIndex,
    approveHiro,
    userNFTTokens,
    currentNFTToken,
    getFlareData,
    getHiroData,
    getCurrentNFT,
    getIgnitionStatus,
    ignite,
    getIgnitedTokenId,
  }
}

function getNFTName(tokenName: string): string {
  return tokenName === flareName ? 'Flare' : 'Hiro'
}

function getNFTImage(tokenName: string): string {
  const isIgnitingInFuture = (!dueDateForHiroNFT || isFuture(new Date(dueDateForHiroNFT)))
  return tokenName === flareName ? flareAsset : isIgnitingInFuture ? hiroBlurredAsset : hiroAsset
}
