import React, { useEffect, useState } from 'react'
import { LoadingWrap } from '@components';
import './LinkingWallets.scss';
import {
  CheckingStatus,
  KycNotPassed,
  LinkingFailed,
  LinkingInProgress,
  SwitchToPolygon,
  SwitchToSolana,
  WalletsLinked,
  WalletsNotLinked
} from '@pages/Account/LinkingWallets/LinkingComponents'
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { isEthNetwork, isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { Col, Container, Row, Tab, Tabs, Image } from 'react-bootstrap'
import { LinkingForm } from '@pages/Account/LinkingWallets/LinkingForm'
import { useSearchParams } from '@hooks/useSearchParams'
import { LinkingStatus, useLinkingStatus } from '@contracts/hooks/useLinkingStatus'
import { getCurrentNetworkId } from '@contracts/networks'
import { FinalizeLinking } from '@pages/Account/LinkingWallets/FinalizeLinking'
import { useKYC } from '@contracts/hooks/useKYC'
import { LinkingFormSui } from '@pages/Account/LinkingWallets/LinkingFormSui'

enum LinkingWalletTabs {
  SOL = 'SOL',
  SUI = 'SUI',
}

const linkingCaption = {
  [LinkingWalletTabs.SOL]: `In order to participate in the early funding rounds of FireStarter Projects on Solana,
  you will need to link your Solana wallet (Phantom) with your Ethereum wallet (Metamask)`,
  [LinkingWalletTabs.SUI]: `In order to participate in the early funding rounds of FireStarter Projects on Sui,
  you will need to link your Sui wallet with your Ethereum wallet (Metamask)`
}

const linkingSteps = {
  [LinkingWalletTabs.SOL]: [
    `Go to the Linking Wallets page with Metamask wallet you want to link,
      and switch to Polygon chain.`,
    `Fill and submit the form using the same email address you used to pass KYC for current wallet and sign message in
      Metamask.\n
      You will get the email with further instructions.`,
    `Click the link you got inside the email and switch to Solana using the Phantom wallet you want to link with. \n
      (Better to do it from desktop device or from Phantom in-app browser from mobile device)`,
    `Confirm linking and sign the message in Phantom`,
  ],
  [LinkingWalletTabs.SUI]: [
    `Go to the Linking Wallets page with Metamask wallet you want to link,
      and switch to Polygon chain.`,
    `Fill the form using the same email address you used to pass KYC for current wallet and Sui wallet address you want to link`,
    `Make sure that the Sui address is proper and submit the form`,
    `After successful submitting the form your addresses are linked and you can participate in campaigns`
  ],
}

export const LinkingWallets = () => {
  const { account } = useWalletContext()
  const { query } = useSearchParams()
  const { currentNetworkId } = useNetwork()
  const {
    loading: linkingStatusLoading,
    data: linkingStatusData,
    getLinkingStatus,
  } = useLinkingStatus(query.reference)

  const { getKYCStatus, ethWalletAddress } = useKYC()

  useEffect(() => {
    getKYCStatus()
  }, [])

  const [activeTab, setActiveTab] = useState<LinkingWalletTabs>(LinkingWalletTabs.SOL)

  const isSolanaConfirmLink = (): boolean =>
    !!query.reference &&
    linkingStatusData?.status === LinkingStatus.linking_in_progress &&
    !!linkingStatusData?.wallet_address &&
    !!linkingStatusData?.message &&
    isSolanaNetwork(currentNetworkId || getCurrentNetworkId()) &&
    !!account

  const isPolygonSwitchToSolana = (): boolean =>
    linkingStatusData?.status !== LinkingStatus.linking_not_started
    && !!query.reference
    && !isSolanaNetwork(currentNetworkId || getCurrentNetworkId())

  const isSolanaSwitchToPolygon = (): boolean =>
    !!linkingStatusData &&
    [LinkingStatus.wallet_not_linked,
      LinkingStatus.linking_error
    ].includes(linkingStatusData.status) &&
    !isEthNetwork(currentNetworkId || getCurrentNetworkId())

  const isPolygonLinkForm = () =>
    !!linkingStatusData &&
    [LinkingStatus.linking_not_started,
      LinkingStatus.linking_error
    ].includes(linkingStatusData.status) &&
    !isSolanaNetwork(currentNetworkId || getCurrentNetworkId())

  const SolLinkingComponent = () => {
    if (linkingStatusLoading)
      return <CheckingStatus />
    if (linkingStatusData && isSolanaConfirmLink())
      return <FinalizeLinking linkingStatusData={linkingStatusData} />
    if (isPolygonLinkForm())
      return <LinkingForm getLinkingStatus={getLinkingStatus} />
    if (isSolanaSwitchToPolygon())
      return <SwitchToPolygon />
    if (isPolygonSwitchToSolana())
      return <SwitchToSolana />
    if (linkingStatusData?.status === LinkingStatus.linking_success)
      return <WalletsLinked
        walletAddress={linkingStatusData.wallet_address!}
        solanaWalletAddress={linkingStatusData.solana_wallet_address}
      />
    if (linkingStatusData?.status === LinkingStatus.linking_in_progress)
      return <LinkingInProgress />
    if (linkingStatusData && [LinkingStatus.kyc_missing, LinkingStatus.kyc_not_passed].includes(linkingStatusData.status))
      return <KycNotPassed />;
    if (linkingStatusData?.status === LinkingStatus.linking_error)
      return <LinkingFailed />;
    return <WalletsNotLinked />
  }

  const SuiLinkingComponent = () => {
    if (linkingStatusLoading)
      return <CheckingStatus />
    if (linkingStatusData && !linkingStatusData.sui_wallet_address) {
      if (!isEthNetwork(currentNetworkId || getCurrentNetworkId())) {
        return <SwitchToPolygon />
      }
      return <LinkingFormSui onSubmitSuccess={getLinkingStatus} />
    }
    if (linkingStatusData && linkingStatusData.sui_wallet_address) {
      return <WalletsLinked
        walletAddress={linkingStatusData.wallet_address!}
        suiWalletAddress={linkingStatusData.sui_wallet_address}
      />
    }
  }


  return (
    <div className="linking-wallets">
      <Container>
        <h2 className="title my-4">Linking Wallets</h2>
        <Row>
          <Col xs={12} lg={6}>
            <p className="linking-wallets__caption">
              {linkingCaption[activeTab]}
            </p>
            <h4 className="title">
              Steps to link wallets:
            </h4>
            <p>
              <ol>
                {linkingSteps[activeTab].map(step => (
                  <li>{step}</li>
                ))}
              </ol>
            </p>
          </Col>
          <Col xs={12} lg={6}>
            <div className="linking-wallets__status tile">
              <Tabs
                id="linkning-wallets-tabs"
                className="fs-tabs"
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(key as LinkingWalletTabs)}
              >
                <Tab
                  eventKey={LinkingWalletTabs.SOL}
                  title={
                    <span>
                      <Image
                        height={24}
                        src="/networks/solana.png"
                      />
                      {' '}
                      Solana
                    </span>
                  }
                >
                  <div className="pt-4">
                    <LoadingWrap loading={!!linkingStatusLoading}>
                      {SolLinkingComponent()}
                    </LoadingWrap>
                  </div>
                </Tab>
                <Tab
                  eventKey={LinkingWalletTabs.SUI}
                  title={
                    <>
                      <Image
                        height={24}
                        src="/networks/sui.png"
                      />
                      {' '}
                      Sui
                    </>
                  }
                >
                  <div className="pt-4">
                    <LoadingWrap loading={!!linkingStatusLoading}>
                      {SuiLinkingComponent()}
                    </LoadingWrap>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
