import { FooterLink, FooterSocial, footerSocials } from '@components/layout/Footer/FooterContent'
import { Accordion as BaseAccordion, Card } from 'react-bootstrap'
import { ArrowIcon, CustomLink, CustomToggle, DynamicImage, RoundButton } from '@components'
import React from 'react'
import { RoutesPaths } from '@router/constants'
import { Link } from 'react-router-dom'
import logo from '@assets/logo.svg'
import { QuantstampCertsUrl } from '@constants'
import QuantstampLogo from '@assets/quantstamp/logo-dark.svg'
import Mark1 from '@assets/quantstamp/mark-1.png'
import Mark2 from '@assets/quantstamp/mark-2.png'
import Mark3 from '@assets/quantstamp/mark-3.png'
import Mark4 from '@assets/quantstamp/mark-4.png'
import Mark5 from '@assets/quantstamp/mark-5.png'
import { SubscribeModal } from '@components/SubscribeModal'

interface FooterElement {
  title: string
  links: Array<FooterLink>
}

export const socialLink = (item: FooterSocial): React.ReactNode => (
  <li key={item.href} className="footer__socials-item">
    <CustomLink href={item.href}>
      <DynamicImage path={`socials/${item.icon}.svg`} />
    </CustomLink>
  </li>
)

const MobileFooterAccordion = (props: { item: FooterElement }) => (
  <BaseAccordion key={props.item.title}>
    <Card className="footer-accordion">
      <Card.Header>
        <CustomToggle eventKey={props.item.title}>
          {props.item.title}
          <ArrowIcon />
        </CustomToggle>
      </Card.Header>
      <BaseAccordion.Collapse eventKey={props.item.title}>
        <Card.Body>
          {props.item.links.map((item) => (
            <CustomLink key={item.title} to={item.to} href={item.href}>
              {item.title}
            </CustomLink>
          ))}
        </Card.Body>
      </BaseAccordion.Collapse>
    </Card>
  </BaseAccordion>
)

const getFooterListItem = (item: FooterLink): React.ReactNode => (
  <li key={item.title} className="footer__list-item">
    <CustomLink to={item.to} href={item.href}>
      {item.title}
    </CustomLink>
  </li>
)

const FooterList = (props: { item: FooterElement }) => (
  <>
    <h4 className="footer__list-title">{props.item.title}</h4>
    <ul className="footer__list">{props.item.links.map(getFooterListItem)}</ul>
  </>
)

export const FooterLinksGroup = (props: { item: FooterElement; mobile: boolean }) => (
  <>{props.mobile ? <MobileFooterAccordion item={props.item} /> : <FooterList item={props.item} />}</>
)

export const SocialLinks = (props: { className: string; showSubscribeModal: any; setShowSubscribeModal: any }) => (
  <div className={`${props.className} footer__col--socials`}>
    <div className="footer__col-content">
      <ul className="footer__socials">{footerSocials.map(socialLink)}</ul>
      <h4 className="footer__title">Join The FireStarter Community</h4>
      <RoundButton size="large" onClick={() => props.setShowSubscribeModal(true)}>
        Fuel The Fire
      </RoundButton>
      <SubscribeModal show={props.showSubscribeModal} setShow={props.setShowSubscribeModal} />
    </div>
  </div>
)

export const CopyrightLinks = (props: { year: number; className: string; character: string }) => (
  <div className={`${props.className} text-center`}>
    <span>{`© ${props.year} FireStarter ${props.character} `}</span>
    <CustomLink href={QuantstampCertsUrl} className="quantstamp-link">
      Security By <img width={131} height={25} src={QuantstampLogo} />
    </CustomLink>
    <ul className="quantstamp-marks">
      <li>
        <span>
          <img src={Mark1} width={24} height={25} />
        </span>
      </li>
      <li>
        <span>
          <img src={Mark2} width={24} height={25} />
        </span>
      </li>
      <li>
        <span>
          <img src={Mark3} width={24} height={25} />
        </span>
      </li>
      <li>
        <span>
          <img src={Mark4} width={24} height={25} />
        </span>
      </li>
      <li>
        <span>
          <img src={Mark5} width={24} height={25} />
        </span>
      </li>
    </ul>
  </div>
)

export const FooterLogo = () => (
  <div className="footer__logo">
    <Link to={RoutesPaths.MAIN}>
      <img src={logo} alt="Logo" />
    </Link>
  </div>
)
