import { AddNetworkButton, Copiable, RoundButton } from '@components'
import React from 'react'
import { defaultSolanaNetworkId } from '@firestarter-private/firestarter-library/lib/constants'
import { shorterETHAddress, shorterSolAddress } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import { ReactComponent as MetamaskIcon } from '@assets/metamask-logo.svg'
import { ReactComponent as PhantomIcon } from '@assets/phantom-logo.svg'
import { ReactComponent as SuiIcon } from '@assets/sui-logo.svg'
import { ReactComponent as CopyIcon } from '@assets/copy.svg'
import { RoutesPaths } from '@router/constants'
import { shorterSuiAddress } from '@utils/string'

export enum SigningStatus {
  message = 'Signing message...',
  email = 'Signing email...',
  request = 'Signing request...'
}

const wallets = ['metamask', 'phantom', 'sui'] as const

const walletIcons = {
  metamask: MetamaskIcon,
  phantom: PhantomIcon,
  sui: SuiIcon
}

const addressShorters = {
  metamask: shorterETHAddress,
  phantom: shorterSolAddress,
  sui: shorterSuiAddress
}

export const WalletAddress = ({
  wallet = 'metamask',
  address
}: {
  wallet?: typeof wallets[number]
  address: string
}) => {
  const shorterAddress = addressShorters[wallet]
  const WalletIcon = walletIcons[wallet]
  return (
    <div className='wallet-address'>
      <WalletIcon />
      <Copiable text={address}>
        <span className="wallet-address__address">
          {shorterAddress(address)}
        </span>
        <CopyIcon className="wallet-address__copy" />
      </Copiable>
    </div>
  )
}

export const SwitchToSolana = () =>
  <LinkingStatusWrapper>
    <p>
      Please switch to the Solana network to complete linking
    </p>
    <AddNetworkButton networkId={defaultSolanaNetworkId} size='large' />
  </LinkingStatusWrapper>

export const WalletsLinked = (props: {
  walletAddress: string
  solanaWalletAddress?: string,
  suiWalletAddress?: string
}) =>
  <LinkingStatusWrapper>
    <div>
      <p>Your wallets have been successfully linked</p>
      <WalletAddress address={props.walletAddress!} />
      {props.solanaWalletAddress && <WalletAddress wallet="phantom" address={props.solanaWalletAddress} />}
      {props.suiWalletAddress && <WalletAddress wallet="sui" address={props.suiWalletAddress} />}
    </div>
  </LinkingStatusWrapper>

export const SwitchToPolygon = () =>
  <LinkingStatusWrapper>
    <p>
      Please switch to the Polygon Network to begin linking your wallets
    </p>
    <AddNetworkButton size='large' />
  </LinkingStatusWrapper>

export const LinkingInProgress = () =>
  <LinkingStatusWrapper>
    Linking your wallets is in progress. Check your email for
    instructions.
  </LinkingStatusWrapper>

export const KycNotPassed = () =>
  <LinkingStatusWrapper>
    <p>
      You need to pass KYC to be able to link your wallets
    </p>
    <RoundButton to={RoutesPaths.KYC_PROCESS} size="large">
      Go to KYC
    </RoundButton>
  </LinkingStatusWrapper>

export const LinkingFailed = () =>
  <LinkingStatusWrapper>
    <span>
      Linking has <span className="red-text">failed</span>. Please, try
      again.
    </span>
  </LinkingStatusWrapper>

export const WalletsNotLinked = () =>
  <LinkingStatusWrapper>
    <span>
      Your wallets are <span className="red-text">not linked</span>
    </span>
  </LinkingStatusWrapper>

export const CheckingStatus = () =>
  <LinkingStatusWrapper>
    Checking linking status...
  </LinkingStatusWrapper>

export const LinkingStatusWrapper = (props: { children: React.ReactNode }) =>
  <p className="text-big fw-semibold linking-wallets-status__text">
    {props.children}
  </p>

