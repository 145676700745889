import createProxyStorage from 'local-session-storage-proxy';
import { NetworkId } from '@firestarter-private/firestarter-library/lib/constants'
import { WalletAddress } from '@firestarter-private/firestarter-library/lib/types'
import { useCallback, useEffect } from 'react'

export interface AppLocalStorage {
  ignitingNFTs: Record<string, boolean>
  currentNetworkId?: NetworkId
  openingLootBoxes: Record<
    string,
    {
      boxId: string
      wallet: WalletAddress
      createdAt: number
    }
  >
  linkClickedEvent: boolean
}

export const APP_STORAGE_NAME = 'appStorage'

export const getAppStorage = (): AppLocalStorage => JSON.parse(localStorage.getItem(APP_STORAGE_NAME) || '{}')

export const withStorage = (storage: AppLocalStorage) => (
  callback: (
    storage: AppLocalStorage,
    ...args: any[]
  ) => any
) => {
  return (...args: any[]) => callback(storage, ...args)
}

const initialStorageData: AppLocalStorage = {
  ignitingNFTs: {},
  currentNetworkId: undefined,
  openingLootBoxes: {},
  linkClickedEvent: false,
}

export const useStorages = () => {
  const appStorage = createProxyStorage(APP_STORAGE_NAME, initialStorageData)

  const setupStorage = useCallback(() => {
    Object.keys(initialStorageData).forEach((k) => {
      const key = k as keyof AppLocalStorage
      if (!appStorage[key]) {
        // @ts-ignore
        appStorage[key] = initialStorageData[key] as any
      }
    })
  }, [appStorage])

  useEffect(() => {
    setupStorage()
  }, [])

  return {
    appStorage,
  }
}
