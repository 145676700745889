import { NormalizedProjectInfo } from '@components/Project/types'
import { DiscoverButton } from '@pages/Homepage/buttons'
import { Container, Row } from 'react-bootstrap'
import { BuyFlameButton, RoundButton } from '@/components'
import React from 'react'
import './HeroRow.scss'

const DiscoverButtons = (props: { bannerButton: DiscoverButton }) => (
  <>
    <RoundButton {...props.bannerButton.props} size="large">
      {props.bannerButton.text}
    </RoundButton>
    <BuyFlameButton />
  </>
)

const CampaignButton = (props: { bannerButton: DiscoverButton }) => (
  <div className="hero-buttons__countdown">
    <RoundButton
      {...props.bannerButton.props}
      size="large"
      color="DARK"
      style={{
        background: 'linear-gradient(108.68deg, #C4A2FF 4.9%, #FF9393 74.14%)'
      }}
    >
      {props.bannerButton.text}
    </RoundButton>
    <span className="hero-buttons__whitelist-date">
      {props.bannerButton.date}
    </span>
  </div>
)

export const HeroRow = (props: {
  currentProject: NormalizedProjectInfo | null,
  featuredProjectId: string | undefined,
  bannerButton: DiscoverButton
}) => (
  <Container>
    <Row className="hero-row">
      <div className="hero-row__main text-center">
        <div className="hero-row__text">
          <h1 className="title">
            A Launchpad for <br />Initial Decentralized Offerings
          </h1>
          <p className="subtitle">
            Own The Future of Digital Innovation
          </p>
        </div>
        {
          props.currentProject && props.currentProject.id === props.featuredProjectId && (
            <div className="hero-buttons">
              {props.currentProject.presale.statusMessage
                ? <DiscoverButtons bannerButton={props.bannerButton} />
                : <CampaignButton bannerButton={props.bannerButton} />}
            </div>
          )
        }
      </div>
    </Row>
  </Container>
)
