import axios from 'axios'
import { CMSContent, CMSResponse } from '@/services/CMS/types'

export const getCMSContent = async (url: string): Promise<CMSContent> => {
  const { data } = await axios.get<CMSResponse>(url)

  return {
    descriptionContent: data.description_layout[0],
    tokenEconomicsContent: data.token_economics_layout[0],
  }
}
