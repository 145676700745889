import React from 'react';
import { Image } from 'react-bootstrap';
import { RoundButton } from '@components/common';
import { buyFlameLink, KuCoinFlameLink } from '@constants';
import KuCoinLogo from '@assets/features/kucoin-logo.png';
import QuickSwapLogo from '@assets/features/quickswap-logo.png';
import { RoutesPaths } from '@router/constants';
import './FeaturesSection.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import useScreenSize from 'use-screen-size'

const navigationOptions = {
  nextEl: '.features-slider__nav-next',
  prevEl: null
}

export const FeaturesSection: React.FC = () => {

  const screenSize = useScreenSize()
  const noSwiping = screenSize.width > 992;

  return (
    <section className='features-section'>
      <div className="features-slider">
        <Swiper
          className="features-slider-slides"
          slidesPerView="auto"
          touchEventsTarget="wrapper"
          centeredSlides={true}
          navigation={navigationOptions}
          initialSlide={1}
          noSwiping={noSwiping}
          allowSlidePrev={!noSwiping}
          allowSlideNext={!noSwiping}
        >
          <SwiperSlide className="features-slide buy" key={0}>
            <div className='features-item features-item--buy'>
              <div className='features-item__content'>
                <div className='features-item__description'>
                  <h4 className='features-item__title'>Buy FLAME</h4>
                </div>
                <div className='features-item__buttons'>
                  <RoundButton
                    href={KuCoinFlameLink}
                    color="DARK"
                  >
                    <Image src={KuCoinLogo} roundedCircle />
                    <span>KUCOIN</span>
                  </RoundButton>
                  <RoundButton
                    href={buyFlameLink}
                    color="DARK"
                  >
                    <Image src={QuickSwapLogo} roundedCircle />
                    <span>QUICKSWAP</span>
                  </RoundButton>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="features-slide lock" key={1}>
            <div className='features-item features-item--lock'>
              <div className='features-item__content'>
                <div className='features-item__description'>
                  <h4 className='features-item__title'>Lock FLAME</h4>
                  <p className='features-item__text'>
                    Lock FLAME and <span
                    className='purple-text fw-bold'>{' '}get access to our upcoming IDOs{' '}</span> on
                    FireStarter
                  </p>
                </div>
                <RoundButton
                  to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
                  size="large"
                >
                  LOCK FLAME
                </RoundButton>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="features-slide earn" key={2}>
            <div className='features-item features-item--earn'>
              <div className='features-item__content'>
                <div className='features-item__description'>
                  <h4 className='features-item__title'>Earn FLAME</h4>
                  <p className='features-item__text'>
                    Earn
                    <span className='purple-text fw-bold'>{' '}up to 800% APY{' '}</span>
                    in $FLAME rewards by staking your LP tokens
                  </p>
                </div>
                <RoundButton
                  size="large"
                  to={RoutesPaths.ACCOUNT.STAKING}
                >
                  EARN FLAME
                </RoundButton>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}
