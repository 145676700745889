import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Image } from 'react-bootstrap';
import { AddNetworkButton } from '../AddNetworkButton';
import './WrongNetworkBlock.scss'
import { AddNetworkDropdown } from '@/components'
import { useNetwork } from '@firestarter-private/firestarter-library'
import { NetworkId, NetworkInfo } from '@firestarter-private/firestarter-library/lib/constants'
import { getNetworkIcon } from '@contracts/networks'

interface Props {
  expectedNetwork: NetworkId | NetworkInfo | NetworkId[] | NetworkInfo[]
  prefix: string
  embedded?: boolean
}

const WrongNetworkBlock: React.FC<Props> = ({
  expectedNetwork,
  prefix,
  embedded
}) => {
  const {
    getNetwork,
  } = useNetwork()

  const networkInfo = useMemo(() => {
    if (Array.isArray(expectedNetwork)) {
      return expectedNetwork.map(
        network => typeof network === 'string'
          ? getNetwork(network)
          : network
      )
    }
    if (typeof expectedNetwork === 'string') {
      return getNetwork(expectedNetwork)
    }
    return expectedNetwork
  }, [expectedNetwork])

  return (
    <div
      className={classNames({
        'wrong-network-block': true,
        embedded
      })}
    >
      {
        Array.isArray(networkInfo) ? (
          <>
            <div className="wrong-network-block__icons">
              {
                networkInfo.map(network => (
                  <Image
                    key={network.networkId}
                    roundedCircle
                    className="wrong-network-block__icon"
                    src={getNetworkIcon(network)}
                  />
                ))
              }
            </div>
            <div className='wrong-network-block__text'>
              {prefix}{' '}
              switch to one of the suggested networks
            </div>
            <AddNetworkDropdown
              className="wrong-network-block__dropdown"
              size="large"
              title="Switch"
              networkIds={networkInfo.map(network => network.networkId)}
            />
          </>
        ) : (
          <>
            <Image
              roundedCircle
              className="wrong-network-block__icon"
              src={getNetworkIcon(networkInfo)}
            />
            <div className='wrong-network-block__text'>
              {prefix}{' '}
              switch to <b>{networkInfo.userFriendlyName} network</b>
            </div>
            <AddNetworkButton
              className="wrong-network-block__button"
              size="large"
              text="Switch"
              networkId={networkInfo.networkId}
            />
          </>
        )
      }
    </div>
  )
}

WrongNetworkBlock.defaultProps = {
  embedded: true
}

export { WrongNetworkBlock }
