export interface ShareArgs {
  text: string
  url: string
}

export interface EmailShareArgs extends ShareArgs {
  subject: string
}

export const shareOnEmail = ({ subject, text, url }: EmailShareArgs) => {
  const body = encodeURIComponent(text)
  subject = encodeURIComponent(subject)
  url = encodeURIComponent(url)
  window.location.href = `mailto:?subject=${subject}&body=${body}%0A%0A${url}`
}

export const shareOnFacebook = ({ text, url }: ShareArgs) => {
  const quote = encodeURIComponent(text)
  url = encodeURIComponent(url)
  window.open(
    `https://www.facebook.com/sharer/sharer.php?t=${quote}&u=${url}`,
    '_blank',
  )
}

export const shareOnTwitter = ({ text, url }: ShareArgs) => {
  const tweetText = encodeURIComponent(text)
  url = encodeURIComponent(url)
  window.open(
    `https://twitter.com/intent/tweet?url=${url}&text=${tweetText}%0A`,
    '_blank',
  )
}

export const shareOnTelegram = ({ text, url }: ShareArgs) => {
  const textParam = encodeURIComponent(text)
  url = encodeURIComponent(url)
  window.open(
    `https://telegram.me/share/url?url=${url}&text=${textParam}%0A`,
    '_blank',
  )
}

export const shareOnWhatsApp = ({ text, url }: ShareArgs) => {
  const textParam = encodeURIComponent(text)
  url = encodeURIComponent(url)
  window.open(
    `https://api.whatsapp.com/send?text=${textParam}%0A${url}`,
    '_blank',
  )
}

export const shareOnDiscord = ({ text, url }: ShareArgs) => {
  const textParam = encodeURIComponent(text)
  url = encodeURIComponent(url)
  window.open(
    `https://discord.com/channels/@me/?message=${textParam}%0A${url}`,
    '_blank',
  )
}
