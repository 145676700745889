import { OGProperties, PageTags } from '../types';
import { RoutesPaths } from '@router/constants';
import { defaultEVMNetworkData } from '@firestarter-private/firestarter-library/lib/constants'

const title = `Add ${defaultEVMNetworkData.userFriendlyName} to Metamask`
const url = window.location.origin + RoutesPaths.ADD_NETWORK_GUIDE

export const addNetwork: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `FireStarter | ${title}`,
    },
    {
      property: OGProperties.description,
      content: `Quick guide how to add ${defaultEVMNetworkData.userFriendlyName} to Metamask.`,
    },
    {
      property: OGProperties.url,
      content: url,
    },
  ]
}
