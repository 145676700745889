import { sendExceptionReport } from '@utils/errors'

const lockingErrorKeys = <const>[
  'No contract',
  'No user account',
  'No PDA accounts',
  'No lock tiers',
  'No Flame ATA',
  'No lock entries',
  'Wrong method'
]

type LockingErrorKey = typeof lockingErrorKeys[number]

const LOCKING_ERRORS: Readonly<Record<LockingErrorKey | string, string>> = {
  'No contract': 'No contract initialized. Please try again later',
  'No user account': 'Cannot retrieve wallet address. Please try to reconnect',
  'No PDA accounts': 'Cannot retrieve PDA accounts. Please refresh the page',
  'No lock tiers': 'Cannot retrieve lock tiers. Please refresh the page',
  'No Flame ATA': 'Cannot retrieve FLAME associated token account. Please refresh the page',
  'No lock entries': 'Cannot retrieve your locks. Please ensure you have previously locked FLAME',
  'Wrong method': 'Wrong contract method. Please refresh the page'
}

export const getLockingError = (msg?: LockingErrorKey | string) =>
  ({
    error: msg
      ? (LOCKING_ERRORS[msg] || msg)
      : 'Transaction failed',
  })

export const getLockingErrorAndReport = (error: Parameters<typeof getLockingError>[0]) => {
  sendExceptionReport(error)
  return getLockingError(error)
}
