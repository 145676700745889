import React, { useCallback, useState } from 'react'
import { CommonTooltip, RoundButton, Spinner } from '@components'
import { ReactComponent as RefundIcon } from '@assets/refund.svg'
import { ReactComponent as TooltipIcon } from '@assets/tooltip-icon.svg'
import { useTimezonFormat } from '@hooks/useTimezonFormat'
import { isFuture, isPast } from 'date-fns'
import './RefundingPanel.scss'

interface Props {
  refundablePeriodEnd: Date | number
  isRefunded: boolean
  onRefund: () => (void | Promise<void>)
}

export const RefundingPanel = ({ refundablePeriodEnd, isRefunded, onRefund }: Props) => {
  const [isRefunding, setIsRefunding] = useState(false)
  const { formatDate } = useTimezonFormat()

  const handleRefund = useCallback(async () => {
    if (isRefunding) return
    setIsRefunding(true)
    await onRefund()
    setIsRefunding(false)
  }, [isRefunding, onRefund])

  return (
    <div className="portfolio-item__refunding refunding">
      <div className="refunding__label">
        <RefundIcon />
        Refundable
      </div>
      <div className="refunding__status">
        {
          isPast(refundablePeriodEnd)
            ? (
              <span>
                <span>Refund period has expired</span>
                <CommonTooltip
                  id="refund-period-tooltip"
                  placement="auto"
                  tooltipClass="refund-tooltip"
                >
                  <h4 className="refund-tooltip__title d-flex align-items-center mb-4">
                    <TooltipIcon className='me-3'/>
                    Refund period info
                  </h4>
                  <ul className="refund-tooltip__list">
                    <li>
                      You have a right to get a full refund during the 5 days after TGE by clicking the “Issue refund”
                      button.
                    </li>
                    <li>
                      Please note If you claim the tokens during the first 5 days after TGE, you won’t be qualified for a
                      refund.
                    </li>
                  </ul>
                </CommonTooltip>
              </span>
            ) : isRefunded ? (
              <span>Tokens have been already refunded</span>
            ) : (
              <>
                <span className="white-transparent-text">Refundable until: </span>
                <strong>{formatDate(refundablePeriodEnd)}</strong>
              </>
            )
        }
      </div>
      {(isFuture(refundablePeriodEnd) && !isRefunded) ? (
        <RoundButton
          color="DARK"
          size="small"
          disabled={isRefunding}
          onClick={handleRefund}
        >
          Issue Refund {' '} {isRefunding && <Spinner/>}
        </RoundButton>
      ) : <span></span>}
    </div>
  )
}