import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LeaderNFT from '@assets/nft/leaders-nft.png'
import { Image } from 'react-bootstrap';
import { Copiable, RenderRowProps, SimpleTable, TableColumn, TableData } from '@components/common';
import { WalletAddress } from '@contracts/address';
import './LeaderboardList.scss'
import classNames from 'classnames';
import { LeaderboardData } from '@api/leaderboard/types';
import { getLeaderboard } from '@api/leaderboard';
import LightTierImage from '@assets/tiers/light.svg';
import SparkTierImage from '@assets/tiers/spark.svg';
import SmokeTierImage from '@assets/tiers/smoke.svg';
import FireTierImage from '@assets/tiers/fire.svg';
import FlameTierImage from '@assets/tiers/flame.svg';
import { formatDistance } from 'date-fns';
import useScreenSize from 'use-screen-size'
import { shorterETHAddress, shorterSolAddress } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import { numberToCurrency } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { FlameTiers, TierAccessTypes } from '@contracts/hooks/useFlameTier/constants'
import { ReactComponent as CopyIcon } from '@assets/copy.svg'

const NFTImage = () => <Image src={LeaderNFT} roundedCircle height={40} width={40} title="HIRO" />

const tierImagesElements = {
  [FlameTiers.LIGHT]: <Image src={LightTierImage} height={40} width={40} title={FlameTiers.LIGHT} />,
  [FlameTiers.SPARK]: <Image src={SparkTierImage} height={40} width={40} title={FlameTiers.SPARK} />,
  [FlameTiers.SMOKE]: <Image src={SmokeTierImage} height={40} width={40} title={FlameTiers.SMOKE} />,
  [FlameTiers.FIRE]: <Image src={FireTierImage} height={40} width={40} title={FlameTiers.FIRE} />,
  [FlameTiers.FLAME]: <Image src={FlameTierImage} height={40} width={40} title={FlameTiers.FLAME} />,
}

const getWalletElement = ({
    ethAddress,
    solAddress,
  }: {
    ethAddress?: WalletAddress,
    solAddress?: WalletAddress
  }) => {
  return (
    <div className="leaderboard-table__wallet">
      {
        ethAddress && (
          <div>
            <Image
              width={24}
              height={24}
              src='/networks/polygon.png'
              className="leaderboard-table__wallet-icon"
            />
            {' '}
            <Copiable text={ethAddress}>
              {shorterETHAddress(ethAddress, 6, -6)}
              <CopyIcon className="token-address__copy" />
            </Copiable>
          </div>
        )
      }
      {
        solAddress && (
          <div>
            <Image
              width={24}
              height={24}
              src='/networks/solana.png'
              className="leaderboard-table__wallet-icon"
            />
            {' '}
            <Copiable text={solAddress}>
              {shorterSolAddress(solAddress, 6, -6)}
              <CopyIcon className="token-address__copy" />
            </Copiable>
          </div>
        )
      }
    </div>
  )
}

const leaderboardColumns: TableColumn[] = [
  {
    id: 'number',
    title: '#',
    headerClass: 'text-center',
    bodyClass: 'text-center fw-bold',
    width: 60
  },
  {
    id: 'NFT',
    title: 'NFT',
    headerClass: 'text-center',
    bodyClass: 'text-center'
  },
  {
    id: 'walletAddress',
    title: 'Flamers',
    bodyClass: 'fw-bold'
  },
  {
    id: 'flamePower',
    title: 'FLAME Power',
    headerClass: 'text-center',
    bodyClass: 'text-center fw-bold'
  },
  {
    id: 'tierImage',
    title: 'Tier',
    headerClass: 'text-center',
    bodyClass: 'text-center'
  },
  {
    id: 'allocationMultiplier',
    title: 'Allocation Multiplier',
    headerClass: 'text-center',
    bodyClass: 'text-center'
  },
  {
    id: 'access',
    title: 'Access/Tickets',
    headerClass: 'text-center',
    bodyClass: 'text-center'
  },
  {
    id: 'cooldown',
    title: 'Cooldown',
    headerClass: 'text-center',
    bodyClass: 'text-center'
  },
]

interface LeaderboardTableData extends TableData {
  number: number
  NFT: React.ReactNode
  walletAddress: React.ReactNode
  flamePower: string
  tierImage: React.ReactNode
  allocationMultiplier: string
  access: string | number
  cooldown: number | string
}

const getRowClass = (item: TableData) => item.access === 'Guaranteed' ? 'highlighted' : ''

const leaderboardOptions: Record<string, any> = {
  top25: {
    size: 25,
  },
  top100: {
    size: 100,
  },
  top200: {
    size: 200,
  },
  NFTHolders: {
    size: 200,
    only_nft_holders: true,
  }
}

export const LeaderboardList = () => {
  const [currentSelection, setCurrentSelection] = useState('top25')
  const options = useMemo(() => leaderboardOptions[currentSelection], [currentSelection])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<LeaderboardData>({
    accounts: [],
    lastUpdatedAt: null
  })
  const screen = useScreenSize();
  const mobile = screen.width < 992;

  const leaderboardListSelections: Record<string, string> = {
    top25: 'Top 25',
    top100: 'Top 100',
    top200: 'Top 200',
    NFTHolders: mobile ? 'NFTs' : 'NFT Holders'
  }

  const loadLeaderboard = useCallback(async () => {
    setLoading(true)
    const result = await getLeaderboard(options)
    setData(result)
    setLoading(false)
  }, [options])

  useEffect(() => {
    loadLeaderboard()
  }, [options])

  const leaders = useMemo<LeaderboardTableData[]>(() => {
    if (!data) return []
    return data.accounts.map((acc, index) => {
      return {
        number: index + 1,
        NFT: acc.hirosAmount ? <NFTImage /> : '',
        walletAddress: getWalletElement({
          ethAddress: acc.walletAddress,
          solAddress: acc.solanaWalletAddress
        }),
        flamePower: numberToCurrency(acc.flamePower, 0),
        tierImage: acc.tier ? tierImagesElements[acc.tier] : null,
        allocationMultiplier: acc.allocationMultiplier ? `${acc.allocationMultiplier} x` : 'n/a',
        access: (acc.accessType === TierAccessTypes.GUARANTEED || index < 25)
          ? 'Guaranteed'
          : acc.lotteryTickets,
        cooldown: ''
      }
    })
  }, [data])


  const renderRow = ({ item, index, rowClass, columns }: RenderRowProps) => {
    const rowClassName = rowClass ? (
      typeof rowClass === 'string'
        ? rowClass
        : rowClass(item, index)
    ) : ''

    return (
      <tr key={index} className={`simple-table__row ${rowClassName ?? ''}`}>
        <td className='first' data-label={item['number']} key={`${index}`}>
          <div className='simple-table__cell'>{item['walletAddress']}</div>
        </td>
        {
          columns
            .filter(col => (col.title !== "#" && col.title !== "Flamers" && item[col.id] !== ''))
            .map(col => (
              <td data-label={col.title} key={`${index}-${col.id}`} className={col.bodyClass ?? ''} width={col.width}>
                <div className='simple-table__cell'>
                  {item[col.id]}
                </div>
              </td>
            ))
        }
      </tr>
    )
  }

  return (
    <div className='leaderboard-list tile'>
      <div className='leaders-list__header'>
        <ul className='leaders-list__selections'>
          {Object.entries(leaderboardListSelections).map(([key, title]) => (
            <li
              key={key}
              className={classNames(
                'leaders-list__selection-item',
                { active: currentSelection === key }
              )}
              onClick={() => setCurrentSelection(key)}
            >
              {title}
            </li>
          ))}
        </ul>
        <div className='leaders-list__update-time'>
          <span className='text-simple white-transparent-text'>
            Last updated:{' '}
          </span>
          <span className='text-simple'>
            {data.lastUpdatedAt ? `${formatDistance(new Date(), data.lastUpdatedAt)} ago` : '--'}
          </span>
        </div>
      </div>
      <SimpleTable
        wrapperClass='leaderboard-table-wrapper'
        tableClass='leaderboard-table'
        columns={leaderboardColumns}
        data={leaders}
        loading={loading}
        rowClass={getRowClass}
        renderRowProp={mobile ? renderRow : undefined}
      />
    </div>
  )
}
