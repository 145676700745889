import { Form } from 'react-bootstrap'
import * as yup from 'yup'
import { isValidSuiAddress } from '@utils/string'
import { useWalletContext } from '@firestarter-private/firestarter-library'
import React, { useState } from 'react'
import { SigningStatus } from '@pages/Account/LinkingWallets/LinkingComponents'
import { createEthWalletSignature } from '@utils/signature'
import { setTimeoutPromise } from '@firestarter-private/firestarter-library/lib/utils/promises'
import { useFormik } from 'formik'
import { RoundButton, Spinner } from '@components'
import { linkSuiWallet } from '@api/link-wallets/linkSuiWallet'
import classNames from 'classnames'

interface LinkingWalletsFormValues {
  email: string
  sui_wallet_address: string
}

const initialValues: LinkingWalletsFormValues = {
  email: '',
  sui_wallet_address: ''
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Wrong format of email')
    .required('Email is required'),
  sui_wallet_address: yup
    .string()
    .required('Sui wallet is requires')
    .test(
      'is-valid-sui-address',
      'Not a valid Sui address',
      (value) => !!value && isValidSuiAddress(value)
    )
})

export const LinkingFormSui = ({
  onSubmitSuccess
}: {
  onSubmitSuccess: () => void | Promise<void>
}) => {
  const { account } = useWalletContext()
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)

  const submitLinkingForm = async (values: LinkingWalletsFormValues) => {
    if (!account) return
    setSubmitError(null)
    setLoading(true)

    const response = await linkSuiWallet({
      email: values.email,
      wallet_address: account,
      sui_wallet_address: values.sui_wallet_address
    })

    await setTimeoutPromise(1000)

    if ('error' in response) {
      setSubmitError(response.error)
    } else {
      await onSubmitSuccess()
    }

    setLoading(false)
  }

  const {
    errors,
    touched,
    handleSubmit,
    isValid,
    getFieldProps,
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: submitLinkingForm,
    initialValues
  })

  return <Form
    noValidate
    className="linking-wallets-form"
    onSubmit={handleSubmit}
  >
    <div className="linking-wallets-form__inner">
      <h4 className="title">Link your wallet</h4>
      <p className="linking-wallets__description">
        Add your email below and specify your <b>Sui</b> wallet address you want to link
      </p>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="username@gmail.com"
          {...getFieldProps('email')}
          isValid={touched.email && !errors.email}
          isInvalid={touched.email && !!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="sui_wallet_address">
        <Form.Label>Sui Wallet Address</Form.Label>
        <Form.Control
          type="text"
          placeholder="0x"
          {...getFieldProps('sui_wallet_address')}
          isValid={touched.sui_wallet_address && !errors.sui_wallet_address}
          isInvalid={touched.sui_wallet_address && !!errors.sui_wallet_address}
        />
        <Form.Control.Feedback type="invalid">
          {errors.sui_wallet_address}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <RoundButton
          className="linking-wallets-form__submit-button"
          type="submit"
          size="large"
          disabled={!isValid || loading}
          loading={loading}
        >
          Link
        </RoundButton>
        <div
          className={classNames(
            'form-message form-message--error form-message--no-padding text-center mt-3',
            { hidden: !submitError }
          )}
        >
          <span>{submitError}</span>
        </div>
      </Form.Group>
    </div>
  </Form>
}