import React from 'react';
import { RoundButton, RoundButtonProps } from '../RoundButton';
import { withNetworkSwitching, WithNetworkSwitchingProps } from '@/hocs'
import { defaultEVMNetworkId, NetworkId } from '@firestarter-private/firestarter-library/lib/constants'

interface Props extends
  Pick<RoundButtonProps, 'color' | 'size' | 'wide' | 'className'>,
  WithNetworkSwitchingProps {
  text?: React.ReactNode
  networkId?: NetworkId
}

const AddNetworkButtonComponent: React.ComponentType<Props> = ({
  color,
  size,
  wide,
  className,
  text,
  networkId,
  isSwitching,
  handleSwitch,
}) => {

  return (
    <RoundButton
      className={`add-network-button ${className}`}
      type="button"
      color={color}
      size={size}
      wide={wide}
      disabled={isSwitching}
      loading={isSwitching}
      onClick={() => handleSwitch(networkId!)}
    >
      <span>{text ?? 'Switch Network'}</span>
    </RoundButton>
  )
}

AddNetworkButtonComponent.defaultProps = {
  color: 'LIGHT',
  size: 'small',
  wide: false,
  networkId: defaultEVMNetworkId
}

export const AddNetworkButton = withNetworkSwitching(AddNetworkButtonComponent)
