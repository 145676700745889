import React, { useMemo } from 'react'
import { useKYC } from '@contracts/hooks/useKYC'
import { KYCStatuses } from '@api/kyc/types'
import { KYCButton } from '../KYCButton'
import { RoundButton } from '../RoundButton'
import { supportEmail } from '@constants'
import './KYCBadge.scss'
import { LoadingWrap } from '../LoadingWrap'
import { Copiable } from '../Copiable'
import { ReactComponent as ClaimImage } from '../../../assets/claim-image.svg'
import { ReactComponent as CopyIcon } from '../../../assets/copy.svg'
import { ReactComponent as VerifiedIcon } from '../../../assets/verified.svg'
import { shorterETHAddress } from '@firestarter-private/firestarter-library/lib/utils/addresses'

export const KYCBadge = () => {
  const { loading, KYCStatus, KYCRefId, updateKYCStatus, ethWalletAddress } = useKYC()

  const statusText: JSX.Element = useMemo(() => {
    if (loading) return <>Checking KYC status...</>

    switch (KYCStatus) {
      case KYCStatuses.approved:
        return (
          <>
            Your wallet is <span className="success-text">Verified</span>
          </>
        )
      case KYCStatuses.incomplete:
      case KYCStatuses.inreview:
      case KYCStatuses.waiting:
        return <>Verifying your wallet is in progress</>
      case KYCStatuses.resubmit:
        return (
          <>
            Your wallet verifying was <span className="red-text">rejected</span>
          </>
        )
      default:
        return (
          <>
            Your wallet is <span className="red-text">Not Verified</span>
          </>
        )
    }
  }, [KYCStatus, loading])

  const messageText = useMemo(() => {
    if (loading) return ''

    switch (KYCStatus) {
      case null:
      case KYCStatuses.not_sent:
        return 'You need to verify your wallet to be able to participate IDO’s'
      case KYCStatuses.incomplete:
      case KYCStatuses.inreview:
      case KYCStatuses.waiting:
        return 'Please come back later to check your status'
      case KYCStatuses.resubmit:
        return 'Contact support to get help'
      case KYCStatuses.approved:
        return 'You can try out to participate IDO’s'
      default:
        return ''
    }
  }, [KYCStatus, loading])

  return (
    <div className="kyc-badge tile">
      <div className="kyc-badge__row  align-items-center">
        <div className="kyc-badge__description">
          <div className="kyc-badge__wallet">
            <ClaimImage className="kyc-badge__icon" />
            {ethWalletAddress && (
              <Copiable text={ethWalletAddress}>
                <span>{shorterETHAddress(ethWalletAddress)}</span>
                <CopyIcon />
              </Copiable>
            )}
          </div>
          <h4 className="kyc-badge__status">{statusText}</h4>
          <p className="kyc-badge__message">{messageText}</p>
        </div>
        <LoadingWrap loading={loading as boolean}>
          <div className="kyc-badge__action">
            {KYCStatus === KYCStatuses.approved && (
              <div className="kyc-approved-mark bordered">
                <VerifiedIcon />
                <span className="text-big text-wide fw-semibold">KYC Approved</span>
              </div>
            )}
            {KYCStatus === KYCStatuses.not_sent && KYCRefId && (
              <KYCButton refId={KYCRefId} onFinish={updateKYCStatus} />
            )}
            {KYCStatus !== KYCStatuses.not_sent && KYCStatus !== KYCStatuses.approved && (
              <RoundButton href={`mailto:${supportEmail}`} size="large" wide color="DARK">
                Get help
              </RoundButton>
            )}
          </div>
        </LoadingWrap>
      </div>
    </div>
  )
}
