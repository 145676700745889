import React from 'react'
import { Dropdown, DropdownProps, Nav } from 'react-bootstrap'
import { Copiable, NetworkSwitcher, RoundButton } from '@components'
import { Link, useRouteMatch } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'
import { WalletAddress } from '@contracts/address'
import logo from '@assets/logo.svg'
import { ReactComponent as AccountIcon } from '@assets/account.svg'

export const AccountDropdown = React.memo(() => {
  const accountPath = useRouteMatch(RoutesPaths.ACCOUNT.LOCKING)
  const newLockingPath = useRouteMatch(RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD)
  const stakingPath = useRouteMatch(RoutesPaths.ACCOUNT.STAKING)
  const linkingWalletsPath = useRouteMatch(RoutesPaths.ACCOUNT.LINKING_WALLETS)
  const portfolioPath = useRouteMatch(RoutesPaths.ACCOUNT.MAIN)

  return <Dropdown className="nav-dropdown account-dropdown">
    <Dropdown.Toggle
      as={RoundButton}
      id={'nav-dropdown-toggle'}
      className="header__nav-button"
    >
      <AccountIcon className="d-none d-lg-block d-xxl-none" />
      <span className="d-lg-none d-xxl-inline">ACCOUNT</span>
    </Dropdown.Toggle>
    <Dropdown.Menu className="nav-dropdown__menu">
      <Dropdown.Item
        as={Link}
        active={accountPath?.isExact}
        to={RoutesPaths.ACCOUNT.LOCKING}
        eventKey={RoutesPaths.ACCOUNT.LOCKING}
        className="nav-dropdown__item"
      >
        Lockup
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        active={newLockingPath?.isExact}
        to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
        eventKey={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
        className="nav-dropdown__item"
      >
        Lockup <span className="purple-text">2.0</span>
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        active={stakingPath?.isExact}
        to={RoutesPaths.ACCOUNT.STAKING}
        eventKey={RoutesPaths.ACCOUNT.STAKING}
        className="nav-dropdown__item"
      >
        Staking
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        active={linkingWalletsPath?.isExact}
        to={RoutesPaths.ACCOUNT.LINKING_WALLETS}
        eventKey={RoutesPaths.ACCOUNT.LINKING_WALLETS}
        className="nav-dropdown__item"
      >
        Linking Wallets
      </Dropdown.Item>
      <Dropdown.Item
        as={Link}
        active={portfolioPath?.isExact}
        to={RoutesPaths.ACCOUNT.MAIN}
        eventKey={RoutesPaths.ACCOUNT.MAIN}
        className="nav-dropdown__item"
      >
        My Portfolio
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
})

export const SwitchNetworkButton = React.memo((props: {
  drop?: DropdownProps['drop']
  onClick: () => void,
  active: boolean | undefined,
  account: string | null | undefined,
  shorterMethod: ((str: string) => string) | null
}) => (
    <NetworkSwitcher drop={props.drop}>
      <RoundButton className='header__connect-wallet' color='DARK' onClick={props.onClick}>
        {props.active
          ? <Copiable text={props.account!}>{props.shorterMethod!(props.account as WalletAddress)}</Copiable>
          : <span>CONNECT</span>
        }
      </RoundButton>
    </NetworkSwitcher>
  )
)

export const HeaderLinks = React.memo(() => {
  const nftPath = useRouteMatch(RoutesPaths.NFTS.MAIN)
  const projectsPath = useRouteMatch(RoutesPaths.PROJECTS)
  const leaderboardPath = useRouteMatch(RoutesPaths.LEADERBOARD)
  const lotteryPath = useRouteMatch(RoutesPaths.LOOT_BOXES.LOTTERY)
  const lotteryClaimingPath = useRouteMatch(RoutesPaths.LOOT_BOXES.CLAIMING)
  const lotteryReferralPath = useRouteMatch(RoutesPaths.LOOT_BOXES.REFERRAL)
  const lotteryNFTsPath = useRouteMatch(RoutesPaths.LOOT_BOXES.NFTS)

  return <>
    <Nav.Link
      as={Link}
      active={!!leaderboardPath && leaderboardPath?.isExact}
      to={RoutesPaths.LEADERBOARD}
      eventKey={RoutesPaths.LEADERBOARD}
    >
      LEADERBOARD
    </Nav.Link>
    <Nav.Link
      as={Link}
      active={!!nftPath && nftPath?.isExact}
      to={RoutesPaths.NFTS.MAIN}
      eventKey={RoutesPaths.NFTS.MAIN}
    >
      NFT
    </Nav.Link>
    <Nav.Link
      as={Link}
      active={!!projectsPath && projectsPath?.isExact}
      to={RoutesPaths.PROJECTS}
      eventKey={RoutesPaths.PROJECTS}
    >
      PROJECTS
    </Nav.Link>
    <Dropdown id="blind-boxes-dropdown" className="nav-dropdown blind-boxes-dropdown">
      <Dropdown.Toggle
        as={RoundButton}
        id={'blind-boxes-dropdown-toggle'}
        color="TRANSPARENT"
        className="header__nav-button"
      >
        Blind Boxes
      </Dropdown.Toggle>
      <Dropdown.Menu className="nav-dropdown__menu">
        <Dropdown.Item
          as={Link}
          active={lotteryPath?.isExact}
          to={RoutesPaths.LOOT_BOXES.LOTTERY}
          eventKey={RoutesPaths.LOOT_BOXES.LOTTERY}
          className="nav-dropdown__item"
        >
          Lottery
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          active={lotteryClaimingPath?.isExact}
          to={RoutesPaths.LOOT_BOXES.CLAIMING}
          eventKey={RoutesPaths.LOOT_BOXES.CLAIMING}
          className="nav-dropdown__item"
        >
          Claiming
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          active={lotteryReferralPath?.isExact}
          to={RoutesPaths.LOOT_BOXES.REFERRAL}
          eventKey={RoutesPaths.LOOT_BOXES.REFERRAL}
          className="nav-dropdown__item"
        >
          My Referral
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          active={lotteryNFTsPath?.isExact}
          to={RoutesPaths.LOOT_BOXES.NFTS}
          eventKey={RoutesPaths.LOOT_BOXES.NFTS}
          className="nav-dropdown__item"
        >
          My NFTs
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </>
})

export const FireStarterLogoLink = React.memo(() => (
  <Nav.Link
    as={Link}
    to={RoutesPaths.MAIN}
    eventKey={RoutesPaths.MAIN}
  >
    <img
      src={logo}
      alt="Logo"
      className="d-inline-block align-top"
    />
  </Nav.Link>
))
