import { RoutesPaths } from '@router/constants'
import { infoEmail } from '@constants'
import { defaultEVMNetworkData } from '@firestarter-private/firestarter-library/lib/constants'

export interface FooterLink {
  title: string,
  href?: string,
  to?: string
}

export interface FooterSocial {
  icon: string,
  href: string,
}

export const footerLinks: Record<string, Array<FooterLink>> = {
  company: [
    {
      title: 'About Us',
      to: RoutesPaths.ABOUT_US,
    },
    {
      title: 'Apply For IDO',
      to: RoutesPaths.APPLY_FOR_IDO,
    },
    {
      title: 'Join Our Network',
      to: RoutesPaths.JOIN_NETWORK,
    },
    //TODO: temporary hidden until Bug Bounty is implemented
    /*{
      title: 'Bug Bounty',
      href: '#',
    },*/
  ],
  developers: [
    /*TODO: temporary hidden until Gitbook is updated*/
    /*{
      title: 'Documentation',
      href: 'https://app.gitbook.com/@firestarter-1/s/firestarter/'
    },*/
    {
      title: 'Press Kit',
      to: RoutesPaths.PRESS_KIT
    },
  ],
  governance: [
    {
      title: 'User Agreement',
      to: RoutesPaths.USER_AGREEMENT
    },
    {
      title: 'Privacy Policy',
      to: RoutesPaths.PRIVACY
    },
  ],
  help: [
    {
      title: 'Support',
      href: `mailto:${infoEmail}`
    },
    {
      title: 'FAQ',
      to: RoutesPaths.FAQ
    },
    {
      title: 'KYC Process',
      to: RoutesPaths.KYC_PROCESS
    },
    {
      title: `Add ${defaultEVMNetworkData.userFriendlyName} to Metamask`,
      to: RoutesPaths.ADD_NETWORK_GUIDE
    }
  ]
}

export const footerSocials: FooterSocial[] = [
  {
    icon: 'twitter',
    href: 'https://twitter.com/firestarter_fi'
  },
  {
    icon: 'medium',
    href: 'https://medium.com/firestarter-fi'
  },
  {
    icon: 'telegram',
    href: 'https://t.me/OfficialFireStarter'
  },
  {
    icon: 'instagram',
    href: 'https://www.instagram.com/firestarter.fi/'
  },
  {
    icon: 'youtube',
    href: 'https://www.youtube.com/channel/UCjAe-WuIJ0ET11CHPvRlPWA'
  },
  {
    icon: 'discord',
    href: 'https://discord.gg/j4rxaVTs6f'
  },
]
