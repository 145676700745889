import React from 'react'
import { RoundButtonProps, Spinner } from '@/components'
import {
  defaultEVMNetworkId,
  defaultSolanaNetworkId,
  NetworkId,
  NetworkInfo,
} from '@firestarter-private/firestarter-library/lib/constants'
import { useNetwork } from '@firestarter-private/firestarter-library'
import { withNetworkSwitching, WithNetworkSwitchingProps } from '@/hocs'
import { Dropdown } from 'react-bootstrap'
import { getNetworkIcon } from '@contracts/networks'
import classNames from 'classnames'
import './AddNetworkDropdown.scss'

interface Props extends
  Pick<RoundButtonProps, 'color' | 'size' | 'wide' | 'className'>,
  WithNetworkSwitchingProps {
  title?: React.ReactNode
  networkIds: NetworkId[]
}

const getNetworkContent = (network: NetworkInfo, showName = true): React.ReactNode => {
  return (
    <>
      <img
        className="add-network-dropdown__icon"
        src={getNetworkIcon(network)}
        alt={network.icon.toUpperCase()}
      />
      {
        showName && (
          <span className="add-network-dropdown__text">{network.userFriendlyName}</span>
        )
      }
    </>
  )
}

const AddNetworkDropdownComponent: React.ComponentType<Props> = ({
  color,
  size,
  wide,
  className,
  title,
  networkIds,
  isSwitching,
  handleSwitch
}) => {
  const { getNetwork } = useNetwork()
  return (
    <Dropdown className={'add-network-dropdown'}>
      <Dropdown.Toggle
        as={'button'}
        className={classNames([
          className,
          'add-network-dropdown__button',
          'btn',
          'round-button',
          {
            [`${size}`]: size,
            [`${color?.toLowerCase() ?? 'light'}`]: color,
            wide,
            loading: isSwitching,
          }
        ])}
        id={'add-network-dropdown-toggle'}
      >
        <span>{title ?? 'Switch network'}</span>
        {isSwitching && <Spinner />}
      </Dropdown.Toggle>
      <Dropdown.Menu className={'add-network-dropdown__menu'}>
        {
          networkIds.map(networkId => (
            <Dropdown.Item
              key={networkId}
              className={'add-network-dropdown__item'}
              onClick={() => handleSwitch(networkId)}
              disabled={isSwitching}
            >
              {getNetworkContent(getNetwork(networkId))}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

AddNetworkDropdownComponent.defaultProps = {
  color: 'LIGHT',
  size: 'small',
  wide: false,
  networkIds: [defaultEVMNetworkId, defaultSolanaNetworkId]
}

const AddNetworkDropdown = withNetworkSwitching(AddNetworkDropdownComponent)

export { AddNetworkDropdown }
