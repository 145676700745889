import { CMSLayout } from '@/services/CMS/types'
import { Section } from '@/services/CMS/components/Section'
import React from 'react'

export const Layout = React.memo(({ section }: CMSLayout) => {
  return (
    <div className="cms-layout">
      {section.map((s) => (
        <Section key={s.id} {...s} />
      ))}
    </div>
  )
})
