import { Modal } from 'react-bootstrap'
import './LotteryRulesModal.scss'
import { RoundButton } from '@components/common'
import { ReactComponent as CloseIcon } from '@assets/close.svg'
import React, { useState } from 'react'

interface Props {
  trigger: React.ReactElement
}

const LotteryRulesModal = ({ trigger }: Props) => {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <>
      {React.cloneElement(trigger, { onClick: openModal })}

      <Modal
        show={showModal}
        onHide={closeModal}
        centered
        className="lottery-rules-modal"
        contentClassName="tile"
      >
        <Modal.Body>
          <CloseIcon
            className="lottery-rules-modal__close"
            onClick={closeModal}
          />
          <h2>Blind Box Lottery Rules</h2>
          <div className="custom-scroll">
            <p>
              The Blind Box Lottery provides a chance to win valuable NFTs and
              FLAME token prizes for the FireStarter community.
              <br />
              In order to take part in a Lottery, users need to buy a Blind Box.
              The prize is randomized using the Chainlink VRF. As you increase
              your $FLAME bid, so does the potential prize. With the chance to
              win up to 5x the bid.
            </p>
            <p>
              The Lottery is divided into 2 Phases. Each Phase lasts for 7 days.
              Please, see details of each phase in the table below.
            </p>
            <h3>Purchasing a Blind Box:</h3>
            <p>
              In order to purchase a Blind Box, users have to follow the next
              steps:
            </p>
            <ol>
              <li>
                Connect Metamask wallet to the FireStarter website (please, disable Phantom wallet extension, if you
                have any issues).
              </li>
              <li>
                Go to “Blind Boxes -{'>'} Lottery” page by clicking the “Get Started” button in the bottom right corner
                of the Blind Boxes promo visual on a Home page.
              </li>
              <li>
                Manually enter the amount of purchase in FLAME tokens within the
                ranges defined for each Phase of the Lottery (please, see the
                table below).
              </li>
              <li>
                Click the “Approve” button to approve FLAME spending limit in
                your wallet.
              </li>
              <li>Click “Buy” to purchase a Blind Box and wait until it automatically opens to reveal your prize..</li>
            </ol>
            <p>
              The winnings of the Blind Box opening will be defined by the
              Chainlink VRF according to probabilities set in the table below.
            </p>
            <table className="lottery-rules-modal__table">
              <thead>
              <tr>
                <th>Phase</th>
                <th>Phase 1</th>
                <th>Phase 2</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  Purchase Amount, <br />
                  FLAME
                </td>
                <td>100 - 5,000 FLAME</td>
                <td>750 - 15,000 FLAME</td>
              </tr>
              <tr>
                <td>Probabilities of prizes</td>
                <td>
                  <ul>
                    <li>NFT - 20%,</li>
                    <li>FLAME tokens - 80%.</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>NFT - 40%,</li>
                    <li>FLAME tokens - 60%.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Probabilities of Token prize multipliers</td>
                <td>
                  <ul>
                    <li>From 0.1 to 1 times - 65%,</li>
                    <li>From 1.1 to 3 times - 20%,</li>
                    <li>From 3.1 to 4 times - 10%,</li>
                    <li>From 4.1 to 5 times - 5%.</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>From 0.1 to 1 times - 55%,</li>
                    <li>From 1.1 to 3 times - 20%,</li>
                    <li>From 3.1 to 4 times - 15%,</li>
                    <li>From 4.1 to 5 times - 10%.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Token Prize Release Schedule</td>
                <td>Immediately after purchasing</td>
                <td>
                  <ul>
                    <li>33.33% &mdash; Immediately</li>
                    <li>33.33% &mdash; After 2 weeks</li>
                    <li>33.33% &mdash; After 4 weeks</li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>

            <h3>NFT prizes</h3>
            <p>
              There are 3 kinds of NFT prizes, which represent different Tiers of the FireStarter platform: Light, Spark
              and Smoke and provide holders with the same utility as respective Tiers.
            </p>
            <p>
              The kind of NFT the users win for opening the Box depends on the amount spent to Purchase the Box.
            </p>

            <table className="lottery-rules-modal__table">
              <thead>
              <tr>
                <th>Purchase Amount, FLAME</th>
                <th>NFT Tier</th>
                <th>Utility</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>&#8805;100 and &lt; 2,500</td>
                <td>Light</td>
                <td>Access to Surprise Lottery Round</td>
              </tr>
              <tr>
                <td>&#8805;2,500 and &lt; 10,000</td>
                <td>Spark</td>
                <td>Holders will get FCFS access to public offerings with Base allocation</td>
              </tr>
              <tr>
                <td>&#8805;10,000 and &#8804;15,000</td>
                <td>Smoke</td>
                <td>Holders will get FCFS access to public offerings with 2x Base allocation</td>
              </tr>
              </tbody>
            </table>

            <p>
              <strong>LIGHT:</strong> Get access to our Surprise Lottery &mdash; Whitelist applicants qualified for the Light
              Tier will get access to the Surprise Lottery Round: 10 lucky lottery winners will receive an airdrop of
              the IDO token on launch date. The winners will be announced publicly and on our project page.
            </p>

            <p>
              <strong>SPARK:</strong> Get access FCFS Whitelist Lottery &mdash; All users with at least Spark Tier will be
              eligible to participate in the lottery for a place in the FCFS Whitelist. Spark NFT will grant you BASE
              allocation.
            </p>

            <p>
              <strong>SMOKE:</strong> Get access FCFS Whitelist Lottery &mdash; All users with at least Smoke Tier will be
              eligible to participate in the lottery for a place in the FCFS Whitelist. Smoke NFT will grant you 2x BASE
              allocation.
            </p>

            <p>
              Firestarter IDO Participation Process
              <ol>
                <li>
                  In order to prepare for IDO participation, users must accumulate FLAME Power or hold a FireStarter
                  Tier NFT in a KYC verified wallet. You do not need to go through separate KYC verification for each
                  IDO.
                </li>
                <li>Users may then apply to a Project’s IDO Whitelist during specified application period.</li>
                <li>Applicants qualified for Guaranteed Access must still apply for Whitelist.</li>
                <li>
                  Applicants qualified for FCFS Whitelist Lottery will take part in a lottery based on the amount of
                  lottery tickets held. Every 500 FLAME Power or Spark NFT grants 1 lottery ticket for a chance to win a
                  spot on the FCFS Whitelist.
                </li>
                <li>
                  All whitelisted users are announced and whitelisted status is displayed on firestarter.fi
                </li>
                <li>
                  Users who qualify for Guaranteed Allocation will be able to invest in the IDO first during the
                  separate time allocated to them before the FCFS Whitelist Lottery starts.
                </li>
                <li>
                  FCFS Whitelist Lottery participants will be able to participate immediately after the time for
                  Guaranteed Whitelist ends. Max allocation depends on the user's Tier. Make sure to swap your funds as
                  soon as possible as we expect most of the FCFS IDOs to be sold out in seconds.
                </li>
                <li>
                  Surprise Lottery Round winners will receive airdrop of the IDO token at the launch date.
                </li>
              </ol>
            </p>

            <h3>Token Prizes Release Schedule & Claiming</h3>

            <p>
              During Phase 1 of the lottery, the prizes will be automatically
              sent to the users’ wallets immediately after purchasing a box.
              Users will be informed about the prize via a pop-up screen that
              contains a link to Polygonscan and ability to share on socials.
            </p>

            <p>
              During Phase 2 of the Lottery, the token prizes will be released
              in 3 equal installments according to the following vesting
              schedule:
            </p>

            <ul>
              <li>
                33.33% &mdash; claimable immediately after placing a buying a Blind Box;{' '}
              </li>
              <li>33.33% &mdash; claimable in 2 weeks after buying a Blind Box;</li>
              <li>33.33% &mdash; claimable in 2 weeks after the second release.</li>
            </ul>

            <p>
              Users will have to claim their token prizes by clicking the “Claim” button on the Claiming page. Prizes
              for each Box will have to be claimed separately, as they vest.
            </p>

            <h2>Blind Box Referral Campaign</h2>

            <p>
              Users can refer other users to participate in a Blind Box Lottery by sharing their unique referral link.
              Referrers will be rewarded with 1 Free Blind Box for each 3 successful referrals, which lead to Blind Box
              purchases.
            </p>

            <p>
              Users can track the status of their referrals in the “My referrals” section of the Blind Boxes page.
            </p>

            <p>
              Once 3 referrals have purchased the Blind Boxes, the referrer gets 1 Blind Box for free and will be able
              to open it by clicking the “Open” button on the Lottery section of Blind Box page.
              The potential token prize is calculated by multiplying the randomly selected prize multiplier on a default
              value defined by FireStarter &mdash; 300 FLAME.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

LotteryRulesModal.defaultProps = {
  trigger: (<RoundButton color="DARK">READ THE RULES</RoundButton>),
}

export { LotteryRulesModal }
