import classNames from 'classnames'
import './ProjectPhasesList.scss'
import React from 'react'

interface IPhaseItem {
  title: string
  opens?: Date | number | null
  closes?: Date | number | null
  active: boolean
}

interface Props {
  phases: IPhaseItem[]
  formatDate: (date: Date | number) => string
}

export const ProjectPhasesList = React.memo(({ phases, formatDate }: Props) => {
  return (
    <article className="phases-list">
      <header className="phases-list__head">
        <div>Phase</div>
        <div>Opens</div>
        <div>Closes</div>
      </header>
      <div className="phases-list__body">
        {
          phases.map(({ title, opens, closes, active}) => (
            <ProjectPhaseRow
              key={title}
              title={title}
              opens={opens}
              closes={closes}
              active={active}
              formatDate={formatDate}
            />
          ))
        }
      </div>
    </article>
  )
})

const ProjectPhaseRow = React.memo(({ title, opens, closes, active, formatDate }: IPhaseItem & { formatDate: Props['formatDate'] }) => (
  <dl className={classNames('phases-list__row', { active })}>
    <div className="phases-list__col phases-list__col--title">
      <dd className={classNames({ 'gradient-text gradient-text--purple' : active})}>
        {title}
      </dd>
    </div>
    <div className="phases-list__col phases-list__col--opens">
      <dt>Opens</dt>
      <dd className={classNames({ 'white-text fw-semibold': active })}>
        {active ? 'Current Phase' : opens ? formatDate(opens) : 'TBA'}
      </dd>
    </div>
    <div className="phases-list__col">
      <dt>Closes</dt>
      <dd>{closes ? formatDate(closes) : 'TBA'}</dd>
    </div>
  </dl>
))
