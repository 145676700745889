import { instanceWithSignature as API } from '../config';
import { ILinkingAccount, LinkSuiWalletArgs, LinkWalletResponse } from './types'
import { sendExceptionReport } from '@utils/errors';
import { AxiosError } from 'axios';
import { OriginalResponseError } from '../types';

export const linkSuiWallet = async ({
 email,
 wallet_address,
 sui_wallet_address
}: LinkSuiWalletArgs): Promise<LinkWalletResponse> => {
  try {
    const { data } = await API.post<{ state: boolean, data: ILinkingAccount  }>('/link-sui-wallet', {
      email,
      wallet_address,
      sui_wallet_address
    });

    return {
      result: 'success',
      data: { status: data }
    };
  } catch (err) {
    sendExceptionReport(err);
    const error = err as AxiosError<OriginalResponseError>
    return {
      result: 'error',
      error: error.response?.data ? error.response.data.error : error.message
    }
  }
};
