import { Keypair, PublicKey } from '@solana/web3.js'
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID, u64 } from '@solana/spl-token'
import { DAY, PERCENTAGE_PRECISION, solanaContractAddresses, YEAR } from '@firestarter-private/firestarter-library/lib/constants'
import { Contract } from 'web3-eth-contract'
import {
  LockEntry,
  LockEntryInfo,
  LockPeriodInfo,
  PenaltyModes,
  SolStakePoolAccount,
  TierInfo,
} from '@contracts/hooks/useMultiperiodLocking/types'
import { createArrayBySize } from '@firestarter-private/firestarter-library/lib/utils/array'
import { mapEntryResponse, mapPeriodResponse } from '@contracts/hooks/useMultiperiodLocking/mapping'
import { mapSettledToFulfilled } from '@utils/promises'
import { Program } from '@project-serum/anchor'
import { withTransactionCaller } from '@/utils/web3'
import { LockingIdlType } from '@firestarter-private/firestarter-library/lib/hooks/useContracts/idls'
import BigNumber from 'bignumber.js'
import { BNToBigNumber, BNToNumber, toBigNumber, toBN } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { SECOND, TOKEN_METADATA_PROGRAM_ID } from '@constants'
import { arrayToCollection } from '@utils/object'

const lockingProgramId = new PublicKey(solanaContractAddresses!.multiperiodLocking)

export const getStakePoolAccount = async () => {
  const [stakePool] = await PublicKey.findProgramAddress(
    [Buffer.from("stake_pool")],
    lockingProgramId
  )
  return stakePool
}

export const getFlameAccount = async (): Promise<PublicKey> => {
  const [flameAccount] = await PublicKey.findProgramAddress(
    [Buffer.from("flame_account")],
    lockingProgramId
  )
  return flameAccount
}

export const getStakeInfoAccount = async (
  publicKey: PublicKey
) => {
  const [stakeInfo] = await PublicKey.findProgramAddress(
    [Buffer.from("stake_info"), publicKey.toBuffer()],
    lockingProgramId
  )
  return stakeInfo
}

export const getStakeRewardsAccount = async (
  publicKey: PublicKey,
  nextStakeId: u64
) => {
  const [stakeRewards] = await PublicKey.findProgramAddress(
    [
      Buffer.from("stake_reward"),
      publicKey.toBuffer(),
      nextStakeId.toArrayLike(Buffer, 'le', 8)
    ],
    lockingProgramId
  );
  return stakeRewards
}

export const getMetadataAddress = async (
  mint: PublicKey
): Promise<PublicKey> => {
  const [metadata] = await PublicKey.findProgramAddress(
    [
      Buffer.from("metadata"),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer()
    ],
    TOKEN_METADATA_PROGRAM_ID
  )
  return metadata
}

export const getMasterEditionAddress = async (
  mint: PublicKey
): Promise<PublicKey> => {
  const [masterEdition] = await PublicKey.findProgramAddress(
    [
      Buffer.from("metadata"),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
      Buffer.from("edition"),
    ],
    TOKEN_METADATA_PROGRAM_ID
  )
  return masterEdition
}

export const getRandomMint = () => {
  return Keypair.generate()
}

export const getNFTTokenAccount = async (
  publicKey: PublicKey,
  mintPubKey: PublicKey
): Promise<PublicKey> => {
  return Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    mintPubKey,
    publicKey
  )
}

interface GetPeriodsResponse {
  periods: LockPeriodInfo[]
  hiroAuthority?: PublicKey
  diamondAuthority?: PublicKey
  diamondsData?: PublicKey
  rewardCapableLockAmount?: BigNumber
}

export const getLockingPeriodsForPolygon = withTransactionCaller<GetPeriodsResponse, [Contract]>(
  async (
    callTransaction,
    blockNumber,
    lockingContract,
  ) => {
    let fetchingStep = 1
    let periods: LockPeriodInfo[] = []
    let isFailed: any

    while (!isFailed) {
      let periodsIds = createArrayBySize(4)
      const periodsBunch = await Promise.allSettled(
        periodsIds.map(async (idx) => {
          const id = idx + ((fetchingStep - 1) * 4)
          const period = await callTransaction(
            lockingContract?.methods.tiers(id),
            blockNumber,
          ) as TierInfo
          return mapPeriodResponse(period, id)
        })
      )
      periods = [
        ...periods,
        ...mapSettledToFulfilled(periodsBunch)
      ]
      fetchingStep++
      if (periodsBunch.some(result => result.status === 'rejected')) {
        isFailed = true
      }
    }

    return { periods }
  },
)

export const getLockingPeriodsForSolana = withTransactionCaller<GetPeriodsResponse, [Program<LockingIdlType>, PublicKey]>(
  async (
    callTransaction,
    blockNumber,
    lockingProgram,
    stakePoolAccount
  ) => {
    const stakePool = await callTransaction<SolStakePoolAccount>(
      () => lockingProgram.account.stakePool.fetch(stakePoolAccount)
    )
    return {
      periods: (stakePool.tiers as TierInfo[]).map(mapPeriodResponse),
      hiroAuthority: stakePool.hiroAuthority,
      diamondAuthority: stakePool.diamondAuthority,
      diamondsData: stakePool.diamondsData,
      rewardCapableLockAmount: BNToBigNumber(stakePool.rewardCapableLockAmount)
    }
  }
)

export const getLockingPenaltyForPolygon = withTransactionCaller<BigNumber, [Contract, string]>(
  async (
    callTransaction,
    blockNumber,
    lockingContract,
    stakeId,
  ) => {
    const penalty = await callTransaction(
      lockingContract.methods.getPenaltyAmount(stakeId),
      blockNumber
    )
    return toBigNumber(penalty)
  }
)

export const getLockingPenaltyForSolana = (
  stakeId: string,
  lockPeriods?: LockPeriodInfo[],
  lockEntries?: Record<string, LockEntryInfo>,
) => {
  if (!lockPeriods || !lockEntries) return toBigNumber(0)
  const entry = lockEntries[stakeId]
  const period = lockPeriods[entry.tierIndex]

  const duration = new Date().getTime() - entry.stakedAt
  if (duration > period.lockPeriod) {
    return toBigNumber(0)
  }

  const penaltyBN = toBigNumber(period.penalty * PERCENTAGE_PRECISION)
  const decimalBase = toBigNumber(PERCENTAGE_PRECISION)

  if (period.penaltyMode === PenaltyModes.STATIC) {
    return entry.amount
      .multipliedBy(penaltyBN)
      .div(decimalBase)
  }
  if (duration < period.fullPenaltyCliff) {
    return entry.amount
  }

  const monthBN = toBigNumber(30 * DAY)
  const total = toBigNumber(period.lockPeriod - period.fullPenaltyCliff).div(monthBN)
  const current = toBigNumber(duration - period.fullPenaltyCliff).div(monthBN)
  const penaltyPercent = penaltyBN
    .minus(penaltyBN.multipliedBy(current).div(total))

  return entry.amount
    .multipliedBy(penaltyPercent)
    .div(decimalBase)
}

export const getAccumulatedLockingRewards = (
  lockInfo: LockEntry,
  lockPeriods?: LockPeriodInfo[]
): BigNumber => {
  if (!lockPeriods) return toBigNumber(0)
  const lockTier = lockPeriods[lockInfo.tierIndex]
  const stakedAmount = lockInfo.amount ? toBigNumber(lockInfo.amount) : BNToBigNumber(lockInfo.amountStaked)
  const stakedAt = (lockInfo.stakedAt ? +lockInfo.stakedAt : BNToNumber(lockInfo.stakeTimestamp, 0)) * SECOND

  const period = lockTier.lockPeriod
  let duration = new Date().getTime() - stakedAt

  if (duration > period) {
    duration = period
  }

  return stakedAmount
    .multipliedBy(lockTier.apy * PERCENTAGE_PRECISION)
    .multipliedBy(duration)
    .div(PERCENTAGE_PRECISION)
    .div(YEAR)
}

interface GetLockEntriesSolArgs {
  lockingProgram: Program<LockingIdlType>
  account: PublicKey
  stakeInfoAccount: PublicKey
  periods: LockPeriodInfo[]
}

interface GetLockEntriesPolygonArgs {
  lockingContract: Contract
  account: string
  periods: LockPeriodInfo[]
}

interface GetLockEntriesResponse {
  entries: Record<string, LockEntryInfo>
  totalLocked: BigNumber
  nextStakeRewardsAccount?: PublicKey
  rewardReceived?: boolean
}

export const getLockEntriesForSolana = withTransactionCaller<
  GetLockEntriesResponse,
  [GetLockEntriesSolArgs]
  >(
  async (
    callTransaction,
    blockNumber,
    {
      lockingProgram,
      account,
      stakeInfoAccount,
      periods,
    }
  ) => {
    let nextStakeId: BigNumber
    let totalLocked = toBigNumber(0)
    let rewardReceived = false
    try {
      const stakeInfo = await lockingProgram.account.stakeInfo.fetch(stakeInfoAccount)
      nextStakeId = BNToBigNumber(stakeInfo.nextStakeId)
      totalLocked = BNToBigNumber(stakeInfo.totalStaked)
      rewardReceived = stakeInfo.rewardReceived
    } catch (error) {
      nextStakeId = toBigNumber(0)
    }

    const stakeRewardAccounts = await Promise.all(
      createArrayBySize(nextStakeId.toNumber() + 1)
        .map((id) => getStakeRewardsAccount(account, toBN(id)))
    )

    const nextRewardAccount = stakeRewardAccounts[stakeRewardAccounts.length - 1]

    const entriesResponse = await Promise.allSettled(
      stakeRewardAccounts.slice(0, -1).map(async (acc, index) => {
        const entry = await callTransaction<LockEntry>(
          () => lockingProgram.account.stakeRewards.fetch(acc)
        )
        const rewards = getAccumulatedLockingRewards(entry, periods)
        return mapEntryResponse(entry, rewards, String(index), acc)
      })
    )
    const entries = mapSettledToFulfilled(entriesResponse)

    return {
      entries: arrayToCollection<LockEntryInfo>(entries, 'stakeId'),
      totalLocked,
      nextStakeRewardsAccount: nextRewardAccount,
      rewardReceived,
    }
  }
)

export const getLockEntriesForPolygon = withTransactionCaller<GetLockEntriesResponse, [GetLockEntriesPolygonArgs]>(
  async (
    callTransaction,
    blockNumber,
    {
      lockingContract,
      periods,
      account
    }
  ) => {
    const stakeIds = await callTransaction(
      lockingContract?.methods.getUserStakeIds(account),
      blockNumber
    ) as string[]
    let lockedAmount = toBigNumber(0)
    const entries = await Promise.all(
      stakeIds.map(async (id) => {
        const entry = await callTransaction<LockEntry>(
          lockingContract?.methods.userStakeOf(id),
          blockNumber
        )
        const rewards = getAccumulatedLockingRewards(entry, periods)
        if (!entry.unstakedAt || !Number(entry.unstakedAt)) {
          lockedAmount = lockedAmount.plus(toBigNumber(entry.amount))
        }
        return mapEntryResponse(entry, rewards, id);
      })
    )

    return {
      entries: arrayToCollection(entries, 'stakeId'),
      totalLocked: lockedAmount
    }
  }
)
