import React from 'react';
import { NormalizedProjectInfo, ProjectStatusesProps } from '../types'
import { ProjectWidget } from '@/components';
import { Col } from 'react-bootstrap';
import { projectBannerThumb } from '@constants'

interface Props {
  projects: NormalizedProjectInfo[]
}

export const ProjectsList = ({ projects }: Props) => (
  <>
    {projects.map(project =>
      <Col
        key={project.id}
        md={{ span: 6 }}
        lg={{ span: 6 }}
        xl={{ span: 4 }}
      >
        <ProjectWidget
          id={project.id}
          name={project.name}
          chainId={project.chainId}
          projectType={project.project_type}
          mainColor={project.info.main_color}
          background={project.assets.banner_image_url ?? projectBannerThumb}
          logo={project.assets.logo_image_url}
          network={project.network}
          status={project.presale.statusMessage}
          fundToken={project.presale.fund_token}
          rewardToken={project.presale.reward_token}
          opens={project.presale.private_starts_at ?? project.presale.public_starts_at}
          closes={project.presale.public_end_at ?? project.presale.private_end_at}
          airdropDate={project.presale.airdrop_starts_at}
          totalFunds={project.stats?.total_raised}
          fundraiseGoal={project.stats?.fundraise_goal}
          airdropAmount={project.stats?.airdrop_amount}
          ROI={project.stats?.ROI}
          pricePerToken={project.stats?.price_per_token}
          participants={project.stats?.participants}
          baseAllocation={project.stats?.base_allocation}
          socialLinks={project.social_links}
        />
      </Col>
    )}
  </>
);
