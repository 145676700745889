import React from 'react'
import './AddNetworkPage.scss'
import { AddNetworkButton, SEOTags, StaticPageWrapper } from '../../../components'
import { Container, Form, Row } from 'react-bootstrap'
import { seoTags } from '../../../seo-content'
import PolygonIcon from '@assets/polygon.png'
import { defaultEVMNetworkData } from '@firestarter-private/firestarter-library/lib/constants'

const {
  name: networkName,
  userFriendlyName,
  rpcUrl,
  networkId,
  nativeCurrency: { symbol: currencySymbol },
  explorerUrl,
} = defaultEVMNetworkData

export const AddNetworkPage = () => {
  return (
    <StaticPageWrapper title={`Add ${userFriendlyName} to Metamask`}>
      <SEOTags {...seoTags.addNetwork} />
      <Container>
        <div className="tile marketing-page-form add-polygon-tutorial">
          <div className="marketing-page-form__inner">
            <Row className="img-container d-flex align-items-center justify-content-center p-0 flex-column">
              <img className="logo" src={PolygonIcon} />
              <p className="tile__description first">
                To add {userFriendlyName} to your <b>Metamask Wallet </b>
                you can just click the <b>"Add {userFriendlyName}"</b> button and approve the action in the Metamask
                widget.
              </p>
            </Row>
            <AddNetworkButton text={`Add ${userFriendlyName}`} size="large" wide className="w-100 mx-0 mb-4" />
            <div className="description-container">
              <p className="tile__description">
                If there were some errors in adding the network, or You just want to add the network manually, please
                follow the guide below.
              </p>
              <p className="tile__description last">
                To begin, open your MetaMask Wallet and click the network dropdown tab. At the bottom click “Custom
                RPC.” Once prompted manually type the below information into your MetaMask Wallet. You will not be able
                to copy and paste.
              </p>
            </div>
            <Form>
              <Form.Group controlId="networkName">
                <Form.Label>Network Name</Form.Label>
                <Form.Control type="text" value={networkName} readOnly />
              </Form.Group>
              <Form.Group controlId="rpcUrl">
                <Form.Label>New RPC URL</Form.Label>
                <Form.Control type="text" value={rpcUrl} readOnly />
              </Form.Group>
              <Form.Group controlId="chainId">
                <Form.Label>Chain ID</Form.Label>
                <Form.Control type="text" value={networkId} readOnly />
              </Form.Group>
              <Form.Group controlId="currencySymbol">
                <Form.Label>Currency Symbol (Optional)</Form.Label>
                <Form.Control type="text" value={currencySymbol} readOnly />
              </Form.Group>
              <Form.Group controlId="blockExplorerUrl">
                <Form.Label>Block Explorer URL (Optional)</Form.Label>
                <Form.Control type="text" value={explorerUrl} readOnly />
              </Form.Group>
            </Form>
          </div>
        </div>
      </Container>
    </StaticPageWrapper>
  )
}
