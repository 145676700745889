import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import './StatusBar.scss'
import ReactDOM from 'react-dom'
import { throttleAnimation } from '@utils/throttle'

interface Props {
  filledFields: number
  totalFields: number
}

function StatusBarComponent(props: { overlayWidth: number }) {
  return (
    <Row className="status-bar justify-content-center p-3">
      <Col className="status-bar-col">
        <Row className="heat-row gx-0">
          <Col className="text-left column">
            <div className="color-strip">
              <div
                className="color-strip__overlay"
                style={{
                  width: `${props.overlayWidth}%`,
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export const StatusBar = ({ filledFields, totalFields }: Props) => {
  const overlayWidth = (filledFields / totalFields) * 100
  const [isStatusBarSticky, setIsStatusBarSticky] = useState(false)
  const [header, setHeader] = useState(document.querySelector('header'))

  const setSticky = useCallback(() => {
    if (!header) setHeader(document.querySelector('header'))

    if (header && window.scrollY > header.offsetHeight) {
      setIsStatusBarSticky(true)
    } else {
      setIsStatusBarSticky(false)
    }
  }, [header])

  useEffect(() => {
    const throttledHandler = throttleAnimation(setSticky)
    window.addEventListener('scroll', throttledHandler)

    return () => window.removeEventListener('scroll', throttledHandler)
  }, [setSticky])

  return (
    <>
      {isStatusBarSticky && header ? (
        ReactDOM.createPortal(<StatusBarComponent overlayWidth={overlayWidth} />, header)
      ) : (
        <StatusBarComponent overlayWidth={overlayWidth} />
      )}
    </>
  )
}
