import { instanceWithSignature as API } from '../config';
import { IGetWhitelistArgs, IGetWhitelistResponse, IWhitelistSuccessResponse } from './types';
import { AxiosError } from 'axios';
import { sendExceptionReport } from '@utils/errors';
import { withWalletRequestFactory } from '@api/factories'

export const getWhitelistData = withWalletRequestFactory(
  async (walletParamName, { account, projectId }: IGetWhitelistArgs): Promise<IGetWhitelistResponse | null> => {
    try {
      const { data } = await API.get<IWhitelistSuccessResponse>('/get-whitelist-data', {
        params: {
          [walletParamName]: account,
          project_id: projectId
        }
      })

      return {
        status: data.status,
        paramsData: data.params_data,
        whitelist_account_address: data.whitelist_account_address
      }
    } catch (err) {
      if ((err as AxiosError).response?.status !== 404) {
        sendExceptionReport(err)
      }
      return null
    }
  }
)
