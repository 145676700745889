import { Modal } from 'react-bootstrap'
import './ShareOnSocials.scss'
import {
  DynamicImage,
  RoundButton,
  RoundButtonProps,
} from '@/components/common'
import { ReactComponent as CloseIcon } from '../../../assets/close.svg'
import { useState } from 'react'

import {
  shareOnEmail,
  shareOnFacebook,
  shareOnTwitter,
  shareOnTelegram,
  shareOnWhatsApp,
  shareOnDiscord,
} from '../../../utils/shareOnSocials'

const socials = {
  Facebook: shareOnFacebook,
  Twitter: shareOnTwitter,
  Telegram: shareOnTelegram,
  WhatsApp: shareOnWhatsApp,
  Discord: shareOnDiscord,
  Email: shareOnEmail,
}

interface Props {
  subject: string
  url: string
  text: string
  buttonLabel?: string
  buttonColor?: RoundButtonProps['color']
  disabled?: boolean
}

export const ShareOnSocialsModal = ({
  subject,
  url,
  text,
  buttonLabel,
  buttonColor,
  disabled,
}: Props) => {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <RoundButton
        color={buttonColor ? buttonColor : 'DARK'}
        onClick={openModal}
        disabled={disabled}
      >
        {buttonLabel ? buttonLabel : 'SHARE ON SOCIALS'}
      </RoundButton>

      <Modal
        show={showModal}
        onHide={closeModal}
        centered
        className="share-on-socials-modal"
        contentClassName="tile"
      >
        <Modal.Body>
          <CloseIcon
            className="share-on-socials-modal__close"
            onClick={closeModal}
          />
          <h2>Share</h2>
          <section className="share-on-socials-modal__buttons">
            {Object.entries(socials).map(([sn, shareFn]) => (
              <button
                className="share-on-socials-modal__button"
                onClick={() =>
                  shareFn({
                    subject,
                    url,
                    text,
                  })
                }
              >
                <span className="logo">
                  <DynamicImage path={`socials/${sn.toLowerCase()}.svg`} />
                </span>
                <span className="label">{sn}</span>
              </button>
            ))}
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}
