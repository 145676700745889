import React, { useState } from 'react'
import './SubscribeForm.css'
import { Form } from 'react-bootstrap'
import { RoundButton } from '@components/common/RoundButton'
import { Spinner } from '@components/common/Spinner'
import { useFormik } from 'formik'
import * as yup from 'yup'

export interface SubscribeFormValues {
  email: string
}

const initialValues: SubscribeFormValues = {
  email: '',
}

const validationSchema = yup.object().shape({
  email: yup.string().email('Wrong format of email').required('Email is required'),
})

interface Props {
  onSubmit: (formData: SubscribeFormValues) => Promise<unknown> | unknown
}

export const SubscribeForm = ({ onSubmit }: Props) => {
  const [loading, setLoading] = useState(false)

  const onSubmitHandler = async (values: SubscribeFormValues) => {
    if (loading) {
      return
    }

    setLoading(true)
    await onSubmit(values)
    setLoading(false)
  }

  const { errors, touched, handleSubmit, isValid, getFieldProps } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: onSubmitHandler,
    initialValues,
  })

  return (
    <Form noValidate className="subscription-form" onSubmit={handleSubmit}>
      <div className="subscription-form__inner text-center">
        <h2 className="title">Subscribe to our newsletter</h2>
        <p className="subscription-form__description">
          Add your email below to receive the latest updates from <b>FireStarter</b>
        </p>
        <Form.Group controlId="email">
          <Form.Control
            type="email"
            placeholder="username@gmail.com"
            {...getFieldProps('email')}
            isValid={touched.email && !errors.email}
            isInvalid={touched.email && !!errors.email}
          />
          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
        </Form.Group>
        <RoundButton
          className="subscription-form__submit-button"
          type="submit"
          size="large"
          disabled={!isValid || loading}
        >
          {loading ? <Spinner /> : 'Subscribe'}
        </RoundButton>
      </div>
    </Form>
  )
}
