import { useCallback, useEffect, useState } from 'react';
import { getEthLinkingStatus } from '@api/link-wallets';
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { getSolanaLinkingStatus } from '@api/link-wallets/getSolanaLinkingStatus'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { getCurrentNetworkId } from '@contracts/networks'
import { ILinkingAccount } from '@api/link-wallets/types'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'

export enum LinkingStatus {
  kyc_missing = 'kyc_missing',
  kyc_not_passed = 'kyc_not_passed',
  linking_not_started = 'linking_not_started',
  linking_in_progress = 'linking_in_progress',
  linking_success = 'linking_success',
  linking_error = 'linking_error',
  wallet_not_linked = 'wallet_not_linked'
}

export const useLinkingStatus = (solana_reference_id?: string) => {

  const { account } = useWalletContext()
  const [loading, setLoading] = useState<boolean>();
  const [data, setData] = useState<ILinkingAccount>();
  const isMountedRef = useIsMounted();
  const { currentNetworkId } = useNetwork();

  const getLinkingStatus = useCallback(async () => {
    if (!account) return;
    setLoading(true);

    const response = await (!isSolanaNetwork(currentNetworkId || getCurrentNetworkId())
      ? getEthLinkingStatus({ eth_wallet_address: account })
      : getSolanaLinkingStatus({
        solana_wallet_address: account,
        solana_reference_id
      }))

    if (!isMountedRef.current) return;
    if (response) setData(response);
    setLoading(false);
  }, [account, solana_reference_id]);

  useEffect(() => {
    getLinkingStatus()
  }, [account, solana_reference_id]);

  return { loading, data, getLinkingStatus };
}
