import React, { useEffect } from 'react';
import { LottieOptions, useLottie } from 'lottie-react';

interface Props {
  options: LottieOptions,
  speed?: number,
}

export const LottieIcon = ({ options, speed }: Props) => {
  const { View, setSpeed, destroy } = useLottie(options)

  useEffect(() => {
    if (speed) {
      setSpeed(speed);
    }

    return () => destroy();
  }, [speed]);

  return (
    <>{View}</>
  );
}
