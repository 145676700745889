import React from 'react'
import './AboutUs.scss'
import { PartnersSection, SEOTags, StaticPageWrapper, TeamCard, TeamMember } from '@components'
import { Col, Container, Row } from 'react-bootstrap'
import { seoTags } from '@/seo-content'

const teamMembers: TeamMember[] = [
  {
    avatar: 'parul_qujral.png',
    name: 'Parul Gujral',
    bio: (
      <>
        Snowball Money Founder <br />
        Forbes Contributor
      </>
    )
  },
  {
    avatar: 'ivan_turkovic.png',
    name: 'Ivan Turkovic',
    bio: <>Snowball Money CTO</>
  },
  {
    avatar: 'dmytro_isaienko.png',
    name: 'Dmytro Isaienko',
    bio: <>Product Designer</>
  },
  {
    avatar: 'oleksii_zhukov.png',
    name: 'Oleksii Zhukov',
    bio: <>Lead Frontend Developer</>
  },
]

const investors: TeamMember[] = [
  {
    avatar: 'ran-neuner.png',
    name: 'Ran Neuner',
    bio: 'cryptobanter.com',
    logo: 'ran-neuner.png'
  },
  {
    avatar: 'seven-letter.png',
    name: 'Casey Zoltan',
    bio: 'The 7th Letter'
  },
  {
    avatar: 'jauz.png',
    name: 'JAUZ',
    bio: 'DJ',
    logo: 'jauz.svg'
  },
  {
    avatar: 'ghastly.png',
    name: 'Ghastly',
    bio: 'DJ',
    logo: 'ghastly.svg'
  },
  {
    avatar: 'jesse-wellens.png',
    name: 'Jesse Wellens',
    bio: 'Youtuber',
    logo: 'jesse-wellens.svg'
  },
  {
    avatar: 'nicky-diamonds.png',
    name: 'Nicky Diamonds',
    bio: 'Diamond Supply',
    logo: 'nicky-diamonds.svg'
  }
]

export const AboutUs = () => {
  return (
    <StaticPageWrapper title="About us">
      <SEOTags {...seoTags.aboutUs} />
      <section className="about-us-caption">
        <Container>
          <p className="gradient-text gradient-text--purple">
            Throughout the year we provide resources to top digital innovation projects. Culminating in an IDO - Initial
            Decentralized Offering, projects open their pre-sale funding rounds to early supporters and $FLAME Token Holders.
          </p>
        </Container>
      </section>
      <section className="team">
        <Container>
          <div className="team__heading">
            <h2 className="title">The Core Team</h2>
            <p className="text-big">
              Our team consists of Founders, Marketers and Key Opinion Leaders from top 100 crypto projects allowing us
              to create network effects for projects joining our incubator with ease.
              <br />
              <br />
              FireStarter is a San Francisco based crypto company
            </p>
          </div>
          <Row className="team__list g-5 justify-content-center">
            {teamMembers.map((member) => (
              <Col key={member.name} md={{ span: 6 }} lg={{ span: 3 }}>
                <TeamCard avatar={`/team/${member.avatar}`} name={member.name} bio={member.bio} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <PartnersSection />
      <section className="investors">
        <Container>
          <h2 className="title text-center">Investors</h2>
          <Row className="investors-list g-5 justify-content-center">
            {investors.map((member) => (
              <Col key={member.name} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                <TeamCard
                  avatar={`/investors/${member.avatar}`}
                  name={member.name}
                  bio={member.bio}
                  logo={member.logo && `/investors/logos/${member.logo}`}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </StaticPageWrapper>
  )
}
