import React, { createContext, useReducer } from 'react';
import { stateReducer } from './reducer';
import {
  AppState,
  StateAction,
} from './types'

const initialState: AppState = {
  loading: false,
  settings: null,
  projects: null,
  whitelistedProjects: null,
  currentProject: null,
  KYC: {
    status: undefined,
    referenceId: null,
    ethWalletAddress: undefined,
  },
  NFT: {
    fetchingCollection: false,
    fetchingCurrentToken: false,
    flare: null,
    hiro: null,
    userNFTTokens: null,
    currentNFTToken: null
  }
}

export const AppContext = createContext<{
  state: AppState,
  dispatch: React.Dispatch<StateAction>
}>({
  state: initialState,
  dispatch: () => undefined
})

export const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  return  (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
