import { RoundButton } from '@components/common/RoundButton';
import { DynamicImage } from '@components/common/DynamicImage';
import { Link, LinkProps } from 'react-router-dom';
import React from 'react';

interface Props {
  to: LinkProps['to']
  children: React.ReactNode
}

export const BackLink = ({
  to,
  children,
}: Props) => {

  return (
    <Link
      className="back-link"
      to={to}
    >
      <RoundButton
        color="DARK"
      >
        <DynamicImage path="back_arrow.svg" />
      </RoundButton>
      <span className='back-link__label'>
          {children}
      </span>
    </Link>
  )
}
