import { LootBoxFormatted, ReferralReward } from '@api/subgraph/loot-boxes'
import { useMemo } from 'react'
import { formatISO9075 } from 'date-fns'
import { balanceToCurrency } from '@utils/balanceFormatter'
import { FLAME_DECIMALS } from '@constants'
import { LotteryWinTypes } from '@contracts/hooks/useBlindBoxLottery'
import './LotteryRersultsList.scss'
import { Divider, LoadingWrap } from '@/components'
import { useLotteryContext } from '@pages/BlindBoxes/useLotteryContext'
import BigNumber from 'bignumber.js'

interface Props {
  loading?: boolean
  lootBoxes?: LootBoxFormatted[]
  referralRewards?: ReferralReward[]
}

export const LotteryResultsList = ({ loading, lootBoxes, referralRewards }: Props) => {
  const {
    phases,
    currentPhaseIndex
  } = useLotteryContext()

  const referralsMap = useMemo(() => {
    return referralRewards ? referralRewards.reduce<Record<string, ReferralReward>>((acc, item) => {
      acc[`${item.contract}_${item.boxId}`] = item
      return acc
    }, {}) : {}
  }, [referralRewards])

  const filteredBoxes = useMemo(() => {
    const result: [LootBoxFormatted[], LootBoxFormatted[]] = [[], []]
    if (!lootBoxes || !phases.length) return result
    for (let box of lootBoxes) {
      if (box.contract.toLowerCase() === phases[0].contractAddress.toLowerCase()) {
        result[0].push(box)
      } else {
        result[1].push(box)
      }
    }
    return result
  }, [
    lootBoxes,
    phases,
  ])

  return (
    <div className="lottery-results-list">
      <div className="lottery-results-list__heading">
        <div>Time</div>
        <div>Purchase Amount</div>
        <div>Prize</div>
      </div>
      <div className="lottery-results-list__body custom-scroll">
        <LoadingWrap loading={!lootBoxes || !referralRewards || !!loading}>
          {
            !!filteredBoxes[currentPhaseIndex].length && (
              <>
                <h5 className="lottery-results-list__title">Phase {currentPhaseIndex + 1}</h5>
                <ul>
                  {filteredBoxes[currentPhaseIndex].map(box => (
                    <ResultsListItem
                      key={box.id}
                      createdAt={box.createdAt}
                      buyAmount={box.buyAmount}
                      rewardAmount={box.rewardAmount}
                      rewardType={box.rewardType}
                      isFree={!!referralsMap[box.id]}
                    />
                  ))}
                </ul>
              </>
            )
          }
          {!!(filteredBoxes[0].length && filteredBoxes[1].length) && <Divider />}
          {
            !!filteredBoxes[+!currentPhaseIndex].length && (
              <>
                <h5 className="lottery-results-list__title">Phase {+!currentPhaseIndex + 1}</h5>
                <ul>
                  {filteredBoxes[+!currentPhaseIndex].map(box => (
                    <ResultsListItem
                      key={box.id}
                      createdAt={box.createdAt}
                      buyAmount={box.buyAmount}
                      rewardAmount={box.rewardAmount}
                      rewardType={box.rewardType}
                      isFree={!!referralsMap[box.id]}
                    />
                  ))}
                </ul>
              </>
            )
          }
        </LoadingWrap>
      </div>
    </div>
  )
}

interface ItemProps {
  createdAt: Date
  buyAmount: BigNumber
  rewardAmount: BigNumber
  rewardType: LotteryWinTypes
  isFree: boolean
}

const ResultsListItem = ({
  createdAt,
  buyAmount,
  rewardAmount,
  rewardType,
  isFree,
}: ItemProps) => {
  return (
    <li className="lottery-results-list__item lottery-results-item">
      <div className="lottery-results-item__col">
        <span>Time: </span>
        <span>{formatISO9075(createdAt)}</span>
      </div>
      <div className="lottery-results-item__col">
        <span>Purchase Amount: </span>
        {isFree ? (
          <span className="purple-text">Free Box</span>
        ) : (
          <span>{balanceToCurrency(buyAmount, FLAME_DECIMALS)} FLAME</span>
        )}
      </div>
      <div className="lottery-results-item__col">
        <span>Prize</span>
        {rewardType === LotteryWinTypes.NFT ? (
          <span className="fw-semibold purple-text">Tier NFT</span>
        ) : (
          <span>{balanceToCurrency(rewardAmount, FLAME_DECIMALS)} FLAME</span>
        )}
      </div>
    </li>
  )
}