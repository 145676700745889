import { useNetwork } from '@firestarter-private/firestarter-library'
import { useWhen } from '@contracts/hooks/useWhen'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { getCurrentNetworkId } from '@contracts/networks'
import { useMultipleVestingEVM } from '@contracts/hooks/useVesting/useMultipleVestingEVM'
import { useVestingSolana } from '@contracts/hooks/useVesting/useVestingSolana'
import { IUseMultipleVestingArgs } from '@contracts/hooks/useVesting/types'

export const useMultipleVesting = (multipleVestingArgs: IUseMultipleVestingArgs) => {
  const { currentNetworkId } = useNetwork()
  return useWhen(
    isSolanaNetwork(currentNetworkId || getCurrentNetworkId()),
    useVestingSolana,
    useMultipleVestingEVM,
  )(multipleVestingArgs)
}
