export enum FlameTiers {
  LIGHT = 'LIGHT',
  SPARK = 'SPARK',
  SMOKE = 'SMOKE',
  FIRE = 'FIRE',
  FLAME = 'FLAME',
}

export type FlameTier = keyof typeof FlameTiers

export const tierLevels: Record<number, FlameTier> = {
  100: FlameTiers.LIGHT,
  500: FlameTiers.SPARK,
  5000: FlameTiers.SMOKE,
  10000: FlameTiers.FIRE,
  25000: FlameTiers.FLAME,
}

export const tierBreakpoints = Object.keys(tierLevels)

export enum TierAccessTypes {
  SURPRISE_LOTTERY = 'surprise_lottery',
  FCFS_LOTTERY = 'fcfs_lottery',
  GUARANTEED = 'guaranteed'
}

export const tierAccesses = {
  [FlameTiers.LIGHT]: TierAccessTypes.SURPRISE_LOTTERY,
  [FlameTiers.SPARK]: TierAccessTypes.FCFS_LOTTERY,
  [FlameTiers.SMOKE]: TierAccessTypes.FCFS_LOTTERY,
  [FlameTiers.FIRE]: TierAccessTypes.FCFS_LOTTERY,
  [FlameTiers.FLAME]: TierAccessTypes.FCFS_LOTTERY,
}

export const allocationMultipliers = {
  [FlameTiers.LIGHT]: null,
  [FlameTiers.SPARK]: 1,
  [FlameTiers.SMOKE]: 2,
  [FlameTiers.FIRE]: 4,
  [FlameTiers.FLAME]: 8,
}

export const flamePowerMultipliers = {
  FLAME_LOCKED: 1,
  LP_STAKED: 1000000,
  HIROS_AMOUNT: 4500
}

export const lotteryTicketPrice = 500

