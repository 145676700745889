import { useEffect } from 'react'
import { ChainvineClient, getReferrerId } from '@chainvine/sdk'
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
export const CHAINVINE_API_KEY = process.env.REACT_APP_CHAINVINE_API_KEY
export const CHAINVINE_CAMPAIGN_ID = process.env.REACT_APP_CHAINVINE_CAMPAIGN_ID

export const useChainVineSync = () => {
  const { connected, error, connect, account } = useWalletContext()
  const { isDefaultSolanaNetworkSelected } = useNetwork()
  let userClient
  const handleUser = async (account: string | null | undefined) => {
    const client = new ChainvineClient({
      apiKey: CHAINVINE_API_KEY, //your api key
    })

    if (!account || isDefaultSolanaNetworkSelected) {
      userClient = null
      return
    }
    //Get a user's wallet address by id
    userClient = await client.syncUser(
      account, // your user's wallet address
    )
    const campaign = {
      id: CHAINVINE_CAMPAIGN_ID || '',
    }
    const referrerId = getReferrerId()
    if (referrerId) {
      await userClient
        .referral({
          campaign,
        })
        .linkToReferrer(referrerId)
    }
  }

  useEffect(() => {
    handleUser(account)
    const { ethereum } = window as any
    if (ethereum && ethereum.on && !connected && !error) {
      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length === 0) {
          userClient = null
        }
      }
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
  }, [connected, error, connect])
}
