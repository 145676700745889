import { ProviderRpcError } from '@constants'

export interface ITransactionResponse<R = any> {
  data: R
  txHash?: string
  blockNumber?: number
}

export interface ITransactionError {
  error: ProviderRpcError | string
}

export type TransactionResult<R = any> = ITransactionResponse<R> | ITransactionError

export enum SolanaWalletAdapterType {
  PHANTOM = 'PHANTOM',
  MOBILE = 'MOBILE'
}
