import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '@hooks/useIsMounted'
import { PublicKey, SystemProgram, SYSVAR_CLOCK_PUBKEY } from '@solana/web3.js'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { getVestingAccountWithBump, getVestingRecipientAccount } from '@contracts/hooks/useVesting/functions'
import {
  IDepositArgs,
  IUsePresaleArgs,
  IUsePresaleReturn,
  PresaleAccountResponse,
  PresaleRecipientInfoResponse,
} from '@contracts/hooks/usePresale/types'
import {
  useProjectPresaleContract,
  useTokenInfo,
  useTransactions,
  useWalletContext,
} from '@firestarter-private/firestarter-library'
import { NetworkType } from '@firestarter-private/firestarter-library/lib/constants'
import { toPubKey } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import {
  BNToBigNumber,
  toBigNumber,
  balanceToNumber,
  numericToBN
} from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { getPresaleErrorAndSendReport } from '@contracts/hooks/usePresale/errors'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'
import { TransactionResult } from '@contracts/types'
import { sendExceptionReport } from '@utils/errors'
import {
  getPresaleAccountWithBump,
  getPresaleRecipientAccount,
  getPresaleVaultAccountWithBump,
} from '@contracts/hooks/usePresale/functions'

const exchangeRateAccuracy = toBigNumber(10).pow(6)

export const usePresaleSolana = ({
  presaleAddress: presaleProgramId,
  fundTokenAddress,
  rewardTokenAddress,
  whitelistDataAccount,
  presaleIdlName
}: IUsePresaleArgs): IUsePresaleReturn => {
  const isMountedRef = useIsMounted()

  const [presaleInfo, setPresaleInfo] = useState<PresaleAccountResponse>()
  const [presaleAccount, setPresaleAccount] = useState<PublicKey>()
  const [presaleAccountBump, setPresaleAccountBump] = useState<number>()
  const [presaleVaultAccount, setVaultAccount] = useState<PublicKey>()
  const [presaleVaultBump, setVaultBump] = useState<number>()
  const [recipientInfoAccount, setRecipientAccount] = useState<PublicKey>()
  const loadingInfo = useMemo(
    () => (!!presaleProgramId && !!fundTokenAddress && !!rewardTokenAddress) && !presaleInfo,
    [
      presaleInfo,
      presaleProgramId,
      fundTokenAddress,
      rewardTokenAddress,
    ]
)

  const [swappedByUser, setSwappedByUser] = useState(toBigNumber(0))
  const [swappedPrivateByUser, setSwappedPrivateByUser] = useState(toBigNumber(0))

  const fundTokenInfo = useTokenInfo(fundTokenAddress, NetworkType.solana)
  const rewardTokenInfo = useTokenInfo(rewardTokenAddress, NetworkType.solana)

  const swappedPublicByUser = useMemo(
    () => swappedByUser.minus(swappedPrivateByUser),
    [swappedByUser, swappedPrivateByUser]
  )

  const participants = useMemo(
    () => presaleInfo ? balanceToNumber(presaleInfo.totalParticipants, 0) : 0,
    [presaleInfo])

  const privateSoldAmount = useMemo(() => {
    if (!presaleInfo) return toBigNumber(0)
    return presaleInfo.privateSoldAmount
  }, [presaleInfo])

  const publicSoldAmount = useMemo(() => {
    if (!presaleInfo) return toBigNumber(0)
    return presaleInfo.publicSoldAmount
  }, [presaleInfo])

  const precisionDiff = useMemo(() => {
    return rewardTokenInfo.decimals - fundTokenInfo.decimals;
  }, [fundTokenInfo.decimals, rewardTokenInfo.decimals]);

  const presaleProgram = useProjectPresaleContract(presaleProgramId, {}, presaleIdlName)
  const { account } = useWalletContext()
  const publicKey = useMemo(() => account ? toPubKey(account) : undefined, [account])

  const { sendTransaction } = useTransactions()

  const resetAddresses = () => {
    setPresaleAccount(undefined)
    setPresaleAccountBump(undefined)
    setVaultAccount(undefined)
    setVaultBump(undefined)
  }

  const fetchAddresses = useCallback(async () => {
    if (!presaleProgram || !rewardTokenAddress || !presaleProgramId) {
      isMountedRef.current && resetAddresses()
      return
    }

    const [vaultAcc, presaleAcc] = await Promise.all([
      getPresaleVaultAccountWithBump(
        toPubKey(rewardTokenAddress),
        toPubKey(presaleProgramId)
      ),
      getPresaleAccountWithBump(
        toPubKey(rewardTokenAddress),
        toPubKey(presaleProgramId)
      )
    ])

    if (isMountedRef.current) {
      setPresaleAccount(presaleAcc[0])
      setPresaleAccountBump(presaleAcc[1])
      setVaultAccount(vaultAcc[0])
      setVaultBump(vaultAcc[1])
    }
  }, [
    presaleProgram,
    rewardTokenAddress,
    presaleProgramId,
    isMountedRef,
  ])

  useEffect(() => {
    fetchAddresses()
  },[presaleProgram, rewardTokenAddress])

  const getRecipientInfoAccount = useCallback(async () => {
    if (!rewardTokenAddress || !publicKey || !presaleProgram || !presaleProgramId) {
      setRecipientAccount(undefined)
      return
    }

    const recpInfoAccount = await getPresaleRecipientAccount(
      publicKey,
      toPubKey(rewardTokenAddress),
      toPubKey(presaleProgramId)
    )

    isMountedRef.current && setRecipientAccount(recpInfoAccount)
  }, [
    presaleProgram,
    publicKey,
    rewardTokenAddress,
    presaleProgramId,
    isMountedRef,
  ])

  useEffect(() => {
    getRecipientInfoAccount()
  }, [publicKey, rewardTokenAddress, presaleProgram])

  const getAccountData = useCallback(async () => {
    if (!presaleProgram || !presaleAccount) {
      isMountedRef.current && setPresaleInfo(undefined)
      return
    }

    const res = await presaleProgram.account.presaleAccount.fetch(presaleAccount)
    isMountedRef.current && setPresaleInfo(
      {
        ...res,
        exchangeRate: BNToBigNumber(res.exchangeRate),
        privateSaleStartTime: BNToBigNumber(res.privateSaleStartTime),
        startTime: BNToBigNumber(res.startTime),
        period: BNToBigNumber(res.period),
        serviceFee: BNToBigNumber(res.serviceFee),
        initialRewardsAmount: BNToBigNumber(res.initialRewardsAmount),
        currentPresalePeriod: BNToBigNumber(res.currentPresalePeriod),
        privateSoldAmount: BNToBigNumber(res.privateSoldAmount),
        publicSoldAmount: BNToBigNumber(res.publicSoldAmount),
        totalParticipants: BNToBigNumber(res.totalParticipants),
      } as PresaleAccountResponse
    )
  } , [presaleProgram, presaleAccount, isMountedRef])

  useEffect(() => {
    if (presaleAccount && presaleProgram) {
      getAccountData()
    }
  }, [presaleAccount, presaleProgram])

  const resetUserInfo = () => {
    setSwappedByUser(toBigNumber(0))
    setSwappedPrivateByUser(toBigNumber(0))
  }

  const getUserInfo = useCallback(async () => {
    if (!presaleProgram || !recipientInfoAccount) {
      isMountedRef.current && resetUserInfo()
      return
    }

    try {
      const res = await presaleProgram.account.recipientInfo.fetch(recipientInfoAccount) as PresaleRecipientInfoResponse
      if (isMountedRef.current) {
        setSwappedByUser(BNToBigNumber(res.fundBalance))
        setSwappedPrivateByUser(BNToBigNumber(res.privateSoldFund))
      }
    } catch (err) {
      isMountedRef.current && resetUserInfo()
    }
  }, [presaleProgram, recipientInfoAccount, isMountedRef])

  useEffect(() => {
    if (recipientInfoAccount) {
      getUserInfo()
    }
  }, [presaleProgram, recipientInfoAccount])

  const fetchActualInfo = useCallback(async () => {
    await Promise.all([
      getAccountData(),
      getUserInfo()
    ])
  }, [getAccountData, getUserInfo])

  const swapExchangeRate = useMemo(() => {
    if (!presaleInfo) return toBigNumber(1)
    return presaleInfo.exchangeRate
  }, [presaleInfo])

  const fundsSwapped = useMemo(() => {
    const isPrecisionNegative = precisionDiff < 0;
    const diff = toBigNumber(10).pow(precisionDiff)

    if (isPrecisionNegative) {
      return privateSoldAmount
        .plus(publicSoldAmount)
        .multipliedBy(swapExchangeRate)
        .div(exchangeRateAccuracy)
        .multipliedBy(diff)
    }
    return privateSoldAmount
      .plus(publicSoldAmount)
      .multipliedBy(swapExchangeRate)
      .div(exchangeRateAccuracy)
      .div(diff)
  }, [privateSoldAmount, publicSoldAmount, swapExchangeRate, precisionDiff])

  const totalRewardsAmount = useMemo(
    () => presaleInfo ? presaleInfo.initialRewardsAmount : toBigNumber(0),
    [presaleInfo])

  const totalSwapAmount = useMemo(() => {
    const isPrecisionNegative = precisionDiff < 0;
    const diff = toBigNumber(10).pow(precisionDiff)
    if(isPrecisionNegative) {
      return totalRewardsAmount
        .multipliedBy(swapExchangeRate)
        .div(exchangeRateAccuracy)
        .multipliedBy(diff)
    }
    return totalRewardsAmount
      .multipliedBy(swapExchangeRate)
      .div(exchangeRateAccuracy)
      .div(diff)
  }, [totalRewardsAmount, swapExchangeRate, precisionDiff, exchangeRateAccuracy])


  const depositFunction = useCallback(async (
    depositMethod,
    amount: string,
    callbacks: INotifyTxCallbacks = {},
  ): Promise<TransactionResult> => {
    if (!publicKey) return getPresaleErrorAndSendReport('No user account')
    if (!presaleInfo) return getPresaleErrorAndSendReport('No actual info')
    if (
      !presaleVaultBump ||
      !presaleAccountBump ||
      !fundTokenAddress ||
      !rewardTokenAddress ||
      !presaleVaultAccount ||
      !presaleAccount ||
      !recipientInfoAccount ||
      !presaleInfo ||
      !whitelistDataAccount
    ) {
      return getPresaleErrorAndSendReport('No PDA accounts')
    }

    const fundTokenAccount = await fundTokenInfo.getOrCreateAssociatedAccount()
    if (!fundTokenAccount) return getPresaleErrorAndSendReport('No fund token ATA')

    const fundMint = toPubKey(fundTokenAddress)
    const rewardMint = toPubKey(rewardTokenAddress)

    const [vestingAccount, vestingAccountBump] = await getVestingAccountWithBump(
      rewardMint,
      presaleInfo.vestingProgram
    )
    const [vestingInfo] = await getVestingRecipientAccount(
      publicKey,
      rewardMint,
      presaleInfo.vestingProgram
    )

    try {
      const tx = await depositMethod(
        presaleVaultBump,
        presaleAccountBump,
        vestingAccountBump,
        numericToBN(amount, fundTokenInfo.decimals),
        {
          accounts: {
            user: publicKey,
            fundMint,
            rewardMint,
            depositorFundTokenAccount: fundTokenAccount,
            vaultAccount: presaleVaultAccount,
            presaleAccount,
            recipientInfo: recipientInfoAccount,
            whitelist: toPubKey(whitelistDataAccount),
            vestingAccount,
            vestingInfo,
            vestingProgram: presaleInfo.vestingProgram,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
            clock: SYSVAR_CLOCK_PUBKEY
          },
        }
      )

      const data = await sendTransaction(tx, callbacks)
      return { data }
    } catch (error) {
      sendExceptionReport(error)
      return { error }
    }
  }, [
    publicKey,
    fundTokenAddress,
    rewardTokenAddress,
    presaleVaultAccount,
    presaleAccount,
    recipientInfoAccount,
    presaleInfo,
    fundTokenInfo,
    presaleVaultBump,
    presaleAccountBump,
    sendTransaction,
    whitelistDataAccount
  ])

  const handleDeposit = useCallback(async ({
    amount,
    callbacks = {},
  }: IDepositArgs): Promise<TransactionResult> => {
    if (!presaleProgram) return getPresaleErrorAndSendReport('No contract')

    return await depositFunction(
      presaleProgram.transaction.deposit,
      amount,
      callbacks
    )
  }, [presaleProgram, depositFunction])

  const handleDepositPrivate = useCallback(async ({
    amount,
    callbacks = {},
  }: IDepositArgs): Promise<TransactionResult> => {
    if (!presaleProgram) return getPresaleErrorAndSendReport('No contract')

    return await depositFunction(
      presaleProgram.transaction.depositPrivateSale,
      amount,
      callbacks
    )
  }, [presaleProgram, depositFunction])

  return {
    loadingInfo,
    totalSwapAmount,
    fundsSwapped,
    totalRewardsAmount,
    swapExchangeRate,
    swappedByUser,
    swappedPublicByUser,
    swappedPrivateByUser,
    fetchActualInfo,
    participants,
    onDeposit: handleDeposit,
    onDepositPrivate: handleDepositPrivate,
    fundTokenInfo,
    rewardTokenInfo
  }
}
