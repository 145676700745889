import { ContractAddress, ProgramId } from '@contracts/address'
import { NetworkName } from '@firestarter-private/firestarter-library/lib/constants'

export enum ProjectStatuses {
  private = 'private',
  coming_soon = 'coming_soon',
  active = 'active',
  completed = 'completed',
}

export enum ProjectTypes {
  token_presale = 'token_presale',
  token_airdrop = 'token_airdrop',
  nodes_sale = 'nodes_sale',
  NFT_presale = 'NFT_presale',
  NFT_claim = 'NFT_claim',
  SFT = 'SFT',
}

export type ProjectType = keyof typeof ProjectTypes

export type ProjectStatus = keyof typeof ProjectStatuses

export enum ProjectStatusesProps {
  'Coming Soon' = 'Coming Soon',
  'Registration Open' = 'Registration Open',
  'Registration Closed' = 'Registration Closed',
  'Guaranteed Live' = 'Guaranteed Live',
  'FCFS Coming' = 'FCFS Coming',
  'FCFS Live' = 'FCFS Live',
  Closed = 'Closed',
}

export type ProjectStatusProp = keyof typeof ProjectStatusesProps

export enum WhitelistAllowances {
  all = 'all', // allow whitelisting for all KYC'd users
  locked = 'locked', // allow whitelisting for users with locked flame
  staked = 'staked', // allow whitelisting for users with staked lp tokens
  hiro = 'hiro', // allow whitelisting for Hiro owners
}

export type WhitelistAllowance = keyof typeof WhitelistAllowances

export enum SocialMedias {
  web = 'web',
  twitter = 'twitter',
  telegram = 'telegram',
  medium = 'medium',
  discord = 'discord',
  instagram = 'instagram',
}

export type SocialMedia = keyof typeof SocialMedias

export type TokenName = string

export interface TokenInfo {
  name: TokenName
  address?: ContractAddress
  icon?: string
}

export interface SocialLink {
  name: SocialMedia
  url: string
}

export interface DatesInterval {
  starts_at: string | null
  end_at: string | null
}

export interface PresaleDates {
  public_starts_at?: string | null
  public_end_at?: string | null
  private_starts_at?: string | null
  private_end_at?: string | null
  nodes_distribution_starts?: string | null
  airdrop_starts_at?: string | null
  airdrop_end_at?: string | null
}

type NormalizeDates<T extends object> = {
  [K in keyof T]: Date | null
}

export type NormalizedDatesInterval = NormalizeDates<DatesInterval>
export type NormalizedPresaleDates = NormalizeDates<PresaleDates>

export interface WhitelistingFields {
  email_required: boolean
  telegram_required: boolean
  twitter_required: boolean
  follow_twitter_url: string | null
  follow_telegram_url: string | null
  follow_discord_url: string | null
}

export interface WhitelistingInfo extends DatesInterval {
  country_restrictions: string[]
  allow_exclusives: boolean
  participants_allowed: WhitelistAllowance[]
  fields: WhitelistingFields
}

export interface NormalizedWhitelistingInfo
  extends Omit<WhitelistingInfo, 'starts_at' | 'end_at'>,
    NormalizedDatesInterval {}

interface BasePresaleInfo extends PresaleDates {
  fund_token: TokenInfo
  reward_token: TokenInfo
  status: ProjectStatus
  open_access_period?: number
  release_schedule?: string
  external_presale_link?: string
  external_claim_link?: string
}

export interface PresaleInfoEVM extends BasePresaleInfo {
  presale_contract_address?: ContractAddress
  vesting_contract_address?: ContractAddress
  whitelist_contract_address: ContractAddress
}

export interface PresaleInfoSolana extends BasePresaleInfo {
  whitelist_program_id?: ProgramId
  presale_program_id?: ProgramId
  vesting_program_id?: ProgramId
  whitelist_idl_name?: string
  presale_idl_name?: string
  vesting_idl_name?: string
}
export type PresaleInfo = PresaleInfoSolana & PresaleInfoEVM

export interface NormalizedPresaleInfoEVM
  extends Omit<PresaleInfoEVM, 'public_starts_at' | 'public_end_at' | 'private_starts_at' | 'private_end_at' | 'nodes_distribution_starts' | 'airdrop_starts_at' | 'airdrop_end_at'>,
    NormalizedPresaleDates {
  statusMessage: ProjectStatusProp
}

export interface NormalizedPresaleInfoSolana
  extends Omit<PresaleInfoSolana, 'public_starts_at' | 'public_end_at' | 'private_starts_at' | 'private_end_at' | 'nodes_distribution_starts' | 'airdrop_starts_at' | 'airdrop_end_at'>,
    NormalizedPresaleDates {
  statusMessage: ProjectStatusProp
}

export type NormalizedPresaleInfo = NormalizedPresaleInfoEVM & NormalizedPresaleInfoSolana

export interface SAFTInfo {
  official_company_name: string
  company_registration_data: string
  contact_email: string
  exchange_rate: number
}

export interface ProjectBio {
  title: string
  image?: string
  text: string
}

export interface ProjectInfoLegacy {
  subtitle?: string
  description: string
  main_color: string
  bio?: ProjectBio
}

export interface ProjectInfo {
  description_content_url: string
  main_color: string
  legacy?: ProjectInfoLegacy
}

export interface ProjectStatsData {
  fundraise_goal?: number
  total_raised?: number
  airdrop_amount?: number
  price_per_token?: number
  participants?: number
  base_allocation?: number
  ROI?: number
}

export interface ProjectAssets {
  logo_image_url: string
  banner_image_url: string
  nft_image_url?: string
}

export interface ProjectSettings {
  multiple_vesting: boolean
  is_external_presale: boolean
  is_refundable: boolean
}

export interface ProjectResponseInfo {
  id: string
  name: string
  network: NetworkName
  chainId: number | string
  project_type: ProjectType
  info: ProjectInfo
  assets: ProjectAssets
  settings: ProjectSettings
  social_links: SocialLink[]
  whitelisting: WhitelistingInfo
  stats?: ProjectStatsData
  presale: PresaleInfo
  saft?: SAFTInfo
  created_at: number
  updated_at: number
}

export interface NormalizedProjectInfo extends Omit<ProjectResponseInfo, 'presale' | 'whitelisting' | 'chainId'> {
  chainId: string
  whitelisting: NormalizedWhitelistingInfo
  presale: NormalizedPresaleInfo
}
