import React, { useEffect, useMemo } from 'react';
import './Homepage.scss';
import {
  AdvantagesSection,
  HeroRow,
  BelowBannerSection,
  FaqSection,
  FeaturesSection,
  GetReadySection,
  HomepageBanner,
  PartnersSection,
  ProjectsSection,
  QuantstampSection,
  TiersSection,
  TimerWrap,
} from '@components'
import { dueDateForProjects } from '@constants'
import { useProjectsState } from '@contracts/hooks/useProjects';
import { ProjectStatusesProps } from '@components/Project/types';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import {
  closedButton,
  comingSoonButton,
  defaultButton,
  DiscoverButton,
  DiscoverButtonProps,
  fcfsLiveButton,
  guaranteedLiveButton,
  regClosedButton,
  regOpenButton
} from '@pages/Homepage/buttons'

export const Homepage = () => {
  const { settings } = usePlatformSettings()
  const featuredProjectId = useMemo(() => settings?.featuredProjectId, [settings])
  const { currentProject, getProjectById, getProjects } = useProjectsState()

  useEffect(() => {
    getProjects()
    if (featuredProjectId && currentProject?.id !== featuredProjectId) {
      getProjectById(featuredProjectId)
    }
  }, [currentProject, featuredProjectId])

  const discoverButton = useMemo((): DiscoverButton => {
    const buttonProps: DiscoverButtonProps = { currentProject, featuredProjectId };
    switch (currentProject?.presale.statusMessage || ProjectStatusesProps['Coming Soon']) {
      case ProjectStatusesProps['Coming Soon']: return comingSoonButton(buttonProps)
      case ProjectStatusesProps['Registration Open']: return regOpenButton(buttonProps)
      case ProjectStatusesProps['Registration Closed']: return regClosedButton(buttonProps)
      case ProjectStatusesProps['Guaranteed Live']: return guaranteedLiveButton(buttonProps)
      case ProjectStatusesProps['FCFS Live']: return fcfsLiveButton(buttonProps)
      case ProjectStatusesProps.Closed : return closedButton(buttonProps)
      default: return defaultButton()
    }
  }, [currentProject])

  return (
    <div className="homepage page">
      <div className="main-background">
        <div className='main-background__shadow-top' />
        <section className="top-banner">
          <HomepageBanner />
          <HeroRow
            currentProject={currentProject}
            featuredProjectId={featuredProjectId}
            bannerButton={discoverButton}
          />
        </section>
        <BelowBannerSection />
        <FeaturesSection />
      </div>
      <ProjectsSection />
      <AdvantagesSection />
      <GetReadySection />
      <TiersSection />
      <TimerWrap dueDate={dueDateForProjects}>
        <PartnersSection />
      </TimerWrap>
      <FaqSection />
      <QuantstampSection />
    </div>
  )
}
