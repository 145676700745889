import React, { useMemo } from 'react';
import { RenderRowProps, RoundButton, SimpleTable, TableColumn, TableData } from '@components/common'
import { LockEntryInfo, LockPeriodInfo } from '@contracts/hooks/useMultiperiodLocking/types';
import { format } from 'date-fns';
import { LockupPeriodTooltip } from '../LockPeriodTooltip';
import { balanceToCurrency, numberToPercentage } from '@utils/balanceFormatter';
import { getReadablePeriod } from '../utils';
import { FLAME_DECIMALS } from '@constants';

const locksColumns: TableColumn[] = [
  {
    id: 'date',
    title: 'Date',
    bodyClass: 'fw-semibold locks-table__date'
  },
  {
    id: 'period',
    title: 'Period',
    bodyClass: 'fw-semibold locks-table__period'
  },
  {
    id: 'locked',
    title: 'Locked',
    bodyClass: 'locks-table__locked'
  },
  {
    id: 'apy',
    title: 'APY',
    bodyClass: 'locks-table__apy'
  },
  {
    id: 'rewards',
    title: 'Rewards',
    bodyClass: 'locks-table__rewards'
  },
  {
    id: 'action',
    title: '',
    bodyClass: 'locks-table__action'
  }
]

interface LocksTableData extends TableData {
  date: string,
  period: React.ReactChild,
  locked: string,
  apy: string
  rewards: string
  action: React.ReactChild
  unlockable: boolean
}

interface Props {
  lockPeriods?: LockPeriodInfo[]
  lockEntries?: Record<string, LockEntryInfo>
  loading: boolean
  onSelect: (id: string) => void | Promise<void>
}

export const LocksTable: React.FC<Props> = ({
  lockPeriods,
  lockEntries,
  loading,
  onSelect
}) => {
  const entriesRows = useMemo((): LocksTableData[] => {
    if (!lockPeriods || !lockEntries) return []
    return Object.values(lockEntries)
      .sort((a, b) => b.stakedAt - a.stakedAt)
      .map(entry => {
        const periodInfo = lockPeriods[entry.tierIndex]
        return {
          date: format(entry.stakedAt, 'yyyy-MM-dd'),
          period: <>
            {getReadablePeriod(periodInfo.lockPeriod)}
            {' '}
            <LockupPeriodTooltip
              period={periodInfo}
              id={`lock-entry-${entry.stakeId}-tooltip`}
              placement={'auto'}
              tooltipClass={'lockup-option__tooltip lockup-tooltip'}
            />
          </>,
          locked: balanceToCurrency(entry.amount, FLAME_DECIMALS),
          apy: numberToPercentage(periodInfo.apy),
          rewards: !entry.unstakedAt ? balanceToCurrency(entry.rewards, FLAME_DECIMALS) : '--',
          action: !entry.unstakedAt
            ? <RoundButton
              size="small"
              color="DARK"
              onClick={() => onSelect(entry.stakeId)}
            >
              Unlock
            </RoundButton>
            : <b>UNLOCKED</b>,
          unlockable: !entry.unstakedAt
        }
      })
  }, [lockEntries, lockPeriods, onSelect])

  return (
    <SimpleTable
      data={entriesRows}
      columns={locksColumns}
      loading={loading}
      rowClass={(item) => !item.unlockable ? 'unlocked' : ''}
      wrapperClass="locks-table-wrapper"
      tableClass="locks-table"
    />
  )
}
