import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import {
  AboutUs,
  Account,
  AddNetworkPage,
  ApplyForIDO,
  FAQPage,
  Homepage,
  IndividualProject,
  JoinCommunity,
  KYCPage,
  Leaderboard,
  NFTLanding,
  NFTPage,
  PressKit,
  Privacy,
  Projects,
  UserAgreement,
  Wallet,
  BlindBoxes,
} from '@pages'
import { Footer, Header, ScrollRestoration, ScrollToTop, Splash, WrongNetworkModal } from '@components'
import { useGasPrice, useWalletContext } from '@firestarter-private/firestarter-library'
import { RoutesPaths } from './constants'
import { usePlatformSettings } from '@hooks/usePlatformSettings'
import { useKYC } from '@contracts/hooks/useKYC'
import { isDefined } from '@utils/object'
import { useIsMounted } from '@hooks/useIsMounted'
import { isEthNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { SolNFTProvider } from '@contracts/hooks/useSolNFTs/context'
import { useIsSolana } from '@hooks/useIsSolana'
import { useChainVineSync } from '@hooks/useChainVineSync'

const Routes = React.memo(() => {
  const { account, error, networkId: chainId, connected, connect } = useWalletContext()
  useChainVineSync()

  const isMountedRef = useIsMounted()
  const { settings, getSettings } = usePlatformSettings()
  const { gasPrice, getGasPrice } = useGasPrice()
  const { KYCStatus, getKYCStatus } = useKYC()

  const isSolana = useIsSolana()
  const isEth = chainId && isEthNetwork(chainId)

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    if (chainId && settings && isSolana && !connected) {
      setTimeout(connect)
    }
  }, [chainId, settings])

  useEffect(() => {
    getKYCStatus()
  }, [account])

  const gasPriceListener = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    if (!isSolana) {
      getGasPrice()
      gasPriceListener.current = setInterval(getGasPrice, 30000)
    } else {
      clearInterval(gasPriceListener.current)
    }

    return () => clearInterval(gasPriceListener.current)
  }, [isSolana, getGasPrice])

  const [showSplash, setShowSplash] = useState(true)

  useEffect(() => {
    if (settings && isDefined(KYCStatus) && (gasPrice || isSolana)) {
      setTimeout(() => {
        isMountedRef.current && setShowSplash(false)
      }, 2000)
    }
  }, [settings, KYCStatus, gasPrice, isSolana, isMountedRef])

  return (
    <SolNFTProvider>
      <BrowserRouter>
        {
          !showSplash && (
            <ScrollRestoration>
              <Header />
              <Switch>
                <Route exact path={RoutesPaths.MAIN} component={Homepage} />
                <Route path={RoutesPaths.NFTS.MAIN} component={NFTLanding} />
                <Route exact path={RoutesPaths.NFT_TOKEN} component={NFTPage} />
                <Route path={RoutesPaths.ACCOUNT.MAIN} component={account ? Account : Wallet} />
                <Route path={RoutesPaths.LOOT_BOXES.LOTTERY} component={account ? BlindBoxes : Wallet} />
                <Route path={RoutesPaths.LEADERBOARD} component={Leaderboard} />
                <Route exact path={RoutesPaths.PROJECTS} component={Projects} />
                <Route exact path={RoutesPaths.PROJECT} component={IndividualProject} />
                <Route exact path={RoutesPaths.ABOUT_US} component={AboutUs} />
                <Route exact path={RoutesPaths.APPLY_FOR_IDO} component={ApplyForIDO} />
                <Route exact path={RoutesPaths.JOIN_NETWORK} component={JoinCommunity} />
                <Route exact path={RoutesPaths.USER_AGREEMENT} component={UserAgreement} />
                <Route exact path={RoutesPaths.PRIVACY} component={Privacy} />
                <Route exact path={RoutesPaths.PRESS_KIT} component={PressKit} />
                <Route exact path={RoutesPaths.FAQ} component={FAQPage} />
                <Route exact path={RoutesPaths.KYC_PROCESS} component={KYCPage} />
                <Route exact path={RoutesPaths.ADD_NETWORK_GUIDE} component={AddNetworkPage} />
                <Route path="/*" component={Homepage} />
              </Switch>
              <Footer />
              <ScrollToTop />
              <WrongNetworkModal error={error} />
            </ScrollRestoration>
          )
        }
        <Splash show={showSplash} />
      </BrowserRouter>
    </SolNFTProvider>
  )
})
export default Routes
