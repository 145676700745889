import React from 'react';
import './SimpleTable.css'
import { Spinner } from '../Spinner';
import classNames from 'classnames'

export interface TableColumn {
  id: string
  title: string
  width?: number
  headerClass?: string
  bodyClass?: string
}

export interface TableData {
  [k: TableColumn['id']]: React.ReactNode
}

interface Props {
  wrapperClass?: string
  tableClass?: string
  columns: TableColumn[]
  data: TableData[]
  loading: boolean
  rowClass?: string | ((item: TableData, index?: number) => string | undefined)
  renderRowProp?: (item: RenderRowProps) => React.ReactNode
}

export interface RenderRowProps {
  item: TableData,
  index: number,
  rowClass?: string | ((item: TableData, index?: number) => string | undefined)
  columns: TableColumn[]
  addPrefix: (element: string) => string
}

const SimpleTable = ({
  columns,
  data,
  tableClass,
  wrapperClass,
  loading,
  rowClass,
  renderRowProp,
}: Props) => {
  const addPrefix = (element: string) => {
    return classNames({
      [`simple-table${element}`]: true,
      [`${tableClass}${element}`]: tableClass
    })
  }

  const renderRowDefault = ({item, index, rowClass, columns, addPrefix}: RenderRowProps ) => {
    const rowClassName = rowClass ? (
      typeof rowClass === 'string'
        ? rowClass
        : rowClass(item, index)
    ) : ''

    return (
      <tr key={index} className={`${addPrefix('__row')} ${rowClassName ?? ''}`}>
        {
          columns.map(col => (
            <td data-label={col.title} key={`${index}-${col.id}`} className={col.bodyClass ?? ''} width={col.width}>
              <div className={addPrefix('__cell')}>
                {item[col.id]}
              </div>
            </td>
          ))
        }
      </tr>
    )
  }

  const renderRow = renderRowProp !== undefined ? renderRowProp : renderRowDefault;

  return (
    <div className={`simple-table-wrapper ${wrapperClass ?? ''}`}>
      <table className={`simple-table ${tableClass ?? ''}`}>
        <thead className={addPrefix('__head')}>
          <tr className={addPrefix('__head-row')}>
            {
              columns.map(col => (
                <th className={col.headerClass ?? ''} key={col.id}>{col.title}</th>
              ))
            }
          </tr>
        </thead>
        <tbody className={addPrefix('__body')}>
        {
          loading ? (
            <tr>
              <td colSpan={columns.length} className='loading'>
                <div className={addPrefix('__empty-cell')}>
                  <Spinner />
                </div>
              </td>
            </tr>
          ) : !data.length ? (
            <tr>
              <td colSpan={columns.length}>
                <div className={`${addPrefix('__empty-cell')} text-big`}>
                  <span>No data</span>
                </div>
              </td>
            </tr>
            ) : data.map((item, index) => renderRow({item, index, rowClass, columns, addPrefix}))
        }
        </tbody>
      </table>
    </div>
  )
}

SimpleTable.defaultProps = {
  loading: false
}

export { SimpleTable }
