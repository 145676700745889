import BigNumber from 'bignumber.js'
import React, { createContext, useReducer } from 'react'
import { ContractAddress } from '@firestarter-private/firestarter-library/lib/types'

export interface LotteryPhaseData {
  startDate: Date | null
  endDate: Date | null
  minFlameAmount: BigNumber
  maxFlameAmount: BigNumber
  maxPrize: BigNumber
  contractAddress: ContractAddress
}

interface ILotteryContext {
  phases: [LotteryPhaseData, LotteryPhaseData] | []
}

export enum LotteryContextActionType {
  SET_PHASES,
}

interface SetPhases {
  type: LotteryContextActionType.SET_PHASES
  payload: [LotteryPhaseData, LotteryPhaseData] | []
}

type LotteryContextAction = SetPhases

const initialState: ILotteryContext = {
  phases: [],
}

export const LotteryContext = createContext<{
  state: ILotteryContext,
  dispatch: React.Dispatch<LotteryContextAction>
}>({
  state: initialState,
  dispatch: () => undefined
})

const lotteryContextReducer = (state: ILotteryContext, action: LotteryContextAction): ILotteryContext => {
  switch (action.type) {
    case LotteryContextActionType.SET_PHASES:
      return {
        ...state,
        phases: action.payload
      }
    default:
      return state
  }
}

export const LotteryContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(lotteryContextReducer, initialState)

  return (
    <LotteryContext.Provider value={{ state, dispatch }}>
      {children}
    </LotteryContext.Provider>
  )
}