import { sendExceptionReport } from '@utils/errors'

const presaleErrorKeys = <const>[
  'No contract',
  'No user account',
  'No PDA accounts',
  'No actual info',
  'No fund token ATA',
  'Wrong method'
]

type PresaleErrorKey = typeof presaleErrorKeys[number]

const PRESALE_ERRORS: Readonly<Record<PresaleErrorKey | string, string>> = {
  'No contract': 'No contract initialized. Please try again later',
  'No user account': 'Cannot retrieve wallet address. Please try to reconnect',
  'No PDA accounts': 'Cannot retrieve PDA accounts. Please refresh the page',
  'No actual info': 'The actual pre-sale info is not available. Please try again later',
  'No fund token ATA': 'Cannot retrieve token associated account. Please refresh the page',
  'Wrong method': 'Wrong contract method. Please refresh the page'
}

export const getPresaleError = (msg?: PresaleErrorKey | string) =>
  ({
    error: msg
      ? (PRESALE_ERRORS[msg] || msg)
      : 'Transaction failed',
  })

export const getPresaleErrorAndSendReport = (error: Parameters<typeof getPresaleError>[0]) => {
  sendExceptionReport(error)
  return getPresaleError(error)
}
