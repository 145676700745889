import { OGProperties, PageTags } from '@/seo-content/types'
import { RoutesPaths } from '@router/constants'

const title = 'Blind Boxes Lottery'
const url = window.location.origin + RoutesPaths.LOOT_BOXES
export const blindBoxes: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `FireStarter | ${title}`
    },
    {
      property: OGProperties.description,
      content: `Experience the excitement of Blind Box Lottery - purchase
      a sealed box for a surprise collectible. Discover amazing prizes!`
    },
    {
      property: OGProperties.url,
      content: url,
    }
  ]
}