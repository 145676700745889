import { OGProperties, PageTags } from '../types';

export const mainPage: PageTags = {
  title: 'FireStarter',
  description: 'A Funding Platform for Initial DEX Offerings',
  url: window.location.origin,
  properties: [
    {
      property: OGProperties.type,
      content: 'website'
    },
    {
      property: OGProperties.url,
      content: window.location.origin
    },
    {
      property: OGProperties.title,
      content: 'FireStarter'
    },
    {
      property: OGProperties.description,
      content: 'A Funding Platform for Initial DEX Offerings'
    },
    {
      property: OGProperties.image,
      content: process.env.PUBLIC_URL + '/apple-touch-icon.png'
    },
    {
      property: OGProperties.imageWidth,
      content: '512'
    },
    {
      property: OGProperties.imageHeight,
      content: '512'
    }
  ]
};
