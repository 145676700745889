import React from 'react';
import { Container } from 'react-bootstrap';
import { WrongNetworkBlock } from '../WrongNetworkBlock';
import { defaultEVMNetworkData, defaultSolanaNetworkData } from '@firestarter-private/firestarter-library/lib/constants'
import { Link } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'

export const LockupNotSupported = () => {

  return (
    <Container>
      <div className='non-polygon-block tile'>
        <h2 className='title mb-4'>Locking</h2>
        <p className="mb-4 fs-4">
          Locking ability is not available in this pool any more <br/>
          Check out the <Link to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}>NEW Locking Pool</Link> instead. <br/>
        </p>
        <WrongNetworkBlock
          prefix={'To unlock your tokens'}
          expectedNetwork={defaultEVMNetworkData}
        />
      </div>
    </Container>
  )
}

export const ContractNotSupportedWidget = () => {
  return (
    <Container>
      <div className='non-polygon-block tile'>
        <h2 className='title mb-4'>Locking and Staking</h2>
        <p className="mb-4 fs-4">
          Locking and Staking are available only on{' '}
          <b>{defaultEVMNetworkData.userFriendlyName}</b> or{' '}
          <b>{defaultSolanaNetworkData!.userFriendlyName}</b> network
        </p>
        <WrongNetworkBlock
          prefix={'To lock or stake your tokens'}
          expectedNetwork={[
            defaultEVMNetworkData,
            defaultSolanaNetworkData!
          ]}
        />
      </div>
    </Container>
  )
}

interface Props {
  featureName: string
}

export const NonPolygonWidget = ({ featureName }: Props) => {

  return (
    <Container>
      <div className='non-polygon-block tile'>
        <h2 className='title mb-4'>{featureName}</h2>
        <p className="mb-4 fs-4">
          {featureName} can be accessed only on{' '}
          <b>{defaultEVMNetworkData.userFriendlyName} network</b>
        </p>
        <WrongNetworkBlock
          prefix={'To lock or stake your tokens'}
          expectedNetwork={defaultEVMNetworkData}
        />
      </div>
    </Container>
  )
}
