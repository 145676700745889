import { Col, Container, Row } from 'react-bootstrap'
import { GetReadyBadge } from '@/components'
import React from 'react'

export const GetReadySection = () => (
  <section className="get-ready-section">
    <Container>
      <Row className="mt-auto">
        <Col>
          <GetReadyBadge />
        </Col>
      </Row>
    </Container>
  </section>
)
