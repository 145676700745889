import React, { useCallback, useEffect } from 'react';
import { Col, Container, Image, Navbar, Row } from 'react-bootstrap';
import { GetReadyBadge, LoadingWrap, NoProjects, ProjectFilter, ProjectsList, SEOTags, TimerWrap } from '@components';
import { dueDateForProjects } from '@constants';
import './Projects.scss';
import { useProjectsState } from '@contracts/hooks/useProjects';
import { seoTags } from '@/seo-content';
import { useSearchParams } from '@hooks/useSearchParams';
import { getNetworkIcon, supportedNetworksFiltered } from '@contracts/networks';
import { NetworkId } from '@firestarter-private/firestarter-library/lib/constants'

export const projectFilterItems = [
  ...Object.values(supportedNetworksFiltered).map(item => ({
    value: item.networkId,
    content: <>
      <Image
        width={32}
        height={32}
        src={getNetworkIcon(item)}
        roundedCircle
      />
      {' '}
      {item.userFriendlyName}
    </>
  })),
  {
    value: 'all',
    content: 'All Networks'
  },
]

export const Projects = () => {
  const {
    loading,
    liveProjects,
    upcomingProjects,
    completedProjects,
    getProjects,
    abortRequest,
  } = useProjectsState()

  const {
    query,
    replaceQuery
  } = useSearchParams()

  const changeFilter = useCallback((value: NetworkId | 'all') => {
    if (value === 'all') {
      return replaceQuery({})
    }
    replaceQuery({ chainId: value })
  }, [replaceQuery])

  const loadProjects = useCallback(async () => {
    abortRequest()
    await getProjects(query)
  }, [abortRequest, getProjects, query])

  useEffect(() => {
    loadProjects()
  }, [query])

  return (
    <div className="projects-page page">
      <SEOTags { ...seoTags.projects } />
      <section className="project-top">
        <Container className="projects-top-container">
          <div className='d-flex align-items-center justify-content-between'>
            <h1 className="title mb-0">Projects</h1>
            <Navbar>
              <ProjectFilter
                value={query.chainId ?? 'all'}
                items={projectFilterItems}
                onChange={changeFilter}
              />
            </Navbar>
          </div>
        </Container>
      </section>
      {/*<section className='filters-section'>*/}
      {/*  <Container>*/}
      {/*  </Container>*/}
      {/*</section>*/}
      <section className="projects-live">
        <Container>
          <h2 className="title mb-3">Live Projects</h2>
          <Row className="g-4">
            <LoadingWrap loading={loading}>
              {liveProjects.length
                ? <ProjectsList projects={liveProjects} />
                : <NoProjects message='There are no live projects yet' />}
            </LoadingWrap>
          </Row>
        </Container>
      </section>
      <TimerWrap dueDate={dueDateForProjects}>
        <section className="projects-upcoming">
          <Container>
            <h2 className="title mb-3">Upcoming Projects</h2>
            <Row className="g-4">
              <LoadingWrap loading={loading}>
                {upcomingProjects.length
                  ? <ProjectsList projects={upcomingProjects} />
                  : <NoProjects message='There are no upcoming projects now' />}
              </LoadingWrap>
            </Row>
          </Container>
        </section>
      </TimerWrap>
      <section className="projects-completed">
        <Container>
          <h2 className="title mb-3">Completed Projects</h2>
          <Row className="g-4">
            <LoadingWrap loading={loading}>
              {completedProjects.length
                ? <ProjectsList projects={completedProjects} />
                : <NoProjects message='No projects have been completed yet' />}
            </LoadingWrap>
          </Row>
        </Container>
      </section>
      <section className="projects-ready">
        <Container>
          <Row className="mt-auto">
            <Col>
              <GetReadyBadge />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
