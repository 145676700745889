export interface IDOFormValues {
  contactName: string
  contactPosition: string
  contactTelegram: string
  contactEmail: string
  teamGeography: string
  projectName: string
  description: string
  projectStatus: string
  websiteUrl: string
  pitchDeckUrl: string
  projectTwitterUrl: string
  sourceAboutFS: string
}

export const initialValues: IDOFormValues = {
  contactName: '',
  contactPosition: '',
  contactTelegram: '',
  contactEmail: '',
  teamGeography: '',
  projectName: '',
  description: '',
  projectStatus: '',
  websiteUrl: '',
  pitchDeckUrl: '',
  projectTwitterUrl: '',
  sourceAboutFS: ''
}

export const requiredFieldNames: Array<keyof IDOFormValues> = [
  'contactName',
  'contactPosition',
  'contactEmail',
  'teamGeography',
  'projectName',
  'description',
  'projectStatus',
  'websiteUrl',
  'sourceAboutFS',
]

export const optionalFieldNames: Array<keyof IDOFormValues> = [
  'contactTelegram',
  'pitchDeckUrl',
  'projectTwitterUrl',
]

export const allFieldNames = [...requiredFieldNames, ...optionalFieldNames]
