import './NFTCollection.scss'
import { Col, Container, Row } from 'react-bootstrap';
import { useNFTs } from '@contracts/hooks/useNFTs';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LoadingWrap, NFTCard, RoundButton, Spinner, WrongNetworkBlock } from '@components';
import { NFTLinkdropCampaign } from '@constants';
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { AbortablePromise } from '@utils/AbortablePromise'
import { usePrevious } from '@hooks/usePrevious'

export const NFTCollection = () => {
  const {
    fetchingCollection: loading,
    flareBalance,
    hiroBalance,
    getUserNFTBalance,
    _getUserTokens,
    userNFTTokens,
  } = useNFTs()

  const [balanceLoaded, setBalanceLoaded] = useState(false)
  const totalTokens = useMemo(() => flareBalance + hiroBalance, [flareBalance, hiroBalance])
  const fetchTokensPromise = useRef(AbortablePromise.from(Promise.resolve().then().catch()))

  const { account, connect } = useWalletContext()
  const prevAccount = usePrevious(account)
  const { isDefaultEVMNetworkSelected, defaultEVMNetwork } = useNetwork()

  useEffect(() => {
    if (account) {
      getUserNFTBalance().then(() => setBalanceLoaded(true))
    }
  }, [account, isDefaultEVMNetworkSelected])

  useEffect(() => {
    if (balanceLoaded) {
      fetchTokensPromise.current.abort()
      fetchTokensPromise.current = AbortablePromise.from(
        _getUserTokens(account, true, prevAccount !== account)
          .then(() => setBalanceLoaded(false))
          .catch()
      )
    }
  }, [balanceLoaded])

  return (
    <section className="nfts-collection">
      <Container>
        {
          (!account || !isDefaultEVMNetworkSelected) ? (
            <Row className="justify-content-center">
              <Col md={{ span: 6 }}>
                <div className="tile--with-shadow no-projects-tile">
                  <h2 className='title mb-4 text-center'>NFT Collection</h2>
                  {!!account && !isDefaultEVMNetworkSelected && (
                    <WrongNetworkBlock
                      prefix={'To see your collection'}
                      expectedNetwork={defaultEVMNetwork}
                    />
                  )}
                  {
                    !account && (
                      <>
                        <p className='fs-4 mb-5 text-center'>
                          To see your collection <b>connect</b> your wallet
                          to <b>{defaultEVMNetwork.userFriendlyName} network</b>
                        </p>
                        <RoundButton
                          size="large"
                          wide
                          onClick={connect}
                        >
                          Connect
                        </RoundButton>
                      </>
                    )
                  }
                </div>
              </Col>
            </Row>
          ) : (
            !!userNFTTokens?.length ? (
              <>
                <div className='nfts-collection__list'>
                    {
                      userNFTTokens.map(token => (
                        <NFTCard key={token.id + token.name} name={token.name} image={token.image} id={token.id} />
                      ))
                    }
                </div>
                <div className="d-flex justify-content-center mt-5">
                  {loading
                    ? <Spinner />
                    : (totalTokens > userNFTTokens.length &&
                      <RoundButton
                        size="large"
                        wide
                        onClick={() => _getUserTokens(account)}
                      >
                        Load more
                      </RoundButton>
                    )
                  }
                </div>
              </>
            ) : (
              <LoadingWrap loading={loading}>
                <Row className="justify-content-center">
                  <Col md={{ span: 6 }}>
                    <div className='tile--with-shadow no-projects-tile'>
                      <div className='dots'>
                        <div />
                        <div />
                        <div />
                      </div>
                      <p className='tile__description'>
                        You don't have FireStarter NFTs yet
                      </p>
                      <RoundButton
                        size="large"
                        wide
                        disabled={!NFTLinkdropCampaign}
                        badgeText={!NFTLinkdropCampaign ? 'Coming soon' : ''}
                        href={NFTLinkdropCampaign}
                      >
                        Claim here
                      </RoundButton>
                    </div>
                  </Col>
                </Row>
              </LoadingWrap>
            )
          )
        }
      </Container>
    </section>
  )
}
