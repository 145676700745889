import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import {
  AccountDropdown,
  FireStarterLogoLink,
  HeaderLinks,
  SwitchNetworkButton,
} from '@components/layout/Header/HeaderComponents'
import './Header.scss'
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { isEthNetwork, isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { shorterETHAddress, shorterSolAddress } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import useScreenSize, { BreakPoint } from 'use-screen-size'
import ReactDOM from 'react-dom'
import { throttleAnimation } from '@utils/throttle'

export const Header = () => {
  const { account, connect, connected } = useWalletContext()
  const { currentNetworkId } = useNetwork()

  const onClickWallet = useCallback(() => connect(), [connected, connect])

  const shorterAddress = useMemo(
    () => (currentNetworkId ? (isSolanaNetwork(currentNetworkId) ? shorterSolAddress : shorterETHAddress) : null),
    [currentNetworkId],
  )

  const [expanded, setExpanded] = useState(false)
  const { screen } = useScreenSize()
  const mobile = useMemo(() => [BreakPoint.xs, BreakPoint.s].includes(screen), [screen])
  const tablet = useMemo(() => screen === BreakPoint.m, [screen])

  const toggleScroll = () => {
    document.documentElement.classList.toggle('no-scroll')
    document.querySelector('body')?.classList.toggle('no-scroll')
  }

  const onToggle = () => {
    setExpanded(!expanded)
    toggleScroll()
    let header = document.querySelector('header')
    if (header) header.classList.toggle('black')
  }

  const animateHeader = () => {
    let header = document.querySelector('header')
    let body = document.querySelector('body')

    if (header && body && window.scrollY > header.offsetHeight) {
      header.classList.add('sticky')
      body.classList.add('sticky-body')
    } else if (header && body) {
      header.classList.remove('sticky')
      body.classList.remove('sticky-body')
    }
  }

  useEffect(() => {
    const throttledHandler = throttleAnimation(animateHeader)
    window.addEventListener('scroll', throttledHandler)

    return () => window.removeEventListener('scroll', throttledHandler)
  }, [expanded])

  return (
    <Container as="header" className="header" fluid>
      <div className="wrapper">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          expanded={(mobile || tablet) && expanded}
          onToggle={onToggle}
        >
          <Navbar.Brand className="header-logo">
            <FireStarterLogoLink />
          </Navbar.Brand>
          {tablet && (
            <SwitchNetworkButton
              onClick={onClickWallet}
              active={connected}
              account={account}
              shorterMethod={shorterAddress}
            />
          )}
          <Navbar.Toggle />
          <Navbar.Collapse className="content">
            <Nav className="header-links">
              <HeaderLinks />
            </Nav>
            <Nav className="header-dropdowns">
              <AccountDropdown />
              {!tablet && !mobile && (
                <SwitchNetworkButton
                  onClick={onClickWallet}
                  active={connected}
                  account={account}
                  shorterMethod={shorterAddress}
                />
              )}
              {mobile &&
                ReactDOM.createPortal(
                  <div className="mobile-wallet-panel">
                    <div className="wrapper">
                      <div className="mobile-wallet-panel__block">
                        <Container>
                          <div className="text-center">
                            <SwitchNetworkButton
                              drop={'up'}
                              onClick={onClickWallet}
                              active={connected}
                              account={account}
                              shorterMethod={shorterAddress}
                            />
                          </div>
                        </Container>
                      </div>
                    </div>
                  </div>,
                  document.querySelector('#mobile-wallet-panel')!,
                )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </Container>
  )
}
