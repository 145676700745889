import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { RoundButton } from '../RoundButton';
import './WrongNetworkModal.scss'
import { CustomNetworkError, NoEthereumProviderError } from '@firestarter-private/firestarter-library/lib/utils/errors'
import { EVMinPhantomError, SolanaNotSupportedError } from '@utils/errors'

interface Props {
  error?: Error
}

const checkError = (error?: Error): boolean => {
  return error instanceof NoEthereumProviderError ||
    error instanceof SolanaNotSupportedError ||
    error instanceof EVMinPhantomError ||
    error instanceof CustomNetworkError
}

export const WrongNetworkModal = ({ error }: Props) => {
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setShow(checkError(error))
  }, [error])

  const title = useMemo(() => {
    return error instanceof NoEthereumProviderError
      ? 'No provider detected'
      : error instanceof SolanaNotSupportedError
      ? 'Not supported chain'
      : error instanceof EVMinPhantomError
      ? 'Not supported chain'
      : 'Error';
  }, [error])

  const text = useMemo(() => {
    if (error instanceof NoEthereumProviderError) {
      return (
        <p>
          Please make sure you have
          <a
            href="https://metamask.io/download.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}MetaMask extension{' '}
          </a>
          installed for your browser and connected your wallet to the website.
        </p>
      )
    }

    if (error instanceof SolanaNotSupportedError) {
      return (
        <p>
          To use Solana on FireStarter please use Phantom in-app browser,
          <br/>
          or open the website from your desktop browser
        </p>
      )
    }

    if (error instanceof EVMinPhantomError) {
      return (
        <p>
          You're trying to use the chain not supported by Phantom in-app browser.
          Please open the website from a regular browser on your phone or desktop
        </p>
      )
    }

    return (<p>{error?.message}</p>)
  }, [error])

  return (
    <Modal
      show={show}
      centered
      onHide={() => setShow(false)}
      className="wrong-network-modal"
      contentClassName="tile text-center"
      backdrop="static"
    >
      <Modal.Title as="h4">{title}</Modal.Title>
      <Modal.Body className="p-0">
        {text}
        <div className='wrong-network-modal__buttons'>
          {
            <RoundButton
              size="large"
              wide
              onClick={() => setShow(false)}
            >
              OK
            </RoundButton>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}
