export const chunkArrayBySize = (arr: any[], chunkSize: number): any[][] => {
  const result = []
  for (let i = 0; i < arr.length; i+= chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    result.push(chunk)
  }
  return result
}

export const arrayFromRange = (start: number, stop: number, step = 1) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  )
