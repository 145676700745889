import { LoadStatsReturns, StakingStats, } from '@contracts/hooks/useStaking/types'
import BigNumber from 'bignumber.js'
import { SECOND, YEAR } from '@firestarter-private/firestarter-library/lib/constants'

export const defaultStakingStats: StakingStats = {
  totalRewards: new BigNumber(0),
  lockedRewards: new BigNumber(0),
  unlockedRewards: new BigNumber(0),
  programDuration: undefined,
}

export const defaultStakingStatsEVM: LoadStatsReturns = {
  totalStakedAmount: new BigNumber(0),
  flamePerSecond: new BigNumber(0),
  startTime: 0,
  stakingPeriod: 0,
  stakingInProgress: false,
  totalRewardsAmount: new BigNumber(0)
}

/**
 * This value is brought from the Staking Program
 */
export const FLAME_PER_SHARE_ACCURACY = 1_000_000_000
export const YEAR_IN_SECONDS = YEAR / SECOND
export const APY_ACCURACY = 10_000
