import { useCallback, useState } from 'react';
import { BlockNumber, TransactionReceipt } from 'web3-core';
import {
  useNetwork,
  useFlameLockingContract,
  useDecimals,
  useTokenBalance,
  useLockedFlame,
  useLockingPenalties,
  useTransactions,
} from '@firestarter-private/firestarter-library';
import { polygonContractAddresses } from '@firestarter-private/firestarter-library/lib/constants'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'

export const useFlame = () => {
  const [loading, setLoading] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const flameLockingContract = useFlameLockingContract();

  const { isDefaultEVMNetworkSelected } = useNetwork()

  const decimals = useDecimals(
    isDefaultEVMNetworkSelected
      ? polygonContractAddresses.flameToken
      : undefined
  );
  const balance = useTokenBalance(
    isDefaultEVMNetworkSelected ? polygonContractAddresses.flameToken : undefined,
    loading,
    blockNumber
  );
  const lockedAmount = useLockedFlame(loading, blockNumber);
  const [lastDepositDays, penalty] = useLockingPenalties(loading, blockNumber);

  const { sendTransaction } = useTransactions()

  const handleLock = useCallback(async (
    amount: string,
    callbacks: INotifyTxCallbacks = {}
  ) => {
    if (!flameLockingContract) {
      return
    }
    setLoading(true);

    const receipt = await sendTransaction(
      flameLockingContract.methods.lock(amount),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [sendTransaction, flameLockingContract])

  const handleUnlock = useCallback(async (
    amount: string,
    callbacks: INotifyTxCallbacks = {}
  ) => {
    if (!flameLockingContract) {
      return
    }

    setLoading(true);

    const receipt = await sendTransaction(
      flameLockingContract.methods.unlock(amount),
      callbacks
    ) as TransactionReceipt

    setBlockNumber(receipt.blockNumber)
    setLoading(false)
  }, [sendTransaction, flameLockingContract])

  return {
    decimals,
    balance,
    lastDepositDays,
    penalty,
    lockedFlame: lockedAmount,
    onLock: handleLock,
    onUnlock: handleUnlock,
  }
}
