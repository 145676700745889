import React, { useMemo } from 'react'
import Routes from '@router/Routes';
import { StateProvider } from '@/store';
import { seoTags } from '@/seo-content';
import { SEOTags } from '@components';
import { getNetworkProvider } from '@firestarter-private/firestarter-library'
import { defaultSolanaNetworkData, defaultSolanaNetworkId } from '@firestarter-private/firestarter-library/lib/constants'
import { clusterApiUrl } from '@solana/web3.js'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { getCurrentNetworkId } from '@contracts/networks'
import { LotteryContextProvider } from '@pages/BlindBoxes/context'
import { useChainVineInit } from '@hooks/useChainVineInit'
import { PRIORITIZED_CONNECTORS } from '@firestarter-private/firestarter-library/lib/connectors'
import { EthProviderProps, SolanaProviderProps } from '@firestarter-private/firestarter-library/lib/providers/types'
import { SOLANA_WALLET_ADAPTERS } from '@contracts/adapters'
import { detectMobile } from '@utils/detectMobile'

const initialNetworkId = getCurrentNetworkId()
const NetworkProvider = getNetworkProvider(initialNetworkId)

function App() {
  const solCluster = defaultSolanaNetworkData?.cluster
  const solPrivateRpc = defaultSolanaNetworkData?.rpcUrl
  const wallets = useMemo(() => Object.values(SOLANA_WALLET_ADAPTERS), [solCluster])
  const solRPCEndpoint = useMemo(() => solPrivateRpc || clusterApiUrl(solCluster), [solCluster, solPrivateRpc])

  const networkProviderProps = useMemo(() => {
    if (isSolanaNetwork(initialNetworkId)) {
      return {
        defaultSolanaNetworkId,
        wallets,
        rpcEndpoint: solRPCEndpoint,
        autoConnect: !detectMobile()
      } as SolanaProviderProps
    }

    const connectors = Object.values(PRIORITIZED_CONNECTORS).map(({ connector, hooks }) => [connector, hooks])

    return { connectors } as EthProviderProps
  }, [wallets, solRPCEndpoint])

  useChainVineInit()

  return (
    //@ts-ignore
    <NetworkProvider
      {...networkProviderProps}
    >
      <StateProvider>
        <LotteryContextProvider>
          <SEOTags {...seoTags.main} />
          <Routes />
        </LotteryContextProvider>
      </StateProvider>
    </NetworkProvider>
  );
}

export default App
