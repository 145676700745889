import React, { ReactNode } from 'react'
import './HTitle.scss'

interface Props {
  type: 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: ReactNode
}
export const HTitle = ({ type, children }: Props) => {
  return React.createElement(
    type,
    {
      className: 'cms-title',
    },
    children,
  )
}
