import React, { useMemo, useState } from 'react';
import './WhitelistForm.scss'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup'
import { RoundButton, Spinner, CommonTooltip } from '@components/common';
import { KYCStatus, KYCStatuses } from '@api/kyc/types';
import { NormalizedProjectInfo, NormalizedWhitelistingInfo, WhitelistAllowances } from '@components/Project/types';
import classNames from 'classnames';
import { SAFTModal } from '@components/Project';
import { IWhitelistRequestFormData } from '@api/whitelist/types';
import { whitelistGetters } from '@contracts/getters/projectGetters';
import { balanceToCurrency } from '@utils/balanceFormatter';
import { ReactComponent as InfoIcon } from '@assets/info-icon.svg';
import { useFlameTier } from '@contracts/hooks/useFlameTier/useFlameTier';
import { FLAME_DECIMALS, FLAME_USDC_LP_DECIMALS } from '@constants';

export interface WLFormValues {
  email: string
  telegram_link: string | null
  twitter_link: string | null
  follow_twitter?: boolean
  follow_telegram?: boolean
  follow_discord?: boolean
  agree_to_saft?: boolean
}

const twitterNameRegex = /^[A-Za-z0-9_]{1,15}$/

const getValidationSchema = (project: NormalizedProjectInfo) => {
  return yup.object().shape({
    ...(whitelistGetters.getIsEmailRequired(project) ? {
      email: yup.string().trim().email('Write email in correct format').required('Email is required'),
    }: {
      email: yup.string().trim().email('Write email in correct format').optional()
    }),
    ...(whitelistGetters.getIsTelegramRequired(project) ? {
      telegram_link: yup.string().trim().required('Telegram profile is required'),
    } : {
      telegram_link: yup.string().trim().optional()
    }),
    ...(whitelistGetters.getIsTwitterRequired(project) ? {
      twitter_link: yup
        .string()
        .trim()
        .matches(twitterNameRegex, {
          message: 'Username can contain only latin letters, numbers and underscores',
          excludeEmptyString: true
        })
        .required('Twitter profile is required'),

    } : {
      twitter_link: yup
        .string()
        .trim()
        .matches(twitterNameRegex, {
          message: 'Username can contain only latin letters, numbers and underscores',
          excludeEmptyString: true
        })
        .optional()
    }),
    ...(whitelistGetters.getIsFollowTwitterRequired(project) ? {
      follow_twitter: yup.bool().required().oneOf([true]),
    } : {}),
    ...(whitelistGetters.getIsFollowTelegramRequired(project) ? {
      follow_telegram: yup.bool().required().oneOf([true]),
    } : {}),
    ...(whitelistGetters.getIsFollowDSRequired(project) ? {
      follow_discord: yup.bool().required().oneOf([true]),
    } : {}),
    ...(project.saft ? {
      agree_to_saft: yup.bool().required().oneOf([true])
    } : {})
  });
}

interface Props {
  project: NormalizedProjectInfo
  kycStatus: KYCStatus
  whitelistInfo: NormalizedWhitelistingInfo
  onSubmit: (formData: IWhitelistRequestFormData) => Promise<unknown> | unknown
}

export const WhitelistForm = ({
  project,
  kycStatus,
  whitelistInfo,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [showSAFTModal, setShowSAFTModal] = useState(false)
  const [failed, setFailed] = useState(false)

  const isEmailRequired = useMemo(() => whitelistGetters.getIsEmailRequired(project), [project])
  const isTelegramRequired = useMemo(() => whitelistGetters.getIsTelegramRequired(project) ,[project])
  const isTwitterRequired = useMemo(() => whitelistGetters.getIsTwitterRequired(project) ,[project])
  const isFollowDSRequired = useMemo(() => whitelistGetters.getIsFollowDSRequired(project) ,[project])
  const isFollowTwitterRequired = useMemo(() => whitelistGetters.getIsFollowTwitterRequired(project) ,[project])
  const isFollowTelegramRequired = useMemo(() => whitelistGetters.getIsFollowTelegramRequired(project) ,[project])
  const whitelistAllowances = useMemo(() => whitelistGetters.getWhitelistAllowances(project) , [project])

  const initialValues: WLFormValues = {
    email: '',
    telegram_link: '',
    twitter_link: '',
    ...(isFollowTwitterRequired ? {
      follow_twitter: false,
    } : {}),
    ...(isFollowTelegramRequired ? {
      follow_telegram: false,
    } : {}),
    ...(isFollowDSRequired ? {
      follow_discord: false,
    } : {}),
    ...(project.saft ? {
      agree_to_saft: false
    } : {})
  }

  const { userTierInfo } = useFlameTier()

  const allowWhitelist = useMemo(() => {
    return whitelistAllowances.map(rule => {
      switch (rule) {
        case WhitelistAllowances.all:
          return true;
        case WhitelistAllowances.locked:
          return !userTierInfo?.lockedAmount.isZero() || !userTierInfo?.multiperiodLockedAmount.isZero();
        case WhitelistAllowances.staked:
          return !userTierInfo?.stakedAmount.isZero()
        case WhitelistAllowances.hiro:
          return userTierInfo ? userTierInfo.hirosAmount > 0 : false;
      }
    }).some(check => check)
  }, [whitelistAllowances, userTierInfo])

  const allowancesTips = useMemo(() => {
    return whitelistAllowances.map(rule => {
      switch (rule) {
        case WhitelistAllowances.locked:
          return `You should lock $FLAME on the Lockup page. (You've locked: ` +
            (userTierInfo ? `${balanceToCurrency(userTierInfo.lockedAmount.plus(userTierInfo.multiperiodLockedAmount), FLAME_DECIMALS)} $FLAME)` : '0 $FLAME)')
        case WhitelistAllowances.hiro:
          return `You should be an owner of Hiro NFT(s). (Your balance: ${userTierInfo?.hirosAmount} Hiros)`
        case WhitelistAllowances.staked:
          return `You should stake LP tokens on Staking page. (You've staked: ` + (userTierInfo ? `${balanceToCurrency(userTierInfo.stakedAmount, FLAME_USDC_LP_DECIMALS)} LP tokens)` : '0 LP')
        default:
          return null
      }
    }).filter(Boolean)
  }, [whitelistAllowances, userTierInfo])

  const onSubmitHandler = async (values: WLFormValues, formikHelpers: FormikHelpers<WLFormValues>) => {
    if (loading) {
      return
    }
    setFailed(false)
    setLoading(true)
    const status = await onSubmit({
      ...values,
      telegram_link: values.telegram_link ? `https://t.me/${values.telegram_link}` : '',
      twitter_link: values.twitter_link ? `https://twitter.com/${values.twitter_link}` : '',
      agree_to_saft: project.saft && new Date().toISOString(),
    })
    setLoading(false)
    if (status) {
      formikHelpers.resetForm({ values: { ...initialValues }})
    } else {
      setFailed(true)
    }
  }

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isValid,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: getValidationSchema(project),
    onSubmit: onSubmitHandler,
    initialValues,
  })

  return (
    <Form noValidate onSubmit={handleSubmit} className='whitelist-form mobile-scroll'>
      <h2 className='title'>Apply to Whitelist</h2>
      <div className="custom-scroll">
        <Form.Group controlId="email">
          <Form.Label>Email address {isEmailRequired && '*'}</Form.Label>
          <Form.Control
            type="email"
            placeholder="username@email.com"
            {...getFieldProps('email')}
            isValid={touched.email && !errors.email}
            isInvalid={touched.email && !!errors.email}
          />
          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="telegram_link">
          <Form.Label>Telegram profile link {isTelegramRequired && '*'}</Form.Label>
          <InputGroup
            className={classNames({
              'is-invalid': touched.telegram_link && !!errors.telegram_link
            })}
          >
            <InputGroup.Prepend>
              https://t.me/
            </InputGroup.Prepend>
            <Form.Control
              type="url"
              placeholder="username"
              {...getFieldProps('telegram_link')}
              isValid={touched.telegram_link && !errors.telegram_link}
              isInvalid={touched.telegram_link && !!errors.telegram_link}
            />
          </InputGroup>
          <Form.Control.Feedback type="invalid">{errors.telegram_link}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="twitter_link">
          <Form.Label>Twitter profile link {isTwitterRequired && '*'}</Form.Label>
          <InputGroup
            className={classNames({
              'is-invalid': touched.twitter_link && !!errors.twitter_link
            })}
          >
            <InputGroup.Prepend>
              https://twitter.com/
            </InputGroup.Prepend>
            <FormControl
              type="text"
              placeholder="username"
              {...getFieldProps('twitter_link')}
              isValid={touched.twitter_link && !errors.twitter_link}
              isInvalid={touched.twitter_link && !!errors.twitter_link}
            />
          </InputGroup>
          <Form.Control.Feedback type="invalid">{errors.twitter_link}</Form.Control.Feedback>
        </Form.Group>
        {
          isFollowTwitterRequired && (
            <Form.Row>
              <Form.Group controlId="follow_twitter">
                <Form.Check
                  label={`Follow ${project.name} on Twitter`}
                  {...getFieldProps('follow_twitter')}
                  isInvalid={touched.follow_twitter && !!errors.follow_twitter}
                />
              </Form.Group>
              <RoundButton href={whitelistInfo.fields.follow_twitter_url as string} color="DARK">
                Open on twitter
              </RoundButton>
            </Form.Row>
          )
        }
        {
          isFollowTelegramRequired && (
            <Form.Row>
              <Form.Group controlId="follow_telegram">
                <Form.Check
                  label={`Follow ${project.name} on Telegram`}
                  {...getFieldProps('follow_telegram')}
                  isInvalid={touched.follow_telegram && !!errors.follow_telegram}
                />
              </Form.Group>
              <RoundButton href={whitelistInfo.fields.follow_telegram_url as string} color="DARK">
                Open on telegram
              </RoundButton>
            </Form.Row>
          )
        }
        {
          isFollowDSRequired && (
            <Form.Row>
              <Form.Group controlId="follow_discord">
                <Form.Check
                  label={`Follow ${project.name} on Discord`}
                  {...getFieldProps('follow_discord')}
                  isInvalid={touched.follow_discord && !!errors.follow_discord}
                />
              </Form.Group>
              <RoundButton href={whitelistInfo.fields.follow_discord_url as string} color="DARK">
                Open on discord
              </RoundButton>
            </Form.Row>
          )
        }
        {
          !!project.saft && (
            <Form.Row>
              <Form.Group controlId="agree_to_saft">
                <Form.Check
                  label={<>Agree to the Terms and <br/> conditions of SAFT agreement</>}
                  {...getFieldProps('agree_to_saft')}
                  checked={values.agree_to_saft}
                  isInvalid={touched.agree_to_saft && !!errors.agree_to_saft}
                />
              </Form.Group>
              <RoundButton onClick={() => setShowSAFTModal(true)} color="DARK">
                Open SAFT
              </RoundButton>
            </Form.Row>
          )
        }
      </div>
      <RoundButton
        type="submit"
        size="large"
        disabled={!isValid || kycStatus !== KYCStatuses.approved || loading || !allowWhitelist}
      >
        {loading ? <Spinner /> : <span>Send</span>}
        <CommonTooltip id="whitelist-tips" placement="bottom-start">
          <p>
            To apply to whitelist you have to pass KYC
            {!!allowancesTips.length && (
              <> and fulfill one of the requirements:
                <ul>
                  {allowancesTips.map(tip => (
                    <li>{tip}</li>
                  ))}
                </ul>
              </>
            )}
          </p>
        </CommonTooltip>
      </RoundButton>
      {
        failed && (
          <div className='form-message form-message--warning text-center mt-3'>
            <InfoIcon />
            <span>Oops! Your application was not submitted. Check your data and try again</span>
          </div>
        )
      }
      {
        !!project.saft && (
          <SAFTModal
            project={project}
            show={showSAFTModal}
            setShow={setShowSAFTModal}
            agreed={values.agree_to_saft ?? false}
            onChangeAgreement={(value) => {
              setFieldValue('agree_to_saft', value, true);
            }}
          />
        )
      }
    </Form>
  )
}
