import React, { useRef } from 'react';
import './HomepageBanner.scss'
import Astronaut from '@assets/top-banner/spaceman.png'
import CardsLeft from '@assets/top-banner/cards-left.png'
import CardsRight from '@assets/top-banner/cards-right.png'
import { Image } from 'react-bootstrap';
import { useParallax } from '@hooks/useParallax';

export const HomepageBanner = () => {
  const spaceRef = useRef<HTMLDivElement | null>(null)
  const spacemanRef = useRef<HTMLImageElement | null>(null)
  const cardsRightRef = useRef<HTMLImageElement | null>(null)
  const cardsLeftRef = useRef<HTMLImageElement | null>(null)

  useParallax<HTMLDivElement>(
    spacemanRef,
    {
      scrollY: {
        translateY: [
          ['elInY', 'elCenterY', 'elOutY'],
          [-100, -50, 0],
        ],
      }
    }
  );

  useParallax<HTMLImageElement>(
    cardsRightRef,
    {
      scrollY: {
        translateY: [
          ['elInY', 'elCenterY', 'elOutY'],
          [-100, -50, 0],
        ]
      }
    }
  );

  useParallax<HTMLImageElement>(
    cardsLeftRef,
    {
      scrollY: {
        translateY: [
          ['elInY', 'elCenterY', 'elOutY'],
          [100, 50, 0],
        ]
      }
    }
  );

  return (
    <>
      <div ref={spaceRef} className='top-banner__space' />
      <Image ref={spacemanRef} src={Astronaut} className='top-banner__spaceman' />
      <Image ref={cardsLeftRef} src={CardsLeft} className='top-banner__cards-left' />
      <Image ref={cardsRightRef} src={CardsRight} className='top-banner__cards-right' />
    </>
  )
}
