/*
* key - is a number of days, value - percentage of penalty
* */
interface WithdrawalPenaltiesList {
  [key: number]: number
}

export const withdrawalPenalties: WithdrawalPenaltiesList = {
  10: 10,
  20: 5,
  30: 3,
};
