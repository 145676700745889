import React from 'react'
import { DynamicImage, LoadingWrap } from '@/components'
import { UserTierInfo } from '@contracts/hooks/useFlameTier/useFlameTier'
import './TierStatus.scss'

interface Props {
  loading: boolean
  userTierInfo: UserTierInfo | null
}

export const TierStatus = ({ loading, userTierInfo }: Props) => {
  return (
    <div className='tier-status'>
      <LoadingWrap loading={loading}>
        {
          (!!userTierInfo && userTierInfo.tier) ? (
            <>
              <DynamicImage path={userTierInfo.tierImage!} />
              Your FireStarter tier is {' '}
              <span
                style={{ color: userTierInfo.tierColor! }}
                className='fst-italic'
              >
                {userTierInfo.tier}
              </span>
            </>
          ) : (
            <>You don't have a FireStarter tier yet</>
          )
        }
      </LoadingWrap>
    </div>
  )
}
