import { IdlTypes, TypeDef } from '@project-serum/anchor/dist/cjs/program/namespace/types'
import { LockingIdlType } from '@firestarter-private/firestarter-library/lib/hooks/useContracts/idls'
import { WalletAddress } from '@contracts/address'
import BigNumber from 'bignumber.js'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'
import {
  IUseTokenInfoEVM,
  IUseTokenInfoSolana,
} from '@firestarter-private/firestarter-library/lib/hooks/useTokenInfo/types'
import { PublicKey } from '@solana/web3.js'
import { INFTAccounts } from '@contracts/hooks/useSolNFTs/types'
import { TransactionResult } from '@contracts/types'

export type SolStakePoolAccount = TypeDef<LockingIdlType['accounts'][0], IdlTypes<LockingIdlType>>
export type SolTierInfo = TypeDef<LockingIdlType['types'][0], IdlTypes<LockingIdlType>>
export type SolLockEntry = TypeDef<LockingIdlType['accounts'][2], IdlTypes<LockingIdlType>>

export interface PolygonTierInfo {
  apy: string
  power: string
  penalty: string
  lockPeriod: string
  fullPenaltyCliff: string
  penaltyMode: number
  isActive: boolean
}

export interface PolygonLockEntry {
  account: WalletAddress
  amount: string
  stakedAt: string
  unstakedAt: string
  tierIndex: string
}

export type TierInfo = PolygonTierInfo & SolTierInfo
export type LockEntry = PolygonLockEntry & SolLockEntry

export interface LockPeriodInfo {
  apy: number
  power: number
  penalty: number
  lockPeriod: number
  fullPenaltyCliff: number
  penaltyMode: string
  isActive: boolean
  rewardPenalty?: number
  additionalPenalty?: number
  exclusiveRewards?: string[]
  tierIndex: number
}

export interface LockEntryInfo {
  account: WalletAddress
  amount: BigNumber
  rewards: BigNumber
  stakedAt: number
  unstakedAt?: number
  tierIndex: number
  stakeId: string
}

export enum PenaltyModes {
  STATIC = 'STATIC',
  LINEAR = 'LINEAR'
}

export const PENALTY_MODES = [
  PenaltyModes.STATIC,
  PenaltyModes.LINEAR
]

type UnlockEarlyWithHiro =
  ((stakeId: string, hiroId: string, callbacks?: INotifyTxCallbacks) => Promise<TransactionResult>) |
    ((stakeId: string, hiroId: INFTAccounts, callbacks?: INotifyTxCallbacks) => Promise<TransactionResult>)

export interface IUseMultiperiodLocking {
  loadingPeriods: boolean
  loadingEntries: boolean
  lockPeriods?: LockPeriodInfo[]
  lockEntries?: Record<string, LockEntryInfo>
  totalLocked: BigNumber
  flameToken: IUseTokenInfoEVM | IUseTokenInfoSolana
  getPeriods: () => Promise<void>
  getEntries: () => Promise<void>
  getPenalty: (stakeId: string) => Promise<BigNumber> | BigNumber
  getAccumulatedRewards: (lockInfo: LockEntry) => BigNumber
  lock: (amount: string, tierIndex: number, callbacks?: INotifyTxCallbacks) => Promise<TransactionResult>
  unlock: (stakeId: string, callbacks?: INotifyTxCallbacks) => Promise<TransactionResult>
  unlockEarly?: (stakeId: string, callbacks?: INotifyTxCallbacks) => Promise<TransactionResult>
  unlockEarlyWithHiro: UnlockEarlyWithHiro
  hiroAuthority?: PublicKey
}

export type UnlockMethod = IUseMultiperiodLocking['unlock'] |
  IUseMultiperiodLocking['unlockEarly'] |
  IUseMultiperiodLocking['unlockEarlyWithHiro']
