export const FlameReleaseSchedule = process.env.PUBLIC_URL + 'pdf/FLAME_release_schedule.pdf';
export const FS3PagerLink = process.env.PUBLIC_URL + 'pdf/FireStarter_3-pager.pdf';
export const googleFormLink = 'https://forms.gle/Ax68HnGhLS42ZWVX8 ';
export const blockpassScript = 'https://cdn.blockpass.org/widget/scripts/release/3.0.2/blockpass-kyc-connect.prod.js'
export const FSBrandBook = 'https://drive.google.com/file/d/1yhL89Ja9lXcmRdEldtPcQw01jxbdk62I/view'
export const FSGraphicAssets = 'https://drive.google.com/drive/folders/1rrHWpwTBar71wxYZ5JVIWCEyQDSpZs1g'
export const FSPlatformScreenshots = 'https://drive.google.com/drive/folders/1SON-KKeEMf3Kpo5YCA-U_MWHyl7Bn_nH'
export const mobileWebsiteUrl = 'https://www.fuel.firestarter.fi/mobile'
export const QuantstampCertsUrl = 'https://certificate.quantstamp.com/full/firestarter'
export const tiersArticleUrl = 'https://firestarter-fi.medium.com/the-firestarter-tiers-2c2eb6ea1131'
export const blindBoxesTutorialUrl = 'https://www.youtube.com/watch?v=QETQXMupYuk'
