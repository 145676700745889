import './KYCPage.scss'
import { KYCBadge, SEOTags, StaticPageWrapper } from '../../../components'
import { Col, Container, Row } from 'react-bootstrap'
import { useKYC } from '../../../contracts/hooks/useKYC'
import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'
import { seoTags } from '../../../seo-content'

export const KYCPage = () => {
  const { account } = useWeb3React()
  const { getKYCStatus } = useKYC()

  useEffect(() => {
    getKYCStatus()
  }, [account])

  return (
    <StaticPageWrapper title={'KYC'}>
      <SEOTags {...seoTags.kycPage} />
      <section className="kyc-page__caption">
        <Container>
          <p>
            In order to participate in the early funding rounds of FireStarter Projects, you will need to complete our
            KYC process.
          </p>
        </Container>
      </section>
      <section className="kyc-page__requirements">
        <Container>
          <Row className="d-flex justify-content-center align-items-start">
            <Col>
              <h3 className="title">Please make sure you have the following before you begin:</h3>
            </Col>
            <Col>
              <ul>
                <li>
                  <span>Passport or National ID</span>
                </li>
                <li>
                  <span>Proof of address</span>
                </li>
              </ul>
            </Col>
          </Row>
          <KYCBadge />
        </Container>
      </section>
    </StaticPageWrapper>
  )
}
