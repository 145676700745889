import { mainPage } from './pages/main';
import { accountLockup } from './pages/lockup';
import { accountStaking } from './pages/staking';
import { accountPortfolio } from './pages/portfolio';
import { projects } from './pages/projects';
import { aboutUs } from './pages/aboutUs';
import { ido } from './pages/ido';
import { joinNetwork } from './pages/joinNetwork';
import { userAgreement } from './pages/userAgreement';
import { privacy } from './pages/privacy';
import { kycPage } from './pages/kyc';
import { addNetwork } from './pages/addNetwork';
import { pressKit } from './pages/pressKit';
import { faqPage } from './pages/faq';
import { accountLockupNew } from './pages/lockup-new';
import { blindBoxes} from './pages/blind-boxes'

export const titleTemplate = 'FireStarter %s'

export const seoTags = {
  main: mainPage,
  accountLockup,
  accountLockupNew,
  accountStaking,
  accountPortfolio,
  blindBoxes,
  projects,
  aboutUs,
  ido,
  joinNetwork,
  userAgreement,
  privacy,
  kycPage,
  pressKit,
  addNetwork,
  faqPage
}
