import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApprovalSteps,
  BuyFlameButton,
  Divider,
  DynamicImage,
  KYCBadge,
  LoadingWrap,
  LocksTable,
  RoundButton,
  SEOTags,
  Spinner,
} from '@components';
import { seoTags } from '@/seo-content';
import { Alert, Col, Container, FormCheck, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import { useFlameTier } from '@contracts/hooks/useFlameTier/useFlameTier';
import InputIcon from '../../../assets/input-icon.svg';
import {
  balanceToCurrency,
  balanceToNumeric,
  numberToPercentage,
  numericToBalance,
} from '@utils/balanceFormatter';
import BigNumber from 'bignumber.js';
import './LockupMultiperiod.scss'
import { ReactComponent as InfoIcon } from '../../../assets/info-icon.svg';
import { useMultiperiodLocking, UnlockMethod } from '@contracts/hooks/useMultiperiodLocking'
import { addMilliseconds, isFuture } from 'date-fns'
import { formatDateToUTC } from '@utils/dates';
import { isDefined } from '@utils/object';
import classNames from 'classnames';
import { useNFTs } from '@contracts/hooks/useNFTs';
import HiroNFTImage from '@assets/nft/leaders-nft.png'
import { getReadablePeriod } from '@components/Lockup/utils';
import { LockupPeriodOptions } from '@components/Lockup/LockupPeriodOptions/LockupPeriodOptions';
import { useSolNFTs } from '@contracts/hooks/useSolNFTs/useSolNFTs'
import { useTokenInfo, useWalletContext, useApproval } from '@firestarter-private/firestarter-library'
import {
  polygonContractAddresses,
  solanaContractAddresses,
} from '@firestarter-private/firestarter-library/lib/constants'
import { useIsSolana } from '@hooks/useIsSolana'
import { MetadataData } from '@metaplex-foundation/mpl-token-metadata'
import { INFTAccounts } from '@contracts/hooks/useSolNFTs/types'
import { toPubKey } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import { ITransactionError } from '@contracts/types'
import { ERROR_DISMISS_TIMEOUT, getStringTxError } from '@contracts/hooks/utils'

export const LockupMultiperiod = () => {
  const {
    loadingPeriods,
    loadingEntries,
    lockPeriods,
    lockEntries,
    totalLocked,
    getEntries,
    lock,
    unlock,
    unlockEarly,
    unlockEarlyWithHiro,
    getPenalty,
    hiroAuthority,
  } = useMultiperiodLocking()

  const {
    hiroBalance,
    getHiroBalance,
    getIdOfHiroByIndex,
    approveHiro,
  } = useNFTs()

  const {
    fetchMetadata: fetchNFTsMetadata,
    getSolNFTsByAuthority,
    getSolNFTAccounts,
  } = useSolNFTs()

  const {
    allowance,
    onApprove
  } = useApproval(
    polygonContractAddresses.flameToken,
    polygonContractAddresses.multiperiodLocking
  )

  const { account } = useWalletContext()
  const {
    loading: loadingTier,
    userTierInfo,
    getTier,
  } = useFlameTier()

  const [periodIndex, setPeriodIndex] = useState(0)
  const [isLocking, setIsLocking] = useState(false)
  const [isUnlocking, setIsUnlocking] = useState(false)
  const [amountToLock, setAmountToLock] = useState('0')
  const [lockingError, setLockingError] = useState<string | undefined>()
  const [unlockingError, setUnlockingError] = useState<string | undefined>()
  const [activeRecordId, setActiveRecordId] = useState<string | undefined>()
  const [unlockPenalty, setUnlockPenalty] = useState<BigNumber | undefined>()
  const [isBurnHiro, setBurnHiro] = useState(false)
  const [hiroIdToBurn, setHiroId] = useState<string | MetadataData | undefined>()

  const isSolana = useIsSolana()

  const {
    amount: balance,
    decimals,
    getTokenInfo
  } = useTokenInfo(
    isSolana
      ? solanaContractAddresses?.flameToken
      : polygonContractAddresses.flameToken
  )

  const goToUnlock = useCallback(async(stakeId: string) => {
    if (!lockEntries) return
    setUnlockPenalty(undefined)
    setActiveRecordId(stakeId)
    const penalty = await getPenalty(stakeId)
    setUnlockPenalty(penalty)
  }, [lockEntries, getPenalty])

  const goToLock = useCallback(() => {
    setActiveRecordId(undefined)
    setUnlockPenalty(undefined)
    setBurnHiro(false)
  }, [])

  const activeRecord = useMemo(() => {
    if (!lockEntries || !activeRecordId) return
    return lockEntries[activeRecordId]
  }, [lockEntries, activeRecordId])

  const activeRecordTier = useMemo(() => {
    return (activeRecord && lockPeriods) ? lockPeriods[activeRecord.tierIndex] : undefined
  }, [activeRecord, lockPeriods])

  useEffect(() => {
    if (lockPeriods && account && !isLocking && !isUnlocking) {
      getEntries()
      getTokenInfo()
      getTier()
    }
  }, [lockPeriods, account, isLocking, isUnlocking])

  const getLastHiroId = useCallback(async () => {
    if (isSolana) {
      if (!hiroAuthority) return
      const hiros = getSolNFTsByAuthority(hiroAuthority)
      if (!hiros.length) {
        setHiroId(undefined)
        return
      }
      setHiroId(hiros[hiros.length - 1])
      return
    }

    const balance = await getHiroBalance()
    if (!balance) {
      setHiroId(undefined)
      return
    }
    const hiroId = await getIdOfHiroByIndex(balance - 1)
    setHiroId(hiroId)
  }, [getHiroBalance, getIdOfHiroByIndex, isSolana, hiroAuthority, getSolNFTsByAuthority])

  useEffect(() => {
    if (!isUnlocking) {
      if (isSolana) {
        fetchNFTsMetadata()
          .then(() => getLastHiroId())
      } else {
        getLastHiroId()
      }
    }
  }, [isUnlocking, isSolana])

  const setMaxToLock = useCallback(() => {
    setAmountToLock(balanceToNumeric(balance, decimals))
  }, [balance, decimals])

  const disableLocking = useMemo(() => {
    return +amountToLock <= 0
      || isLocking
      || balance.isLessThan(numericToBalance(amountToLock, decimals))
      || (!isSolana && allowance.isLessThan(numericToBalance(amountToLock, decimals)))
      || !isDefined(periodIndex)
      || !lockPeriods
      || !lockPeriods[periodIndex].isActive
  }, [
    isSolana,
    amountToLock,
    isLocking,
    balance,
    allowance,
    decimals,
    periodIndex,
    lockPeriods,
  ])

  const setLockErrorMessage = (error: ITransactionError['error']) => {
    setLockingError(getStringTxError(error))
    setTimeout(() => setLockingError(undefined), ERROR_DISMISS_TIMEOUT)
  }

  const setUnlockErrorMessage = (error: ITransactionError['error']) => {
    setUnlockingError(getStringTxError(error))
    setTimeout(() => setUnlockingError(undefined), ERROR_DISMISS_TIMEOUT)
  }

  const handleLock = useCallback(async () => {
    if (disableLocking) return
    setIsLocking(true)
    const result = await lock(
      amountToLock,
      periodIndex,
      {
        onHash: () => setAmountToLock('0')
      }
    )
    setIsLocking(false)
    if ('error' in result) {
      setLockErrorMessage(result.error)
      return
    }
  }, [
    amountToLock,
    decimals,
    disableLocking,
    lockPeriods,
    periodIndex,
    lock,
  ])

  const disableUnlocking = useMemo(() => {
    return !activeRecord
      || !activeRecordTier
      || isUnlocking
      || !unlockPenalty
      || (activeRecord.unstakedAt ?? 0) > 0
      || loadingEntries
      || (isFuture(activeRecord.stakedAt + activeRecordTier.fullPenaltyCliff) && !isBurnHiro)
      || activeRecord.amount.eq(unlockPenalty)
  }, [
    activeRecord,
    activeRecordTier,
    isBurnHiro,
    isUnlocking,
    unlockPenalty,
    loadingEntries
  ])

  const getUnlockArgs = useCallback(async (isEarlyUnlocking: boolean) => {
    let args: [string, (INFTAccounts | string)?] = [activeRecord!.stakeId]
    if (!isEarlyUnlocking || !isBurnHiro || !hiroIdToBurn || !account) {
      return args
    }

    try {
      if (isSolana) {
        const hiroAccounts = await getSolNFTAccounts(
          toPubKey(account),
          (hiroIdToBurn as MetadataData).mint
        )
        args.push(hiroAccounts)
      } else {
        await approveHiro(
          polygonContractAddresses.multiperiodLocking,
          hiroIdToBurn as string
        )
        args.push(hiroIdToBurn as string)
      }
    } catch (error) {
      return { error }
    }

    return args
  }, [
    activeRecord,
    isBurnHiro,
    hiroIdToBurn,
    account,
    getSolNFTAccounts,
    approveHiro
  ])

  const handleUnlock = useCallback(async () => {
    if (disableUnlocking || !account) return
    setIsUnlocking(true)

    const isEarlyUnlocking = isFuture(addMilliseconds(activeRecord!.stakedAt, activeRecordTier!.lockPeriod))
    let unlockMethod: UnlockMethod = unlock
    const args = await getUnlockArgs(isEarlyUnlocking)
    if ('error' in args) {
      setIsUnlocking(false)
      return
    }

    if (isEarlyUnlocking) {
      unlockMethod = isBurnHiro ? unlockEarlyWithHiro : (unlockEarly ?? unlock)
    }
    // @ts-ignore
    const result = await unlockMethod(...args)
    setIsUnlocking(false)

    if ('error' in result) {
      setUnlockErrorMessage(result.error)
      return
    }
  }, [
    account,
    disableUnlocking,
    activeRecord,
    activeRecordTier,
    getUnlockArgs,
    isBurnHiro,
    unlock,
    unlockEarly,
    unlockEarlyWithHiro
  ])

  return (
    <div className={'lockup-page account-lockup'}>
      <SEOTags {...seoTags.accountLockupNew} />
      <section className='lockup-content'>
        <Container>
          <Row className={'gx-4 gy-4 gx-xl-5 gy-xl-5'}>
            <Col
              xs={{ span: 12 }}
              xl={{ span: 6 }}
            >
              <div className='lockup-content__caption mb-4'>
                <h2 className="title mb-3">
                  Lock your $<span className="purple-text">FLAME</span>. <br/> Earn APY. <br/> Get Access to IDO’s.
                </h2>
                <div className="bottom-description text-wide">
                  The amount of allocation will depend on the amount locked.
                </div>
              </div>
              <div className='d-flex mb-4'>
                <BuyFlameButton/>
              </div>
              <div className='tile lockup-content__locks lockup-locks'>
                <h2 className='title mb-2 mb-md-5'>My Locks</h2>
                <LocksTable
                  lockPeriods={lockPeriods}
                  lockEntries={lockEntries}
                  loading={loadingEntries}
                  onSelect={goToUnlock}
                />
              </div>
            </Col>
            <Col
              xs={{ span: 12 }}
              xl={{ span: 6 }}
            >
              <div className={classNames(
                'tile lockup-form',
                {
                  'hidden': !!activeRecord
                }
              )}>
                <h2 className='title mb-4'>Lock</h2>
                <div className='lockup-form__tier'>
                  <LoadingWrap loading={loadingTier}>
                    {
                      (!!userTierInfo && userTierInfo.tier) ? (
                        <>
                          <DynamicImage path={userTierInfo.tierImage!} />
                          Your FireStarter tier is {' '}
                          <span
                            style={{ color: userTierInfo.tierColor! }}
                            className='fst-italic'
                          >
                            {userTierInfo.tier}
                          </span>
                        </>
                      ) : (
                        <>You don't have a FireStarter tier yet</>
                      )
                    }
                  </LoadingWrap>
                </div>
                <Divider />
                <div className='lockup-form__balances'>
                  <dl className='info-list'>
                    <div>
                      <dt className='name'>Available to lock</dt>
                      <dd className='value'>{balanceToCurrency(balance, decimals)} <small>FLAME</small></dd>
                    </div>
                    <div>
                      <dt className='name'>Amount locked</dt>
                      <dd className='value'>
                        <LoadingWrap loading={loadingEntries}>
                          {balanceToCurrency(totalLocked, decimals)} <small>FLAME</small>
                        </LoadingWrap>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className='lockup-form__controls'>
                  <InputGroup className="lockup-form__input-group">
                    <InputGroup.Prepend>
                      <Image
                        src={InputIcon}
                        width={32}
                        height={32}
                        roundedCircle
                        className='lockup-form__input-icon'
                      />
                    </InputGroup.Prepend>
                    <FormControl
                      className="lockup-form__input"
                      placeholder="0.0"
                      type="number"
                      value={amountToLock}
                      onChange={(e) => setAmountToLock(e.target.value)}
                    />
                    <InputGroup.Append>
                      <RoundButton
                        size="small"
                        color="DARK"
                        onClick={setMaxToLock}
                      >
                        MAX
                      </RoundButton>
                    </InputGroup.Append>
                  </InputGroup>
                  <h5 className='white-transparent-text text-wide my-4'>
                    Locking period
                  </h5>
                  <div className='lockup-form__options mt-2 mb-5'>
                    <LoadingWrap loading={loadingPeriods}>
                      {
                        !!lockPeriods?.length && (
                          <LockupPeriodOptions
                            lockPeriods={lockPeriods}
                            value={periodIndex}
                            onChange={setPeriodIndex}
                          />
                        )
                      }
                    </LoadingWrap>
                  </div>
                  <div className="lockup-form__buttons">
                    {
                      !isSolana && (
                        <RoundButton
                          size="large"
                          disabled={allowance.isGreaterThanOrEqualTo(numericToBalance(amountToLock, decimals))}
                          onClick={() => onApprove()}
                        >
                          Approve
                        </RoundButton>
                      )
                    }
                    <RoundButton
                      size="large"
                      disabled={disableLocking}
                      onClick={handleLock}
                    >
                      {isLocking ? <Spinner /> : 'LOCK'}
                    </RoundButton>
                  </div>
                  {
                    !isSolana && (
                      <ApprovalSteps fillingCondition={allowance.isGreaterThanOrEqualTo(numericToBalance(amountToLock, decimals))} />
                    )
                  }
                  <div
                    className={classNames(
                      'form-message form-message--warning text-center mt-3',
                      { hidden: !lockingError }
                    )}
                  >
                    <InfoIcon />
                    <span>{lockingError}</span>
                  </div>
                </div>
              </div>
              <div className={classNames(
                'tile lockup-form lockup-form--unlock',
                {
                  'hidden': !activeRecord
                }
              )}>
                <button onClick={goToLock} disabled={isUnlocking} className={'btn-close btn-close-white'} />
                <h2 className='title mb-4'>Unlock</h2>
                <div className='lockup-form__tier'>
                  <LoadingWrap loading={loadingTier}>
                    {
                      (!!userTierInfo && userTierInfo.tier) ? (
                        <>
                          <DynamicImage path={userTierInfo.tierImage!} />
                          Your FireStarter tier is {' '}
                          <span
                            style={{ color: userTierInfo.tierColor! }}
                            className='fst-italic'
                          >
                            {userTierInfo.tier}
                          </span>
                        </>
                      ) : (
                        <>You don't have a FireStarter tier yet</>
                      )
                    }
                  </LoadingWrap>
                </div>
                <Divider />
                <LoadingWrap loading={!unlockPenalty}>
                  <div className='lockup-form__balances'>
                    <dl className='info-list'>
                      <div>
                        <dt className='name'>Locked amount</dt>
                        <dd className='value'>{activeRecord ? balanceToCurrency(activeRecord.amount, decimals) : '--'} <small>FLAME</small></dd>
                      </div>
                      <div>
                        <dt className='name'>APY</dt>
                        <dd className='value'>{activeRecordTier ? numberToPercentage(activeRecordTier.apy) : '--'}</dd>
                      </div>
                      <div>
                        <dt className='name'>Accumulated Rewards</dt>
                        <dd className='value'>{activeRecord ? balanceToCurrency(activeRecord.rewards, decimals) : '--'} <small>FLAME</small></dd>
                      </div>
                      <div>
                        <dt className='name'>Lock Period</dt>
                        <dd className='value'>{activeRecordTier ? getReadablePeriod(activeRecordTier.lockPeriod) : '--'}</dd>
                      </div>
                      <div>
                        <dt className='name'>Locked at</dt>
                        <dd className='value'>{activeRecord ? formatDateToUTC(activeRecord.stakedAt) : '--'}</dd>
                      </div>
                    </dl>
                  </div>
                  <div className='lockup-form__controls mt-3'>
                    <InputGroup className="lockup-form__input-group">
                      <InputGroup.Prepend>
                        <Image
                          src={InputIcon}
                          width={32}
                          height={32}
                          roundedCircle
                          className='lockup-form__input-icon'
                        />
                      </InputGroup.Prepend>
                      <FormControl
                        className="lockup-form__input"
                        placeholder="0.0"
                        readOnly
                        type="number"
                        value={activeRecord ? balanceToNumeric(activeRecord?.amount, decimals) : ''}
                        onChange={(e) => setAmountToLock(e.target.value)}
                      />
                    </InputGroup>
                    {
                      !!+hiroBalance && !activeRecord?.unstakedAt && (
                        <div className='my-3 p-4 lockup-form__use-hiro'>
                          <Image src={HiroNFTImage} roundedCircle height={48} width={48} title="HIRO" />
                          <span>Use <b className='purple-text'>HIRO #{hiroIdToBurn}</b> to unlock without penalties</span>
                          <FormCheck
                            type="switch"
                            id="burn-hiro-switch"
                            checked={isBurnHiro}
                            onChange={() => setBurnHiro(!isBurnHiro)}
                          />
                        </div>
                      )
                    }
                    {
                      (!!unlockPenalty && !unlockPenalty.isZero() && !isBurnHiro && !activeRecord?.unstakedAt) && (
                        <Alert bsPrefix={'custom-alert'} variant={'dark'} className="mt-3 text-center">
                          The penalty for early unlocking is <b>{balanceToCurrency(unlockPenalty, decimals)} FLAME</b>
                        </Alert>
                      )
                    }
                    <div className="lockup-form__buttons mt-5">
                      <RoundButton
                        size="large"
                        disabled={disableUnlocking}
                        onClick={handleUnlock}
                      >
                        {isUnlocking ? <Spinner /> : 'Unlock'}
                      </RoundButton>
                      <RoundButton
                        size="large"
                        color="TRANSPARENT"
                        disabled={isUnlocking}
                        onClick={goToLock}
                      >
                        Back To Lock
                      </RoundButton>
                    </div>

                    <div
                      className={classNames(
                        'form-message form-message--warning text-center mt-3',
                        { hidden: !unlockingError }
                      )}
                    >
                      <InfoIcon />
                      <span>{unlockingError}</span>
                    </div>
                  </div>
                </LoadingWrap>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='kyc-section mt-5'>
        <Container>
          <KYCBadge />
        </Container>
      </section>
    </div>
  )
}
