import Spaceman from '@assets/lottery-banner/spaceman.png'
import Ufo from '@assets/lottery-banner/ufo.png'
import React, { useRef } from 'react'
import { Container, Image } from 'react-bootstrap'
import './LotteryBanner.scss'
import { RoundButton } from '@components/common'
import { ReactComponent as LinkArrow } from '@assets/right_arrow.svg'
import { RoutesHashes } from '@/router/constants'
interface Props {
  children?: React.ReactNode
  link?: string
  buttonText?: string
}

export const LotteryBanner = ({
  children,
  link,
  buttonText = 'Get Started',
}: Props) => {
  const spacemanRef = useRef<HTMLImageElement | null>(null)
  const ufoRef = useRef<HTMLImageElement | null>(null)

  return (
    <div className="lottery-banner">
      <Container>
        <div className="lottery-banner__card">
          <div className="lottery-banner__inner">
            <div className="lottery-banner__contents">
              {children}
            </div>
            {link && (
              <RoundButton
                color="DARK"
                to={{
                  pathname: link,
                  hash: RoutesHashes.LOOT_BOXES.LOTTERY_BLOCK,
                }}
                className="lottery-banner__link"
              >
                {buttonText}
                <LinkArrow />
              </RoundButton>
            )}
          </div>

          <Image
            ref={spacemanRef}
            src={Spaceman}
            className="lottery-banner__spaceman"
          />

          <Image ref={ufoRef} src={Ufo} className="lottery-banner__ufo" />
        </div>
      </Container>
    </div>
  )
}
