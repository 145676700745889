import React, { createContext, useReducer } from 'react'
import { MetadataData } from '@metaplex-foundation/mpl-token-metadata'
import { IURIMetadata } from '@contracts/hooks/useSolNFTs/types'

export interface ISolNFTContext {
  fetchingNFTs: boolean
  userNFTs?: Record<string, MetadataData[]>
  diamondNFTs?: MetadataData[]
  URIMetadataDict: Record<string, IURIMetadata>
}

export enum SolNFTActionType {
  SET_FETCHIN_NFTS,
  SET_USER_NFTS,
  SET_DIAMOND_NFTS,
  SET_URI_METADATA
}

interface SetFetchingNFTs {
  type: SolNFTActionType.SET_FETCHIN_NFTS,
  payload: boolean
}

interface SetUserNFTs {
  type: SolNFTActionType.SET_USER_NFTS,
  payload: ISolNFTContext['userNFTs']
}

interface SetDiamondNFTs {
  type: SolNFTActionType.SET_DIAMOND_NFTS,
  payload: ISolNFTContext['diamondNFTs']
}

interface SetURIMetadata {
  type: SolNFTActionType.SET_URI_METADATA,
  payload: ISolNFTContext['URIMetadataDict']
}

type SolNFTAction = SetUserNFTs | SetURIMetadata | SetFetchingNFTs |SetDiamondNFTs

const initialState: ISolNFTContext = {
  fetchingNFTs: false,
  userNFTs: undefined,
  diamondNFTs: undefined,
  URIMetadataDict: {}
}
export const SolNFTContext = createContext<{
  state: ISolNFTContext,
  dispatch: React.Dispatch<SolNFTAction>
}>({
  state: initialState,
  dispatch: () => undefined
})

const solNFTReducer = (state: ISolNFTContext, action: SolNFTAction): ISolNFTContext => {
  switch (action.type) {
    case SolNFTActionType.SET_FETCHIN_NFTS: {
      return {
        ...state,
        fetchingNFTs: action.payload
      }
    }
    case SolNFTActionType.SET_USER_NFTS:
      return {
        ...state,
        userNFTs: action.payload
      }
    case SolNFTActionType.SET_DIAMOND_NFTS:
      return {
        ...state,
        diamondNFTs: action.payload
      }
    case SolNFTActionType.SET_URI_METADATA:
      return {
        ...state,
        URIMetadataDict: {
          ...state.URIMetadataDict,
          ...action.payload
        }
      }
  }
}
export const SolNFTProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(solNFTReducer, initialState)

  return (
    <SolNFTContext.Provider value={{ state, dispatch }}>
      {children}
    </SolNFTContext.Provider>
  )
}
