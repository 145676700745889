import {
  ProjectStatusesProps,
  ProjectStatusProp,
  ProjectType,
  ProjectTypes,
} from '@components/Project/types'
import { numberToCurrency } from '@utils/balanceFormatter'
import React, { useCallback, useMemo } from 'react'
import { Divider } from '@components'
import { ProjectProgress } from '@components/Project/ProjectStats/ProjectProgress'
import {
  getBaseAllocationString,
  getParticipantsString,
  getTokenPriceString,
  getFundraiseGoalString,
} from '@components/Project/utils'

interface Props {
  fundTokenName: string
  rewardTokenName: string
  projectType: ProjectType
  totalAmount?: number
  fundsSwapped?: number
  exchangeRate?: number
  totalRewards?: number
  participants?: number
  baseAllocation?: number
  status: ProjectStatusProp
}

export const ProjectStatsValues = React.memo(({
  fundTokenName,
  rewardTokenName,
  projectType,
  totalAmount,
  fundsSwapped,
  exchangeRate,
  totalRewards,
  participants,
  baseAllocation,
  status,
}: Props) => {
  const renderListItems = useCallback(() => {
    if (projectType === ProjectTypes.token_airdrop) {
      return (
        <AirdropProjectList
          rewardTokenName={rewardTokenName}
          totalRewards={totalRewards}
        />
      )
    }

    switch (status) {
      case ProjectStatusesProps.Closed:
        return <ClosedProjectList
          fundsSwapped={fundsSwapped}
          totalAmount={totalAmount}
          fundTokenName={fundTokenName}
          rewardTokenName={rewardTokenName}
          projectType={projectType}
          exchangeRate={exchangeRate}
          participants={participants}
          baseAllocation={baseAllocation}
        />
      case ProjectStatusesProps['Guaranteed Live']:
      case ProjectStatusesProps['FCFS Coming']:
      case ProjectStatusesProps['FCFS Live']:
        return <ActiveProjectList
          fundTokenName={fundTokenName}
          rewardTokenName={rewardTokenName}
          projectType={projectType}
          fundsSwapped={fundsSwapped}
          totalAmount={totalAmount}
          totalRewards={totalRewards}
          participants={participants}
          exchangeRate={exchangeRate}
        />
      default:
        return <ComingProjectList
          fundTokenName={fundTokenName}
          rewardTokenName={rewardTokenName}
          projectType={projectType}
          exchangeRate={exchangeRate}
          totalAmount={totalAmount}
          baseAllocation={baseAllocation}
        />
    }
  }, [
    fundTokenName,
    rewardTokenName,
    projectType,
    totalAmount,
    fundsSwapped,
    exchangeRate,
    totalRewards,
    participants,
    baseAllocation,
    status,
  ])

  return (
    <div className="project-stats__values">
      <dl className="project-stats__list info-list">
        {renderListItems()}
      </dl>
    </div>
  )
})

const AirdropProjectList = React.memo(({
  totalRewards,
  rewardTokenName,
}: Pick<Props, 'totalRewards' | 'rewardTokenName'>) => {
  return (
    <div>
      <dt className="name">Airdrop Amount</dt>
      <dd className="value">{getFundraiseGoalString(rewardTokenName, totalRewards)}</dd>
    </div>
  )
})

const ComingProjectList = React.memo(({
  totalAmount,
  fundTokenName,
  rewardTokenName,
  projectType,
  baseAllocation,
  exchangeRate
}: Pick<Props, 'totalAmount' | 'baseAllocation' | 'exchangeRate' | 'fundTokenName' | 'rewardTokenName' | 'projectType'>) => {
  return (
    <>
      <div>
        <dt className="name">Fundraise Goal</dt>
        <dd className="value">
          {getFundraiseGoalString(fundTokenName, totalAmount)}
        </dd>
      </div>
      <Divider />
      {!!baseAllocation && (
        <div>
          <dt className="name">Base allocation</dt>
          <dd className='value'>{numberToCurrency(baseAllocation, 0)}</dd>
        </div>
      )}
      <div>
        <dt className="name">{projectType === ProjectTypes.nodes_sale ? 'Node price' : 'Price per token'}</dt>
        <dd className="value">
          {getTokenPriceString(fundTokenName, rewardTokenName, exchangeRate)}
        </dd>
      </div>
    </>
  )
})

const ActiveProjectList = React.memo(({
  fundTokenName,
  rewardTokenName,
  projectType,
  fundsSwapped,
  totalAmount,
  participants,
  totalRewards,
  exchangeRate,
}: Omit<Props, 'baseAllocation' | 'status'>) => {
  return (
    <>
      <span className="name">Total funds swapped</span>
      <div>
        <ProjectProgress
          fundTokenName={fundTokenName}
          fundsSwapped={fundsSwapped}
          totalAmount={totalAmount}
        />
      </div>
      <div>
        <dt className="name">Participants</dt>
        <dd className='value'>{getParticipantsString(participants)}</dd>
      </div>
      <div>
        <dt className="name">{projectType === ProjectTypes.nodes_sale ? 'Total nodes amount' : 'Total swap amount'}</dt>
        <dd className='value'>
          {getFundraiseGoalString(rewardTokenName, totalRewards)}
        </dd>
      </div>
      <div>
        <dt className="name">{projectType === ProjectTypes.nodes_sale ? 'Node price' : 'Swap rate'}</dt>
        <dd className="value">
          {getTokenPriceString(fundTokenName, rewardTokenName, exchangeRate)}
        </dd>
      </div>
    </>
  )
})

const ClosedProjectList = React.memo(({
  fundTokenName,
  rewardTokenName,
  projectType,
  fundsSwapped,
  totalAmount,
  participants,
  exchangeRate,
  baseAllocation,
}: Omit<Props, 'totalRewards' | 'status'>) => {
  return (
    <>
      <span className="name">Total funds swapped</span>
      <div>
        <ProjectProgress
          fundTokenName={fundTokenName}
          fundsSwapped={fundsSwapped}
          totalAmount={totalAmount}
        />
      </div>
      <div>
        <dt className="name">Participants</dt>
        <dd className='value'>{getParticipantsString(participants)}</dd>
      </div>
      <div>
        <dt className="name">{projectType === ProjectTypes.nodes_sale ? 'Node price' : 'Price per token'}</dt>
        <dd className="value">{getTokenPriceString(fundTokenName, rewardTokenName, exchangeRate)}</dd>
      </div>
      <div>
        <dt className="name">Base allocation</dt>
        <dd className='value'>{getBaseAllocationString(baseAllocation)}</dd>
      </div>
    </>
  )
})
