import { FormControl, FormControlProps } from 'react-bootstrap'
import { RoundButton } from '@/components'
import { useCallback, useMemo } from 'react'
import './IncrementInput.scss'

interface Props extends Omit<FormControlProps, 'value' | 'onChange'> {
  min?: number
  max?: number
  step?: number
  value: number
  onChange: (value: number) => void
}

export const IncrementInput = ({ value, onChange, min, max, step, ...rest }: Props) => {
  const decrement = useCallback(() => {
    onChange(value - (step ?? 1))
  }, [value, onChange])

  const increment = useCallback(() => {
    onChange(value + (step ?? 1))
  }, [value, onChange])

  const disableDecrement = useMemo(() => min !== undefined && (value === min), [value, min])
  const disableIncrement = useMemo(() => !!max && (value === max), [value, max])

  return <div className="increment-input">
    <RoundButton
      color="LIGHT"
      disabled={disableDecrement}
      onClick={decrement}
    >
      -
    </RoundButton>
    <FormControl
      type="number"
      readOnly
      value={value}
      {...rest}
    />
    <RoundButton
      color="LIGHT"
      disabled={disableIncrement}
      onClick={increment}
    >
      +
    </RoundButton>
  </div>
}
