import React, { useEffect, useRef } from 'react'
import './BlindBoxes.scss'
import {
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { RoutesHashes, RoutesPaths } from '@router/constants'
import { Container } from 'react-bootstrap'
import { BlindBoxesLottery } from '@pages/BlindBoxes/BlindBoxesLottery'
import { BlindBoxesClaiming } from '@pages/BlindBoxes/BlindBoxesClaiming'
import {
  LotteryBannerSection,
  NonPolygonWidget,
  SEOTags, SlidingNav,
} from '@components'
import { BlindBoxesReferral } from './BlindBoxesReferral'
import { BlindBoxNFTs } from '@pages/BlindBoxes/BlindBoxNFTs'
import { seoTags } from '@/seo-content'
import { useNetwork } from '@firestarter-private/firestarter-library'
import { isEthNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { getCurrentNetworkId } from '@contracts/networks'

export const BlindBoxes = () => {
  const { path } = useRouteMatch()
  const { isDefaultEVMNetworkSelected } = useNetwork()

  const scrollRef = useRef<HTMLDivElement | null>(null)

  const { hash } = useLocation()
  useEffect(() => {
    // Scroll to the element with the corresponding ID
    if (
      scrollRef &&
      scrollRef.current &&
      hash.includes(RoutesHashes.LOOT_BOXES.LOTTERY_BLOCK)
    ) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollRef, hash])

  return (
    <div className="blind-boxes-page page">
      <SEOTags {...seoTags.blindBoxes} />
      {isEthNetwork(getCurrentNetworkId()) &&
        <LotteryBannerSection buttonText="PURCHASE A BOX" />
      }
      <Container ref={scrollRef}>
        <SlidingNav
          defaultActiveKey={path}
          items={[
            {
              to: `${RoutesPaths.LOOT_BOXES.LOTTERY}#${RoutesHashes.LOOT_BOXES.LOTTERY_BLOCK}`,
              label: 'Lottery'
            },
            {
              to: `${RoutesPaths.LOOT_BOXES.CLAIMING}#${RoutesHashes.LOOT_BOXES.LOTTERY_BLOCK}`,
              label: 'Claiming'
            },
            {
              to: `${RoutesPaths.LOOT_BOXES.REFERRAL}#${RoutesHashes.LOOT_BOXES.LOTTERY_BLOCK}`,
              label: 'My Referral'
            },
            {
              to: `${RoutesPaths.LOOT_BOXES.NFTS}#${RoutesHashes.LOOT_BOXES.LOTTERY_BLOCK}`,
              label: 'My NFTs'
            },
          ]}
        />
      </Container>
      {isDefaultEVMNetworkSelected ? (
        <Switch>
          <Route
            exact
            path={RoutesPaths.LOOT_BOXES.LOTTERY}
            component={BlindBoxesLottery}
          />
          <Route
            exact
            path={RoutesPaths.LOOT_BOXES.CLAIMING}
            component={BlindBoxesClaiming}
          />
          <Route
            exact
            path={RoutesPaths.LOOT_BOXES.REFERRAL}
            component={BlindBoxesReferral}
          />
          <Route
            exact
            path={RoutesPaths.LOOT_BOXES.NFTS}
            component={BlindBoxNFTs}
          />
        </Switch>
      ) : (
        <NonPolygonWidget featureName="Blind Box Lottery" />
      )}
    </div>
  )
}
