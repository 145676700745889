import { SECOND } from '@constants'
import {
  LockEntry,
  PENALTY_MODES,
  TierInfo,
  LockEntryInfo,
  LockPeriodInfo,
} from '@contracts/hooks/useMultiperiodLocking/types'
import { isDefined } from '@firestarter-private/firestarter-library/lib/utils/object'
import { BNToBigNumber, BNToNumber, toBigNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { PublicKey } from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import { PERCENTAGE_PRECISION } from '@firestarter-private/firestarter-library/lib/constants'

const additionalPeriodInfos: Record<string, Pick<LockPeriodInfo, 'additionalPenalty' | 'rewardPenalty' | 'exclusiveRewards'>> = {
  '2592000': { // 30 days
    exclusiveRewards: [
      'In the event you own a Hiro, you can burn for 0 penalty to reward and principle'
    ],
  },
  '15552000': { // 180 days
    rewardPenalty: 1,
    exclusiveRewards: [
      'In the event you own a Hiro, you can burn for 0 penalty to reward and principle'
    ],
  },
  '31536000': { // 1 year
    rewardPenalty: 1,
    additionalPenalty: 1,
    exclusiveRewards: [
      'In the event you own a Hiro, you can burn for 0 penalty to reward and principle'
    ],
  },
  '94608000': { // 3 years
    rewardPenalty: 1,
    additionalPenalty: 1,
    exclusiveRewards: [
      'Exclusive Pools For  >5000 FLAME POWER (we can use these for smaller allocation launches <$100K Raises)',
      'Guaranteed Allocation to Exclusive Pools (Lava Pits)',
      'No Cooldown',
      'In the event you own a Hiro, you can burn for 0 penalty to reward and principle',
    ]
  },
}

export function mapPeriodResponse(period: TierInfo, index: number): LockPeriodInfo {
  const power = isDefined(period.multiplier)
    ? period.multiplier
    : +period.power / PERCENTAGE_PRECISION

  const penalty = (
    isDefined(period.earlyUnlockPrincipalPenalty)
      ? period.earlyUnlockPrincipalPenalty
      : +period.penalty
  ) / PERCENTAGE_PRECISION

  const lockPeriodSeconds = typeof period.lockPeriod === 'string'
    ? +period.lockPeriod
    : BNToNumber(period.lockPeriod, 0)

  const fullPenaltyCliff = (
    typeof period.fullPenaltyCliff === 'string'
      ? +period.fullPenaltyCliff
      : BNToNumber(period.fullPenaltyCliff, 0)
  ) * SECOND

  const penaltyMode = typeof period.penaltyMode === 'number'
    ? PENALTY_MODES[period.penaltyMode]
    : Object.keys(period.penaltyMode)[0].toUpperCase()

  const rewardPenalty = isDefined(period.earlyUnlockRewardPenalty)
    ? period.earlyUnlockRewardPenalty / PERCENTAGE_PRECISION
    : additionalPeriodInfos[lockPeriodSeconds]?.rewardPenalty

  return {
    apy: +period.apy / PERCENTAGE_PRECISION,
    power,
    penalty,
    lockPeriod: lockPeriodSeconds * SECOND,
    fullPenaltyCliff,
    penaltyMode,
    isActive: period.isActive,
    rewardPenalty,
    additionalPenalty: additionalPeriodInfos[lockPeriodSeconds]?.additionalPenalty,
    exclusiveRewards: additionalPeriodInfos[lockPeriodSeconds]?.exclusiveRewards,
    tierIndex: index
  }
}

export function mapEntryResponse(
  entry: LockEntry,
  rewards: BigNumber,
  stakeId: string,
  account?: PublicKey,
): LockEntryInfo {

  return {
    account: entry.account || account!.toString(),
    amount: entry.amount ? toBigNumber(entry.amount) : BNToBigNumber(entry.amountStaked),
    rewards,
    stakedAt: (entry.stakedAt ? +entry.stakedAt : BNToNumber(entry.stakeTimestamp, 0)) * SECOND,
    unstakedAt: isDefined(entry.unstakedAt) ? +entry.unstakedAt * SECOND : undefined,
    tierIndex: +entry.tierIndex,
    stakeId,
  }
}
