import { ChainvineClient, getReferrerId, storeReferrerId } from '@chainvine/sdk'
import { useEffect, useMemo } from 'react'
import { useStorages } from './useStorages'
export const CHAINVINE_API_KEY = process.env.REACT_APP_CHAINVINE_API_KEY
export const CHAINVINE_CAMPAIGN_ID = process.env.REACT_APP_CHAINVINE_CAMPAIGN_ID

export const useChainVineInit = () => {
  const { appStorage } = useStorages()
  const query = useMemo(
    () => Object.fromEntries(new URLSearchParams(window.location.search)),
    [window.location.search],
  )
  useEffect(() => {
    storeReferrerId()

    if (query.referrer_id && !appStorage.linkClickedEvent) {
      const client = new ChainvineClient({
        apiKey: CHAINVINE_API_KEY,
      })

      const referrerId = getReferrerId()
      client
        .recordClick(referrerId, CHAINVINE_CAMPAIGN_ID)
        .then(() => (appStorage.linkClickedEvent = true))
    }
  }, [appStorage.linkClickedEvent, query.referrer_id])
}
