import BigNumber from 'bignumber.js'
import {
  ApprovalSteps,
  CommonTooltip,
  LoadingWrap,
  BalanceItem,
  RoundButton,
} from '@components'
import { stakingTooltips } from '@pages/Account/Staking/tooltips'
import {
  balanceToCurrency,
  balanceToNumber,
  numberToCurrency,
  numericToBalance,
} from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import React from 'react'
import { FormControl, Image, InputGroup } from 'react-bootstrap'
import FlameUSDCToken from '@assets/flame-usdc-lp.svg'
import FlameUSDTToken from '@assets/flame-usdt-lp.svg'
import { buyFlameUSDCLpLink, buyFlameUSDTLpLinkSol } from '@constants'
import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { defaultEVMNetworkId } from '@firestarter-private/firestarter-library/lib/constants'
import { StakingStats } from '@contracts/hooks/useStaking'
import { getCurrentNetworkId } from '@contracts/networks'

export function MyStakesComponent(props: {
  readableTimeSinceLastStake?: string
  currentPenalty: BigNumber
  flameDecimals: number
}) {
  return (
    <div className="my-stakes tile">
      <h1>My stakes</h1>
      <dl className="info-list">
        <div className="info-list__item top-item">
          <dt className="name">
            Last Stake
            <CommonTooltip id="apy-tooltip" placement="bottom-start">
              {stakingTooltips.lastStake}
            </CommonTooltip>
          </dt>
          <dd className="value">
            {
              props.readableTimeSinceLastStake
                ? `${props.readableTimeSinceLastStake} ago`
                : '--'
            }
          </dd>
        </div>
        <div className="info-list__item bottom-item">
          <dt className="name">
            Current Penalty
            <CommonTooltip id="apy-tooltip" placement="bottom-start">
              {stakingTooltips.currentPenalty}
            </CommonTooltip>
          </dt>
          <dd className="value">
            {balanceToCurrency(props.currentPenalty, props.flameDecimals)}
          </dd>
        </div>
      </dl>
    </div>
  )
}

export function BalancesComponent(props: {
  apy: number
  staked: BigNumber
  lpDecimals: number
  rewards: BigNumber
  flameDecimals: number
}) {
  return (
    <div className="balances tile">
      <BalanceItem
        image="/token-logos/percent.svg"
        title="APY"
        balance={props.apy.toString()}
        token="%"
        tooltipText={stakingTooltips.APY}
        className="top-item"
      />
      <BalanceItem
        image="/token-logos/locked.svg"
        title="Amount staked"
        balance={balanceToCurrency(props.staked, props.lpDecimals)}
        token="QS LP"
        className="center-item"
      />
      <BalanceItem
        image="/token-logos/FLAME.svg"
        title="Current rewards"
        balance={balanceToCurrency(props.rewards, props.flameDecimals)}
        token="FLAME"
        tooltipText={stakingTooltips.currentRewards}
        className="bottom-item"
      />
    </div>
  )
}

export function StakingTabComponent(props: {
  lpBalance: BigNumber
  lpDecimals: number
  amountToStake: string
  setAmountToStake: (e: any) => void
  disableStaking: boolean
  setMaxToStake: () => void
  estimatedRewards: number
  currentNetworkId: string | undefined
  allowance: BigNumber
  handleApprove: () => Promise<void>
  handleStake: () => Promise<void>
}) {
  const isSolana = isSolanaNetwork(props.currentNetworkId || getCurrentNetworkId())
  const buyLpLink = isSolana ? buyFlameUSDTLpLinkSol : buyFlameUSDCLpLink

  return (
    <>
      <div className="stake-form__heading">
        <Image
          src={
            isSolana
              ? FlameUSDTToken
              : FlameUSDCToken
          }
        />
        <div>
          <p className="get-the-token">
            <span className="stake-form__heading__title">
              {isSolana ? 'FLAME-USDT LP' : 'FLAME-USDC QS LP'}{' '}
            </span>
            <div>
              <RoundButton
                href={buyLpLink}
                disabled={!buyLpLink}
                size="small"
                color="DARK"
              >
                Get the token <LinkArrow />
              </RoundButton>
              <CommonTooltip id="lp-token-tooltip">
                {isSolana ? stakingTooltips.getTokenLinkSol : stakingTooltips.getTokenLink}
              </CommonTooltip>
            </div>
          </p>
        </div>
      </div>
      <p className="info-list">
        <span className="name">Available to stake: </span>
        <span className="value">
          {balanceToCurrency(props.lpBalance, props.lpDecimals)}
        </span>
      </p>
      <InputGroup className="stake-form__input-group input-group-big">
        <FormControl
          placeholder="0.00"
          type="number"
          inputMode="numeric"
          value={props.amountToStake}
          onChange={props.setAmountToStake}
          isInvalid={props.disableStaking}
          isValid={!props.disableStaking}
        />
        <InputGroup.Append>
          <RoundButton size="small" color="DARK" onClick={props.setMaxToStake}>
            MAX
          </RoundButton>
        </InputGroup.Append>
      </InputGroup>
      <BalanceItem
        image="/token-logos/FLAME.svg"
        title="Your Estimated Rewards"
        balance={numberToCurrency(props.estimatedRewards)}
        token="FLAME"
        tooltipText={stakingTooltips.estimatedRewards}
        className="estimated-rewards"
      />
      <div className="stake-block__buttons">
        {!isSolanaNetwork(props.currentNetworkId || defaultEVMNetworkId) && (
          <RoundButton
            size="large"
            disabled={props.allowance.isGreaterThanOrEqualTo(
              numericToBalance(props.amountToStake, props.lpDecimals),
            )}
            onClick={props.handleApprove}
          >
            Approve
          </RoundButton>
        )}
        <RoundButton
          size="large"
          disabled={props.disableStaking}
          onClick={props.handleStake}
        >
          Stake
        </RoundButton>
      </div>
      {!isSolanaNetwork(props.currentNetworkId || defaultEVMNetworkId) && (
        <ApprovalSteps
          fillingCondition={props.allowance.isGreaterThanOrEqualTo(
            numericToBalance(props.amountToStake, props.lpDecimals),
          )}
        />
      )}
    </>
  )
}

export function UnstakeTabComponent(props: {
  amountToUnstake: string
  setAmountToUnstake: (e: any) => void
  disableUnstaking: boolean
  setMaxToUnstake: () => void
  currentPenalty: BigNumber
  flameDecimals: number
  rewards: BigNumber
  handleClaim: () => Promise<void>
  handleUnstake: () => Promise<void>
}) {
  return (
    <>
      <div className="stake-form__heading">
        <Image src={FlameUSDCToken} />
        <div>
          <p className="get-the-token">
            <span className="stake-form__heading__title">
              FLAME-USDC QS LP{' '}
            </span>
            <div>
              <RoundButton
                href={buyFlameUSDCLpLink}
                disabled={!buyFlameUSDCLpLink}
                size="small"
                color="DARK"
              >
                Get the token <LinkArrow />
              </RoundButton>
              <CommonTooltip id="lp-token-tooltip">
                {stakingTooltips.getTokenLink}
              </CommonTooltip>
            </div>
          </p>
        </div>
      </div>
      <InputGroup className="stake-form__input-group input-group-big">
        <FormControl
          placeholder="0.0"
          type="number"
          inputMode="numeric"
          value={props.amountToUnstake}
          onChange={props.setAmountToUnstake}
          isInvalid={props.disableUnstaking}
          isValid={!props.disableUnstaking}
        />
        <InputGroup.Append>
          <RoundButton
            size="small"
            color="DARK"
            onClick={props.setMaxToUnstake}
          >
            MAX
          </RoundButton>
        </InputGroup.Append>
      </InputGroup>
      {!!balanceToNumber(props.currentPenalty, props.flameDecimals) && (
        <div className="form-message form-message--warning text-center">
          <span>
            The 50% of rewards will be lost if you staked less then 90 days ago
          </span>
        </div>
      )}
      <div className="stake-block__buttons">
        <RoundButton
          size="large"
          disabled={!balanceToNumber(props.rewards)}
          onClick={props.handleClaim}
        >
          Claim
        </RoundButton>
        <RoundButton
          size="large"
          disabled={props.disableUnstaking}
          onClick={props.handleUnstake}
        >
          Unstake & Claim
        </RoundButton>
      </div>
    </>
  )
}

export function StakingStatsTabComponent(props: {
  stakingStats: StakingStats
  flameDecimals: number
  totalStaked: BigNumber
  lpDecimals: number
}) {
  return (
    <LoadingWrap loading={!props.stakingStats}>
      {!!props.stakingStats && (
        <dl className="info-list staking-stats">
          <div className="info-list__item  first-item">
            <dt className="name">Total rewards</dt>
            <dd className="value">
              {balanceToCurrency(
                props.stakingStats.totalRewards,
                props.flameDecimals,
              )}{' '}
              FLAME
            </dd>
          </div>
          <div className="info-list__item">
            <dt className="name">Total Staked</dt>
            <dd className="value">
              {balanceToCurrency(props.totalStaked, props.lpDecimals)} QS LP
            </dd>
          </div>
          <div className="info-list__item">
            <dt className="name">Locked Rewards</dt>
            <dd className="value">
              {balanceToCurrency(
                props.stakingStats.lockedRewards,
                props.flameDecimals,
              )}{' '}
              FLAME
            </dd>
          </div>
          <div className="info-list__item">
            <dt className="name">Unlocked Rewards</dt>
            <dd className="value">
              {balanceToCurrency(
                props.stakingStats.unlockedRewards,
                props.flameDecimals,
              )}{' '}
              FLAME
            </dd>
          </div>
          <div className="info-list__item last-item">
            <dt className="name">Program Duration</dt>
            <dd className="value">
              {props.stakingStats.programDuration
                ? `${props.stakingStats.programDuration} left`
                : '--'}
            </dd>
          </div>
        </dl>
      )}
    </LoadingWrap>
  )
}
