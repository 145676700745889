import React from 'react';
import { BoxItem } from '../BoxItem';
import './BoxesList.scss'
import { LoadingWrap } from '@components/common/LoadingWrap';
import { LootBoxFormatted } from '@api/subgraph/loot-boxes'

interface Props {
  boxes?: LootBoxFormatted[]
  onAfterClaim: (boxId: string) => Promise<void>
}

export const BoxesList = ({ boxes, onAfterClaim }: Props) => {

  return (
    <div className="loot-boxes-list">
      <ul className='loot-boxes-list__header'>
        <li>Box id</li>
        <li>Start time</li>
        <li>Unvested</li>
        <li>Claimed</li>
        <li>Claimable</li>
        <li>Action</li>
      </ul>
      <div className='loot-boxes-list__body'>
        <LoadingWrap loading={!boxes}>
          {
            !!boxes && boxes.length
              ? boxes.map(box => (
                <BoxItem key={box.id} box={box} onAfterClaim={onAfterClaim} />
              )
              ) : (
                <div className="tile--with-shadow no-projects-tile">
                  <div className="dots">
                    <div/>
                    <div/>
                    <div/>
                  </div>
                  <p className="tile__description">
                    Your Blind Boxes that are vesting will appear here
                  </p>
                </div>
              )
          }
        </LoadingWrap>
      </div>
      <div className="tile--with-shadow no-projects-tile hidden">
        <div className="dots">
          <div/>
          <div/>
          <div/>
        </div>
        <p className="tile__description">
          Your Blind Boxes that are vesting will appear here
        </p>
      </div>
    </div>
  )
}
