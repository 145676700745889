import { useCallback, useEffect, useMemo, useState } from 'react'
import { PublicKey, SystemProgram } from '@solana/web3.js'
import {
  BNToBigNumber,
  BNToNumber,
  numericToBN,
  toBigNumber,
} from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { SECOND } from '@constants/dates'
import { differenceInMilliseconds, isFuture, isPast } from 'date-fns'
import {
  useFlameStakingContract,
  useTokenInfo,
  useTransactions,
  useWalletContext,
} from '@firestarter-private/firestarter-library'
import { toPubKey } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import { StakingStats } from '@contracts/hooks/useStaking/types'
import {
  NetworkType,
  solanaContractAddresses,
} from '@firestarter-private/firestarter-library/lib/constants'
import {
  getAPY,
  getFlameAccount,
  getFlameAndUSDCPrice,
  getLpAccount,
  getStakePoolAccount,
  getStakeRewardsAccount,
  getStakingStats,
  newflamePerShare,
  rewardsCalc,
} from '@contracts/hooks/useStaking/functions'
import BigNumber from 'bignumber.js'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import { Address } from '@project-serum/anchor'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'

export const useStakingSolana = () => {
  const isMountedRef = useIsMounted()
  const { account } = useWalletContext()
  const publicKey = account && toPubKey(account)
  const { sendTransaction } = useTransactions()
  const stakingProgram = useFlameStakingContract(NetworkType.solana)
  const stakingProgramId = toPubKey(stakingProgram?.programId as Address)
  const flameToken = useTokenInfo(
    solanaContractAddresses?.flameToken,
    NetworkType.solana,
  )
  const lpToken = useTokenInfo(
    solanaContractAddresses?.flameUSDCLpToken,
    NetworkType.solana,
  )
  const lpDecimals = lpToken.decimals
  const lpBalance = lpToken.amount
  const flameDecimals = flameToken.decimals

  const [flamePrice, setFlamePrice] = useState(0)
  const [lpTokenPrice, setLpTokenPrice] = useState(0)

  const [stakePoolAccount, setStakePoolAccount] = useState<PublicKey>()
  const [stakeRewardsAccount, setStakeRewardsAccount] = useState<PublicKey>()

  const [staked, setStaked] = useState<BigNumber>(toBigNumber(0))
  const [lastStaked, setLastStaked] = useState<number>(0)
  const [accumulatedReward, setAccumulatedReward] = useState<BigNumber>(
    toBigNumber(0),
  )
  const [rewardDebt, setRewardDebt] = useState<BigNumber>(toBigNumber(0))

  const [stakingStart, setStakingStart] = useState<number>(0)
  const [stakingDuration, setStakingDuration] = useState<number>(0)
  const [rewardsPerSecond, setRewardsPerSecond] = useState<BigNumber>(
    toBigNumber(0),
  )

  const [lastFlameTimestamp, setLastFlameTimestamp] = useState<number>()
  const [earlyWithdrawlPeriod, setEarlyWithdrawlPeriod] = useState<number>(0)

  const [totalStaked, setTotalStaked] = useState<BigNumber>(toBigNumber(0))
  const [initialFlamePerShare, setFlamePerShare] = useState<BigNumber>(
    toBigNumber(1),
  )

  const [blockHash, setBlockHash] = useState<string>()

  const getAccounts = useCallback(async () => {
    if (!account) return
    const stakePool = await getStakePoolAccount(stakingProgramId)
    const stakeRewards = await getStakeRewardsAccount(
      toPubKey(account),
      stakePool,
      stakingProgramId,
    )

    if (isMountedRef.current) {
      setStakePoolAccount(stakePool)
      setStakeRewardsAccount(stakeRewards)
    }
  }, [account, isMountedRef, stakingProgramId])

  useEffect(() => {
    if (account) {
      getAccounts()
    }
  }, [account, blockHash])

  const getStakePoolSettings = useCallback(async () => {
    if (!stakePoolAccount || !stakingProgram) return

    const stakePool = await stakingProgram.account.stakePool.fetch(
      stakePoolAccount,
    )

    if (stakePool) {
      setLastFlameTimestamp(
        BNToNumber(stakePool.lastFlameTimestamp, 0) * SECOND,
      )
      setTotalStaked(BNToBigNumber(stakePool.totalStaked))
      setFlamePerShare(BNToBigNumber(stakePool.flamePerShare))
      setEarlyWithdrawlPeriod(
        BNToNumber(stakePool.settings.earlyWithdrawSeconds, 0) * SECOND,
      )
      setStakingStart(BNToNumber(stakePool.settings.startTimestamp, 0) * SECOND)
      setStakingDuration(
        BNToNumber(stakePool.settings.stakingPeriod, 0) * SECOND,
      )
      setRewardsPerSecond(BNToBigNumber(stakePool.settings.flamePerSecond))
    }
  }, [stakePoolAccount, stakingProgram])

  const getStakeRewards = useCallback(async () => {
    if (!stakeRewardsAccount || !stakingProgram) return

    try {
      const stakeRewards = await stakingProgram.account.stakeRewards.fetch(
        stakeRewardsAccount,
      )

      if (stakeRewards) {
        setStaked(BNToBigNumber(stakeRewards.amountStaked))
        setLastStaked(BNToNumber(stakeRewards.lastDepositTimestamp, 0) * SECOND)
        setAccumulatedReward(BNToBigNumber(stakeRewards.accumulatedReward))
        setRewardDebt(BNToBigNumber(stakeRewards.rewardDebt))
      }
    } catch (err) {
      setStaked(toBigNumber(0))
      setLastStaked(0)
      setAccumulatedReward(toBigNumber(0))
      setRewardDebt(toBigNumber(0))
    }
  }, [stakeRewardsAccount, stakingProgram])

  // TODO: replace with actual formula using raydium sdk
  const getLpTokenPrice = async () => {

    return 0.77
  }

  const loadPrices = useCallback(async () => {
    const { flamePrice: flamePriceValue, usdcPrice } = await getFlameAndUSDCPrice()
    const lpPrice = await getLpTokenPrice()

    setFlamePrice(flamePriceValue)
    setLpTokenPrice(lpPrice)
  }, [])

  useEffect(() => {
    loadPrices()
  }, [lpToken, blockHash])

  useEffect(() => {
    if (stakeRewardsAccount && stakePoolAccount && stakingProgram) {
      getStakePoolSettings()
      getStakeRewards()
      lpToken.getTokenInfo()
    }
  }, [stakeRewardsAccount, stakePoolAccount, stakingProgram, blockHash])

  const isStakingActive = useMemo(() => {
    if (!stakingStart || !stakingDuration) return false
    return isPast(stakingStart) && isFuture(stakingStart + stakingDuration)
  }, [stakingStart, stakingDuration])

  const flamePerShare = useMemo(() => {
    if (!rewardsPerSecond || !lastFlameTimestamp) return toBigNumber(0)

    return initialFlamePerShare.plus(
      newflamePerShare(rewardsPerSecond, totalStaked, lastFlameTimestamp),
    )
  }, [rewardsPerSecond, lastFlameTimestamp, totalStaked])

  const rewards = useMemo(() => {
    if (!staked || !rewardDebt || !accumulatedReward || !flamePerShare)
      return toBigNumber(0)

    return rewardsCalc(staked, rewardDebt, accumulatedReward, flamePerShare)
  }, [staked, rewardDebt, accumulatedReward, flamePerShare])

  const stakingStats = useMemo<StakingStats>(() => {
    const totalRewards = rewardsPerSecond.times(stakingDuration / SECOND)
    return getStakingStats(
      isStakingActive,
      stakingStart,
      stakingDuration,
      rewardsPerSecond,
      totalRewards,
    )
  }, [isStakingActive, stakingStart, stakingDuration, rewardsPerSecond])

  const currentPenalty = useMemo(() => {
    if (!rewards || rewards.isZero() || !lastStaked || !earlyWithdrawlPeriod)
      return toBigNumber(0)

    if (differenceInMilliseconds(new Date(), lastStaked) < earlyWithdrawlPeriod)
      return rewards.div(2)

    return toBigNumber(0)
  }, [rewards, lastStaked, earlyWithdrawlPeriod])

  const APY = useMemo(() => {
    return getAPY(
      rewardsPerSecond,
      totalStaked,
      lpDecimals,
      flameDecimals,
      lpTokenPrice,
      flamePrice,
    )
  }, [
    rewardsPerSecond,
    totalStaked,
    flameToken,
    flamePrice,
    lpToken,
    lpTokenPrice,
  ])

  const onStake = useCallback(
    async (amount: string, callbacks: INotifyTxCallbacks = {}) => {
      if (
        !stakingProgram ||
        !publicKey ||
        !stakePoolAccount ||
        !stakeRewardsAccount
      )
        return

      const lpAccount = await getLpAccount(stakingProgramId)
      const userLpAccount = await lpToken.getOrCreateAssociatedAccount()

      if (!userLpAccount) return

      try {
        const tx = await stakingProgram.transaction.depositStake(
          numericToBN(amount, lpToken.decimals),
          {
            accounts: {
              staker: publicKey,
              stakePool: stakePoolAccount,
              lpAccount,
              stakeRewards: stakeRewardsAccount,
              stakerLp: userLpAccount,
              systemProgram: SystemProgram.programId,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
          },
        )

        const receipt = await sendTransaction(tx, callbacks)
        setBlockHash(tx.recentBlockhash)
        return receipt
      } catch (error) {
        return { error }
      }
    },
    [
      stakingProgram,
      publicKey,
      stakePoolAccount,
      stakeRewardsAccount,
      lpToken,
      sendTransaction,
    ],
  )

  const onClaim = useCallback(
    async (callbacks: INotifyTxCallbacks = {}) => {
      if (
        !stakingProgram ||
        !publicKey ||
        !stakePoolAccount ||
        !stakeRewardsAccount
      )
        return

      const flameAccount = await getFlameAccount(stakingProgramId)
      const userFlameAccount = await flameToken.getOrCreateAssociatedAccount()

      if (!userFlameAccount) return

      try {
        const tx = await stakingProgram.transaction.harvest({
          accounts: {
            staker: publicKey,
            stakePool: stakePoolAccount,
            stakeRewards: stakeRewardsAccount,
            flameAccount,
            stakerFlame: userFlameAccount,
            tokenProgram: TOKEN_PROGRAM_ID,
          },
        })
        const receipt = await sendTransaction(tx, callbacks)
        setBlockHash(tx.recentBlockhash)
        return receipt
      } catch (error) {
        return { error }
      }
    },
    [
      stakingProgram,
      publicKey,
      stakePoolAccount,
      stakeRewardsAccount,
      flameToken,
      sendTransaction,
    ],
  )

  const onUnstake = useCallback(
    async (amount: string, callbacks: INotifyTxCallbacks = {}) => {
      if (
        !stakingProgram ||
        !publicKey ||
        !stakePoolAccount ||
        !stakeRewardsAccount
      )
        return

      const [lpAccount, flameAccount, userLpAccount, userFlameAccount] =
        await Promise.all([
          getLpAccount(stakingProgramId),
          getFlameAccount(stakingProgramId),
          lpToken.getOrCreateAssociatedAccount(),
          flameToken.getOrCreateAssociatedAccount(),
        ])

      if (!userLpAccount || !userFlameAccount) return
      try {
        const tx = stakingProgram.transaction.withdrawAndHarvest(
          numericToBN(amount, lpToken.decimals),
          {
            accounts: {
              staker: publicKey,
              stakePool: stakePoolAccount,
              lpAccount,
              flameAccount,
              stakeRewards: stakeRewardsAccount,
              stakerLp: userLpAccount,
              stakerFlame: userFlameAccount,
              tokenProgram: TOKEN_PROGRAM_ID,
            },
          },
        )

        const receipt = await sendTransaction(tx, callbacks)
        setBlockHash(tx.recentBlockhash)
        return receipt
      } catch (error) {
        return { error }
      }
    },
    [
      stakingProgram,
      publicKey,
      stakePoolAccount,
      stakeRewardsAccount,
      lpToken,
      flameToken,
      sendTransaction,
    ],
  )

  return {
    rewardsPerSecond,
    lastStaked,
    lpDecimals,
    lpBalance,
    staked,
    rewards,
    flameDecimals,
    totalStaked,
    isStakingActive,
    stakingStats,
    currentPenalty,
    APY,
    onStake,
    onClaim,
    onUnstake,
  }
}
