import React from 'react';
import { PortfolioItem } from '../PortfolioItem';
import './PortfolioList.scss'
import { NormalizedProjectInfo } from '@components/Project/types';
import { LoadingWrap } from '@components/common/LoadingWrap';
import { Row } from 'react-bootstrap'

interface Props {
  projects?: NormalizedProjectInfo[]
}

interface TileProps {
  className?: string
}

function NoProjectsTile({ className }: TileProps) {
  return <div className={`tile--with-shadow no-projects-tile ${className}`}>
    <div className="dots">
      <div />
      <div />
      <div />
    </div>
    <p className="tile__description">
      The projects you are participating in will appear here
    </p>
  </div>
}

export const PortfolioList = ({ projects }: Props) => {

  return (
    <div className="portfolio-list">
      <ul className='portfolio-list__header'>
        <li>Project</li>
        <li>Tokens</li>
        <li>Unvested</li>
        <li>Claimed</li>
        <li>Claim</li>
        <li>Action</li>
      </ul>
      <Row className='portfolio-list__body'>
        <LoadingWrap loading={!projects}>
          {
            !!projects && projects.length
              ? projects.map(project =>
                <PortfolioItem
                  key={project.id}
                  project={project} />
              )
              : <NoProjectsTile />
          }
        </LoadingWrap>
      </Row>
      <NoProjectsTile className='hidden' />
    </div>
  )
}
