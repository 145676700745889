import { utils } from 'web3';
import { PublicKey } from '@solana/web3.js';

export const shorterETHAddress = (str: string, fromStart = 6, fromEnd = -4) => {
  let addr = toChecksumAddress(str);
  return addr?.length > 8
    ? addr.slice(0, fromStart) + '...' + addr.slice(fromEnd)
    : addr;
};

export const shorterSolAddress = (str: string, fromStart = 6, fromEnd = -4) => {
  let addr = new PublicKey(str).toString();
  return addr?.length > 8
    ? addr.slice(0, fromStart) + '...' + addr.slice(fromEnd)
    : addr;
};

export function shorterSuiAddress(str: string) {
  if (str.length <= 6) {
    return str;
  }

  const offset = str.startsWith('0x') ? 2 : 0;

  return `0x${str.slice(offset, offset + 4)}\u{2026}${str.slice(-4)}`;
}

export const getIPFSGateway = (url: string) =>
  url.replace('ipfs://', 'https://ipfs.io/ipfs/');

export const toChecksumAddress = utils.toChecksumAddress;

function isHex(value: string): boolean {
  return /^(0x|0X)?[a-fA-F0-9]+$/.test(value) && value.length % 2 === 0;
}

function getHexByteLength(value: string): number {
  return /^(0x|0X)/.test(value) ? (value.length - 2) / 2 : value.length / 2;
}

export const SUI_ADDRESS_LENGTH = 32;
export function isValidSuiAddress(value: string): value is string {
  return isHex(value) && getHexByteLength(value) === SUI_ADDRESS_LENGTH;
}

export const copyToClipBoard = async (text: string): Promise<boolean> => {
  const copy = () => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    const el = document.createElement('input');
    el.setAttribute('readonly', 'readonly');
    el.setAttribute('style', 'position: fixed; top:0; left:0; opacity:0;');
    el.setAttribute('value', text);
    document.body.appendChild(el);
    el.setSelectionRange(0, text.length);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  try {
    await copy();
    return true;
  } catch (err) {
    return false;
  }
};

