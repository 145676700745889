import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { useNetwork } from '@firestarter-private/firestarter-library'
import { useWhen } from '@contracts/hooks/useWhen'
import { useStakingSolana } from '@contracts/hooks/useStaking/useStakingSolana'
import { useStakingEVM } from '@contracts/hooks/useStaking/useStakingEVM'
import { getCurrentNetworkId } from '@contracts/networks'

export const useStaking = () => {
  const { currentNetworkId } = useNetwork()
  return useWhen(
    isSolanaNetwork(currentNetworkId || getCurrentNetworkId()),
    useStakingSolana,
    useStakingEVM,
  )()
}
