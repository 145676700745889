import { ReactNode } from 'react'
import './Link.scss'

interface Props {
  url: string
  newTab: boolean
  children: ReactNode
}
export const Link = ({ url, newTab, children }: Props) => {
  return (
    <a
      className="cms-link"
      href={url}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noreferrer noopener' : undefined}
    >
      {children}
    </a>
  )
}
