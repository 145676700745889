import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  LoadingWrap,
  RoundButton,
  Spinner,
  WrongNetworkBlock,
} from '@components/common'
import { balanceToCurrency, balanceToNumber } from '@utils/balanceFormatter'
import './BoxItem.scss'
import { useIsMounted } from '@hooks/useIsMounted'
import { formatDateToUTC } from '@utils/dates'
import { LootBoxFormatted } from '@api/subgraph/loot-boxes'
import { usePlatformSettings } from '@hooks/usePlatformSettings'
import { useBlindBoxLottery } from '@contracts/hooks/useBlindBoxLottery'
import BigNumber from 'bignumber.js'
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { FLAME_DECIMALS } from '@constants'

interface Props {
  box: LootBoxFormatted
  onAfterClaim: (boxId: string) => Promise<void>
}

export const BoxItem = ({ box, onAfterClaim }: Props) => {
  const isMountedRef = useIsMounted()
  const [isClaiming, setIsClaiming] = useState(false)
  const [withdrawable, setWithdrawable] = useState(new BigNumber(0))

  const { settings } = usePlatformSettings()
  const { getWithdrawable, claim } = useBlindBoxLottery(settings?.contracts?.blindBoxLotteryPhase2)
  const { account } = useWalletContext()

  useEffect(() => {
    getWithdrawable(box.boxId)
      .then(setWithdrawable)
  }, [account, box])

  const unvested = useMemo(() => {
    return box.rewardAmount
      .minus(box.claimedAmount)
      .minus(withdrawable)
  }, [])

  const { isDefaultEVMNetworkSelected, defaultEVMNetwork } = useNetwork()

  const handleClaim = useCallback(async () => {
    setIsClaiming(true)
    await claim(box.boxId)
    await onAfterClaim(box.boxId)
    setIsClaiming(false)
  }, [onAfterClaim, claim, box.boxId])

  return (
    <div className="loot-box tile">
      <div className="loot-box__main">
        <LoadingWrap loading={!box}>
          <div className="loot-box__col">
            <h4 className="loot-box__name">
              Box {box.boxId}
            </h4>
          </div>
          {
            !isDefaultEVMNetworkSelected ? (
              <div className="loot-box__col loot-box__col--wrong-network">
                <WrongNetworkBlock
                  prefix={'To claim your tokens'}
                  expectedNetwork={defaultEVMNetwork}
                  embedded={false}
                />
              </div>
            ) : (
              <>
                <div className="loot-box__col">
                  <span>Start time</span>
                  <span>
                    {formatDateToUTC(box.createdAt)}
                  </span>
                </div>
                <div className="loot-box__col">
                  <span>Unvested</span>
                  <span>
                    {balanceToCurrency(unvested, FLAME_DECIMALS)} <br /> FLAME
                  </span>
                </div>
                <div className="loot-box__col">
                  <span>Claimed</span>
                  <span>
                    {balanceToCurrency(box.claimedAmount, FLAME_DECIMALS)} <br /> FLAME
                  </span>
                </div>
                <div className="loot-box__col purple-text">
                  <span>Claimable</span>
                  <span>
                    {balanceToCurrency(withdrawable, FLAME_DECIMALS)} <br /> FLAME
                  </span>
                </div>
                <div className="loot-box__col">
                  <RoundButton
                    size="large"
                    color="LIGHT"
                    disabled={!balanceToNumber(withdrawable, FLAME_DECIMALS) || isClaiming}
                    onClick={handleClaim}
                  >
                    {isClaiming ? <Spinner /> : 'Claim'}
                  </RoundButton>
                </div>
              </>
            )
          }
        </LoadingWrap>
      </div>
    </div>
  )
}
