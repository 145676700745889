import Polygon from '../../assets/partners/Polygon.svg'
import Parsiq from '../../assets/partners/parsiq.svg'
import Blockpass from '../../assets/partners/blockpass.svg'
import Quantstamp from '../../assets/partners/quantstamp.svg'
import SSB from '../../assets/partners/ssb.svg'
import Chainlink from '../../assets/partners/chainlink.svg'
import TransformGroup from '../../assets/partners/transform-group.svg'
import Kucoin from '../../assets/partners/kucoin.svg'
import Quickswap from '../../assets/partners/quickswap.svg'
import CoinGecko from '../../assets/partners/coingecko.svg'

export const partners = [
  {
    image: Polygon,
    name: 'Polygon'
  },
  {
    image: Parsiq,
    name: 'Parsiq'
  },
  {
    image: Blockpass,
    name: 'BlockPass'
  },
  {
    image: Quantstamp,
    name: 'Quantstamp'
  },
  {
    image: SSB,
    name: 'Satoshi Street Bets'
  },
  {
    image: Chainlink,
    name: 'Chainlink'
  },
  {
    image: TransformGroup,
    name: 'Transform Group'
  },
  {
    image: Kucoin,
    name: 'KuCoin'
  },
  {
    image: Quickswap,
    name: 'QuickSwap'
  },
  {
    image: CoinGecko,
    name: 'CoinGecko'
  },
];

export const faqItems = [
  {
    title: 'How can projects use FireStarter?',
    body: (
      <p>
        The FireStarter platform allows early-stage crypto projects to raise funds through our IDO launch process. In
        utilizing the FireStarter Networks, projects are able to reach massive audiences and fund at an unmatched
        pace. We tokenize culture.
      </p>
    )
  },
  {
    title: 'What is Token Burn?',
    body: (
      <p>
        A token burn is done by permanently removing some tokens from circulation, thus increasing scarcity. Unlocking
        or unstacking before the 30 day mark will result in penalties. A % of penalties if burned to increase scarcity
      </p>
    )
  },
  {
    title: 'What is the $FLAME Token Address?',
    body: (
      <p>
        The $FLAME Token Addresses:
        <ul>
          <li>
            0x22e3f02f86Bc8eA0D73718A2AE8851854e62adc5 (Polygon)
          </li>
          <li>
            ViH6BEZWoT2uFrjiy9FNHuXrspTPqPQNB15oYxHheJW (Solana)
          </li>
        </ul>
      </p>
    )
  },
  {
    title: 'How do I lock $FLAME?',
    body: (
      <p>
        <b>Step 1.</b> Go to firestarter.fi
        <br/>
        <b>Step 2.</b> Connect your Wallet
        <br/>
        <b>Step 3.</b> Go to Accounts Page
        <br/>
        <b>Step 4.</b> Scroll Down to Lock Section
        <br/>
        <b>Step 5.</b> Determine amount of $FLAME to be locked and click Approve to make sure enough funds are available to participate.
        <br/>
        <b>Step 6.</b> Once approved, you can then lock your $FLAME with the Lock Button and establish your Tier.
      </p>
    )
  },
  //TODO: fill the answer when the Tiers logic is fully defined
  /*{
    title: 'What are the FireStarter Tiers?',
    body: (
      <p>
        Please leave room for Tiers.
      </p>
    )
  },*/
  {
    title: 'How do I choose a FireStarter Project?',
    body: (
      <>
        <p>
          FireStarter Projects is where Defi and Culture Collide. Providing backers with a diverse range of investment
          opportunities that have mainstream stay and influence.
        </p>
        <p>
          <b>Step 1.</b> Go to firestarter.fi
          <br/>
          <b>Step 2.</b> Connect your Wallet
          <br/>
          <b>Step 3.</b> Go to Projects Page
          <br/>
          <b>Step 4.</b> Select an "In Progress" Project
          <br/>
          <b>Step 5.</b> Review Project's details and hit select to proceed to Whitelisting.
        </p>
      </>
    )
  },
  {
    title: 'How to Apply to Whitelist?',
    body: (
      <>
        <p>
          To ensure safety and security, FireStarter implements a Whitelisting process for users wishing to invest in
          FireStarter Projects. This process should be done before investing in a project. Users are required to go
          through a KYC process, provide additional details, and follow us on our Social Channels.
        </p>
        <p>
          <b>Step 1.</b> Go to firestarter.fi
          <br/>
          <b>Step 2.</b> Connect your Wallet
          <br/>
          <b>Step 3.</b> Go to Projects Page
          <br/>
          <b>Step 4.</b> Go to Upcoming Project's Page and Choose Project
          <br/>
          <b>Step 5.</b> Click on Apply to Whitelist
        </p>
      </>
    )
  },
  {
    title: 'How to Swap?',
    body: (
      <>
        <p>
          FireStarter facilities the peer to peer swapping of tokens that make up our fundraising rounds. Exchange the
          Projects Contribution Token, determined by protocol, for Project Reward Tokens specific to the project.
        </p>
        <p>
          <b>Step 1.</b> Go to firestarter.fi
          <br/>
          <b>Step 2.</b> Connect your Wallet
          <br/>
          <b>Step 3.</b> Go to Projects Page
          <br/>
          <b>Step 4.</b> Select an "In Progress" Project
          <br/>
          <b>Step 5.</b> Locate Swap section and enter the amount you wish to contribute.
          <br/>
          <b>Step 6.</b> Click on Approve to ensure enough funds are available.
          <br/>
          <b>Step 7.</b> Confirm by clicking on Swap.
        </p>
      </>
    )
  },
  {
    title: 'How to Claim?',
    body: (
      <p>
        <b>Step 1.</b> Go to FireStarter website
        <br/>
        <b>Step 2.</b> Connect your wallet
        <br/>
        <b>Step 3.</b> Go to Account Page
        <br/>
        <b>Step 4.</b> Switch to My Portfolio
        <br/>
        <b>Step 5.</b> Locate needed project and click on Claim
        <br/>
        <b>Step 6.</b> Submit transaction in you wallet
      </p>
    )
  },
  {
    title: 'How to Bridge USDC for the Polygon Network?',
    body: (
      <>
        <p>
          Please see our guide here:&nbsp;
          <a
            href='https://firestarter-fi.medium.com/fs-project-resources-bridging-usdc-8326637563b7'
            target="_blank"
            rel="noreferrer noopener"
          >
            FS Project Resources: Bridging USDC for QuickSwap
          </a>
        </p>
        <p>
          <span><b>Pre-Requisites</b></span>
          <ul>
            <li>ETH in your Ethereum Wallet</li>
            <li>USDC you wish to bridge</li>
          </ul>
        </p>
        <p>
          <b>Step 1.</b> Head over to the&nbsp;
          <a
            href='https://wallet.polygon.technology/login?next=%2Fwallet'
            target="_blank"
            rel="noreferrer noopener"
          >
            Polygon Web Wallet app.
          </a>
          <br/>
          <b>Step 2.</b> Connect to your Ethereum wallet
          <br/>
          <b>Step 3.</b> You’ll be asked to sign the transaction — click on Sign.
          <br/>
          <b>Step 4.</b> Click on Move funds from Ethereum to Polygon near the top right.
          <br/>
          <b>Step 5.</b> Use the dropdown menu to the right of the word “MAX” and choose USDC to bridge over.
          <br/>
          <b>Step 6.</b> Then input the amount of USDC to bridge.
          <br/>
          <b>Step 7.</b> Click Transfer, then Continue to move funds from Ethereum to Polygon.
        </p>
      </>
    )
  },
];
