import React from 'react'
import classNames from 'classnames'
import './TextNode.scss'

interface Props {
  text: string
  bold?: boolean
  italic?: boolean
  underline?: boolean
  highlighted?: boolean
}
export const TextNode = ({ text, bold, italic, underline, highlighted }: Props) => {
  return (
    <span
      className={classNames('cms-text-node', {
        'cms-bold': bold,
        'cms-italic': italic,
        'cms-underline': underline,
        'cms-highlighted': highlighted,
      })}
    >
      {text}
    </span>
  )
}
