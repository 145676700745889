import { DynamicImage } from '@components'
import React from 'react'
import { SocialLink } from '@components/Project/types'
import './ProjectSocialLinks.scss'

export const ProjectSocialLinks = (props: { socialLinks: SocialLink[] }) => (
  <>
    {!!props.socialLinks.length && (
      <div className='project-social-links'>
        {props.socialLinks.map(link =>
          <a key={link.name}
             href={link.url}
             target="_blank"
             rel="noreferrer noopener"
             onClick={(e) => e.stopPropagation()}
          >
            <DynamicImage path={`socials/${link.name}.svg`} />
          </a>
        )}
      </div>
    )}
  </>
)
