import React, { ReactNode } from 'react'
import './List.scss'

interface Props {
  type: 'ol' | 'ul'
  children: ReactNode
}

export const List = ({ type, children }: Props) => {
  return React.createElement(
    type,
    {
      className: 'cms-list',
    },
    children,
  )
}
