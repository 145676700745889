import { Col, Container, Row } from 'react-bootstrap'
import { partners } from '@pages/Homepage/contents'
import React from 'react'
import './PartnersSection.scss'

export const PartnersSection = () => {
  return <section className="partnerships">
    <Container>
      <h2 className="title text-center">Partners</h2>
      <Row className="align-items-stretch justify-content-center">
        {partners.map((item) => (
          <Col
            key={item.name}
            xs={{ span: 6 }}
            sm={{ span: 6 }}
            md={{ span: 4 }}
            lg={{ span: 3 }}
            className="partnerships-col"
          >
            <img
              src={item.image} alt={item.name}
              className="partnerships-item"
            />
          </Col>
        ))}
      </Row>
    </Container>
  </section>
}
