export const RoutesPaths = {
  MAIN: '/',
  NFTS: {
    MAIN: '/nfts',
    COLLECTION: '/nfts/collection',
  },
  NFT_TOKEN: '/nft/:token_name/:nft_id',
  ACCOUNT: {
    MAIN: '/account',
    LOCKING: '/account/lockup',
    LOCKING_MULTIPERIOD: '/account/lockup-new',
    STAKING: '/account/staking',
    LINKING_WALLETS: '/account/linking-wallets',
  },
  LOOT_BOXES: {
    LOTTERY: '/loot-boxes',
    CLAIMING: '/loot-boxes/claiming',
    NFTS: '/loot-boxes/nfts',
    REFERRAL: '/loot-boxes/referral',
  },
  LEADERBOARD: '/leaderboard',
  PROJECTS: '/projects',
  PROJECT: '/projects/:id',
  ABOUT_US: '/about-us',
  APPLY_FOR_IDO: '/apply-for-ido',
  JOIN_NETWORK: '/join-our-network',
  USER_AGREEMENT: '/user-agreement',
  PRIVACY: '/privacy-policy',
  PRESS_KIT: '/press-kit',
  FAQ: '/faq',
  KYC_PROCESS: '/kyc-process',
  ADD_NETWORK_GUIDE: '/add-network-to-metamask',
}

export const RoutesHashes = {
  LOOT_BOXES: {
    LOTTERY_BLOCK: 'lottery'
  }
}
