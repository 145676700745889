import React, { useState } from 'react'
import { FormFillResponseView, JoinForm, JoinFormValues, SEOTags, StaticPageWrapper } from '../../../components'
import { Container } from 'react-bootstrap'
import { submitToJoinCommunity } from '../../../api/form-fills'
import { seoTags } from '../../../seo-content'
import './JoinCommunity.scss'

export const JoinCommunity = () => {
  const [formStage, setFormStage] = useState<'FILL' | 'RESPONSE'>('FILL')
  const [responseStatus, setResponseStatus] = useState<'error' | 'success' | null>(null)

  const resetForm = () => {
    setFormStage('FILL')
    setResponseStatus(null)
  }

  const submitJoining = async (values: JoinFormValues) => {
    const response = await submitToJoinCommunity(values)
    setResponseStatus(response.result)
    setFormStage('RESPONSE')
  }

  return (
    <StaticPageWrapper title="Join Our Network">
      <SEOTags {...seoTags.joinNetwork} />
      <section className="join-our-network">
        <Container>
          {formStage === 'FILL' ? (
            <JoinForm onSubmit={submitJoining} />
          ) : (
            <FormFillResponseView status={responseStatus} onReset={resetForm} />
          )}
        </Container>
      </section>
    </StaticPageWrapper>
  )
}
