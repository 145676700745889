import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RoundButton } from '../../components';
import WalletIcon from '../../assets/wallet.svg';
import './Wallet.css';
import { shorterETHAddress } from '../../utils/string';
import { WalletAddress } from '../../contracts/address';
import { useWalletContext } from '@firestarter-private/firestarter-library'

const ConnectIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.061 6.61196C23.5787 6.61196 23.9984 6.1923 23.9984 5.6746V4.69037C23.9984 2.10409 21.8943 0 19.308 0C19.308 0 4.61448 0.0022028 4.57825 0.00646779C3.34145 0.0496801 2.15339 0.580414 1.29702 1.47775C0.419225 2.39749 -0.0383943 3.59375 0.00467745 4.85239C0.00289646 4.87625 0.0019591 18.3723 0.0019591 18.3723C0.0019591 21.4754 2.52655 24 5.62969 24H19.308C21.8943 24 23.9984 21.8959 23.9984 19.3096V12.1928C23.9984 9.60654 21.8943 7.50245 19.308 7.50245H4.68872C3.18374 7.50245 1.94989 6.32616 1.87973 4.8245C1.84406 4.06093 2.11875 3.33204 2.65314 2.77206C3.19527 2.20402 3.95664 1.87828 4.74196 1.87828C4.76966 1.87828 19.3079 1.87472 19.3079 1.87472C20.8605 1.87472 22.1236 3.13782 22.1236 4.69037V5.6746C22.1237 6.1923 22.5433 6.61196 23.061 6.61196ZM4.68872 9.37717H19.308C20.8605 9.37717 22.1236 10.6403 22.1236 12.1928V19.3096C22.1236 20.8622 20.8605 22.1253 19.308 22.1253H5.62969C3.56028 22.1253 1.87668 20.4417 1.87668 18.3723V8.44361C2.66069 9.03086 3.63484 9.37717 4.68872 9.37717Z"
        fill="white"
      />
      <path
        d="M20.2497 15.7513C20.2497 16.3984 19.7251 16.923 19.078 16.923C18.4309 16.923 17.9063 16.3984 17.9063 15.7513C17.9063 15.1042 18.4309 14.5796 19.078 14.5796C19.7251 14.5796 20.2497 15.1042 20.2497 15.7513ZM20.2497 4.69041C20.2497 4.17271 19.83 3.75305 19.3123 3.75305H4.6895C4.17179 3.75305 3.75214 4.17271 3.75214 4.69041C3.75214 5.20812 4.17179 5.62777 4.6895 5.62777H19.3123C19.83 5.62777 20.2497 5.20812 20.2497 4.69041Z"
        fill="#FE4646"
      />
      <path
        d="M23.061 6.61196C23.5787 6.61196 23.9984 6.1923 23.9984 5.6746V4.69037C23.9984 2.10409 21.8943 0 19.308 0C19.308 0 4.61448 0.0022028 4.57825 0.00646779C3.34145 0.0496801 2.15339 0.580414 1.29702 1.47775C0.419225 2.39749 -0.0383943 3.59375 0.00467745 4.85239C0.00289646 4.87625 0.0019591 18.3723 0.0019591 18.3723C0.0019591 21.4754 2.52655 24 5.62969 24H19.308C21.8943 24 23.9984 21.8959 23.9984 19.3096V12.1928C23.9984 9.60654 21.8943 7.50245 19.308 7.50245H4.68872C3.18374 7.50245 1.94989 6.32616 1.87973 4.8245C1.84406 4.06093 2.11875 3.33204 2.65314 2.77206C3.19527 2.20402 3.95664 1.87828 4.74196 1.87828C4.76966 1.87828 19.3079 1.87472 19.3079 1.87472C20.8605 1.87472 22.1236 3.13782 22.1236 4.69037V5.6746C22.1237 6.1923 22.5433 6.61196 23.061 6.61196ZM4.68872 9.37717H19.308C20.8605 9.37717 22.1236 10.6403 22.1236 12.1928V19.3096C22.1236 20.8622 20.8605 22.1253 19.308 22.1253H5.62969C3.56028 22.1253 1.87668 20.4417 1.87668 18.3723V8.44361C2.66069 9.03086 3.63484 9.37717 4.68872 9.37717Z"
        fill="white"
      />
    </svg>
  );
};

interface Props {}

export const Wallet = (props: Props) => {
  const { account, connected, connect } = useWalletContext()

  const onClickWallet = () => {
    connect()
  };

  return (
    <div className="wallet-page page">
      <Container>
        <Row className="hero-row">
          <Col md={{ span: 10, offset: 1 }} className="text-left">
            <div className="title">Account</div>
          </Col>
        </Row>
        <Row className="connect-row">
          <Col md={{ span: 5 }} className="text-center column tile">
            <span className="icon">
              <img src={WalletIcon} alt="connect your wallet" />
            </span>
            <h4 className="tile__main">Connect your wallet</h4>
            <RoundButton
              color="DARK"
              size="large"
              onClick={onClickWallet}
            >
              <>
                {connected ? shorterETHAddress(account as WalletAddress) : 'Connect Wallet'}
                <ConnectIcon />
              </>
            </RoundButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
