import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Image, Modal } from 'react-bootstrap'
import { numberToCurrency } from '@utils/balanceFormatter'
import { RoundButton } from '@/components'
import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg'
import { ReactComponent as CloseIcon } from '@assets/close.svg'
import ResultBanner from '@assets/lottery/lottery-result-banner.png'
import ResultBannerNonProfit from '@assets/lottery/lottery-result-banner-nonprofit.png'
import './LotteryResultModal.scss'
import { ShareOnSocialsModal } from '@components/common/ShareOnSocials'
import { RoutesPaths } from '@router/constants'
import { ChainvineClient, getReferrerId } from '@chainvine/sdk'
import { useWeb3React } from '@web3-react/core'
import confetti from 'canvas-confetti'

export const CHAINVINE_API_KEY = process.env.REACT_APP_CHAINVINE_API_KEY
export const CHAINVINE_CAMPAIGN_ID = process.env.REACT_APP_CHAINVINE_CAMPAIGN_ID

export interface LotteryResult {
  isNFT: boolean
  isVesting: boolean
  buyAmount: number
  rewardAmount: number
  explorerLink: string
}

interface Props {
  result?: LotteryResult
  show: boolean
  onHide: () => void
}

const lootBoxesPageURL = 'https://firestarter.fi/loot-boxes'

const nftWInnerShareText = () => `I just won my @firestarter_fi tier NFT in a Blind Box! You can get one here too

#crypto #gamefi #ownthefuture
`

const tokenWinnerShareText = (
  rewardAmount: string,
  buyAmount?: string,
) => `Just ${buyAmount ? `spent ${buyAmount} FLAME and ` : ' '}won ${rewardAmount} FLAME on @firestarter_fi Blind Box! Join in on the fun now!

#crypto #gamefi #ownthefuture
`

const confettiCanvasId = 'confetti-canvas'

export const LotteryResultModal = ({ result, show, onHide }: Props) => {
  const [refLink, setRefLink] = useState<string>(lootBoxesPageURL)
  const { account } = useWeb3React()
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    const fetchRefLink = async () => {
      if (!account) return
      const client = new ChainvineClient({
        apiKey: CHAINVINE_API_KEY, //your api key
      })
      const userClient = await client.syncUser(account)
      const campaign = {
        id: CHAINVINE_CAMPAIGN_ID || '',
      }

      const { url } = await userClient
        .referral({
          campaign,
        })
        .url()

      setRefLink(url)
    }
    fetchRefLink()
  }, [account])

  const hasProfit = useMemo(() => {
    if (!result) return false
    return result.isNFT || result.rewardAmount > result.buyAmount
  }, [result])

  useEffect(() => {
    let myConfetti: ReturnType<typeof confetti.create>
    if (result && hasProfit && canvasRef.current) {
      myConfetti = confetti.create(canvasRef.current, {
        resize: true,
        useWorker: true,
      })

      myConfetti({
        particleCount: 150,
        angle: 270,
        gravity: 0.5,
        origin: { x: 0.5, y: -0.3 },
        spread: 180,
        ticks: 500,
      })
    }

    return () => {
      myConfetti && myConfetti.reset()
    }
  }, [result])

  return (
    <>
      {(result && hasProfit) && <canvas ref={canvasRef} id={confettiCanvasId}></canvas>}
      <Modal
        show={show}
        onHide={onHide}
        centered
        className="lottery-result-modal"
        contentClassName="tile text-center lottery-result"
      >
        <CloseIcon className="lottery-result__close" onClick={onHide} />
        {!!result && (
          <Modal.Body>
            <Image src={hasProfit ? ResultBanner : ResultBannerNonProfit} className="lottery-result__banner" />
            {
              hasProfit ? (
                <p className="lottery-result__message">
                  Congratulations! You won <br />
                  <span className="purple-text">
                    {result?.isNFT
                      ? 'a FireStarter Tier NFT!'
                      : `${numberToCurrency(result.rewardAmount, 0)} FLAME!`}
                  </span>
                </p>
              ) : (
                <p className="lottery-result__message">
                  You won <span className="purple-text">
                    {numberToCurrency(result.rewardAmount, 0)} FLAME,
                  </span>
                  <br />
                  better luck next time!
                </p>
              )
            }
            <div className="lottery-result__buttons">
              <ShareOnSocialsModal
                subject="Firestarter Blind Boxes"
                buttonLabel="SHARE AND EARN"
                text={
                  result.isNFT
                    ? nftWInnerShareText()
                    : tokenWinnerShareText(
                      numberToCurrency(result.rewardAmount, 0),
                      hasProfit ? numberToCurrency(result.buyAmount, 0) : undefined,
                    )
                }
                url={refLink}
              />
              {
                result.isNFT ? (
                  <RoundButton color="LIGHT" to={RoutesPaths.LOOT_BOXES.NFTS}>
                    Check your NFT
                  </RoundButton>
                ) : result.isVesting ? (
                  <RoundButton color="LIGHT" to={RoutesPaths.LOOT_BOXES.CLAIMING}>
                    Go to Claiming
                  </RoundButton>
                ) : (
                  <RoundButton color="LIGHT" href={result.explorerLink}>
                    See on Polygonscan <LinkArrow />
                  </RoundButton>
                )
              }
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  )
}
