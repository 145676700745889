import React, { useMemo, useState } from 'react'
import './ProjectStats.scss'
import { ProjectStatusesProps, ProjectStatusProp, ProjectType, ProjectTypes, TokenName } from '../types'
import { TimezoneSwitcher } from '@components/common';
import { Tab, Tabs } from 'react-bootstrap'
import classNames from 'classnames';
import { useTimezonFormat } from '@hooks/useTimezonFormat';
import { ProjectPhasesList } from '@components/Project/ProjectStats/ProjectPhasesList'
import { ProjectStatusBar } from '@components/Project/ProjectStats/ProjectStatusBar'
import { ProjectStatsValues } from '@components/Project/ProjectStats/ProjectStatsValues'

export interface ProjectContractProps {
  totalRewards?: number
  fundsSwapped?: number
  totalAmount?: number
  swapExchangeRate?: number
}

export interface ProjectNonContractProps {
  fundTokenName: TokenName
  rewardTokenName: TokenName
  projectType: ProjectType
  status: ProjectStatusProp
  whitelistOpens?: Date | null
  whitelistCloses?: Date | null
  privateOpens?: Date | null
  privateCloses?: Date | null
  publicOpens?: Date | null
  publicCloses?: Date | null
  airdropOpens?: Date | null
  airdropCloses?: Date | null
  participants?: number
  isExternalPresale: boolean
}

interface Props extends ProjectContractProps, ProjectNonContractProps{
  expanded?: boolean
  baseAllocation?: number
  isProjectNetworkSelected: boolean
}

enum StatsTabs {
  CURRENT = 'CURRENT',
  ALL = 'ALL'
}

const ProjectStats: React.FC<Props> = React.memo(({
  fundTokenName,
  rewardTokenName,
  projectType,
  status,
  whitelistOpens,
  whitelistCloses,
  privateOpens,
  privateCloses,
  publicOpens,
  publicCloses,
  airdropOpens,
  airdropCloses,
  totalAmount,
  fundsSwapped,
  swapExchangeRate,
  totalRewards,
  participants,
  expanded,
  baseAllocation,
  isExternalPresale,
  isProjectNetworkSelected,
  children
}) => {
  const isWhitelistingInProgress = useMemo(
    () => status === ProjectStatusesProps['Registration Open'],
    [status]
  )

  const isPrivatePhaseInProgress = useMemo(
    () => status === ProjectStatusesProps['Guaranteed Live'],
    [status]
  )

  const isPublicPhaseInProgress = useMemo(
    () => status === ProjectStatusesProps['FCFS Live'],
    [status]
  )

  const {
    currentFormat,
    switchFormat,
    formatDate
  } = useTimezonFormat()

  const [activeTab, setActiveTab] = useState<StatsTabs>(StatsTabs.CURRENT)

  return (
    <div className={classNames('project-stats', { 'tile': expanded })}>
      <div className="white-transparent-text text-wide">
        Current phase
      </div>
      <Tabs
        id="project-stats-tabs"
        className="project-stats__tabs fs-tabs"
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key as StatsTabs)}
      >
        <Tab
          eventKey={StatsTabs.CURRENT}
          title={status}
          className="project-stats-tab"
        >
          <ProjectStatusBar
            status={status}
            whitelistOpens={whitelistOpens}
            whitelistCloses={whitelistCloses}
            presaleOpens={privateOpens}
          />
          {(isProjectNetworkSelected || projectType === ProjectTypes.token_airdrop) && !isExternalPresale && (
            <ProjectStatsValues
              fundTokenName={fundTokenName}
              rewardTokenName={rewardTokenName}
              projectType={projectType}
              totalAmount={totalAmount}
              fundsSwapped={fundsSwapped}
              exchangeRate={swapExchangeRate}
              totalRewards={totalRewards}
              participants={participants}
              status={status}
              baseAllocation={baseAllocation}
            />
          )}
          {children}
        </Tab>
        <Tab
          eventKey={StatsTabs.ALL}
          title="All Phases"
          className="project-stats-tab"
        >
          <ProjectPhasesList
            phases={[
              {
                title: 'Registration',
                opens: whitelistOpens,
                closes: whitelistCloses,
                active: isWhitelistingInProgress,
              },
              ...(projectType === ProjectTypes.token_airdrop ? [
                {
                  title: 'Airdrop',
                  opens: airdropOpens,
                  closes: airdropCloses,
                  active: false,
                }
              ] : [
                {
                  title: 'Guaranteed',
                  opens: privateOpens,
                  closes: privateCloses,
                  active: isPrivatePhaseInProgress,
                },
                {
                  title: 'FCFS',
                  opens: publicOpens,
                  closes: publicCloses,
                  active: isPublicPhaseInProgress,
                },
              ])
            ]}
            formatDate={formatDate}
          />
          <div className={'text-center d-flex align-items-center justify-content-center mt-4 mb-4'}>
            <div className={'me-2 white-transparent-text text-wide'}>
              Timezone
            </div>
            <TimezoneSwitcher
              name={`${rewardTokenName}-tz-format`}
              id={`${rewardTokenName}-tz-format`}
              value={currentFormat}
              onChange={switchFormat}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  )
})

ProjectStats.defaultProps = {
  privateOpens: undefined,
  privateCloses: undefined,
  publicOpens: undefined,
  publicCloses: undefined,
  expanded: false,
}

export { ProjectStats }
