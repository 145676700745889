import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import { RoundButton, WhitelistForm } from '@/components'
import { ReactComponent as CloseIcon } from '@assets/close.svg'
import { useKYC } from '@contracts/hooks/useKYC'
import { NormalizedProjectInfo } from '@components/Project/types'
import { IUseWhitelistReturn } from '@contracts/hooks/useWhitelist'
import { KYCStatuses } from '@api/kyc/types'
import './WhitelistModal.scss'
import { ConfirmationPopup } from '@components/common/ConfirmationPopup'

interface Props {
  project: NormalizedProjectInfo
  applyToWhitelist: IUseWhitelistReturn['applyToWhitelist']
}

export const WhitelistModal = ({ project, applyToWhitelist }: Props) => {
  const [show, setShow] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const onConfirmClose = () => {
    setShowConfirm(false)
    setShow(false)
  }

  const { KYCStatus } = useKYC()

  return (
    <>
      <RoundButton className="w-100" size="large" onClick={() => setShow(true)}>
        Apply to Whitelist
      </RoundButton>
      <Modal
        show={show}
        size="lg"
        centered
        onHide={() => setShowConfirm(true)}
        className="wl-modal"
        contentClassName="wl-modal__content tile"
        dialogClassName="wl-modal__dialog"
      >
        <button className="modal-close-button" onClick={() => setShowConfirm(true)}>
          <CloseIcon />
        </button>
        <WhitelistForm
          project={project}
          kycStatus={KYCStatus ?? KYCStatuses.not_sent}
          whitelistInfo={project.whitelisting}
          onSubmit={applyToWhitelist}
        />
        <ConfirmationPopup
          show={showConfirm}
          title={'Are you sure you want to cancel the Whitelist Application?'}
          cancelButton={
            <RoundButton size="large" color="DARK">
              Cancel Application
            </RoundButton>
          }
          confirmButton={
            <RoundButton size="large" color="LIGHT">
              Continue
            </RoundButton>
          }
          onCancel={onConfirmClose}
          onConfirm={() => setShowConfirm(false)}
        >
          <p>The application will be terminated and all data will be reset</p>
        </ConfirmationPopup>
      </Modal>
    </>
  )
}