/**
 * This request is used on the Ethereum side to initiate the linking
 * of the Solana wallet. To finalize the linking, we need to call
 * the finalizeLinkSolanaWallet function on the Solana side.
 */

import { instanceWithSignature as API } from '../config';
import { LinkSolanaWalletArgs, LinkWalletResponse } from './types';
import { sendExceptionReport } from '@utils/errors';
import { AxiosError } from 'axios';
import { OriginalResponseError } from '../types';

export const linkSolanaWallet = async ({
  email,
  wallet_address,
  signed_message
}: LinkSolanaWalletArgs): Promise<LinkWalletResponse> => {
  try {
    const { data } = await API.post<string>('/link-solana-wallet', {
      email,
      wallet_address,
      signed_message
    });

    return {
      result: 'success',
      data: { status: data }
    };
  } catch (err) {
    sendExceptionReport(err);
    const error = err as AxiosError<OriginalResponseError>
    return {
      result: 'error',
      error: error.response?.data ? error.response.data.error : error.message
    }
  }
};
