import React from 'react';
import './GetReadyBadge.scss'
import { RoundButton } from '../RoundButton';
import { RoutesPaths } from '@router/constants';

export const GetReadyBadge = () => {
  return (
    <div className="tile--with-shadow text-center get-ready-badge">
      <div className="tile__main">
        Is your project ready to be launched?
      </div>
      <RoundButton to={RoutesPaths.APPLY_FOR_IDO} size="large" wide>
        Apply here
      </RoundButton>
    </div>
  )
}
