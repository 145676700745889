import { Image } from 'react-bootstrap'
import { Copiable } from '@/components'
import { shorterETHAddress } from '@utils/string'
import { ReactComponent as CopyIcon } from '@assets/copy.svg'
import { ReactComponent as MetamaskIcon } from '@assets/metamask-logo.svg'
import './AddressComponent.scss'
import React from 'react'
import { polygonContractAddresses } from '@firestarter-private/firestarter-library/lib/constants'


export const AddressComponent = (props: { lg: boolean, className: string }) => (
  <div className={`${props.className} token-address`}>
    <Image
      width={32}
      height={32}
      src="/networks/polygon.png"
      className="token-address__network"
    />
    <Copiable text={polygonContractAddresses.flameToken}>
      <span className="token-address__address">
        {shorterETHAddress(polygonContractAddresses.flameToken)}
      </span>
      <CopyIcon className="token-address__copy" />
    </Copiable>
    {props.lg && <MetamaskIcon className="token-address__metamask" />}
  </div>
)
