import { instanceWithSignature as API } from '../config';
import { IApplyToExclusiveWhitelistArgs, IWhitelistSuccessResponse } from '@api/whitelist/types'
import { WhitelistStatus } from '@contracts/hooks/useWhitelist'
import { sendExceptionReport } from '@utils/errors'
import { withWalletRequestFactory } from '@api/factories'

export const sendDataForExclusiveWhitelist = withWalletRequestFactory(
  async (walletParamName, {
    project_id,
    account,
    form_data,
    password,
    referrer
  }: IApplyToExclusiveWhitelistArgs): Promise<WhitelistStatus | null> => {
    try {
      const { data } = await API.post(
        '/apply-to-exclusive-whitelist',
        {
          [walletParamName]: account,
          form_data,
          referrer,
          password
        },
        {
          params: {
            project_id
          }
        }
      )

      return (data as IWhitelistSuccessResponse).status
    } catch (err) {
      sendExceptionReport(err)
      return null
    }
  }
)
