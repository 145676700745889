import React, { useCallback, useMemo, useState } from 'react'
import { useFlame } from '@contracts/hooks/useFlame'
import { withdrawalPenalties } from '@constants'
import {
  balanceToCurrency,
  balanceToNumeric,
  numberToCurrency,
  numericToBalance,
  numericToUint256,
} from '@utils/balanceFormatter'
import {
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap'
import {
  ApprovalSteps,
  BalanceItem,
  CommonTooltip,
  FlameLevel,
  FlameLevelMobile,
  KYCBadge,
  LoadingWrap,
  RoundButton,
  SEOTags,
} from '@components'
import { ReactComponent as InfoIcon } from '@assets/info-icon.svg'
import './Lockup.scss'
import { useFlameTier } from '@contracts/hooks/useFlameTier/useFlameTier'
import { seoTags } from '@/seo-content'
import { Link } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'
import useScreenSize from 'use-screen-size'
import { useApproval } from '@firestarter-private/firestarter-library'
import { polygonContractAddresses } from '@firestarter-private/firestarter-library/lib/constants'
import { differenceInDays } from 'date-fns'

const penaltiesDays = Object.keys(withdrawalPenalties)

enum FormTypes {
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
}

type FormType = keyof typeof FormTypes

const UnlockFormTitle = () => {
  return (
    <>
      {FormTypes.UNLOCK.toLowerCase()}{' '}
      <CommonTooltip id={'lock-form-title-tooltip'} placement={'auto'}>
        Locking in this pool is disabled. <br />
        You can unlock your <b className={'purple-text'}>$FLAME</b> and lock it
        in{' '}
        <Link to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}>
          the NEW locking pool
        </Link>
      </CommonTooltip>
    </>
  )
}

export const Lockup = () => {
  const { allowance, onApprove } = useApproval(
    polygonContractAddresses.flameToken,
    polygonContractAddresses.flameLocking
  )
  const {
    decimals,
    balance,
    lastDepositDays,
    penalty,
    lockedFlame,
    onLock,
    onUnlock,
  } = useFlame()
  const { userTierInfo, getTier, loading: loadingTier } = useFlameTier()

  const [lockFormType, setLockFormType] = useState<FormType>(FormTypes.UNLOCK)
  const [amountToLock, setAmountToLock] = useState('0')
  const [amountToUnlock, setAmountToUnlock] = useState('0')

  const currentPenalty = useMemo(() => {
    let percent: number = 0
    if (penalty.eq(0)) {
      return 0
    }

    if (+lastDepositDays > +penaltiesDays[penaltiesDays.length - 1]) {
      return 0
    }

    const closestDay = penaltiesDays.reduce((a, b) => {
      return +lastDepositDays <= +a ? a : b
    })
    percent = withdrawalPenalties[+closestDay]

    return numberToCurrency((+amountToUnlock / 100) * percent)
  }, [lastDepositDays, amountToUnlock, penalty])

  const setMaxToLock = useCallback(() => {
    setAmountToLock(balanceToNumeric(balance, decimals))
  }, [balance, decimals])

  const setMaxToUnlock = useCallback(() => {
    setAmountToUnlock(balanceToNumeric(lockedFlame, decimals))
  }, [lockedFlame, decimals])

  const disabledLocking = useMemo(() => {
    return true
    // return +amountToLock <= 0
    //   || balance.isLessThan(numericToBalance(amountToLock, decimals))
    //   || allowance.isLessThan(numericToBalance(amountToLock, decimals));
  }, [amountToLock, balance, allowance, decimals])

  const disabledUnlocking = useMemo(() => {
    return (
      +amountToUnlock <= 0 ||
      lockedFlame.isLessThan(numericToBalance(amountToUnlock, decimals))
    )
  }, [amountToUnlock, lockedFlame, decimals])

  const handleApprove = () => onApprove()

  const handleLock = useCallback(async () => {
    if (disabledLocking) {
      return
    }
    const amount = numericToUint256(amountToLock, decimals)
    await onLock(amount, {
      onHash: () => setAmountToLock('0'),
    })
    await getTier()
  }, [amountToLock, onLock, decimals, disabledLocking])

  const handleUnlock = useCallback(async () => {
    if (disabledUnlocking) {
      return
    }
    const amount = numericToUint256(amountToUnlock, decimals)
    await onUnlock(amount, {
      onHash: () => setAmountToUnlock('0'),
    })
    await getTier()
  }, [amountToUnlock, onUnlock, decimals, disabledUnlocking])

  const screen = useScreenSize()
  const mobile = screen.width < 992

  return (
    <div className="account-lockup">
      <SEOTags {...seoTags.accountLockup} />
      <section className="stake-allocation-section">
        <Container>
          <Row className="stake-block account-lockup__stake-block">
            <Col className="block-col" md={{ span: 6 }}>
              <Row className="stake-allocation-row">
                <Col className="text-left">
                  <h2 className="title">
                    Lock your $<span className="purple-text">FLAME</span>.
                    Determine your Tier. <br />
                    Receive Allocations from FireStarter Projects
                  </h2>
                  <div className="bottom-description">
                    The amount of allocation will depend on the amount locked.
                  </div>
                </Col>
              </Row>
              <div className="balances tile">
                <BalanceItem
                  image="/token-logos/FLAME.svg"
                  title="Available to lock"
                  balance={balanceToCurrency(balance, decimals)}
                  token="FLAME"
                  className="top-item"
                />
                <BalanceItem
                  image="/token-logos/locked.svg"
                  title="Amount locked"
                  balance={balanceToCurrency(lockedFlame, decimals)}
                  className="bottom-item"
                />
              </div>
            </Col>
            <Col className="block-col" md={{ span: 6 }}>
              <div className="stake-form">
                <Tabs
                  id="lock-block-tabs"
                  className="stake-block-tabs"
                  activeKey={lockFormType}
                  onSelect={(eventKey) => setLockFormType(eventKey as FormType)}
                >
                  <Tab
                    eventKey={FormTypes.LOCK}
                    disabled
                    title={FormTypes.LOCK.toLowerCase()}
                    className="tile"
                  >
                    <InputGroup className="stake-form__input-group input-group-big">
                      <FormControl
                        placeholder="0.0"
                        type="number"
                        value={amountToLock}
                        onChange={(e) => setAmountToLock(e.target.value)}
                        isInvalid={disabledLocking}
                        isValid={!disabledLocking}
                      />
                      <InputGroup.Append>
                        <RoundButton
                          size="small"
                          color="DARK"
                          onClick={setMaxToLock}
                        >
                          MAX
                        </RoundButton>
                      </InputGroup.Append>
                    </InputGroup>
                    <div className="stake-block__buttons">
                      <RoundButton
                        size="large"
                        disabled={allowance.isGreaterThanOrEqualTo(
                          numericToBalance(amountToLock, decimals),
                        )}
                        onClick={handleApprove}
                      >
                        Approve
                      </RoundButton>
                      <RoundButton
                        size="large"
                        disabled={disabledLocking}
                        onClick={handleLock}
                      >
                        LOCK
                      </RoundButton>
                    </div>
                    <ApprovalSteps
                      fillingCondition={allowance.isGreaterThanOrEqualTo(
                        numericToBalance(amountToLock, decimals),
                      )}
                    />
                  </Tab>
                  <Tab
                    eventKey={FormTypes.UNLOCK}
                    title={<UnlockFormTitle />}
                    className="tile"
                  >
                    <InputGroup className="stake-form__input-group input-group-big">
                      <FormControl
                        placeholder="0.0"
                        type="number"
                        value={amountToUnlock}
                        onChange={(e) => setAmountToUnlock(e.target.value)}
                        isInvalid={disabledUnlocking}
                        isValid={!disabledUnlocking}
                      />
                      <InputGroup.Append>
                        <RoundButton
                          size="small"
                          color="DARK"
                          onClick={setMaxToUnlock}
                        >
                          MAX
                        </RoundButton>
                      </InputGroup.Append>
                    </InputGroup>
                    {+currentPenalty ? (
                      <div className="form-message form-message--warning text-center">
                        <InfoIcon />
                        <span>
                          The penalty for early unlocking is {currentPenalty}{' '}
                          FLAME
                        </span>
                      </div>
                    ) : null}
                    <div className="stake-block__buttons">
                      <RoundButton
                        size="large"
                        disabled={disabledUnlocking}
                        onClick={handleUnlock}
                      >
                        UNLOCK
                      </RoundButton>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <LoadingWrap loading={loadingTier}>
                <p className="bottom-description">
                  Your FLAME power is{' '}
                  <span className="purple-text fw-bold">
                    {' '}
                    {numberToCurrency(userTierInfo?.flamePower ?? 0)}
                  </span>
                  .{' '}
                  {!!(userTierInfo && userTierInfo.tier) ? (
                    <>
                      Your FireStarter Tier is{' '}
                      <span
                        style={{ color: userTierInfo.tierColor ?? '#fff' }}
                        className="fst-italic"
                      >
                        {userTierInfo.tier}
                      </span>
                    </>
                  ) : (
                    <>You don't have a FireStarter Tier yet</>
                  )}
                </p>
              </LoadingWrap>
            </Col>
          </Row>
          {!mobile ? (
            <FlameLevel currentTier={userTierInfo?.tier} />
          ) : (
            <FlameLevelMobile currentTier={userTierInfo?.tier} />
          )}
        </Container>
      </section>
      <section className="kyc-section">
        <Container>
          <KYCBadge />
        </Container>
      </section>
      <section className="penalties-section">
        <Container>
          <Row className="penalties-row justify-content-between">
            <Col md={{ span: 6 }} className="text-left column mb-4">
              <h2 className="title">Early unlock penalties</h2>
              <div className="main">
                There are penalties if you unlock before <span>30 days</span>{' '}
                from the date of your last deposit. Dependent on the number of
                days since the date of your last deposit, the share of the
                unlocked tokens will be burnt:
              </div>
            </Col>
            <Col md={{ span: 6 }} className="text-left column">
              {penaltiesDays.map((key, i) => (
                <div className="layer" key={key}>
                  Less than {key} days ago{' '}
                  <div
                    style={{
                      color:
                        'rgba(254, ' + Math.min(70 * (i + 1), 255) + ', 70, 1)',
                    }}
                  >
                    {withdrawalPenalties[+key]}
                    <span>%</span>
                  </div>
                </div>
              ))}
              <div className="layer">
                After {penaltiesDays[penaltiesDays.length - 1]} days{' '}
                <div className="layer6">
                  0<span>%</span>
                </div>
              </div>
            </Col>
          </Row>
          <h2 className="title mb-4 stats-title">Your stats</h2>
          <Row className="claim-row gx-4">
            <Col md={{ span: 6 }} className="text-center">
              <div className="claim-column tile mb-4">
                <div className="top-description tile__description">
                  Current Penalty
                </div>
                <div className="main tile__main">
                  {balanceToCurrency(penalty, decimals)}
                </div>
              </div>
            </Col>
            <Col md={{ span: 6 }} className="text-center">
              <div className="claim-column tile">
                <div className="top-description tile__description">
                  Last Deposit
                </div>
                <div className="main tile__main">
                  {differenceInDays(new Date(),  lastDepositDays)} day(s) ago
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
