import './TiersTableMobile.scss'
import React from 'react';
import { Accordion as BaseAccordion, Card } from 'react-bootstrap';
import {
  FCFSHeader,
  GuaranteedBody,
  GuaranteedHeader,
  LightAccordion,
  noCooldown,
  sevenDayCooldown,
  TierAccordion
} from '@components/common/TiersTableMobile/TiersComponents'
import { ArrowIcon, CustomToggle } from '@/components';


export const TiersTableMobile = (props: { breakpoints: string[] }) => (
  <div>
    <LightAccordion
      tier="LIGHT"
      power={props.breakpoints[0]}
      multiple="n/a"
      tickets="10"
      cooldown={sevenDayCooldown}
    />
    <BaseAccordion key="tiers">
      <Card className="tiers-mobile-accordion">
        <Card.Header>
          <CustomToggle eventKey="tiers">
            <FCFSHeader />
            <ArrowIcon />
          </CustomToggle>
        </Card.Header>
        <BaseAccordion.Collapse eventKey="tiers">
          <Card.Body className="fcfs">
            <TierAccordion
              tier="SPARK"
              multiple="base allocation"
              power={props.breakpoints[1]}
              tickets="1-9"
              cooldown={sevenDayCooldown}
            />
            <TierAccordion
              tier="SMOKE"
              multiple="2x"
              power={props.breakpoints[2]}
              tickets="10-19"
              cooldown={sevenDayCooldown}
            />
            <TierAccordion
              tier="FIRE"
              multiple="4x"
              power={props.breakpoints[3]}
              tickets="20-49"
              cooldown={sevenDayCooldown}
            />
            <TierAccordion
              tier="FLAME"
              multiple="8x"
              power={props.breakpoints[4]}
              tickets="50+"
              cooldown={noCooldown}
            />
          </Card.Body>
        </BaseAccordion.Collapse>
      </Card>
    </BaseAccordion>
    <BaseAccordion key="guaranteed">
      <Card className="tiers-mobile-accordion">
        <Card.Header>
          <CustomToggle eventKey="guaranteed">
            <GuaranteedHeader />
            <ArrowIcon />
          </CustomToggle>
        </Card.Header>
        <BaseAccordion.Collapse eventKey="guaranteed">
          <Card.Body>
            <GuaranteedBody />
          </Card.Body>
        </BaseAccordion.Collapse>
      </Card>
    </BaseAccordion>
  </div>
);
