import { numberToCurrency } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'

export const getFundraiseGoalString = (tokenName: string, amount?: number) =>
  amount ? `${numberToCurrency(amount, 0)} ${tokenName}` : ' TBA'

export const getTokenPriceString = (fundTokenName: string, rewardTokenName: string, exchangeRate?: number) =>
  exchangeRate ? `1 ${rewardTokenName} = ${numberToCurrency(exchangeRate, 10)} ${fundTokenName}` : 'TBA'

export const getParticipantsString = (participants?: number) => participants ?? '--'

export const getBaseAllocationString = (baseAllocation?: number) =>
  baseAllocation ? `$${numberToCurrency(baseAllocation, 0)}` : 'TBD'

export const getTotalRaisedFundsString = (goal?: number, raised?: number) =>
  (goal && raised) ? `${numberToCurrency(raised, 0)}/${numberToCurrency(goal, 0)}` : 'TBA'

export const getRoiString = (ROI?: number) =>
  (ROI && ROI > 1) ? `x${Math.floor(ROI * 10)/10}` : '--'
