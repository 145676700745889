import { useEffect, useState } from 'react'

export const usePrevious = (value: any) => {
  const [current, setCurrent] = useState(value);
  const [previous, setPrevious] = useState(value);

  useEffect(() => {
    if (value !== current) {
      setPrevious(current);
      setCurrent(value);
    }
  }, [value])

  return previous;
}