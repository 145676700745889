import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { getWhitelistData, sendDataForExclusiveWhitelist, sendDataForWhitelist } from '@api/whitelist';
import { useNetwork, useWalletContext, useWhitelistContract } from '@firestarter-private/firestarter-library'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import { isEmptyObject } from '@firestarter-private/firestarter-library/lib/utils/object'
import {
  IUseWhitelistArgs,
  IUseWhitelistReturn,
  WhitelistStatus,
  WhitelistStatuses
} from '@contracts/hooks/useWhitelist/types'
import { defaultEVMNetworkId } from '@firestarter-private/firestarter-library/lib/constants'

export const useWhitelistEVM = ({ contract, projectId }: IUseWhitelistArgs): IUseWhitelistReturn => {
  const isMountedRef = useIsMounted()
  const { account } = useWalletContext()
  const { currentNetworkId } = useNetwork()
  const [whiteListStatus, setStatus] = useState<WhitelistStatus | null>(null)
  const [privatePresaleAllowed, setPrivatePresaleAllowed] = useState(false)
  const [airdropAmount, setAirdropAmount] = useState<BigNumber | undefined>()
  const [publicMaxAllocation, setPublicMaxAllocation] = useState<BigNumber>(new BigNumber(0))
  const [privateMaxAllocation, setPrivateMaxAllocation] = useState<BigNumber>(new BigNumber(0))
  const [isKycPassed, setIsKycPassed] = useState(false)
  const [merkleProof, setMerkleProof] = useState<string[]>()
  const whitelistContract = useWhitelistContract(contract)

  const resetStatus = () => setStatus(WhitelistStatuses.not_submitted)

  const resetAllo = () => {
    setIsKycPassed(false)
    setPublicMaxAllocation(new BigNumber(0))
    setPrivateMaxAllocation(new BigNumber(0))
    setPrivatePresaleAllowed(false)
    setMerkleProof(undefined)
  }

  const getStatusAndAllo = useCallback(async () => {
    if (!account) {
      resetStatus()
      resetAllo()
      return
    }

    const data = await getWhitelistData(currentNetworkId || defaultEVMNetworkId)( {
      account,
      projectId
    })
    if (!isMountedRef.current) return
    if (!data) {
      resetStatus()
      resetAllo()
      return
    }

    setStatus(data.status)
    if (data.paramsData && !isEmptyObject(data.paramsData)) {
      const {
        is_kyc_passed,
        airdrop,
        private_allocation,
        private_presale_allowed,
        public_allocation,
        merkle_proof,
      } = data.paramsData
      setIsKycPassed(is_kyc_passed)
      airdrop && setAirdropAmount(new BigNumber(airdrop))
      setPrivateMaxAllocation(new BigNumber(private_allocation))
      setPublicMaxAllocation(new BigNumber(public_allocation))
      setPrivatePresaleAllowed(private_presale_allowed)
      setMerkleProof(merkle_proof)
      return
    }

    if (!whitelistContract) {
      resetAllo()
      return
    }

    const {
      '1': kycPassed,
      '2': publicAllocation,
      '3': privateAllowed,
      '4': privateAllocation
    } = await whitelistContract.methods.getUser(account).call()

    if (isMountedRef.current) {
      setIsKycPassed(kycPassed)
      setPublicMaxAllocation(new BigNumber(publicAllocation))
      setPrivateMaxAllocation(new BigNumber(privateAllocation))
      setPrivatePresaleAllowed(privateAllowed)
    }

  }, [account, whitelistContract, isMountedRef, currentNetworkId])

  useEffect(() => {
    getStatusAndAllo()
  }, [projectId, whitelistContract, account])

  const applyToWhitelist = useCallback(async (formData) => {
    if (!account) return null

    const status = await sendDataForWhitelist(currentNetworkId || defaultEVMNetworkId)( {
      project_id: projectId,
      account,
      form_data: formData
    })

    if (status) setStatus(status)

    return status
  }, [projectId, account, currentNetworkId])

  const applyToExclusiveWhitelist = useCallback(async (
    formData,
    referrer,
    password
  ) => {
    if (!account) return null

    const status = await sendDataForExclusiveWhitelist(currentNetworkId || defaultEVMNetworkId)( {
      project_id: projectId,
      account,
      form_data: formData,
      referrer,
      password
    })

    if (status) setStatus(status)

    return status
  }, [projectId, account, currentNetworkId])

  return {
    whiteListStatus,
    isKycPassed,
    privatePresaleAllowed,
    airdropAmount,
    publicMaxAllocation,
    privateMaxAllocation,
    merkleProof,
    applyToWhitelist,
    applyToExclusiveWhitelist
  }
}
