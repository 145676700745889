import { PublicKey } from '@solana/web3.js'
import { getCurrentNetworkId } from '@contracts/networks'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'

export const FSProjectId = process.env.REACT_APP_FIRESTARTER_PROJECT_ID;
export const NFTLinkdropCampaign = '';
export const OpenSeaFlareNFTCollection = 'https://opensea.io/collection/firestartermetaversecollection'
export const OpenSeaHiroNFTCollection = 'https://opensea.io/collection/firestartermetaversechampion'
export const buyFlameLink = process.env.REACT_APP_BUY_FLAME_LINK;
export const KuCoinFlameLink = 'https://www.kucoin.com/trade/FLAME-USDT?rcode=e21sNJ'
export const buyFlameUSDCLpLink = process.env.REACT_APP_BUY_FLAME_USDC_LP_LINK;
export const buyFlameUSDTLpLinkSol = process.env.REACT_APP_SOL_BUY_FLAME_USDT_LP_LINK

export const POLYGON_FLAME_DECIMALS = 18;
export const POLYGON_FLAME_USDC_LP_DECIMALS = 18;
export const SOL_FLAME_DECIMALS = 9;
export const SOL_FLAME_USDC_LP_DECIMALS = 9;

export const FLAME_DECIMALS = isSolanaNetwork(getCurrentNetworkId())
  ? SOL_FLAME_DECIMALS
  : POLYGON_FLAME_DECIMALS

export const FLAME_USDC_LP_DECIMALS = isSolanaNetwork(getCurrentNetworkId())
  ? SOL_FLAME_USDC_LP_DECIMALS
  : POLYGON_FLAME_USDC_LP_DECIMALS

export const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'
)
