import React, { useMemo } from 'react'
import { ProjectStatusProp, ProjectType, ProjectTypes, SocialLink, TokenInfo } from '../types';
import './ProjectWidget.scss';
import { NetworkId, NetworkName } from '@firestarter-private/firestarter-library/lib/constants';
import { ProjectSocialLinks } from '@components/Project/ProjectSocialLinks/ProjectSocialLinks'
import { ProjectTag } from '@/components'
import { Image } from 'react-bootstrap'
import { generatePath, useHistory } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'
import { format, isPast } from 'date-fns'
import {
  getBaseAllocationString,
  getParticipantsString,
  getTokenPriceString,
  getFundraiseGoalString,
  getTotalRaisedFundsString,
  getRoiString,
} from '@components/Project/utils'


export interface ProjectWidgetProps {
  id: string
  name: string
  logo: string
  background: string
  mainColor?: string
  chainId: NetworkId
  status: ProjectStatusProp
  fundToken: TokenInfo
  rewardToken: TokenInfo
  socialLinks: SocialLink[]
  network: NetworkName
  projectType: ProjectType
  totalFunds?: number
  fundraiseGoal?: number
  airdropAmount?: number
  ROI?: number
  pricePerToken?: number
  opens?: Date | number | null
  closes?: Date | number | null
  airdropDate?: Date | number | null
  baseAllocation?: number
  participants?: number
}

const ProjectWidget = ({
  id,
  mainColor,
  chainId,
  logo,
  name,
  background,
  status,
  socialLinks,
  network,
  projectType,
  fundToken,
  rewardToken,
  closes,
  opens,
  airdropDate,
  baseAllocation,
  participants,
  pricePerToken,
  totalFunds,
  fundraiseGoal,
  airdropAmount,
  ROI,
}: ProjectWidgetProps) => {
  const history = useHistory();
  const pushRoute = () => history.push(generatePath(RoutesPaths.PROJECT, { id }))

  return (
      <div
        className="project-widget tile"
        data-color={mainColor}
        style={{
          // @ts-ignore
          '--project-main-color': mainColor,
        }}
        onClick={pushRoute}
      >
        <div className="project-widget__inner">
          <div className='project-widget__background'>
            <Image src={background} className='project-widget__background-image' />
            <ProjectTag type="status" value={status} />
            <Image src={logo} roundedCircle className='project-widget__background-logo' />
          </div>
          <div className="text-center">
            <h3 className='project-widget__name'>{name}</h3>
          </div>
          <div className='project-tags'>
            {projectType !== ProjectTypes.token_presale &&
              <ProjectTag type="project" projectType={projectType} value={projectType} />}
            <ProjectTag type="network" value={network} />
            {![ProjectTypes.NFT_claim, ProjectTypes.NFT_presale, ProjectTypes.token_airdrop].includes(projectType as ProjectTypes) &&
              <ProjectTag type="token" projectType={projectType} value={fundToken.name} />}
          </div>
          {
            projectType === ProjectTypes.token_airdrop ? (
              <AirdropInfo
                airdropAmount={airdropAmount}
                tokenName={rewardToken.name}
                airdropDate={airdropDate}
              />
            ) : (
              <ProjectInfo
                totalFunds={totalFunds}
                fundraiseGoal={fundraiseGoal}
                ROI={ROI}
                fundTokenName={fundToken.name}
                rewardTokenName={rewardToken.name}
                opens={opens}
                closes={closes}
                baseAllocation={baseAllocation}
                participants={participants}
                pricePerToken={pricePerToken}
              />
            )
          }
          <ProjectSocialLinks socialLinks={socialLinks} />
        </div>
      </div>
  )
}

interface ProjectInfoFields {
  totalFunds?: number
  fundraiseGoal?: number
  pricePerToken?: number
  baseAllocation?: number
  ROI?: number
  opens?: Date | number | null
  closes?: Date | number | null
  participants?: number
  fundTokenName: string
  rewardTokenName: string
}

function ProjectInfo({
  totalFunds,
  fundraiseGoal,
  ROI,
  pricePerToken,
  baseAllocation,
  participants,
  opens,
  closes,
  fundTokenName,
  rewardTokenName,
}: ProjectInfoFields) {
  const closed = useMemo(() => closes ? isPast(closes) : false, [closes])

  const dateString = useMemo(() => {
    const date = closed ? closes : opens
    return date ? format(date, 'yyyy-MM-dd') : 'TBA'
  }, [closed, closes, opens])


  return (
    <div className='project-widget__info'>
      <dl className='info-list'>
        <div>
          <dt className='name'>{closed ? 'Total Raised' : 'Fundraise Goal'}</dt>
          <dd className='value goal'>
            {closed
              ? getTotalRaisedFundsString(fundraiseGoal, totalFunds)
              : getFundraiseGoalString(fundTokenName, fundraiseGoal)}
          </dd>
        </div>
        {
          closed ? (
            <div>
              <dt className='name'>Participants</dt>
              <dd className='value'>{getParticipantsString(participants)}</dd>
            </div>
          ) : (
            <div>
              <dt className='name'>Price Per Token</dt>
              <dd className='value'>
                {getTokenPriceString(fundTokenName, rewardTokenName, pricePerToken)}
              </dd>
            </div>
          )
        }
        <div>
          <dt className='name'>Base Allocation</dt>
          <dd className='value'>{getBaseAllocationString(baseAllocation)}</dd>
        </div>
        <div>
          <dt className='name'>{closed ? 'Ended on' : 'IDO Date'}</dt>
          <dd className='value'>{dateString}</dd>
        </div>
        {closed && (
          <div>
            <dt className="name">ROI</dt>
            <dd className="value">{getRoiString(ROI)}</dd>
          </div>
        )}
      </dl>
    </div>
  )
}

interface AirdropInfoFields {
  tokenName: string
  airdropAmount?: number
  airdropDate?: Date | number | null
}

function AirdropInfo({
  tokenName,
  airdropAmount,
  airdropDate
}: AirdropInfoFields) {
  const dateString = useMemo(() => airdropDate ? format(airdropDate, 'yyyy-MM-dd') : 'TBA', [airdropDate])

  return (
    <div className="project-widget__info">
      <dl className="info-list">
        <div>
          <dt className="name">Airdrop Amount</dt>
          <dl className="value goal">{getFundraiseGoalString(tokenName, airdropAmount)}</dl>
        </div>
        <div>
          <dt className="name">Airdrop Date</dt>
          <dl className="value goal">{dateString}</dl>
        </div>
      </dl>
    </div>
  )
}

ProjectWidget.defaultProps = {
  mainColor: 'rgba(254, 111, 70, 0.5)'
}

export { ProjectWidget }
