import { useEffect, useState } from 'react'
import { CMSContent } from '@/services/CMS/types'
import { getCMSContent } from '@/services/CMS/api/getCMSContent'

export const useCMSContent = (url?: string) => {
  const [content, setContent] = useState<CMSContent>()

  useEffect(() => {
    if (url) {
      getCMSContent(url).then(setContent)
    }
  }, [url])

  return content
}
