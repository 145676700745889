import React from 'react'
import './BalanceItem.scss'
import { Image } from 'react-bootstrap'
import { CommonTooltip } from '../CommonTooltip'
import useScreenSize from 'use-screen-size'

interface Props {
  image: string
  title: string
  balance: string
  token?: string
  tooltipText?: string
  className?: string
}

function BalanceComponent(props: {
  balance: string
  token: string | undefined
}) {
  return (
    <div className="tile__main balance-item__main">
      {props.balance} {props.token}
    </div>
  )
}


export const BalanceItem = ({
  image,
  title,
  balance,
  token,
  tooltipText,
  className,
}: Props) => {
  const screenSize = useScreenSize()
  const mobile = screenSize.width < 992

  return (
    <div className={`balance-item ${className ?? ''}`}>
      <div className="balance-item__desc">
        <Image className="balance-item__icon" src={image} rounded />
        <div>
          <div className="tile__description balance-item__title">
            {title}
            {tooltipText ? (
              <CommonTooltip
                id={`${title.toLowerCase().replace(' ', '-')}-tooltip`}
                placement="auto"
              >
                {tooltipText}
              </CommonTooltip>
            ) : (
              ''
            )}
          </div>
          {mobile && <BalanceComponent balance={balance} token={token} />}
        </div>
      </div>
      {!mobile && <BalanceComponent balance={balance} token={token} />}
    </div>
  )
}
