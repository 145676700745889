export function throttle<A extends any[]>(callback: (...args: A) => unknown, delay: number) {
  let running = false
  let storedArgs: any = null

  function checkStoredArgs() {
    if (storedArgs === null) {
      running = false
    } else {
      callback(...storedArgs)
      storedArgs = null
      setTimeout(checkStoredArgs, delay)
    }
  }

  return (...args: A) => {
    if (running) {
      storedArgs = args
      return
    }

    callback(...args)
    running = true
    setTimeout(checkStoredArgs, delay)
  }
}

export function throttleAnimation<A extends any[]>(callback: (...args: A) => unknown) {
  let running = false

  return (...args: A) => {
    if (running) {
      return
    }

    running = true

    requestAnimationFrame(() => {
      callback(...args)
      running = false
    })
  }
}