import { Container } from 'react-bootstrap'
import { TiersTable } from '@components'
import React from 'react'
import './TiersSection.scss'

export const TiersSection = () => (
  <section className="tiers-section">
    <Container>
      <h2 className="title">FireStarter Tiers & Access to IDOs</h2>
      <TiersTable />
    </Container>
  </section>
);
