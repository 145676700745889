import { Col, Container, Row } from 'react-bootstrap'
import { faqItems } from '../../Homepage/contents'
import { Accordion, RoundButton, SEOTags } from '../../../components'
import React from 'react'
import { supportEmail } from '../../../constants'
import { seoTags } from '../../../seo-content'
import './FAQ.scss'
import useScreenSize from 'use-screen-size'

const FAQButton = () => (
  <div className="faq-btn">
    <p className="subtitle">Didn't find the answer to your question?</p>
    <RoundButton href={`mailto:${supportEmail}`} size="large">
      Contact Support
    </RoundButton>
  </div>
)

export const FAQPage = () => {
  const screen = useScreenSize()
  const mobile = screen.width < 992

  return (
    <div className="page marketing-page faq-page">
      <SEOTags {...seoTags.faqPage} />
      <section className="marketing-content">
        <Container>
          <Row>
            <Col xs={{ span: 12 }} lg={{ span: 3 }} xl={{ span: 4 }}>
              <h1 className="title">FAQ</h1>
              {!mobile && <FAQButton />}
            </Col>
            <Col xs={{ span: 12 }} lg={{ span: 9 }} xl={{ span: 8 }}>
              <Accordion items={faqItems} />
            </Col>
          </Row>
          {mobile && <FAQButton />}
        </Container>
      </section>
    </div>
  )
}
