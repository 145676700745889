import { useMemo } from 'react'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { useNetwork } from '@firestarter-private/firestarter-library'
import { getCurrentNetworkId } from '@contracts/networks'

export const useIsSolana = () => {
  const { currentNetworkId } = useNetwork()
  return useMemo(
    () => isSolanaNetwork(currentNetworkId || getCurrentNetworkId()),
    [currentNetworkId]
  )
}
