import { IgnitionStatuses } from '@contracts/hooks/useNFTs';
import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RoundButton, Spinner } from '@components/common';
import './IgnitingResultModal.css'

interface Props {
  show: boolean
  result: IgnitionStatuses
  onConfirmSuccess: () => void | Promise<void>
  onConfirmFail: () => void | Promise<void>
}

export const IgnitingResultModal = ({
  show,
  result,
  onConfirmSuccess,
  onConfirmFail,
}: Props) => {
  const [whileConfirming, setWhileConfirming] = useState(false)

  const ignitingResultMessage = useMemo(() => {
    switch (result) {
      case IgnitionStatuses.UNIGNITED:
        return `We're sorry.\n Your Flare was not ignited. Look out for future campaigns for a chance to try again.`
      case IgnitionStatuses.IGNITED:
        return `Congratulations!\n Your Flare has been ignited into Hiro!`
      default:
        return ''
    }
  }, [result])

  const handleConfirm = useCallback(async () => {
    setWhileConfirming(true)
    result === IgnitionStatuses.IGNITED
      ? await onConfirmSuccess()
      : await onConfirmFail()
    setWhileConfirming(false)
  }, [onConfirmFail, onConfirmSuccess, result])

  return (
    <Modal
      show={show}
      centered
      className="igniting-result-modal"
      contentClassName="tile text-center"
      backdrop="static"
    >
      <Modal.Body>
        <p className='igniting-result-message'>
          {ignitingResultMessage}
        </p>
        <div className='igniting-result-buttons'>
          <RoundButton
            className="igniting-result-button"
            size="large"
            wide
            disabled={whileConfirming}
            onClick={handleConfirm}
          >
            {whileConfirming ? <Spinner /> : (
              result === IgnitionStatuses.IGNITED ? 'View Hiro' : 'Ok'
            )}
          </RoundButton>
        </div>
      </Modal.Body>
    </Modal>
  )
}
