export type ContractAddress = string;
export type WalletAddress = string;
export type ProgramId = string
export type ProgramAccountAddress = string

/**
 * Token addresses in lower case for which approval cannot be increased
 * while it is different from 0
 */
const singleApprovalTokens: Set<ContractAddress> = new Set([
  '0xdac17f958d2ee523a2206206994597c13d831ec7',
  // USDT token on Ethereum network (ERC-20) has such behavior
  // while on other networks it doesn't
])

export const getIsSingleApprovalToken = (tokenAddress: ContractAddress): boolean => {
  return singleApprovalTokens.has(tokenAddress.toLowerCase())
}
