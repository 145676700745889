import { LootBox, LootBoxFormatted, LootBoxNFT, LootBoxNFTFormatted } from './types'
import { toChecksumAddress } from '@firestarter-private/firestarter-library/lib/utils/addresses'
import BigNumber from 'bignumber.js'

export const mapLootBox = (box: LootBox): LootBoxFormatted => {
  return {
    id: box.id,
    boxId: box.boxId,
    wallet: toChecksumAddress(box.owner),
    contract: box.contract,
    buyAmount: new BigNumber(box.buyAmount),
    rewardType: Number(box.rewardType) as (0 | 1),
    rewardAmount: new BigNumber(box.rewardAmount),
    claimedAmount: new BigNumber(box.receivedAmount),
    createdAt: new Date(+box.createdAt * 1000),
    isVesting: box.isVesting,
    txHash: box.transaction
  }
}

export const mapLootBoxNFT = (nft: LootBoxNFT): LootBoxNFTFormatted => {
  return {
    id: nft.id,
    wallet: toChecksumAddress(nft.owner),
    tokenId: nft.tokenId,
    amount: Number(nft.value)
  }
}