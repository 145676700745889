import { instance as API } from '../config';
import { GetSolanaLinkingStatusArgs, GetSolanaLinkingStatusSuccessResponse } from './types';
import { sendExceptionReport } from '@utils/errors';

export const getSolanaLinkingStatus = async ({
    solana_wallet_address,
    solana_reference_id
  }: GetSolanaLinkingStatusArgs): Promise<GetSolanaLinkingStatusSuccessResponse | null> => {
  try {
    const { data } = await API.get('/get-linking-status', {
      params: {
        solana_wallet_address,
        solana_reference_id
      }
    });

    return data as GetSolanaLinkingStatusSuccessResponse;
  } catch (err) {
    sendExceptionReport(err);
    return null;
  }
};
