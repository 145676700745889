import {
  IUseMultiperiodLocking,
  LockEntry,
  LockEntryInfo,
  LockPeriodInfo,
} from '@contracts/hooks/useMultiperiodLocking/types'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import {
  useMultiperiodLockingContract,
  useTokenInfo,
  useTransactions,
  useWalletContext,
} from '@firestarter-private/firestarter-library'
import {
  NetworkType,
  polygonContractAddresses,
} from '@firestarter-private/firestarter-library/lib/constants'
import { useCallback, useEffect, useState } from 'react'
import { BlockNumber, TransactionReceipt } from 'web3-core'
import {
  toBigNumber,
  numericToUint256,
} from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import {
  getAccumulatedLockingRewards,
  getLockEntriesForPolygon,
  getLockingPenaltyForPolygon,
  getLockingPeriodsForPolygon,
} from '@contracts/hooks/useMultiperiodLocking/functions'
import BigNumber from 'bignumber.js'
import { sendExceptionReport } from '@utils/errors'
import { getLockingError, getLockingErrorAndReport } from '@contracts/hooks/useMultiperiodLocking/errors'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'
import { TransactionResult } from '@contracts/types'

export const useMultiperiodLockingEVM = (): IUseMultiperiodLocking => {
  const isMountedRef = useIsMounted()

  const lockingContract = useMultiperiodLockingContract(NetworkType.evm)
  const { account } = useWalletContext()
  const { callTransaction, sendTransaction } = useTransactions()
  const flameToken = useTokenInfo(polygonContractAddresses.flameToken)

  const [loadingPeriods, setLoadingPeriods] = useState(false)
  const [loadingEntries, setLoadingEntries] = useState(false)
  const [lockPeriods, setLockPeriods] = useState<LockPeriodInfo[]>()
  const [lockEntries, setLockEntries] = useState<
    Record<string, LockEntryInfo>
  >()
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const [totalLocked, setTotalLocked] = useState(toBigNumber(0))

  const getPeriods = useCallback(async () => {
    if (!lockingContract) return
    setLoadingPeriods(true)

    const periodsResponse = await getLockingPeriodsForPolygon(
      callTransaction,
      blockNumber,
    )(lockingContract)

    setLockPeriods(periodsResponse.periods)
    setLoadingPeriods(false)
  }, [lockingContract, blockNumber, callTransaction])

  const getAccumulatedRewards = useCallback(
    (lockInfo: LockEntry): BigNumber => {
      return getAccumulatedLockingRewards(lockInfo, lockPeriods)
    },
    [lockPeriods],
  )

  const getPenalty = useCallback(
    async (stakeId: string): Promise<BigNumber> => {
      if (!lockingContract) return toBigNumber(0)
      return await getLockingPenaltyForPolygon(callTransaction, blockNumber)(
        lockingContract,
        stakeId,
      )
    },
    [lockingContract, callTransaction, blockNumber],
  )

  const getEntries = useCallback(async () => {
    if (!lockingContract || !account || !lockPeriods) return
    setLoadingEntries(true)
    const entriesResponse = await getLockEntriesForPolygon(
      callTransaction,
      blockNumber,
    )({
      lockingContract,
      account,
      periods: lockPeriods,
    })

    if (isMountedRef.current) {
      setLockEntries(entriesResponse.entries)
      setTotalLocked(entriesResponse.totalLocked)
      setLoadingEntries(false)
    }
  }, [
    lockingContract,
    account,
    lockPeriods,
    blockNumber,
    callTransaction,
    isMountedRef,
  ])

  useEffect(() => {
    getPeriods()
  }, [lockingContract])

  const lock = useCallback(
    async (
      amount: string,
      tierIndex: number,
      callbacks: INotifyTxCallbacks = {},
    ): Promise<TransactionResult> => {
      if (!lockingContract) return getLockingErrorAndReport('No contract')
      if (!account) return getLockingErrorAndReport('No user account')
      if (!lockPeriods) return getLockingErrorAndReport('No lock tiers')

      try {
        const receipt = (await sendTransaction(
          lockingContract.methods.stake(
            numericToUint256(amount, flameToken.decimals),
            tierIndex,
          ),
          callbacks,
        )) as TransactionReceipt
        setBlockNumber(receipt.blockNumber)

        return {
          data: receipt.events?.Stake,
        }
      } catch (error) {
        sendExceptionReport(error)
        return { error }
      }
    },
    [lockingContract, sendTransaction, account, lockPeriods, flameToken],
  )

  const unlock = useCallback(
    async (stakeId: string, callbacks: INotifyTxCallbacks = {}) => {
      if (!lockingContract) return getLockingErrorAndReport('No contract')
      if (!lockEntries) return getLockingErrorAndReport('No lock entries')
      if (!account) return getLockingErrorAndReport('No user account')

      try {
        const receipt = (await sendTransaction(
          lockingContract.methods.unstake(stakeId),
          callbacks,
        )) as TransactionReceipt
        setBlockNumber(receipt.blockNumber)
        return {
          data: receipt.events?.Unstake,
        }
      } catch (error) {
        sendExceptionReport(error)
        return { error }
      }
    },
    [lockingContract, sendTransaction, lockEntries, account, flameToken],
  )

  const unlockEarly = useCallback(
    async (stakeId: string, callbacks: INotifyTxCallbacks = {}) => {
      if (!lockingContract) return getLockingErrorAndReport('No contract')

      try {
        const receipt = (await sendTransaction(
          lockingContract.methods.unstakeEarly(stakeId),
          callbacks,
        )) as TransactionReceipt
        setBlockNumber(receipt.blockNumber)
        return {
          data: receipt.events?.UnstakeEarly,
        }
      } catch (error) {
        sendExceptionReport(error)
        return { error }
      }
    },
    [lockingContract, sendTransaction],
  )

  const unlockEarlyWithHiro = useCallback(
    async (
      stakeId: string,
      hiro: string,
      callbacks: INotifyTxCallbacks = {},
    ) => {
      if (!lockingContract) return getLockingErrorAndReport('No contract')
      if (!account) return getLockingErrorAndReport('No user account')
      if (!lockEntries) return getLockingErrorAndReport('No lock entries')

      try {
        const receipt = (await sendTransaction(
          lockingContract.methods.unstakeEarlyUsingHiro(stakeId, hiro),
          callbacks,
        )) as TransactionReceipt
        setBlockNumber(receipt.blockNumber)
        return {
          data: receipt.events?.UnstakeWithHiro,
        }
      } catch (error) {
        sendExceptionReport(error)
        return { error }
      }
    },
    [lockingContract, sendTransaction],
  )

  return {
    loadingPeriods,
    loadingEntries,
    lockPeriods,
    lockEntries,
    totalLocked,
    flameToken,
    getPeriods,
    getEntries,
    getPenalty,
    getAccumulatedRewards,
    lock,
    unlock,
    unlockEarly,
    unlockEarlyWithHiro,
  }
}
