import { CMSBlock, CMSBlockWithType, CMSComponentBase, CMSComponentUpload, CMSTextNode } from '@/services/CMS/types'
import React, { Fragment } from 'react'
import { HTitle } from '@/services/CMS/components/HTitle'
import { LargeBody } from '@/services/CMS/components/LargeBody'
import { Link } from '@/services/CMS/components/Link'
import { Indent } from '@/services/CMS/components/Indent'
import { List } from '@/services/CMS/components/List'
import { ListItem } from '@/services/CMS/components/ListItem'
import { Media } from '@/services/CMS/components/Media'
import { TextNode } from '@/services/CMS/components/TextNode'
import { Paragraph } from '@/services/CMS/components/Paragraph'

const components = {
  h2: HTitle,
  h3: HTitle,
  h4: HTitle,
  h5: HTitle,
  h6: HTitle,
  p: Paragraph,
  'large-body': LargeBody,
  link: Link,
  indent: Indent,
  ol: List,
  ul: List,
  li: ListItem,
  upload: Media,
}

const isTextNode = (block: CMSBlock): block is CMSTextNode => 'text' in block
const isParagraph = (block: CMSBlock): block is Omit<CMSComponentBase, 'type'> => 'children' in block && !block.type
const isMedia = (block: CMSBlock): block is CMSComponentUpload => 'mimeType' in block && 'url' in block

export const renderBlocks = (blocks: CMSBlock[]) => {
  return (
    <Fragment>
      {blocks.map((block, i) => {
        if (isTextNode(block) && !block.text) {
          return <span key={i} />
        }

        const Component: React.FC<any> = isTextNode(block)
            ? TextNode
            : isParagraph(block)
              ? Paragraph
              : components[(block as CMSBlockWithType).type]

        return isMedia(block) ? (
          <Component key={i} {...block} />
        ) : isTextNode(block) ? (
          <Component key={i} {...block}>
            {block.text}
          </Component>
        ) : (
          <Component key={i} {...block}>
            {renderBlocks(block.children)}
          </Component>
        )
      })}
    </Fragment>
  )
}
