import React, { useCallback, useState } from 'react'
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import { useStorages, withStorage } from '@hooks/useStorages'
import { NetworkId } from '@firestarter-private/firestarter-library/lib/constants'
import { switchNetworkCallback } from '@utils/web3'
import { getComponentDisplayName } from '@/hocs/utils'
import { UserRejectedTxError } from '@firestarter-private/firestarter-library/lib/utils/errors'
import { detectMobile, detectPhantomInAppBrowser } from '@utils/detectMobile'
import { isEthNetwork, isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { EVMinPhantomError, SolanaNotSupportedError } from '@utils/errors'

type Optionalize<T extends K, K> = Omit<T, keyof K>

export interface WithNetworkSwitchingProps {
  isSwitching: boolean
  handleSwitch: (id: NetworkId) => Promise<void>
}

export const withNetworkSwitching = <
  P extends WithNetworkSwitchingProps = WithNetworkSwitchingProps
>(
  WrappedComponent: React.ComponentType<P>
) => {

  const ComponentWithSwitching = (props: Optionalize<P, WithNetworkSwitchingProps>) => {
    const [isSwitching, setSwitching] = useState(false)
    const isMountedRef = useIsMounted()
    const { appStorage } = useStorages()
    const { switchNetwork } = useNetwork()
    const { setError } = useWalletContext()

    const handleSwitch = useCallback(async (id: NetworkId) => {
      if (detectPhantomInAppBrowser() && isEthNetwork(id)) {
        return setError(new EVMinPhantomError())
      }

      if (isSolanaNetwork(id) && detectMobile() && !detectPhantomInAppBrowser()) {
        return setError(new SolanaNotSupportedError())
      }

      setSwitching(true)
      try {
        await switchNetwork(id, withStorage(appStorage)(switchNetworkCallback))
      } catch (err) {
        if (!(err instanceof UserRejectedTxError)) {
          setError(err)
        }
      } finally {
        isMountedRef.current && setSwitching(false)
      }
    }, [switchNetwork, isMountedRef, appStorage])

    const switchingProps: WithNetworkSwitchingProps = {
      isSwitching,
      handleSwitch,
    }

    return <WrappedComponent
      {...switchingProps}
      {...(props as P)}
    />
  }

  ComponentWithSwitching.displayName = `withNetworkSwitching(${getComponentDisplayName(WrappedComponent)})`
  ComponentWithSwitching.defaultProps = WrappedComponent.defaultProps

  return ComponentWithSwitching
}
