import { useCallback, useContext, useRef } from 'react';
import { useSelector } from '@hooks/useSelector';
import { getCompletedProjects, getFeaturedProjects, getLiveProjects, getUpcomingProjects } from '@store/getters';
import { AppContext } from '@/store';
import { ActionType, AppState } from '@store/types';
import { _getProjectById, _getProjects, _getWhitelistedProjects, ProjectFilters } from '@api/projects';
import axios, { CancelTokenSource } from 'axios';
import { useNetwork, useWalletContext } from '@firestarter-private/firestarter-library'
import { getCurrentNetworkId } from '@contracts/networks'

export const useProjectsState = () => {
  const { state, dispatch } = useContext(AppContext)
  const { account } = useWalletContext()
  const { currentNetworkId } = useNetwork()
  const cancelSource = useRef<CancelTokenSource | null>(null)

  const {
    loading,
    projects,
    currentProject,
    whitelistedProjects
  } = useSelector<AppState>(state => state)
  const liveProjects = getLiveProjects(state)
  const upcomingProjects = getUpcomingProjects(state)
  const featuredProjects = getFeaturedProjects(state)
  const completedProjects = getCompletedProjects(state)

  const getProjects = useCallback(async ({ chainId }: ProjectFilters = {}) => {
    const cancelToken = axios.CancelToken
    cancelSource.current = cancelToken.source()

    dispatch({ type: ActionType.SET_LOADING })

    try {
      const payload = await _getProjects(
        cancelSource.current?.token,
        { chainId }
      )
      dispatch({
        type: ActionType.SET_PROJECTS,
        payload
      })
    } catch (err) {
      cancelSource.current = null
    }
  }, [dispatch])

  const getWhitelistedProjects = useCallback(async ({ chainId }: ProjectFilters = {}) => {
    const cancelToken = axios.CancelToken
    cancelSource.current = cancelToken.source()

    if (!account) {
      dispatch({
        type: ActionType.SET_WHITElLISTED_PROJECTS,
        payload: []
      })
      return
    }

    dispatch({ type: ActionType.SET_LOADING })

    try {
      const payload = await _getWhitelistedProjects(currentNetworkId || getCurrentNetworkId())(
        account,
        cancelSource.current?.token,
        { chainId }
      )
      dispatch({
        type: ActionType.SET_WHITElLISTED_PROJECTS,
        payload: payload
      })
    } catch (err) {
      cancelSource.current = null
    }
  }, [account])

  const getProjectById = useCallback(async (id: string) => {
    dispatch({ type: ActionType.SET_LOADING })
    const payload = await _getProjectById(id)
    dispatch({
      type: ActionType.SET_PROJECT,
      payload,
    })
  }, [])

  const abortRequest = useCallback(() => {
    dispatch({ type: ActionType.SET_LOADING, payload: false })
    cancelSource.current && cancelSource.current?.cancel()
  }, [cancelSource, dispatch])

  return {
    loading,
    projects,
    currentProject,
    whitelistedProjects,
    liveProjects,
    upcomingProjects,
    completedProjects,
    featuredProjects,
    getProjects,
    getWhitelistedProjects,
    getProjectById,
    abortRequest,
  }
}
