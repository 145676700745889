import { useNetwork } from '@firestarter-private/firestarter-library'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { IUseMultiperiodLocking } from './types'
import { getCurrentNetworkId } from '@contracts/networks'
import { useWhen } from '@contracts/hooks/useWhen'
import { useMultiperiodLockingSolana } from '@contracts/hooks/useMultiperiodLocking/useMultiperiodLockingSolana'
import { useMultiperiodLockingEVM } from '@contracts/hooks/useMultiperiodLocking/useMultiperiodLockingEVM'


export const useMultiperiodLocking = (): IUseMultiperiodLocking => {
  const { currentNetworkId } = useNetwork()
  return useWhen(
    isSolanaNetwork(currentNetworkId || getCurrentNetworkId()),
    useMultiperiodLockingSolana,
    useMultiperiodLockingEVM
  )()
}
