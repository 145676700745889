/**
 * This request is used on the Solana side to finalize the linking
 * of the Solana wallet. To start the linking, we need to call
 * the linkSolanaWallet function on the Ethereum side.
 */

import { instanceWithSignature as API } from '../config';
import {
  FinalizeLinkSolanaWalletArgs,
  FinalizeLinkSolanaWalletResponse
} from './types';
import { sendExceptionReport } from '@utils/errors';

export const finalizeLinkSolanaWallet = async (
  data: FinalizeLinkSolanaWalletArgs
): Promise<FinalizeLinkSolanaWalletResponse> => {
  try {
    return await API.post('/confirm-solana-wallet', data);
  } catch (err) {
    sendExceptionReport(err);
    return {
      result: 'error',
      error: err.response?.data ? err.response.data : err.message
    };
  }
};
