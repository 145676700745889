import { Col, Container, Row } from "react-bootstrap";
import { QuantstampCertsUrl } from "@constants";
import LinkArrow from "@assets/link-arrow.svg";
import QuantstampLogo from "@assets/quantstamp/logo.svg";
import './QuantstampSection.scss'
import React from "react";

export const QuantstampSection = () => {
  return <section className="quantstamp-section">
    <Container>
      <Row className="mt-auto">
        <Col>
          <a
            href={QuantstampCertsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="tile--with-shadow text-center quantstamp-badge"
          >
            <img src={LinkArrow} className="quantstamp-badge__arrow" />
            <h4 className="quantstamp-badge__title">Security By</h4>
            <img src={QuantstampLogo} alt="Quantstamp" className="quantstamp-badge__logo" />
          </a>
        </Col>
      </Row>
    </Container>
  </section>;
}
