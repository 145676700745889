import { NetworkId } from '@firestarter-private/firestarter-library/lib/constants'
import { isSolanaNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { WalletParamName } from '@api/types'

type RequestWithWalletCallback<T, A extends any[] = any[]> = (walletParamName: WalletParamName, ...args: A) => Promise<T>
type RequestWithWallet<T, A extends any[] = any[]> = (...args: A) => Promise<T>

export const withWalletRequestFactory = <P, A extends any[] = []>(
  callback: (walletParamName: WalletParamName, ...args: A) => P,
) => (networkId: NetworkId): (...args: A) => P => {
  const walletParamName = isSolanaNetwork(networkId)
    ? WalletParamName.SOLANA
    : WalletParamName.ETHEREUM

  return callback.bind(null, walletParamName)
}
