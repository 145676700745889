import React, { CSSProperties, useMemo } from 'react';
import { ProjectStatus, ProjectType, ProjectTypes } from '../types';
import { Image } from 'react-bootstrap';
import './ProjectTag.scss'
import { CommonTooltip } from '@/components'
import { SFTTooltip } from './tooltips'


enum ProjectTagTypes {
  status = 'status',
  network = 'network',
  token = 'token',
  project = 'project'
}

type ProjectTagType = keyof typeof ProjectTagTypes

type NetworkAbbreviation = 'ETH' | 'BSC' | 'POLYGON' | 'SOLANA' | 'RINKEBY'

interface Props {
  type: ProjectTagType
  value: ProjectStatus | NetworkAbbreviation | string
  projectType?: ProjectType
  style?: CSSProperties
}

export const ProjectTag = ({ type, value, projectType, style }: Props) => {
  const iconImage = () =>
    type === 'network'
      ? <Image src={`/networks/${value.toLowerCase()}.png`} className="project-tag__image" />
      : null

  const statusClass = () =>
    type === 'status' ? value.split(' ').join('-').toLowerCase() : ''

  const formattedValue = useMemo(() => {
    if (value === projectType) {
      switch (projectType) {
        case ProjectTypes.NFT_claim: return 'NFT DROP';
        case ProjectTypes.NFT_presale: return 'NFT PRE-SALE';
        case ProjectTypes.nodes_sale: return 'NODES SALE';
        case ProjectTypes.token_airdrop: return 'AIRDROP'
        default: return value;
      }
    }

    return value
  }, [projectType, value])

  return (
    <span className={`project-tag ${statusClass()} project-tag--${type}`} style={style}>
      {iconImage()}
      <span>
        {formattedValue}
      </span>
      {value === 'SFT' &&
        <CommonTooltip
          id={`${value.split(' ').join('-').toLowerCase()}`}
          tooltipClass="project-tooltip"
          placement="top-start"
        >
          <SFTTooltip />
        </CommonTooltip>}
    </span>
  )
}
