import { useCallback, useContext, useMemo } from 'react'
import { LotteryContext, LotteryContextActionType, LotteryPhaseData } from '@pages/BlindBoxes/context'
import { usePlatformSettings } from '@hooks/usePlatformSettings'
import { useBlindBoxLottery } from '@contracts/hooks/useBlindBoxLottery'
import { isFuture } from 'date-fns'
import Web3 from 'web3'

export const useLotteryContext = (web3?: Web3) => {
  const {
    state: {
      phases,
    },
    dispatch
  } = useContext(LotteryContext)
  const { settings } = usePlatformSettings()

  const {
    getPhaseData: getPhaseData1,
  } = useBlindBoxLottery(settings?.contracts?.blindBoxLotteryPhase1, web3)
  const {
    getPhaseData: getPhaseData2,
  } = useBlindBoxLottery(settings?.contracts?.blindBoxLotteryPhase2, web3)

  const currentPhaseIndex = useMemo(() => {
    if (!phases.length) return 0
    const start1 = phases[0].startDate
    const end1 = phases[0].endDate
    const start2 = phases[1].startDate
    const end2 = phases[1].endDate

    if (!start1 || !end1 || isFuture(start1) || isFuture(end1)) return 0
    if (!start2 || !end2 || isFuture(start2) || isFuture(end2)) return 1
    return 0
  }, [phases])

  const currentPhase = useMemo<LotteryPhaseData | undefined>(() => {
    return phases[currentPhaseIndex]
  }, [phases, currentPhaseIndex])

  const getData = useCallback(async () => {
    if (
      !settings?.contracts?.blindBoxLotteryPhase1 ||
      !settings.contracts?.blindBoxLotteryPhase2
    ) return
    const [phase1Data, phase2Data] = await Promise.all([
      getPhaseData1(),
      getPhaseData2(),
    ])

    if (!phase1Data || !phase2Data) return

    dispatch({
      type: LotteryContextActionType.SET_PHASES,
      payload: [
        {
          ...phase1Data,
          maxPrize: phase1Data.maxFlameAmount.multipliedBy(5),
          contractAddress: settings.contracts.blindBoxLotteryPhase1
        },
        {
          ...phase2Data,
          maxPrize: phase2Data.maxFlameAmount.multipliedBy(5),
          contractAddress: settings.contracts.blindBoxLotteryPhase2
        }
      ]
    })
  }, [settings?.contracts, getPhaseData1, getPhaseData2])

  return {
    phases,
    currentPhaseIndex,
    currentPhase,
    getData,
  }
}